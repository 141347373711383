.temp-clr{
    color: #fff !important;
}
.metric-txt-clr{
    color: #fff !important;
}
.compare-txt-clr{
    color: #fff !important;
}
.custom{
    background: #fff;
    color:#000;
}
.slick-slide .useful-card-title{
    padding-right: unset !important;
    padding-left: unset !important;
}
/* .title:hover .overlay {
    clip-path: polygon(0 0,100% 0,100% 100%,0% 100%); 
  } */
  /* .is-row.overlay.is-no-gutter {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(8px);
    clip-path: polygon(0 calc(100% - 100px),100% calc(100% - 100px),100% 100%,0% 100%);
    transition: all 0.5s;
} */
/* .hidden-text {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    text-align: center;
    width: 60%;
    margin: 0 auto;
    color: white;
  } */
  .content_img{
    position: relative;
    
}
.content_img .content_description{
    position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(24, 31, 36, 0.72);
  color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s, visibility .2s;

}
.content_img:hover{
    cursor: pointer;
}

.content_img:hover .content_description{ 
    
    visibility: visible;
    opacity:1; 
    background: var(--descriptionBg) !important;
    margin-right: -5px;
    margin-left: -5px;

}
.content_description .is-row.is-display-flex.is-border.is-rounded-pill.is-no-gutter{
    margin-left: 128px !important;
    position: absolute;
    bottom: 46px;
    background: var(--dashboardLinkBg) !important;
    width: 120px !important;
    font-size: 14px;
}
.is-row.is-margin-auto.is-display-flex.is-border.is-rounded-pill.is-no-gutter{
    width: 120px !important;
    font-size: 14px;
}
.content_description .content_title{
    font-size: 19px;
    position: relative;
    top: 10px;
    left: 5px;
}
.content_description .content_desc{
    text-align: justify;
    font-size: 14px;
    position: relative;
    top: 30px;
    left: 5px;
    padding-right: 3%;
}

.content_description .content_desc img.className{
    filter: brightness(0) contrast(1);
}