.temp-clr{
    color: #fff !important;
}
.metric-txt-clr{
    color: #fff !important;
}
.compare-txt-clr{
    color: #fff !important;
}

.ant-collapse.ant-collapse-borderless {
    width: 100%;
    color: rgba(0,0,0,0.7);
}

.is-fade {
    animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}