/*! antd v3.22.2 */
html {
  font-family: sans-serif;
  font-size: var(--is-font-size-pivot)!important;
  line-height: 1.15;
}
body {
  color: var(--is-text)!important;
  font-size: var(--is-font-size)!important;
  font-family: var(--is-font-family)!important;
  background-color: var(--is-theme-bg)!important;
  overflow-x: hidden !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit!important;
}
a {
  color: var(--is-primary);
}
a:hover {
  color: var(--is-primary-dark);
}
a:active {
  color: var(--is-primary-darker);
}
a[disabled] {
  color: var(--is-text-muted);
}
figure {
  margin: 0 0 var(--is-su-4);
}
caption {
  color: var(--is-text-muted);
}
mark {
  background-color: var(--is-warning-light);
}
::-moz-selection {
  color: var(--is-primary-text);
  background: var(--is-primary);
}
::selection {
  color: var(--is-primary-text);
  background: var(--is-primary);
}
html {
  --antd-wave-shadow-color: var(--is-primary);
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  -webkit-box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
          box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
}
@-webkit-keyframes waveEffect {
  100% {
    -webkit-box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
            box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes waveEffect {
  100% {
    -webkit-box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
            box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-alert {
  color: var(--is-text);
  font-size: 1rem;
  border-radius: var(--is-rounded);
}
.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.ant-alert-success .ant-alert-icon {
  color: var(--is-success);
}
.ant-alert-info {
  background-color: var(--is-primary-inverse-light);
  border: 1px solid var(--is-primary-inverse);
}
.ant-alert-info .ant-alert-icon {
  color: var(--is-primary);
}
.ant-alert-warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
}
.ant-alert-warning .ant-alert-icon {
  color: var(--is-warning);
}
.ant-alert-error {
  background-color: #fff1f0;
  border: 1px solid #ffa39e;
}
.ant-alert-error .ant-alert-icon {
  color: var(--is-danger);
}
.ant-alert-close-icon .anticon-close {
  color: var(--is-text-muted);
}
.ant-alert-close-icon .anticon-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-close-text {
  color: var(--is-text-muted);
}
.ant-alert-close-text:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-with-description {
  color: var(--is-text);
  border-radius: var(--is-rounded);
}
.ant-alert-with-description .ant-alert-message {
  color: var(--is-default-dark);
}
.ant-alert-message {
  color: var(--is-default-dark);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-anchor {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-anchor-wrapper {
  background-color: #fff;
}
.ant-anchor-ink::before {
  background-color: var(--is-border-thin);
}
.ant-anchor-ink-ball {
  background-color: var(--is-primary-text);
  border: 2px solid var(--is-primary);
}
.ant-anchor-link-title {
  color: var(--is-text);
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: var(--is-primary);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-select-auto-complete {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-select-auto-complete.ant-select .ant-input:focus,
.ant-select-auto-complete.ant-select .ant-input:hover {
  border-color: var(--is-primary-dark);
}
.ant-select-auto-complete.ant-select .ant-input[disabled] {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
}
.ant-select-auto-complete.ant-select .ant-input[disabled]:hover {
  border-color: var(--is-border);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-select {
  color: var(--is-text)!important;
  font-size: 1rem;
}
.ant-select-arrow {
  color: var(--is-component-text-muted)!important;
}
.ant-select-selection {
  background-color: transparent !important;
  border: 1px solid var(--is-border) !important;
  border-radius: var(--is-rounded) !important;
}
.ant-select {
  /* color: var(--is-text-inverse)!important;
  background-color: transparent !important; */
}
.ant-select-selection:hover {
  border-color: var(--is-primary-light)!important;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: var(--is-primary-light)!important;
  -webkit-box-shadow: 0 0 0 2px var(--is-primary-inverse)!important;
          box-shadow: 0 0 0 2px var(--is-primary-inverse)!important;
}
.ant-select-selection__clear {
  color: var(--is-component-text-muted);
  background: var(--is-component-bg);
}
.ant-select-selection__clear:hover {
  color: var(--is-text-muted);
}
.ant-select-disabled {
  color: var(--is-component-text-muted);
}
.ant-select-disabled .ant-select-selection {
  background: var(--is-theme-bg-muted);
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  color: rgba(0, 0, 0, 0.33);
  background: var(--is-theme-bg-muted);
}
.ant-select-disabled .ant-select-selection__choice__remove {
  color: var(--is-component-text-muted);
}
.ant-select-disabled .ant-select-selection__choice__remove:hover {
  color: var(--is-component-text-muted);
}
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  color: var(--is-theme-bg-muted);
}
.ant-select-search--inline .ant-select-search__field {
  border-radius: var(--is-rounded);
}
.ant-select-selection--multiple .ant-select-selection__choice {
  color: var(--is-text)!important;
  font-size: .86em;
  padding-left: .5em;
  background-color: var(--is-theme-bg-muted)!important;
  border: 1px solid var(--is-border-thin)!important;
}
.ant-select-selection--multiple .ant-select-selection__choice__disabled {
  padding: 0 .5em;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: var(--is-text-muted)!important;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.75)!important;
}
.ant-select-open .ant-select-selection {
  border-color: var(--is-primary)!important;
  -webkit-box-shadow: 0 0 0 2px rgba(0,0,0,0.1)!important;
          box-shadow: 0 0 0 2px rgba(0,0,0,0.1)!important;
}
.ant-select-dropdown {
  color: var(--is-text)!important;
  background-color: var(--is-component-bg)!important;
  border-radius: var(--is-rounded)!important;
}
.ant-select-dropdown-menu-item-group-title {
  color: var(--is-text-muted)!important;
}
.ant-select-dropdown-menu-item {
  color: var(--is-text)!important;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: var(--is-component-bg-muted);
}
.ant-select-dropdown-menu-item:first-child {
  border-radius: var(--is-rounded) var(--is-rounded) 0 0;
}
.ant-select-dropdown-menu-item:last-child {
  border-radius: 0 0 var(--is-rounded) var(--is-rounded);
}
.ant-select-dropdown-menu-item-selected {
  color: var(--is-primary-light-text)!important;
  background-color: var(--is-primary-light)!important;
}
.ant-select-dropdown-menu-item-disabled {
  color: var(--is-component-text-muted);
}
.ant-select-dropdown-menu-item-disabled:hover {
  color: var(--is-component-text-muted);
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: var(--is-primary-inverse-light);
}
.ant-select-dropdown-menu-item-divider {
  background-color: var(--is-border-thin);
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover .ant-select-selected-icon {
  color: rgba(0, 0, 0, 0.87);
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
  color: var(--is-primary);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-empty-normal {
  color: var(--is-component-text-muted);
}
.ant-empty-small {
  color: var(--is-component-text-muted);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-input {
  /* color: var(--is-text-inverse) !important; */
  font-size: 1rem;
  /* background-color: transparent !important; */
  /* border: 1px solid var(--is-border)  !important; */
  border-radius: var(--is-rounded);
}
.ant-input::-moz-placeholder {
  color: var(--is-default-inverse);
  opacity: 1;
}
.ant-input:-ms-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-input::-webkit-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-input:hover {
  border-color: var(--is-primary-dark);
}
.ant-input:focus {
  border-color: var(--is-primary-dark);
  -webkit-box-shadow: 0 0 0 2px var(--is-primary-darker);
          box-shadow: 0 0 0 2px var(--is-primary-darker);
}
.ant-input-disabled {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
}
.ant-input-disabled:hover {
  border-color: var(--is-border);
}
.ant-input[disabled] {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
}
.ant-input[disabled]:hover {
  border-color: var(--is-border);
}
.ant-input-group {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-input-group-addon {
  color: var(--is-text);
  font-size: 1rem;
  background-color: var(--is-theme-bg-muted);
  border: 1px solid var(--is-border);
  border-radius: var(--is-rounded);
}
.ant-input-group-addon .ant-select-open .ant-select-selection,
.ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: var(--is-primary);
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
  border-top-left-radius: var(--is-rounded);
  border-bottom-left-radius: var(--is-rounded);
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
  border-top-right-radius: var(--is-rounded);
  border-bottom-right-radius: var(--is-rounded);
}
.ant-input-affix-wrapper {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: var(--is-primary-dark);
}
.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
  color: var(--is-text);
}
.ant-input-password-icon {
  color: var(--is-text-muted);
}
.ant-input-password-icon:hover {
  color: #333;
}
.ant-input-clear-icon {
  color: var(--is-component-text-muted);
}
.ant-input-clear-icon:hover {
  color: var(--is-text-muted);
}
.ant-input-clear-icon:active {
  color: var(--is-text);
}
.ant-input-search-icon {
  color: var(--is-text-muted);
}
.ant-input-search-icon:hover {
  color: rgba(0, 0, 0, 0.8);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-btn {
  font-size: var(--is-font-size-xs);
  text-transform: uppercase;
  border-radius: var(--is-rounded);
  color: var(--is-text);
  background-color: var(--is-theme-bg);
  border-color: var(--is-border);
}
.ant-btn-lg {
  border-radius: var(--is-rounded);
}
.ant-btn-sm {
  border-radius: var(--is-rounded);
}
/* .ant-btn:hover,
.ant-btn:focus {
  color: var(--is-primary-text);
  background-color: transparent;
  border-color: var(--is-primary-text);
} */
.ant-btn:active,
.ant-btn.active {
  color: var(--is-primary);
  background-color: var(--is-theme-bg);
  border-color: var(--is-primary);
}
.ant-btn-disabled,
.ant-btn.disabled,
.ant-btn[disabled],
.ant-btn-disabled:hover,
.ant-btn.disabled:hover,
.ant-btn[disabled]:hover,
.ant-btn-disabled:focus,
.ant-btn.disabled:focus,
.ant-btn[disabled]:focus,
.ant-btn-disabled:active,
.ant-btn.disabled:active,
.ant-btn[disabled]:active,
.ant-btn-disabled.active,
.ant-btn.disabled.active,
.ant-btn[disabled].active {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
  border-color: var(--is-border);
}
/* .ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  background: var(--is-theme-bg);
} */
.ant-btn-primary {
  color: var(--is-primary-text);
  background-color: var(--is-primary);
  border-color: var(--is-primary-text);
}
/* .ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: var(--is-primary-dark-text);
  background-color: var(--is-primary-dark);
  border-color: var(--is-primary-dark);
} */
.ant-btn-primary:active,
.ant-btn-primary.active {
  color: var(--is-primary-darker-text);
  background-color: var(--is-primary-darker);
  border-color: var(--is-primary-darker);
}
.ant-btn-primary-disabled,
.ant-btn-primary.disabled,
.ant-btn-primary[disabled],
.ant-btn-primary-disabled:hover,
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled]:hover,
.ant-btn-primary-disabled:focus,
.ant-btn-primary.disabled:focus,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary-disabled:active,
.ant-btn-primary.disabled:active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary-disabled.active,
.ant-btn-primary.disabled.active,
.ant-btn-primary[disabled].active {
  color: var(--is-primary-inverse-dark);
  background-color: var(--is-primary-light);
  border-color: var(--is-primary-light);
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: var(--is-primary);
  border-left-color: var(--is-primary);
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: var(--is-border);
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: var(--is-primary);
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: var(--is-border);
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: var(--is-primary);
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: var(--is-border);
}
.ant-btn-ghost {
  color: var(--is-text);
  border-color: var(--is-border);
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: var(--is-primary-dark);
  border-color: var(--is-primary-dark);
}
.ant-btn-ghost:active,
.ant-btn-ghost.active {
  color: var(--is-primary);
  border-color: var(--is-primary);
}
.ant-btn-ghost-disabled,
.ant-btn-ghost.disabled,
.ant-btn-ghost[disabled],
.ant-btn-ghost-disabled:hover,
.ant-btn-ghost.disabled:hover,
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost-disabled:focus,
.ant-btn-ghost.disabled:focus,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost-disabled:active,
.ant-btn-ghost.disabled:active,
.ant-btn-ghost[disabled]:active,
.ant-btn-ghost-disabled.active,
.ant-btn-ghost.disabled.active,
.ant-btn-ghost[disabled].active {
  color: var(--is-text-muted);
  background-color: var(--is-theme-bg-muted);
  border-color: var(--is-border);
}
.ant-btn-dashed {
  color: var(--is-text);
  background-color: var(--is-theme-bg);
  border-color: var(--is-border);
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: var(--is-primary-light);
  background-color: var(--is-theme-bg);
  border-color: var(--is-primary-light);
}
.ant-btn-dashed:active,
.ant-btn-dashed.active {
  color: var(--is-primary);
  background-color: var(--is-theme-bg);
  border-color: var(--is-primary);
}
.ant-btn-dashed-disabled,
.ant-btn-dashed.disabled,
.ant-btn-dashed[disabled],
.ant-btn-dashed-disabled:hover,
.ant-btn-dashed.disabled:hover,
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed-disabled:focus,
.ant-btn-dashed.disabled:focus,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed-disabled:active,
.ant-btn-dashed.disabled:active,
.ant-btn-dashed[disabled]:active,
.ant-btn-dashed-disabled.active,
.ant-btn-dashed.disabled.active,
.ant-btn-dashed[disabled].active {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
  border-color: var(--is-border);
}
.ant-btn-danger {
  color: var(--is-danger-text);
  background-color: var(--is-danger);
  border-color: var(--is-danger);
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: var(--is-danger-dark-text);
  background-color: var(--is-danger-dark);
  border-color: var(--is-danger-dark);
}
.ant-btn-danger:active,
.ant-btn-danger.active {
  color: var(--is-danger-darker-text);
  background-color: var(--is-danger-darker);
  border-color: var(--is-danger-darker);
}
.ant-btn-danger-disabled,
.ant-btn-danger.disabled,
.ant-btn-danger[disabled],
.ant-btn-danger-disabled:hover,
.ant-btn-danger.disabled:hover,
.ant-btn-danger[disabled]:hover,
.ant-btn-danger-disabled:focus,
.ant-btn-danger.disabled:focus,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger-disabled:active,
.ant-btn-danger.disabled:active,
.ant-btn-danger[disabled]:active,
.ant-btn-danger-disabled.active,
.ant-btn-danger.disabled.active,
.ant-btn-danger[disabled].active {
  color: var(--is-danger-light-text);
  background-color: var(--is-danger-light);
  border-color: var(--is-danger-light);
}
.ant-btn-link {
  color: var(--is-primary);
  background-color: transparent;
  border-color: transparent;
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: var(--is-primary-dark);
  border-color: transparent;
}
.ant-btn-link:active,
.ant-btn-link.active {
  color: var(--is-primary-darker);
  border-color: transparent;
}
.ant-btn-link-disabled,
.ant-btn-link.disabled,
.ant-btn-link[disabled],
.ant-btn-link-disabled:hover,
.ant-btn-link.disabled:hover,
.ant-btn-link[disabled]:hover,
.ant-btn-link-disabled:focus,
.ant-btn-link.disabled:focus,
.ant-btn-link[disabled]:focus,
.ant-btn-link-disabled:active,
.ant-btn-link.disabled:active,
.ant-btn-link[disabled]:active,
.ant-btn-link-disabled.active,
.ant-btn-link.disabled.active,
.ant-btn-link[disabled].active {
  color: var(--is-component-text-muted);
  background-color: transparent;
  border-color: transparent;
}
.ant-btn-icon-only {
  border-radius: var(--is-rounded);
}
.ant-btn-icon-only.ant-btn-lg {
  border-radius: var(--is-rounded);
}
.ant-btn::before {
  background: #fff;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: var(--is-rounded);
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: var(--is-rounded);
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: var(--is-rounded);
  border-bottom-left-radius: var(--is-rounded);
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: var(--is-rounded);
  border-bottom-right-radius: var(--is-rounded);
}
.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: var(--is-rounded);
}
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: var(--is-rounded);
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: var(--is-rounded);
  border-bottom-left-radius: var(--is-rounded);
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: var(--is-rounded);
  border-bottom-right-radius: var(--is-rounded);
}
.ant-btn-background-ghost {
  color: var(--is-default-inverse-light);
  border-color: var(--is-default-inverse-light);
}
.ant-btn-background-ghost.ant-btn-primary {
  color: var(--is-primary);
  border-color: var(--is-primary);
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: var(--is-primary-light);
  border-color: var(--is-primary-light);
}
.ant-btn-background-ghost.ant-btn-primary:active,
.ant-btn-background-ghost.ant-btn-primary.active {
  color: var(--is-primary-dark);
  border-color: var(--is-primary-dark);
}
.ant-btn-background-ghost.ant-btn-primary-disabled,
.ant-btn-background-ghost.ant-btn-primary.disabled,
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary-disabled:hover,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary-disabled:active,
.ant-btn-background-ghost.ant-btn-primary.disabled:active,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active,
.ant-btn-background-ghost.ant-btn-primary-disabled.active,
.ant-btn-background-ghost.ant-btn-primary.disabled.active,
.ant-btn-background-ghost.ant-btn-primary[disabled].active {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
  border-color: var(--is-border);
}
.ant-btn-background-ghost.ant-btn-danger {
  color: var(--is-danger);
  border-color: var(--is-danger);
}
.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: var(--is-danger-light);
  border-color: var(--is-danger-light);
}
.ant-btn-background-ghost.ant-btn-danger:active,
.ant-btn-background-ghost.ant-btn-danger.active {
  color: var(--is-danger-dark);
  border-color: var(--is-danger-dark);
}
.ant-btn-background-ghost.ant-btn-danger-disabled,
.ant-btn-background-ghost.ant-btn-danger.disabled,
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger-disabled:hover,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger-disabled:active,
.ant-btn-background-ghost.ant-btn-danger.disabled:active,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active,
.ant-btn-background-ghost.ant-btn-danger-disabled.active,
.ant-btn-background-ghost.ant-btn-danger.disabled.active,
.ant-btn-background-ghost.ant-btn-danger[disabled].active {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
  border-color: var(--is-border);
}
.ant-btn-background-ghost.ant-btn-link {
  color: var(--is-default-inverse-light);
}
.ant-btn-background-ghost.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-link:focus {
  color: var(--is-primary);
}
.ant-btn-background-ghost.ant-btn-link:active,
.ant-btn-background-ghost.ant-btn-link.active {
  color: var(--is-primary-dark);
}
.ant-btn-background-ghost.ant-btn-link-disabled,
.ant-btn-background-ghost.ant-btn-link.disabled,
.ant-btn-background-ghost.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-link-disabled:hover,
.ant-btn-background-ghost.ant-btn-link.disabled:hover,
.ant-btn-background-ghost.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-link-disabled:focus,
.ant-btn-background-ghost.ant-btn-link.disabled:focus,
.ant-btn-background-ghost.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-link-disabled:active,
.ant-btn-background-ghost.ant-btn-link.disabled:active,
.ant-btn-background-ghost.ant-btn-link[disabled]:active,
.ant-btn-background-ghost.ant-btn-link-disabled.active,
.ant-btn-background-ghost.ant-btn-link.disabled.active,
.ant-btn-background-ghost.ant-btn-link[disabled].active {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
  border-color: var(--is-border);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-avatar {
  color: var(--is-text);
  font-size: 1rem;
  color: #fff;
  background: #ccc;
}
.ant-avatar-square {
  border-radius: var(--is-rounded);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-back-top {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-back-top-content {
  color: #fff;
  background-color: var(--is-text-muted);
}
.ant-back-top-content:hover {
  background-color: var(--is-text);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-badge {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-badge-multiple-words {
  padding: 0 .5rem;
}
.ant-badge-count {
  font-size: .8rem;
  min-width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
  padding: 0 .4rem;
  color: var(--is-danger-text);
  background: var(--is-danger);
  -webkit-box-shadow: 0 0 0 1px var(--is-theme-bg);
          box-shadow: 0 0 0 1px var(--is-theme-bg);
}
.ant-badge-count a,
.ant-badge-count a:hover {
  color: var(--is-danger-text);
}
.ant-badge-dot {
  background: var(--is-danger);
  -webkit-box-shadow: 0 0 0 1px var(--is-theme-bg);
          box-shadow: 0 0 0 1px var(--is-theme-bg);
}
.ant-badge-status-success {
  background-color: var(--is-success);
}
.ant-badge-status-processing {
  background-color: var(--is-primary);
}
.ant-badge-status-processing::after {
  border: 1px solid var(--is-primary);
}
.ant-badge-status-default {
  background-color: var(--is-border);
}
.ant-badge-status-error {
  background-color: var(--is-danger);
}
.ant-badge-status-warning {
  background-color: var(--is-warning);
}
.ant-badge-status-pink {
  background: var(--is-color-pink);
}
.ant-badge-status-magenta {
  background: var(--is-color-magenta);
}
.ant-badge-status-red {
  background: var(--is-danger);
}
.ant-badge-status-volcano {
  background: var(--is-color-volcano);
}
.ant-badge-status-orange {
  background: var(--is-color-orange);
}
.ant-badge-status-yellow {
  background: var(--is-color-yellow);
}
.ant-badge-status-gold {
  background: var(--is-color-gold);
}
.ant-badge-status-cyan {
  background: var(--is-color-cyan);
}
.ant-badge-status-lime {
  background: var(--is-color-lime);
}
.ant-badge-status-green {
  background: var(--is-color-green);
}
.ant-badge-status-blue {
  background: var(--is-color-blue);
}
.ant-badge-status-geekblue {
  background: var(--is-color-geekblue);
}
.ant-badge-status-purple {
  background: var(--is-color-purple);
}
.ant-badge-status-text {
  color: var(--is-text);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-breadcrumb {
  color: var(--is-text-muted);
  font-size: 1rem;
}
.ant-breadcrumb .anticon {
  font-size: 1rem;
}
.ant-breadcrumb a {
  color: var(--is-text-muted);
}
.ant-breadcrumb a:hover {
  color: var(--is-primary-dark);
}
.ant-breadcrumb > span:last-child {
  color: var(--is-text);
}
.ant-breadcrumb > span:last-child a {
  color: var(--is-text);
}
.ant-breadcrumb-separator {
  color: var(--is-text-muted);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-menu {
  font-size: 1rem;
  color: var(--is-text);
  background: var(--is-theme-bg);
}
.ant-menu-item-group-title {
  color: var(--is-text-muted);
}
.ant-menu-submenu-selected {
  color: var(--is-primary);
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: var(--is-primary-inverse-light);
}
.ant-menu-item > a {
  color: var(--is-text);
}
.ant-menu-item > a:hover {
  color: var(--is-primary);
}
.ant-menu-item-divider {
  background-color: var(--is-border);
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: var(--is-primary);
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: var(--is-primary);
}
.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: var(--is-primary);
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--is-primary-inverse-light);
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid var(--is-border-thin);
}
.ant-menu-vertical-right {
  border-left: 1px solid var(--is-border-thin);
}
.ant-menu > .ant-menu-item-divider {
  background-color: var(--is-border-thin);
}
.ant-menu-submenu-popup {
  background: var(--is-theme-bg);
  border-radius: var(--is-rounded);
}
.ant-menu-submenu > .ant-menu {
  background-color: var(--is-theme-bg);
  border-radius: var(--is-rounded);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background: var(--is-theme-bg);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.65)), to(rgba(0, 0, 0, 0.65)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: -webkit-gradient(linear, left top, right top, from(var(--is-primary)), to(var(--is-primary-dark)));
  background: linear-gradient(to right, var(--is-primary), var(--is-primary-dark));
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: var(--is-primary);
}
.ant-menu-vertical .ant-menu-submenu-selected > a,
.ant-menu-vertical-left .ant-menu-submenu-selected > a,
.ant-menu-vertical-right .ant-menu-submenu-selected > a {
  color: var(--is-primary);
}
.ant-menu-horizontal {
  border-bottom: 1px solid var(--is-border-thin);
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: var(--is-primary);
  border-bottom: 2px solid var(--is-primary);
}
.ant-menu-horizontal > .ant-menu-item > a {
  color: var(--is-text);
}
.ant-menu-horizontal > .ant-menu-item > a:hover {
  color: var(--is-primary);
}
.ant-menu-horizontal > .ant-menu-item-selected > a {
  color: var(--is-primary);
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid var(--is-primary);
}
.ant-menu-inline-collapsed-tooltip a {
  color: var(--is-default-inverse-light);
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: var(--is-default-inverse-dark) !important;
}
.ant-menu-item-disabled > a,
.ant-menu-submenu-disabled > a {
  color: var(--is-default-inverse-dark) !important;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: var(--is-default-inverse-dark) !important;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: var(--is-default-inverse-dark) !important;
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: var(--is-primary-inverse);
  background: var(--is-primary-darker);
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: var(--is-primary-inverse-light);
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: rgba(0,0,0,0.4);
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  border-color: var(--is-primary-darker);
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item a {
  color: var(--is-primary-inverse);
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: var(--is-primary-inverse-light);
  background-color: var(--is-primary);
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a {
  color: var(--is-primary-inverse-light);
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  background: var(--is-primary-inverse-light);
}
.ant-menu-dark .ant-menu-item-selected {
  color: var(--is-primary-inverse-light);
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > a:hover {
  color: var(--is-primary-inverse-light);
}
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: var(--is-primary-inverse-light);
}
.ant-menu-dark .ant-menu-item-selected span {
  color: var(--is-primary-inverse-light);
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: var(--is-primary);
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a {
  color: var(--is-primary-light) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: var(--is-primary-light) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: var(--is-primary-light) !important;
}

/*CUSTOM*/
/*=> Adiciona um espaço entre os botões de ação e o primeiro ícone do menu*/
#main-side-menu .ant-menu-root > li.ant-menu-item:first-child,
#main-side-menu .ant-menu-root > div:first-child + li {
  margin-top: 20px;
}
/*=> Evita mudança da posição do ícone quando o menu está aberto*/
#main-side-menu .ant-menu-root > li.ant-menu-item,
#main-side-menu .ant-menu-root > li.ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 21px!important;
}
/*=> Fixa o tamanho do ícone*/
#main-side-menu .ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
  font-size: var(--is-font-size-lg)!important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-tooltip {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-tooltip-inner {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: var(--is-rounded);
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-tooltip-arrow::before {
  background-color: rgba(0, 0, 0, 0.75);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-dropdown {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-dropdown-menu {
  background-color: var(--is-component-bg);
  border-radius: var(--is-rounded);
}
.ant-dropdown-menu-item-group-title {
  color: var(--is-text-muted);
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  color: var(--is-text);
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: var(--is-primary);
  background-color: var(--is-primary-inverse-light);
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: var(--is-primary-inverse-light);
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: var(--is-component-text-muted);
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg);
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  background-color: var(--is-border-thin);
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: var(--is-text-muted);
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg);
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: var(--is-primary-darker);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a {
  color: var(--is-primary-inverse);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after {
  color: var(--is-primary-inverse);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover {
  color: var(--is-primary-inverse-light);
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: var(--is-primary-inverse-light);
  background: var(--is-primary);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-fullcalendar {
  color: var(--is-text);
  font-size: 1rem;
  border-top: 1px solid var(--is-border);
}
.ant-fullcalendar-value {
  color: var(--is-text);
}
.ant-fullcalendar-value:hover {
  background: var(--is-primary-inverse-light);
}
.ant-fullcalendar-value:active {
  color: var(--is-primary-text);
  background: var(--is-primary);
}
.ant-fullcalendar-today .ant-fullcalendar-value,
.ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value {
  -webkit-box-shadow: 0 0 0 1px var(--is-primary) inset;
          box-shadow: 0 0 0 1px var(--is-primary) inset;
}
.ant-fullcalendar-selected-day .ant-fullcalendar-value,
.ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
  color: var(--is-primary-text);
  background: var(--is-primary);
}
.ant-fullcalendar-disabled-cell-first-of-row .ant-fullcalendar-value {
  border-top-left-radius: var(--is-rounded);
  border-bottom-left-radius: var(--is-rounded);
}
.ant-fullcalendar-disabled-cell-last-of-row .ant-fullcalendar-value {
  border-top-right-radius: var(--is-rounded);
  border-bottom-right-radius: var(--is-rounded);
}
.ant-fullcalendar-last-month-cell .ant-fullcalendar-value,
.ant-fullcalendar-next-month-btn-day .ant-fullcalendar-value {
  color: var(--is-component-text-muted);
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  color: var(--is-text);
  border-top: 2px solid var(--is-border-thin);
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month:hover,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover {
  background: var(--is-primary-inverse-light);
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month:active,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date:active {
  background: var(--is-primary-inverse-light);
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
  color: var(--is-text);
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date {
  border-top-color: var(--is-primary);
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background: var(--is-primary-inverse-light);
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value,
.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-value {
  color: var(--is-primary);
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-last-month-cell .ant-fullcalendar-date,
.ant-fullcalendar-fullscreen .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-date {
  color: var(--is-component-text-muted);
}
.ant-fullcalendar-disabled-cell .ant-fullcalendar-value {
  color: var(--is-component-text-muted);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-radio-group {
  display: inline-flex;
  color: var(--is-text);
}
.ant-radio-wrapper {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-radio {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--is-primary);
}
.ant-radio-input:focus + .ant-radio-inner {
  -webkit-box-shadow: 0 0 0 3px var(--is-primary-inverse);
          box-shadow: 0 0 0 3px var(--is-primary-inverse);
}
.ant-radio-checked::after {
  border: 1px solid var(--is-primary);
}
.ant-radio-inner {
  background-color: var(--is-component-bg);
  border-color: var(--is-border);
}
.ant-radio-inner::after {
  background-color: var(--is-primary);
}
.ant-radio-checked .ant-radio-inner {
  border-color: var(--is-primary);
}
.ant-radio-disabled .ant-radio-inner {
  background-color: var(--is-default-inverse-light);
  border-color: var(--is-border) !important;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: var(--is-text-muted);
}
.ant-radio-disabled + span {
  color: var(--is-component-text-muted);
}
.ant-radio-button-wrapper {
  color: var(--is-text);
  font-size: var(--is-font-size-xs);
  text-transform: uppercase;
  background: var(--is-component-bg);
  border: 1px solid var(--is-component-border-color);
}
.ant-radio-button-wrapper a {
  color: var(--is-text);
}
.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: var(--is-border);
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid var(--is-border);
  border-radius: var(--is-rounded) 0 0 var(--is-rounded);
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 var(--is-rounded) var(--is-rounded) 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: var(--is-rounded);
}
.ant-radio-button-wrapper:hover {
  color: var(--is-primary);
}
.ant-radio-button-wrapper:focus-within {
  outline: 3px solid var(--is-primary-light);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff !important;
  background: #026939  !important;
  border-color: #026939  !important;
  border-radius:30px ;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--is-primary);
  background: var(--is-component-bg);
  border-color: #026939;
  -webkit-box-shadow: -1px 0 0 0 #026939;
          box-shadow: -1px 0 0 0 #026939 !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: var(--is-primary) !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: var(--is-primary);
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: var(--is-primary-dark);
  border-color: var(--is-primary-dark);
  -webkit-box-shadow: -1px 0 0 0 var(--is-primary-dark);
          box-shadow: -1px 0 0 0 var(--is-primary-dark);
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: var(--is-primary);
  border-color: var(--is-primary);
  -webkit-box-shadow: -1px 0 0 0 var(--is-primary);
          box-shadow: -1px 0 0 0 var(--is-primary);
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: 3px solid var(--is-primary-light);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--is-component-bg);
  background: var(--is-primary);
  border-color: var(--is-primary);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: var(--is-component-bg);
  background: var(--is-primary-dark);
  border-color: var(--is-primary-dark);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: var(--is-component-bg);
  background: var(--is-primary);
  border-color: var(--is-primary);
}
/* .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: 3px solid var(--is-primary-inverse-light);
} */
.ant-radio-button-wrapper-disabled {
  color: var(--is-component-text-muted);
  background-color: var(--is-default-inverse-light);
  border-color: var(--is-border);
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: var(--is-component-text-muted);
  background-color: var(--is-default-inverse-light);
  border-color: var(--is-border);
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: var(--is-border);
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: var(--is-component-bg);
  background-color: var(--is-default-light);
  border-color: var(--is-border);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-card {
  color: var(--is-text);
  font-size: 1rem;
  background: var(--is-component-bg);
}
.ant-card-hoverable:hover {
  border-color: var(--is-border);
}
.ant-card-bordered {
  border: 1px solid var(--is-border);
}
.ant-card-head {
  color: var(--is-text-inverse) !important;
  border-bottom: 1px solid var(--is-border-thin);
  padding: var(--is-su-3);
  background: var(--is-theme-bg);
  min-height: var(--is-hu-3);
  border-radius: var(--is-su-2);
}
.ant-card-head .ant-tabs {
  color: var(--is-text);
}
.ant-card-head .ant-tabs-bar {
  border-bottom: 1px solid var(--is-border-thin);
}
.ant-card-head-title {
    padding: var(--is-su-1) 0;
  }
  .ant-card-body {
    padding: var(--is-su-1);
  }
.ant-card-extra {
  color: var(--is-text);
}
.ant-card-cover img {
  border-radius: var(--is-rounded-1) var(--is-rounded-1) 0 0;
}
.ant-card-actions {
  background: var(--is-default-inverse-light);
  border-top: 1px solid var(--is-border-thin);
}
.ant-card-actions > li {
  color: var(--is-text-muted);
}
.ant-card-actions > li > span:hover {
  color: var(--is-primary);
}
.ant-card-actions > li > span a,
.ant-card-actions > li > span > .anticon {
  color: var(--is-text-muted);
}
.ant-card-actions > li > span a:hover,
.ant-card-actions > li > span > .anticon:hover {
  color: var(--is-primary);
}
.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid var(--is-border-thin);
}
.ant-card-type-inner .ant-card-head {
  background: var(--is-default-inverse-light);
}
.ant-card-meta-title {
  color: var(--is-default-dark);
}
.ant-card-meta-description {
  color: var(--is-text-muted);
}
.ant-card-loading-block {
  background: -webkit-gradient(linear, left top, right top, from(rgba(207, 216, 220, 0.2)), color-stop(rgba(207, 216, 220, 0.4)), to(rgba(207, 216, 220, 0.2)));
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  background: var(--is-default-inverse-light);
  border: 1px solid var(--is-border-thin);
  border-radius: var(--is-rounded) var(--is-rounded) 0 0;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  color: var(--is-primary);
  background: var(--is-theme-bg);
  border-color: var(--is-border-thin);
  border-bottom: 1px solid var(--is-theme-bg);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-disabled {
  color: var(--is-component-text-muted);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x {
  color: var(--is-text-muted);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x:hover {
  color: var(--is-default-dark);
}
.ant-tabs-extra-content .ant-tabs-new-tab {
  color: var(--is-text);
  font-size: 12px;
  border: 1px solid var(--is-border-thin);
}
.ant-tabs-extra-content .ant-tabs-new-tab:hover {
  color: var(--is-primary);
  border-color: var(--is-primary);
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
  border-bottom: 1px solid var(--is-border-thin);
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab {
  border-radius: var(--is-rounded) 0 0 var(--is-rounded);
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
  border-radius: 0 var(--is-rounded) var(--is-rounded) 0;
}
.ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab {
  border-bottom: 1px solid var(--is-border-thin);
  border-radius: 0 0 var(--is-rounded) var(--is-rounded);
}
.ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab-active {
  color: var(--is-primary);
}
.ant-tabs {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-tabs-ink-bar {
  background-color: var(--is-primary);
}
.ant-tabs-bar {
  border-bottom: 1px solid var(--is-border-thin);
}
.ant-tabs-bottom .ant-tabs-bottom-bar {
  border-top: 1px solid var(--is-border-thin);
}
.ant-tabs-tab-prev,
.ant-tabs-tab-next {
  color: var(--is-text-muted);
}
.ant-tabs-tab-prev:hover,
.ant-tabs-tab-next:hover {
  color: var(--is-text);
}
.ant-tabs-tab-btn-disabled,
.ant-tabs-tab-btn-disabled:hover {
  color: var(--is-component-text-muted);
}
.ant-tabs-nav .ant-tabs-tab::before {
  border-radius: var(--is-rounded) var(--is-rounded) 0 0;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: var(--is-primary-dark);
}
.ant-tabs-nav .ant-tabs-tab:active {
  color: var(--is-primary);
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: var(--is-primary);
}
.ant-tabs-nav .ant-tabs-tab-disabled,
.ant-tabs-nav .ant-tabs-tab-disabled:hover {
  color: var(--is-component-text-muted);
}
.ant-tabs .ant-tabs-left-bar {
  border-right: 1px solid var(--is-border-thin);
}
.ant-tabs .ant-tabs-left-content {
  border-left: 1px solid var(--is-border-thin);
}
.ant-tabs .ant-tabs-right-bar {
  border-left: 1px solid var(--is-border-thin);
}
.ant-tabs .ant-tabs-right-content {
  border-right: 1px solid var(--is-border-thin);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-carousel {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-carousel .slick-dots li button {
  background: var(--is-light);
}
.ant-carousel .slick-dots li.slick-active button {
  background: var(--is-light);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-cascader {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-cascader-picker {
  color: var(--is-text);
  font-size: 1rem;
  background-color: var(--is-theme-bg);
  border-radius: var(--is-rounded);
}
.ant-cascader-picker-disabled {
  color: var(--is-component-text-muted);
  background: var(--is-default-inverse-light);
}
.ant-cascader-picker:focus .ant-cascader-input {
  border-color: var(--is-primary-dark);
}
.ant-cascader-picker-show-search.ant-cascader-picker-focused {
  color: var(--is-component-text-muted);
}
.ant-cascader-picker-clear {
  color: var(--is-component-text-muted);
  background: var(--is-theme-bg);
}
.ant-cascader-picker-clear:hover {
  color: var(--is-text-muted);
}
.ant-cascader-picker-arrow {
  color: var(--is-text-muted);
}
.ant-cascader-picker-label:hover + .ant-cascader-input {
  border-color: var(--is-primary-dark);
}
.ant-cascader-menus {
  background: var(--is-theme-bg);
  border-radius: var(--is-rounded);
}
.ant-cascader-menu {
  border-right: 1px solid var(--is-border-thin);
}
.ant-cascader-menu:first-child {
  border-radius: var(--is-rounded) 0 0 var(--is-rounded);
}
.ant-cascader-menu:last-child {
  border-radius: 0 var(--is-rounded) var(--is-rounded) 0;
}
.ant-cascader-menu:only-child {
  border-radius: var(--is-rounded);
}
.ant-cascader-menu-item:hover {
  background: var(--is-primary-inverse-light);
}
.ant-cascader-menu-item-disabled {
  color: var(--is-component-text-muted);
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background-color: var(--is-default-inverse-light);
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  color: var(--is-text-muted);
}
.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: var(--is-danger);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-checkbox {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--is-primary);
}
.ant-checkbox-checked::after {
  border: 1px solid var(--is-primary);
}
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
.ant-checkbox-inner {
  background-color: var(--is-component-bg);
  border: 1px solid var(--is-border);
}
.ant-checkbox-inner::after {
  border: 2px solid var(--is-component-bg);
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: var(--is-component-bg);
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #019087 !important;
  border-color: #019087 !important;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: var(--is-component-text-muted);
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: var(--is-default-inverse-light);
  border-color: var(--is-border) !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: var(--is-default-inverse-light);
}
.ant-checkbox-disabled + span {
  color: var(--is-component-text-muted);
}
.ant-checkbox-wrapper {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-checkbox-group {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: var(--is-component-bg);
  border-color: var(--is-border);
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: var(--is-primary);
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: var(--is-component-text-muted);
  border-color: var(--is-component-text-muted);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-collapse {
  color: rgba(0,0,0,0.7) !important;
  background-color: unset !important;
  border: 1px solid var(--is-border);
  border-radius: var(--is-rounded);
}
.ant-collapse > .ant-collapse-item {
  border-bottom: unset !important;
  background-color: #E8EBF3 !important;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 var(--is-rounded) var(--is-rounded);
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  /* color: var(--is-color-white) !important; */
}
.ant-collapse-content {
  /* color: var(--is-color-white) !important; */
  background-color: unset !important;;
  border-top: 1px solid var(--is-border);
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 var(--is-rounded) var(--is-rounded);
}
.ant-collapse-borderless {
  background-color: var(--is-theme-bg);
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid var(--is-border);
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: var(--is-component-text-muted);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-comment-content-author-name {
  color: var(--is-text-muted);
}
.ant-comment-content-author-name > * {
  color: var(--is-text-muted);
}
.ant-comment-content-author-name > *:hover {
  color: var(--is-text-muted);
}
.ant-comment-content-author-time {
  color: var(--is-component-text-muted);
}
.ant-comment-actions > li {
  color: var(--is-text-muted);
}
.ant-comment-actions > li > span {
  color: var(--is-text-muted);
}
.ant-comment-actions > li > span:hover {
  color: var(--is-text);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-calendar-picker-container {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-calendar-picker {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: var(--is-primary-dark);
}
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: var(--is-primary-dark);
}
.ant-calendar-picker-clear {
  color: var(--is-component-text-muted);
  font-size: 1rem;
  background: var(--is-component-bg);
}
.ant-calendar-picker-clear:hover {
  color: var(--is-text-muted);
}
.ant-calendar-picker-icon {
  color: var(--is-component-text-muted);
}
.ant-calendar {
  background-color: var(--is-theme-bg);
  border: 1px solid var(--is-theme-bg);
  border-radius: var(--is-rounded);
}
.ant-calendar-input-wrap {
  border-bottom: 1px solid var(--is-border-thin);
}
.ant-calendar-input {
  color: var(--is-text);
  background: var(--is-component-bg);
}
.ant-calendar-input::-moz-placeholder {
  color: var(--is-default-inverse);
}
.ant-calendar-input:-ms-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-calendar-input::-webkit-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-calendar-header {
  border-bottom: 1px solid var(--is-border-thin);
}
.ant-calendar-header a:hover {
  color: var(--is-primary-dark);
}
.ant-calendar-header .ant-calendar-century-select,
.ant-calendar-header .ant-calendar-decade-select,
.ant-calendar-header .ant-calendar-year-select,
.ant-calendar-header .ant-calendar-month-select {
  color: var(--is-default-dark);
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-prev-month-btn,
.ant-calendar-header .ant-calendar-next-month-btn,
.ant-calendar-header .ant-calendar-prev-year-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  color: var(--is-text-muted);
}
.ant-calendar-header .ant-calendar-prev-century-btn::before,
.ant-calendar-header .ant-calendar-prev-decade-btn::before,
.ant-calendar-header .ant-calendar-prev-year-btn::before,
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
  border: 0 solid var(--is-border-thin);
}
.ant-calendar-header .ant-calendar-prev-century-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-decade-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-year-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-century-btn:hover::after,
.ant-calendar-header .ant-calendar-prev-decade-btn:hover::after,
.ant-calendar-header .ant-calendar-prev-year-btn:hover::after {
  border-color: var(--is-text);
}
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before,
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  border: 0 solid var(--is-border-thin);
}
.ant-calendar-header .ant-calendar-next-century-btn:hover::before,
.ant-calendar-header .ant-calendar-next-decade-btn:hover::before,
.ant-calendar-header .ant-calendar-next-year-btn:hover::before,
.ant-calendar-header .ant-calendar-next-century-btn:hover::after,
.ant-calendar-header .ant-calendar-next-decade-btn:hover::after,
.ant-calendar-header .ant-calendar-next-year-btn:hover::after {
  border-color: var(--is-text);
}
.ant-calendar-header .ant-calendar-prev-month-btn::before,
.ant-calendar-header .ant-calendar-prev-month-btn::after {
  border: 0 solid var(--is-border-thin);
}
.ant-calendar-header .ant-calendar-prev-month-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-month-btn:hover::after {
  border-color: var(--is-text);
}
.ant-calendar-header .ant-calendar-next-month-btn::before,
.ant-calendar-header .ant-calendar-next-month-btn::after {
  border: 0 solid var(--is-border-thin);
}
.ant-calendar-header .ant-calendar-next-month-btn:hover::before,
.ant-calendar-header .ant-calendar-next-month-btn:hover::after {
  border-color: var(--is-text);
}
.ant-calendar-date {
  color: var(--is-text);
}
.ant-calendar-date:hover {
  background: var(--is-primary-inverse-light);
}
.ant-calendar-date:active {
  color: var(--is-primary-dark-text);
  background: var(--is-primary-dark);
}
.ant-calendar-today .ant-calendar-date {
  color: var(--is-primary);
  border-color: var(--is-primary);
}
.ant-calendar-selected-day .ant-calendar-date {
  background: var(--is-primary-inverse-light);
}
.ant-calendar-selected-date .ant-calendar-date {
  color: var(--is-primary-text);
  background: var(--is-primary);
}
.ant-calendar-selected-date .ant-calendar-date:hover {
  background: var(--is-primary);
}
.ant-calendar-last-month-cell .ant-calendar-date,
.ant-calendar-next-month-btn-day .ant-calendar-date,
.ant-calendar-last-month-cell .ant-calendar-date:hover,
.ant-calendar-next-month-btn-day .ant-calendar-date:hover {
  color: var(--is-component-text-muted);
}
.ant-calendar-disabled-cell .ant-calendar-date {
  color: var(--is-component-text-muted);
  background: var(--is-component-bg-muted);
}
.ant-calendar-disabled-cell .ant-calendar-date:hover {
  background: var(--is-default-inverse-light);
}
.ant-calendar-disabled-cell.ant-calendar-selected-day .ant-calendar-date::before {
  background: rgba(0, 0, 0, 0.1);
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
  border: 1px solid var(--is-component-text-muted);
}
.ant-calendar-footer {
  border-top: 1px solid var(--is-border-thin);
}
.ant-calendar .ant-calendar-today-btn-disabled,
.ant-calendar .ant-calendar-clear-btn-disabled {
  color: var(--is-component-text-muted);
}
.ant-calendar .ant-calendar-clear-btn::after {
  color: var(--is-component-text-muted);
  font-size: 1rem;
}
.ant-calendar .ant-calendar-clear-btn:hover::after {
  color: var(--is-text-muted);
}
.ant-calendar .ant-calendar-ok-btn {
  color: var(--is-primary-text);
  background-color: var(--is-primary);
  border-color: var(--is-primary);
  border-radius: var(--is-rounded);
}
.ant-calendar .ant-calendar-ok-btn-lg {
  border-radius: var(--is-rounded);
}
.ant-calendar .ant-calendar-ok-btn-sm {
  border-radius: var(--is-rounded);
}
.ant-calendar .ant-calendar-ok-btn:hover,
.ant-calendar .ant-calendar-ok-btn:focus {
  color: var(--is-primary-dark-text);
  background-color: var(--is-primary-dark);
  border-color: var(--is-primary-dark);
}
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn.active {
  color: var(--is-primary-text);
  background-color: var(--is-primary);
  border-color: var(--is-primary);
}
.ant-calendar .ant-calendar-ok-btn-disabled,
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn-disabled:hover,
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover,
.ant-calendar .ant-calendar-ok-btn-disabled:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn-disabled:active,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn-disabled.active,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: var(--is-component-text-muted);
  background-color: var(--is-default-inverse-light);
  border-color: var(--is-border);
}
.ant-calendar .ant-calendar-ok-btn-disabled,
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn-disabled:hover,
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover,
.ant-calendar .ant-calendar-ok-btn-disabled:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn-disabled:active,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn-disabled.active,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: var(--is-component-text-muted);
  background-color: var(--is-default-inverse-light);
  border-color: var(--is-border);
}
.ant-calendar-range-picker-input::-moz-placeholder {
  color: var(--is-default-inverse);
}
.ant-calendar-range-picker-input:-ms-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-calendar-range-picker-input::-webkit-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-calendar-range-picker-separator {
  color: var(--is-text-muted);
}
.ant-calendar-range-left .ant-calendar-time-picker-inner {
  border-right: 1px solid var(--is-border-thin);
}
.ant-calendar-range-right .ant-calendar-time-picker-inner {
  border-left: 1px solid var(--is-border-thin);
}
.ant-calendar-range-middle {
  color: var(--is-text-muted);
}
.ant-calendar-range .ant-calendar-today :not(.ant-calendar-disabled-cell) :not(.ant-calendar-last-month-cell) :not(.ant-calendar-next-month-btn-day) .ant-calendar-date {
  color: var(--is-primary);
  background: var(--is-primary-inverse-light);
  border-color: var(--is-primary);
}
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  color: var(--is-primary-text);
  background: var(--is-primary);
}
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover {
  background: var(--is-primary);
}
.ant-calendar-range .ant-calendar-input,
.ant-calendar-range .ant-calendar-time-picker-input {
  background-color: var(--is-component-bg);
  border: 1px solid var(--is-border);
  border-radius: var(--is-rounded);
}
.ant-calendar-range .ant-calendar-input::-moz-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-moz-placeholder {
  color: var(--is-default-inverse);
}
.ant-calendar-range .ant-calendar-input:-ms-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input:-ms-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-calendar-range .ant-calendar-input::-webkit-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-webkit-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-calendar-range .ant-calendar-input:hover,
.ant-calendar-range .ant-calendar-time-picker-input:hover {
  border-color: var(--is-primary-dark);
}
.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
  border-color: var(--is-primary-dark);
}
.ant-calendar-range .ant-calendar-input-disabled,
.ant-calendar-range .ant-calendar-time-picker-input-disabled {
  color: var(--is-component-text-muted);
  background-color: var(--is-default-inverse-light);
}
.ant-calendar-range .ant-calendar-input-disabled:hover,
.ant-calendar-range .ant-calendar-time-picker-input-disabled:hover {
  border-color: var(--is-border);
}
.ant-calendar-range .ant-calendar-input[disabled],
.ant-calendar-range .ant-calendar-time-picker-input[disabled] {
  color: var(--is-component-text-muted);
  background-color: var(--is-default-inverse-light);
}
.ant-calendar-range .ant-calendar-input[disabled]:hover,
.ant-calendar-range .ant-calendar-time-picker-input[disabled]:hover {
  border-color: var(--is-border);
}
.ant-calendar-range .ant-calendar-in-range-cell::before {
  background: var(--is-primary-inverse-light);
}
.ant-calendar-range .ant-calendar-body,
.ant-calendar-range .ant-calendar-month-panel-body,
.ant-calendar-range .ant-calendar-year-panel-body,
.ant-calendar-range .ant-calendar-decade-panel-body {
  border-top: 1px solid var(--is-border-thin);
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-combobox {
  background-color: var(--is-component-bg);
  border-top: 1px solid var(--is-border-thin);
}
.ant-calendar-time-picker {
  background-color: var(--is-component-bg);
}
.ant-calendar-time-picker-inner {
  font-size: 1rem;
  background-color: var(--is-component-bg);
}
.ant-calendar-time-picker-select {
  border-right: 1px solid var(--is-border-thin);
}
.ant-calendar-time-picker-select li:hover {
  background: var(--is-primary-inverse-light);
}
.ant-calendar-time-picker-select li:focus {
  color: var(--is-primary);
}
li.ant-calendar-time-picker-select-option-selected {
  background: var(--is-default-inverse-light);
}
li.ant-calendar-time-picker-select-option-disabled {
  color: var(--is-component-text-muted);
}
.ant-calendar-time .ant-calendar-day-select {
  color: var(--is-default-dark);
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn-disabled {
  color: var(--is-component-text-muted);
}
.ant-calendar-month-panel {
  background: var(--is-theme-bg);
  border-radius: var(--is-rounded);
}
.ant-calendar-month-panel-header {
  border-bottom: 1px solid var(--is-border-thin);
}
.ant-calendar-month-panel-header a:hover {
  color: var(--is-primary-dark);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select {
  color: var(--is-default-dark);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  color: var(--is-text-muted);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
  border: 0 solid var(--is-border-thin);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover::after {
  border-color: var(--is-text);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  border-width: 1.5px 0 0 1.5px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:hover::after {
  border-color: var(--is-text);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::after {
  border: 0 solid var(--is-border-thin);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:hover::after {
  border-color: var(--is-text);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::after {
  border: 0 solid var(--is-border-thin);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:hover::after {
  border-color: var(--is-text);
}
.ant-calendar-month-panel-footer {
  border-top: 1px solid var(--is-border-thin);
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  color: var(--is-primary-text);
  background: var(--is-primary);
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
  color: var(--is-primary-text);
  background: var(--is-primary);
}
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month,
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month:hover {
  color: var(--is-component-text-muted);
  background: var(--is-default-inverse-light);
}
.ant-calendar-month-panel-month {
  color: var(--is-text);
}
.ant-calendar-month-panel-month:hover {
  background: var(--is-primary-inverse-light);
}
.ant-calendar-year-panel {
  background: var(--is-theme-bg);
  border-radius: var(--is-rounded);
}
.ant-calendar-year-panel-header {
  border-bottom: 1px solid var(--is-border-thin);
}
.ant-calendar-year-panel-header a:hover {
  color: var(--is-primary-dark);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
  color: var(--is-default-dark);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  color: var(--is-text-muted);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  border: 0 solid var(--is-border-thin);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:hover::after {
  border-color: var(--is-text);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  border: 0 solid var(--is-border-thin);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:hover::after {
  border-color: var(--is-text);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::after {
  border: 0 solid var(--is-border-thin);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:hover::after {
  border-color: var(--is-text);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  border: 0 solid var(--is-border-thin);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:hover::after {
  border-color: var(--is-text);
}
.ant-calendar-year-panel-footer {
  border-top: 1px solid var(--is-border-thin);
}
.ant-calendar-year-panel-year {
  color: var(--is-text);
}
.ant-calendar-year-panel-year:hover {
  background: var(--is-primary-inverse-light);
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
  color: var(--is-primary-text);
  background: var(--is-primary);
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
  color: var(--is-primary-text);
  background: var(--is-primary);
}
.ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
.ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year {
  color: var(--is-component-text-muted);
}
.ant-calendar-decade-panel {
  background: var(--is-theme-bg);
  border-radius: var(--is-rounded);
}
.ant-calendar-decade-panel-hidden {
  display: none;
}
.ant-calendar-decade-panel-header {
  border-bottom: 1px solid var(--is-border-thin);
}
.ant-calendar-decade-panel-header a:hover {
  color: var(--is-primary-dark);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select {
  color: var(--is-default-dark);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  color: var(--is-text-muted);
}

.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
  border: 0 solid var(--is-border-thin);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:hover::after {
  border-color: var(--is-text);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  border: 0 solid var(--is-border-thin);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:hover::after {
  border-color: var(--is-text);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::after {
  border: 0 solid var(--is-border-thin);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn:hover::after {
  border-color: var(--is-text);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::after {
  border: 0 solid var(--is-border-thin);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:hover::after {
  border-color: var(--is-text);
}
.ant-calendar-decade-panel-footer {
  border-top: 1px solid var(--is-border-thin);
}
.ant-calendar-decade-panel-decade {
  color: var(--is-text);
}
.ant-calendar-decade-panel-decade:hover {
  background: var(--is-primary-inverse-light);
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
  color: var(--is-primary-text);
  background: var(--is-primary);
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
  color: var(--is-primary-text);
  background: var(--is-primary);
}
.ant-calendar-decade-panel-last-century-cell .ant-calendar-decade-panel-decade,
.ant-calendar-decade-panel-next-century-cell .ant-calendar-decade-panel-decade {
  color: var(--is-component-text-muted);
}
.ant-calendar-week-number .ant-calendar-body tr:hover {
  background: var(--is-primary-inverse-light);
}
.ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
  background: var(--is-primary-inverse-light);
}
.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day .ant-calendar-date,
.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day:hover .ant-calendar-date {
  color: var(--is-text);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-time-picker-panel {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-time-picker-panel-inner {
  font-size: 1rem;
  background-color: var(--is-component-bg);
  border-radius: var(--is-rounded);
}
.ant-time-picker-panel-input::-moz-placeholder {
  color: var(--is-default-inverse);
}
.ant-time-picker-panel-input:-ms-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-time-picker-panel-input::-webkit-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-time-picker-panel-input-wrap {
  border-bottom: 1px solid var(--is-border-thin);
}
.ant-time-picker-panel-input-invalid {
  border-color: var(--is-danger);
}
.ant-time-picker-panel-select {
  border-left: 1px solid var(--is-border-thin);
}
.ant-time-picker-panel-select li:focus {
  color: var(--is-primary);
}
.ant-time-picker-panel-select li:hover {
  background: var(--is-primary-inverse-light);
}
li.ant-time-picker-panel-select-option-selected {
  background: var(--is-default-inverse-light);
}
li.ant-time-picker-panel-select-option-selected:hover {
  background: var(--is-default-inverse-light);
}
li.ant-time-picker-panel-select-option-disabled {
  color: var(--is-component-text-muted);
}
.ant-time-picker-panel-addon {
  border-top: 1px solid var(--is-border-thin);
}
.ant-time-picker {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-time-picker-input {
  color: var(--is-text);
  font-size: 1rem;
  background-color: var(--is-component-bg);
  border: 1px solid var(--is-border);
  border-radius: var(--is-rounded);
}
.ant-time-picker-input::-moz-placeholder {
  color: var(--is-default-inverse);
}
.ant-time-picker-input:-ms-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-time-picker-input::-webkit-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-time-picker-input:hover {
  border-color: var(--is-primary-dark);
}
.ant-time-picker-input:focus {
  border-color: var(--is-primary-dark);
}
.ant-time-picker-input-disabled {
  color: var(--is-component-text-muted);
  background-color:var(--is-component-bg-muted);
}
.ant-time-picker-input-disabled:hover {
  border-color: var(--is-border);
}
.ant-time-picker-input[disabled] {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
}
.ant-time-picker-input[disabled]:hover {
  border-color: var(--is-border);
}
.ant-time-picker-input[disabled] {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
}
.ant-time-picker-input[disabled]:hover {
  border-color: var(--is-border);
}
.ant-time-picker-icon,
.ant-time-picker-clear {
  color: var(--is-component-text-muted);
}
.ant-time-picker-icon .ant-time-picker-clock-icon,
.ant-time-picker-clear .ant-time-picker-clock-icon {
  color: var(--is-component-text-muted);
}
.ant-time-picker-clear {
  background: var(--is-component-bg);
}
.ant-time-picker-clear:hover {
  color: var(--is-text-muted);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-tag {
  color: var(--is-text);
  font-size: 1rem;
  background: var(--is-default-inverse-light);
  border: 1px solid var(--is-border);
  border-radius: var(--is-rounded);
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: var(--is-text);
}
.ant-tag .anticon-close {
  color: var(--is-text-muted);
}
.ant-tag .anticon-close:hover {
  color: var(--is-default-dark);
}
.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: var(--is-primary);
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: var(--is-primary-text);
}
.ant-tag-checkable-checked {
  background-color: var(--is-primary);
}
.ant-tag-checkable:active {
  background-color: var(--is-primary);
}
.ant-tag-pink {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-pink-inverse {
  color: var(--is-pink-text);
  background: var(--is-pink);
  border-color: var(--is-pink);
}
.ant-tag-magenta {
  color: var(--is-pink);
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-red {
  color: var(--is-danger);
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-red-inverse {
  color: #fff;
  background: var(--is-danger);
  border-color: var(--is-danger);
}
.ant-tag-volcano {
  color: #fa541c;
  background: #fff2e8;
  border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.ant-tag-orange {
  color: var(--is-orange);
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag-orange-inverse {
  color: var(--is-orange-text);
  background: var(--is-orange);
  border-color: var(--is-orange);
}
.ant-tag-yellow {
  color: var(--is-yellow);
  background: #feffe6;
  border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
  color: var(--is-yellow-text);
  background: var(--is-yellow);
  border-color: var(--is-yellow);
}
.ant-tag-gold {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
.ant-tag-cyan {
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.ant-tag-lime {
  color: #a0d911;
  background: #fcffe6;
  border-color: #eaff8f;
}
.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.ant-tag-green {
  color: var(--is-green);
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-green-inverse {
  color: var(--is-green-text);
  background: var(--is-green);
  border-color: var(--is-green);
}
.ant-tag-blue {
  color: var(--is-blue);
  background: var(--is-primary-inverse);
  border-color: var(--is-primary-inverse);
}
.ant-tag-blue-inverse {
  color: var(--is-blue-text);
  background: var(--is-blue);
  border-color: var(--is-blue);
}
.ant-tag-geekblue {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
.ant-tag-purple {
  color: var(--is-purple);
  background: #f9f0ff;
  border-color: #d3adf7;
}
.ant-tag-purple-inverse {
  color: var(--is-purple-text);
  background: var(--is-purple);
  border-color: var(--is-purple);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-descriptions-title {
  color: var(--is-default-dark);
}
.ant-descriptions-view {
  border-radius: var(--is-rounded);
}
.ant-descriptions-item-label {
  color: var(--is-default-dark);
  font-size: 1rem;
}
.ant-descriptions-item-content {
  color: var(--is-text);
}
.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid var(--is-border-thin);
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px 24px;
  border-right: 1px solid var(--is-border-thin);
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: var(--is-default-inverse-light);
}
.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid var(--is-border-thin);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-divider {
  color: var(--is-text);
  font-size: 1rem;
  background: var(--is-border-thin);
}
.ant-divider-horizontal.ant-divider-with-text-center,
.ant-divider-horizontal.ant-divider-with-text-left,
.ant-divider-horizontal.ant-divider-with-text-right {
  color: var(--is-default-dark);
}
.ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-left::before,
.ant-divider-horizontal.ant-divider-with-text-right::before,
.ant-divider-horizontal.ant-divider-with-text-center::after,
.ant-divider-horizontal.ant-divider-with-text-left::after,
.ant-divider-horizontal.ant-divider-with-text-right::after {
  border-top: 1px solid var(--is-border-thin);
}
.ant-divider-dashed {
  border-color: var(--is-border-thin);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-drawer-title {
  color: var(--is-default-dark);
}
.ant-drawer-content {
  background-color: var(--is-theme-bg);
}
.ant-drawer-close {
  color: var(--is-text-muted);
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-drawer-header {
  color: var(--is-text);
  background: var(--is-theme-bg);
  border-bottom: 1px solid var(--is-border-thin);
  border-radius: var(--is-rounded) var(--is-rounded) 0 0;
}
.ant-drawer-header-no-title {
  color: var(--is-text);
  background: var(--is-theme-bg);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-form {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-form legend {
  color: var(--is-text-muted);
  border-bottom: 1px solid var(--is-border);
}
.ant-form output {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-form-item-required::before {
  color: var(--is-danger);
  font-size: 1rem;
}
.ant-form-item-label > label {
  color: var(--is-default-dark);
}
.ant-form-item {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-form-explain,
.ant-form-extra {
  color: var(--is-text-muted);
  font-size: 1rem;
}
.has-success.has-feedback .ant-form-item-children-icon {
  color: var(--is-success);
}
.has-warning .ant-form-explain,
.has-warning .ant-form-split {
  color: var(--is-warning);
}
.has-warning .ant-input,
.has-warning .ant-input:hover {
  background-color: var(--is-component-bg);
  border-color: var(--is-warning);
}
.has-warning .ant-input:focus {
  border-color: var(--is-warning-light);
}
.has-warning .ant-input:not([disabled]):hover {
  border-color: var(--is-warning);
}
.has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: var(--is-warning);
}
.has-warning .ant-input-affix-wrapper .ant-input,
.has-warning .ant-input-affix-wrapper .ant-input:hover {
  background-color: var(--is-component-bg);
  border-color: var(--is-warning);
}
.has-warning .ant-input-affix-wrapper .ant-input:focus {
  border-color: var(--is-warning);
}
.has-warning .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: var(--is-warning);
}
.has-warning .ant-input-prefix {
  color: var(--is-warning);
}
.has-warning .ant-input-group-addon {
  color: var(--is-warning);
  background-color: var(--is-component-bg);
  border-color: var(--is-warning);
}
.has-warning .has-feedback {
  color: var(--is-warning);
}
.has-warning.has-feedback .ant-form-item-children-icon {
  color: var(--is-warning);
}
.has-warning .ant-select-selection {
  border-color: var(--is-warning);
}
.has-warning .ant-select-selection:hover {
  border-color: var(--is-warning);
}
.has-warning .ant-select-open .ant-select-selection,
.has-warning .ant-select-focused .ant-select-selection {
  border-color: var(--is-warning-light);
}
.has-warning .ant-calendar-picker-icon::after,
.has-warning .ant-time-picker-icon::after,
.has-warning .ant-picker-icon::after,
.has-warning .ant-select-arrow,
.has-warning .ant-cascader-picker-arrow {
  color: var(--is-warning);
}
.has-warning .ant-input-number,
.has-warning .ant-time-picker-input {
  border-color: var(--is-warning);
}
.has-warning .ant-input-number-focused,
.has-warning .ant-time-picker-input-focused,
.has-warning .ant-input-number:focus,
.has-warning .ant-time-picker-input:focus {
  border-color: var(--is-warning-light);
}
.has-warning .ant-input-number:not([disabled]):hover,
.has-warning .ant-time-picker-input:not([disabled]):hover {
  border-color: var(--is-warning);
}
.has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: var(--is-warning-light);
}
.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: var(--is-danger);
}
.has-error .ant-input,
.has-error .ant-input:hover {
  background-color: var(--is-component-bg);
  border-color: var(--is-danger);
}
.has-error .ant-input:focus {
  border-color: var(--is-danger);
}
.has-error .ant-input:not([disabled]):hover {
  border-color: var(--is-danger);
}
.has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: var(--is-danger);
}
.has-error .ant-input-affix-wrapper .ant-input,
.has-error .ant-input-affix-wrapper .ant-input:hover {
  background-color: var(--is-component-bg);
  border-color: var(--is-danger);
}
.has-error .ant-input-affix-wrapper .ant-input:focus {
  border-color: var(--is-danger);
}
.has-error .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: var(--is-danger);
}
.has-error .ant-input-prefix {
  color: var(--is-danger);
}
.has-error .ant-input-group-addon {
  color: var(--is-danger);
  background-color: var(--is-component-bg);
  border-color: var(--is-danger);
}
.has-error .has-feedback {
  color: var(--is-danger);
}
.has-error.has-feedback .ant-form-item-children-icon {
  color: var(--is-danger);
}
.has-error .ant-select-selection {
  border-color: var(--is-danger);
}
.has-error .ant-select-selection:hover {
  border-color: var(--is-danger);
}
.has-error .ant-select-open .ant-select-selection,
.has-error .ant-select-focused .ant-select-selection {
  border-color: var(--is-danger);
}
.has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: var(--is-danger);
}
.has-error .ant-calendar-picker-icon::after,
.has-error .ant-time-picker-icon::after,
.has-error .ant-picker-icon::after,
.has-error .ant-select-arrow,
.has-error .ant-cascader-picker-arrow {
  color: var(--is-danger);
}
.has-error .ant-input-number,
.has-error .ant-time-picker-input {
  border-color: var(--is-danger);
}
.has-error .ant-input-number-focused,
.has-error .ant-time-picker-input-focused,
.has-error .ant-input-number:focus,
.has-error .ant-time-picker-input:focus {
  border-color: var(--is-danger);
}
.has-error .ant-input-number:not([disabled]):hover,
.has-error .ant-time-picker-input:not([disabled]):hover {
  border-color: var(--is-danger);
}
.has-error .ant-mention-wrapper .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
  border-color: var(--is-danger);
}
.has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  border-color: var(--is-danger);
}
.has-error .ant-cascader-picker:focus .ant-cascader-input {
  border-color: var(--is-danger);
}
.has-error .ant-transfer-list {
  border-color: var(--is-danger);
}
.has-error .ant-transfer-list-search:not([disabled]) {
  border-color: var(--is-border);
}
.has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: var(--is-primary-dark);
}
.has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: var(--is-primary-dark);
}
.is-validating.has-feedback .ant-form-item-children-icon {
  color: var(--is-primary);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-input-number {
  color: var(--is-text);
  font-size: 1rem;
  background-color: var(--is-component-bg);
  border: 1px solid var(--is-border);
  border-radius: var(--is-rounded);
}
.ant-input-number::-moz-placeholder {
  color: var(--is-default-inverse);
}
.ant-input-number:-ms-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-input-number::-webkit-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-input-number:hover {
  border-color: var(--is-primary-dark);
}
.ant-input-number:focus {
  border-color: var(--is-primary-dark);
}
.ant-input-number-disabled {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
}
.ant-input-number-disabled:hover {
  border-color: var(--is-border);
}
.ant-input-number[disabled] {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
}
.ant-input-number[disabled]:hover {
  border-color: var(--is-border);
}
.ant-input-number-handler {
  color: var(--is-text-muted);
}
.ant-input-number-handler:active {
  background: var(--is-default-inverse-light);
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: var(--is-primary-dark);
}
.ant-input-number:hover {
  border-color: var(--is-primary-dark);
}
.ant-input-number-focused {
  border-color: var(--is-primary-dark);
}
.ant-input-number-disabled {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
}
.ant-input-number-disabled:hover {
  border-color: var(--is-border);
}
.ant-input-number-input {
  border-radius: var(--is-rounded);
}
.ant-input-number-input::-moz-placeholder {
  color: var(--is-default-inverse);
}
.ant-input-number-input:-ms-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-input-number-input::-webkit-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-input-number-handler-wrap {
  background: var(--is-component-bg);
  border-left: 1px solid var(--is-border);
  border-radius: 0 var(--is-rounded) var(--is-rounded) 0;
}
.ant-input-number-handler-down {
  border-top: 1px solid var(--is-border);
}
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: var(--is-default-inverse-dark);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-layout {
  background: var(--is-default-inverse-light);;
}
.ant-layout-header {
  background: var(--is-primary-darker);
}
.ant-layout-footer {
  color: var(--is-text);
  font-size: 1rem;
  background: var(--is-default-inverse-light);;
}
.ant-layout-sider {
  background: var(--is-primary-darker);
}
.ant-layout-sider-trigger {
  color: var(--is-primary-darker-text);
  background: var(--is-primary-darker);
}
.ant-layout-sider-zero-width-trigger {
  color: var(--is-primary-darker-text);
  background: var(--is-primary-darker);
  border-radius: 0 var(--is-rounded) var(--is-rounded) 0;
}
.ant-layout-sider-zero-width-trigger:hover {
  background: var(--is-primary-dark);
}
.ant-layout-sider-zero-width-trigger-right {
  border-radius: var(--is-rounded) 0 0 var(--is-rounded);
}
.ant-layout-sider-light {
  background: var(--is-theme-bg);
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: var(--is-text);
  background: var(--is-theme-bg);
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: var(--is-text);
  background: var(--is-theme-bg);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-list {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-list-empty-text {
  color: var(--is-default-inverse-dark);
}
.ant-list-item-content {
  color: var(--is-text);
}
.ant-list-item-meta-title {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-list-item-meta-title > a {
  color: var(--is-text);
}
.ant-list-item-meta-title > a:hover {
  color: var(--is-primary);
}
.ant-list-item-meta-description {
  color: var(--is-text-muted);
}
.ant-list-item-action > li {
  color: var(--is-text-muted);
  font-size: 1rem;
}
.ant-list-item-action-split {
  background-color: var(--is-border-thin);
}
.ant-list-empty {
  color: var(--is-text-muted);
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid var(--is-border-thin);
}
.ant-list-split .ant-list-header {
  border-bottom: 1px solid var(--is-border-thin);
}
.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid var(--is-border-thin);
}
.ant-list-vertical .ant-list-item-meta-title {
  color: var(--is-default-dark);
}
.ant-list-bordered {
  border: 1px solid var(--is-border);
  border-radius: var(--is-rounded);
}
.ant-list-bordered .ant-list-item {
  border-bottom: 1px solid var(--is-border-thin);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-spin {
  color: var(--is-text);
  font-size: 1rem;
  color: var(--is-primary);
}
.ant-spin-nested-loading > div > .ant-spin{
  max-height: unset !important;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  text-shadow: 0 1px 2px var(--is-theme-bg);
}
.ant-spin-container::after {
  background: var(--is-theme-bg);
}
.ant-spin-tip {
  color: var(--is-text-muted);
}
/* .ant-spin-dot-item {
  background-color: #026939 !important;
} */
/* .ant-spin-spinning {
  color:#026939 !important
} */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: var(--is-theme-bg);
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pagination {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-pagination-item {
  background-color: var(--is-theme-bg);
  border: 1px solid var(--is-border);
  border-radius: var(--is-rounded);
}
.ant-pagination-item a {
  color: var(--is-text);
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: var(--is-primary);
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: var(--is-primary);
}
.ant-pagination-item-active {
  background: var(--is-primary-text);
  border-color: var(--is-primary);
}
.ant-pagination-item-active a {
  color: var(--is-primary);
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: var(--is-primary-dark);
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: var(--is-primary-dark);
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: var(--is-primary);
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: var(--is-default-inverse-dark);
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  color: var(--is-text);
  border-radius: var(--is-rounded);
}
.ant-pagination-prev a,
.ant-pagination-next a {
  color: var(--is-text);
}
.ant-pagination-prev:hover a,
.ant-pagination-next:hover a {
  border-color: var(--is-primary-dark);
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: var(--is-theme-bg);
  border: 1px solid var(--is-border);
  border-radius: var(--is-rounded);
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: var(--is-primary);
  border-color: var(--is-primary);
}
.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: var(--is-component-text-muted);
  border-color: var(--is-border);
}
.ant-pagination-options-quick-jumper input {
  color: var(--is-text);
  font-size: 1rem;
  background-color: var(--is-theme-bg);
  border: 1px solid var(--is-border);
  border-radius: var(--is-rounded);
}
.ant-pagination-options-quick-jumper input::-moz-placeholder {
  color: var(--is-default-inverse);
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: var(--is-primary-dark);
}
.ant-pagination-options-quick-jumper input:focus {
  border-color: var(--is-primary-dark);
}
.ant-pagination-options-quick-jumper input-disabled {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: var(--is-border);
}
.ant-pagination-options-quick-jumper input[disabled] {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: var(--is-border);
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  background-color: var(--is-theme-bg);
  border: 1px solid var(--is-border);
  border-radius: var(--is-rounded);
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: var(--is-primary);
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: var(--is-default-inverse-light);
  border-color: var(--is-border);
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: var(--is-default-inverse-dark);
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: var(--is-theme-bg);
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: var(--is-primary-text);
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link:hover,
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link:focus {
  color: var(--is-text-muted);
  background: var(--is-default-inverse-light);
  border-color: var(--is-border);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-mention-wrapper {
  color: var(--is-text);
  font-size: 1rem;
  font-variant: tabular-nums;
}
.ant-mention-wrapper .ant-mention-editor {
  color: var(--is-text);
  background-color: var(--is-theme-bg);
  border: 1px solid var(--is-border);
  border-radius: var(--is-rounded);
}
.ant-mention-wrapper .ant-mention-editor::-moz-placeholder {
  color: var(--is-default-inverse);
}
.ant-mention-wrapper .ant-mention-editor:-ms-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-mention-wrapper .ant-mention-editor::-webkit-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-mention-wrapper .ant-mention-editor:hover {
  border-color: var(--is-primary-dark);
}
.ant-mention-wrapper .ant-mention-editor:focus {
  border-color: var(--is-primary-dark);
}
.ant-mention-wrapper .ant-mention-editor-disabled {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
}
.ant-mention-wrapper .ant-mention-editor-disabled:hover {
  border-color: var(--is-border);
}
.ant-mention-wrapper .ant-mention-editor[disabled] {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
}
.ant-mention-wrapper .ant-mention-editor[disabled]:hover {
  border-color: var(--is-border);
}
.ant-mention-wrapper.ant-mention-active:not(.disabled) .ant-mention-editor {
  border-color: var(--is-primary-dark);
}
.ant-mention-wrapper.disabled .ant-mention-editor {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
}
.ant-mention-wrapper.disabled .ant-mention-editor:hover {
  border-color: var(--is-border);
}
.ant-mention-wrapper .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
  color: var(--is-default-inverse);
}
.ant-mention-dropdown {
  color: var(--is-text);
  font-size: 1rem;
  background-color: var(--is-theme-bg);
  border-radius: var(--is-rounded);
}
.ant-mention-dropdown-notfound.ant-mention-dropdown-item {
  color: var(--is-default-inverse-dark);
}
.ant-mention-dropdown-notfound.ant-mention-dropdown-item .anticon-loading {
  color: var(--is-primary);
}
.ant-mention-dropdown-item {
  color: var(--is-text);
}
.ant-mention-dropdown-item:hover {
  background-color: var(--is-primary-inverse-light);
}
.ant-mention-dropdown-item.focus,
.ant-mention-dropdown-item-active {
  background-color: var(--is-primary-inverse-light);
}
.ant-mention-dropdown-item-disabled {
  color: var(--is-component-text-muted);
}
.ant-mention-dropdown-item-disabled:hover {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg);
}
.ant-mention-dropdown-item-selected,
.ant-mention-dropdown-item-selected:hover {
  color: var(--is-text);
  background-color: var(--is-default-inverse-light);
}
.ant-mention-dropdown-item-divider {
  background-color: var(--is-border-thin);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-mentions {
  color: var(--is-text);
  background-color: var(--is-theme-bg);
  border: 1px solid var(--is-border);
  border-radius: var(--is-rounded);
}
.ant-mentions::-moz-placeholder {
  color: var(--is-default-inverse);
}
.ant-mentions:-ms-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-mentions::-webkit-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-mentions:hover {
  border-color: var(--is-primary-dark);
}
.ant-mentions:focus {
  border-color: var(--is-primary-dark);
}
.ant-mentions-disabled {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
}
.ant-mentions-disabled:hover {
  border-color: var(--is-border);
}
.ant-mentions[disabled] {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
}
.ant-mentions[disabled]:hover {
  border-color: var(--is-border);
}
.ant-mentions-disabled > textarea {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg-muted);
}
.ant-mentions-disabled > textarea:hover {
  border-color: var(--is-border);
}
.ant-mentions-focused {
  border-color: var(--is-primary-dark);
}
.ant-mentions > textarea::-moz-placeholder {
  color: var(--is-default-inverse);
}
.ant-mentions > textarea:-ms-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-mentions > textarea::-webkit-input-placeholder {
  color: var(--is-default-inverse);
}
.ant-mentions-dropdown {
  color: var(--is-text);
  font-size: 1rem;
  background-color: var(--is-theme-bg);
  border-radius: var(--is-rounded);
}
.ant-mentions-dropdown-menu-item {
  color: var(--is-text);
}
.ant-mentions-dropdown-menu-item:hover {
  background-color: var(--is-primary-inverse-light);
}
.ant-mentions-dropdown-menu-item:first-child {
  border-radius: var(--is-rounded) var(--is-rounded) 0 0;
}
.ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 var(--is-rounded) var(--is-rounded);
}
.ant-mentions-dropdown-menu-item-disabled {
  color: var(--is-component-text-muted);
}
.ant-mentions-dropdown-menu-item-disabled:hover {
  color: var(--is-component-text-muted);
  background-color: var(--is-component-bg);
}
.ant-mentions-dropdown-menu-item-selected {
  color: var(--is-text);
  background-color: var(--is-default-inverse-light);
}
.ant-mentions-dropdown-menu-item-active {
  background-color: var(--is-primary-inverse-light);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-message {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-message-notice-content {
  background: var(--is-theme-bg);
  border-radius: var(--is-rounded);
}
.ant-message-success .anticon {
  color: var(--is-success);
}
.ant-message-error .anticon {
  color: var(--is-danger);
}
.ant-message-warning .anticon {
  color: var(--is-warning);
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: var(--is-primary);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-modal-title {
  color: var(--is-default-dark);
}
.ant-modal-content {
  border-radius: var(--is-rounded);
}
.ant-modal-close {
  color: var(--is-text-muted);
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-modal-header {
  color: var(--is-text);
  background: var(--is-theme-bg);
  border-bottom: 1px solid var(--is-border-thin);
  border-radius: var(--is-rounded) var(--is-rounded) 0 0;
}
.ant-modal-footer {
  border-top: 1px solid var(--is-border-thin);
  border-radius: 0 0 var(--is-rounded) var(--is-rounded);
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  color: var(--is-default-dark);
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: var(--is-danger);
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: var(--is-warning);
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: var(--is-primary);
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: var(--is-success);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-notification {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-notification-notice {
  background: var(--is-theme-bg);
  border-radius: var(--is-rounded);
}
.ant-notification-notice-message {
  color: var(--is-default-dark);
}
.anticon.ant-notification-notice-icon-success {
  color: var(--is-success);
}
.anticon.ant-notification-notice-icon-info {
  color: var(--is-primary);
}
.anticon.ant-notification-notice-icon-warning {
  color: var(--is-warning);
}
.anticon.ant-notification-notice-icon-error {
  color: var(--is-danger);
}
.ant-notification-notice-close {
  color: var(--is-text-muted);
}
.ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-page-header {
  color: var(--is-text);
  font-size: 1rem;
  background: var(--is-theme-bg);
}
.ant-page-header-back-button {
  color: var(--is-primary);
  color: var(--is-text);
}
.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
  color: var(--is-primary-dark);
}
.ant-page-header-back-button:active {
  color: var(--is-primary);
}
.ant-page-header-heading-title {
  color: var(--is-default-dark);
}
.ant-page-header-heading-sub-title {
  color: var(--is-text-muted);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-popover {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-popover::after {
  background: rgba(255, 255, 255, 0.01);
}
.ant-popover-inner {
  background-color: var(--is-theme-bg);
  border-radius: var(--is-rounded);
}
.ant-popover-title {
  color: var(--is-default-dark);
  border-bottom: 1px solid var(--is-border-thin);
}
.ant-popover-inner-content {
  color: var(--is-text);
}
.ant-popover-message {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-popover-message > .anticon {
  color: var(--is-warning);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  border-right-color: var(--is-component-bg);
  border-bottom-color: var(--is-component-bg);
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  border-bottom-color: var(--is-component-bg);
  border-left-color: var(--is-component-bg);
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  border-top-color: var(--is-component-bg);
  border-left-color: var(--is-component-bg);
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  border-top-color: var(--is-component-bg);
  border-right-color: var(--is-component-bg);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-progress {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-progress-inner {
  background-color: var(--is-default-inverse-light);
}
.ant-progress-circle-trail {
  stroke: var(--is-default-inverse-light);
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: var(--is-primary);
}
.ant-progress-success-bg,
.ant-progress-bg {
  background-color: var(--is-primary);
}
.ant-progress-success-bg {
  background-color: var(--is-success);
}
.ant-progress-text {
  color: var(--is-text-muted);
}
.ant-progress-text .anticon {
  font-size: 1rem;
}
.ant-progress-status-active .ant-progress-bg::before {
  background: var(--is-theme-bg);
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: var(--is-danger);
}
.ant-progress-status-exception .ant-progress-text {
  color: var(--is-danger);
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: var(--is-danger);
}
.ant-progress-status-success .ant-progress-bg {
  background-color: var(--is-success);
}
.ant-progress-status-success .ant-progress-text {
  color: var(--is-success);
}
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: var(--is-success);
}
.ant-progress-circle .ant-progress-text {
  color: var(--is-text);
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: var(--is-danger);
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: var(--is-success);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-rate {
  color: var(--is-text);
  color: var(--is-yellow);
  font-size: 1rem;
}
.ant-rate-star-first,
.ant-rate-star-second {
  color: var(--is-border-thin);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-result-success .ant-result-icon > .anticon {
  color: var(--is-success);
}
.ant-result-error .ant-result-icon > .anticon {
  color: var(--is-danger);
}
.ant-result-info .ant-result-icon > .anticon {
  color: var(--is-primary);
}
.ant-result-warning .ant-result-icon > .anticon {
  color: var(--is-warning);
}
.ant-result-title {
  color: var(--is-default-dark);
}
.ant-result-subtitle {
  color: var(--is-text-muted);
  font-size: 1rem;
}
.ant-result-content {
  background-color: var(--is-default-inverse-light);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-skeleton-header .ant-skeleton-avatar {
  background: var(--is-default-inverse);
}
.ant-skeleton-content .ant-skeleton-title {
  background: var(--is-default-inverse);
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  background: var(--is-default-inverse);
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: -webkit-gradient(linear, left top, right top, color-stop(25%, var(--is-default-inverse)), color-stop(37%, var(--is-default-inverse-dark)), color-stop(63%, var(--is-default-inverse)));
  background: linear-gradient(90deg, var(--is-default-inverse) 25%, var(--is-default-inverse-dark) 37%, var(--is-default-inverse) 63%);
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  background: -webkit-gradient(linear, left top, right top, color-stop(25%, var(--is-default-inverse)), color-stop(37%, var(--is-default-inverse-dark)), color-stop(63%, var(--is-default-inverse)));
  background: linear-gradient(90deg, var(--is-default-inverse) 25%, var(--is-default-inverse-dark) 37%, var(--is-default-inverse) 63%);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-slider {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-slider-rail {
  background-color: var(--is-default-inverse-light);
}
.ant-slider-track {
  background-color: var(--is-primary-inverse);
  border-radius: var(--is-rounded);
}
.ant-slider-handle {
  background-color: var(--is-theme-bg);
  border: solid 2px var(--is-primary-inverse);
}
.ant-slider-handle:focus {
  border-color: var(--is-primary);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: var(--is-primary);
}
.ant-slider:hover .ant-slider-rail {
  background-color: var(--is-default-inverse-dark);
}
.ant-slider:hover .ant-slider-track {
  background-color: var(--is-primary-light);
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: var(--is-primary-light);
}
.ant-slider-mark-text {
  color: var(--is-text-muted);
}
.ant-slider-mark-text-active {
  color: var(--is-text);
}
.ant-slider-dot {
  background-color: var(--is-theme-bg);
  border: 2px solid var(--is-border-thin);
}
.ant-slider-dot-active {
  border-color: var(--is-default-inverse);
}
.ant-slider-disabled .ant-slider-track {
  background-color: var(--is-default-inverse-dark) !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: var(--is-theme-bg);
  border-color: var(--is-default-inverse-dark) !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-statistic {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-statistic-title {
  color: var(--is-text-muted);
  font-size: 1rem;
}
.ant-statistic-content {
  color: var(--is-default-dark);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-steps {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-steps-item-icon > .ant-steps-icon {
  color: var(--is-primary);
}
.ant-steps-item-tail::after {
  background: var(--is-border-thin);
}
.ant-steps-item-title {
  padding-right: 16px;
  color: var(--is-text);
  font-size: 16px;
  line-height: 32px;
}
.ant-steps-item-title::after {
  background: var(--is-border-thin);
}
.ant-steps-item-subtitle {
  color: var(--is-text-muted);
}
.ant-steps-item-description {
  color: var(--is-text-muted);
  font-size: 1rem;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: var(--is-component-bg);
  border-color: var(--is-component-border-color-muted);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: var(--is-component-text-muted);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: var(--is-component-text-muted);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: var(--is-text-muted);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: var(--is-border-thin);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: var(--is-text-muted);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: var(--is-border-thin);
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--is-theme-bg);
  border-color: var(--is-primary);
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: var(--is-primary);
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: var(--is-primary);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: var(--is-default-dark);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: var(--is-border-thin);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: var(--is-text);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: var(--is-border-thin);
}
.ant-steps-item-process .ant-steps-item-icon {
  background: var(--is-primary);
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: var(--is-primary-text);
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: var(--is-primary-text);
  border-color: var(--is-primary);
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--is-primary);
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: var(--is-primary);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: var(--is-text);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: var(--is-primary);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: var(--is-text-muted);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: var(--is-primary);
}
.ant-steps-item-error .ant-steps-item-icon {
  background-color: var(--is-danger-text);
  border-color: var(--is-danger);
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: var(--is-danger);
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: var(--is-danger);
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: var(--is-danger);
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: var(--is-border-thin);
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: var(--is-danger);
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: var(--is-border-thin);
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: var(--is-danger);
}
.ant-steps:not(.ant-steps-navigation) .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps:not(.ant-steps-navigation) .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps:not(.ant-steps-navigation) .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: var(--is-primary);
}
.ant-steps:not(.ant-steps-navigation) .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: var(--is-primary);
}
.ant-steps:not(.ant-steps-navigation) .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: var(--is-primary);
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: var(--is-primary);
}
.ant-steps-small .ant-steps-item-description {
  color: var(--is-text-muted);
  font-size: 1rem;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  background: rgba(0, 0, 0, 0.001);
}
.ant-steps-navigation .ant-steps-item::after {
  border: 1px solid var(--is-component-border-color-muted);
}
.ant-steps-navigation .ant-steps-item::before {
  background-color: var(--is-primary);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-switch {
  color: var(--is-text);
  font-size: 1rem;
  background-color: var(--is-default-inverse-dark);
}
.ant-switch-inner {
  color: var(--is-component-bg);
}
.ant-switch-loading .ant-switch-loading-icon {
  color: var(--is-text);
}
.ant-switch-checked.ant-switch-loading .ant-switch-loading-icon {
  color: var(--is-primary);
}
.ant-switch-checked {
  background-color: var(--is-primary);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* .ant-table {
  color: var(--is-text-inverse) !important;
  font-size: 1rem;
}
.ant-table table {
  border-radius: var(--is-rounded) var(--is-rounded) 0 0;
}
.ant-table-thead > tr > th {
  color: var(--is-text-inverse) !important;
  background: transparent !important;
  border-bottom: 1px solid transparent !important;
} */
.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  color: var(--is-text-muted);
  font-size: 12px;
}
.ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter {
  color: var(--is-primary);
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
  color: var(--is-default);
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
  color: var(--is-primary);
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
  color: var(--is-text-muted);
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover {
  color: var(--is-text-muted);
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:active,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:active {
  color: var(--is-primary);
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-up:not(.on),
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-down:not(.on) {
  color: var(--is-text-muted);
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters:hover::before {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: var(--is-rounded);
}
.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: var(--is-rounded);
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  line-height: 1;
  word-break: normal;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid transparent !important;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: rgba(0, 0, 0, 0.04) !important;
}
.ant-table-thead > tr.ant-table-row-selected > td.ant-table-column-sort,
.ant-table-tbody > tr.ant-table-row-selected > td.ant-table-column-sort {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead > tr:hover.ant-table-row-selected > td,
.ant-table-tbody > tr:hover.ant-table-row-selected > td {
  background: rgba(0, 0, 0, 0.04) !important;
}
.ant-table-thead > tr:hover.ant-table-row-selected > td.ant-table-column-sort,
.ant-table-tbody > tr:hover.ant-table-row-selected > td.ant-table-column-sort {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-footer {
  color: var(--is-default-dark);
  background: var(--is-theme-bg-muted);
  border-top: 1px solid var(--is-border-thin);
  border-radius: 0 0 var(--is-rounded) var(--is-rounded);
}
.ant-table-footer::before {
  background: var(--is-theme-bg-muted);
}
.ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid var(--is-border-thin);
}
.ant-table-title {
  position: relative;
  top: 1px;
  padding: 16px 0;
  border-radius: var(--is-rounded) var(--is-rounded) 0 0;
}
.ant-table.ant-table-bordered .ant-table-title {
  padding-right: 16px;
  padding-left: 16px;
  border: 1px solid var(--is-border-thin);
}
.ant-table-title + .ant-table-content {
  position: relative;
  border-radius: var(--is-rounded) var(--is-rounded) 0 0;
}
.ant-table-without-column-header.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-top: 1px solid var(--is-border-thin);
  border-radius: var(--is-rounded);
}
.ant-table-tbody > tr.ant-table-row-selected td {
  background: transparent  !important;
}
.ant-table-thead > tr > th.ant-table-column-sort {
  background: var(--is-default-inverse-light);
}
.ant-table-tbody > tr > td.ant-table-column-sort {
  background: rgba(0, 0, 0, 0.01);
}
.ant-table-header {
  background: var(--is-default-inverse-light);
}
.ant-table-header table {
  border-radius: var(--is-rounded) var(--is-rounded) 0 0;
}
.ant-table-loading .ant-table-body {
  background: var(--is-theme-bg);
  opacity: 0.5;
}
.ant-table-bordered .ant-table-header > table,
.ant-table-bordered .ant-table-body > table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table {
  border: 1px solid var(--is-border-thin);
}
.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-right: 1px solid var(--is-border-thin);
  border-left: 1px solid var(--is-border-thin);
}
.ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
  border-bottom: 1px solid var(--is-border-thin);
}
.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid var(--is-border-thin);
}
.ant-table-placeholder {
  color: var(--is-default-inverse-dark);
  font-size: 1rem;
  background: var(--is-theme-bg);
  border-top: 1px solid var(--is-border-thin);
  border-bottom: 1px solid var(--is-border-thin);
  border-radius: 0 0 var(--is-rounded) var(--is-rounded);
}
.ant-table-filter-dropdown {
  background: var(--is-component-bg);
  border-radius: var(--is-rounded);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  border-radius: var(--is-rounded) var(--is-rounded) 0 0;
}
.ant-table-filter-dropdown .ant-dropdown-menu-sub {
  border-radius: var(--is-rounded);
}
.ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-submenu-contain-selected .ant-dropdown-menu-submenu-title::after {
  color: var(--is-primary);
  text-shadow: 0 0 2px var(--is-primary-inverse-light);
}
.ant-table-filter-dropdown-btns {
  border-top: 1px solid var(--is-border-thin);
}
.ant-table-filter-dropdown-link {
  color: var(--is-primary);
}
.ant-table-filter-dropdown-link:hover {
  color: var(--is-primary-dark);
}
.ant-table-filter-dropdown-link:active {
  color: var(--is-primary);
}
.ant-table-selection .anticon-down {
  color: var(--is-default-inverse);
}
.ant-table-selection-menu {
  background: var(--is-component-bg);
  border-radius: var(--is-rounded);
}
.ant-table-selection-menu .ant-action-down {
  color: var(--is-default-inverse);
}
.ant-table-selection-down:hover .anticon-down {
  color: rgba(0, 0, 0, 0.6);
}
/* .ant-table-row-expand-icon {
  color: var(--is-primary);
  background: var(--is-theme-bg);
  border: 1px solid var(--is-border-thin);
} */
.ant-table-row-expand-icon{
  border-radius: 50%;
  color: white;
  background: transparent !important;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: var(--is-primary-dark);
}
.ant-table-row-expand-icon:active {
  color: var(--is-primary);
}
tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
  background: transparent !important;
}
.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  background: var(--is-theme-bg);
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
  border: 1px solid var(--is-border-thin);
}
.ant-table-bordered.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
  border: 1px solid var(--is-border-thin);
}
.ant-table-fixed-left table,
.ant-table-fixed-right table {
  background: var(--is-theme-bg);
}
.ant-table-fixed-left,
.ant-table-fixed-left table {
  border-radius: var(--is-rounded) 0 0 0;
}
.ant-table-fixed-right,
.ant-table-fixed-right table {
  border-radius: 0 var(--is-rounded) 0 0;
}
.ant-table-small {
  border: 1px solid var(--is-border-thin);
  border-radius: var(--is-rounded);
}
.ant-table-small > .ant-table-title {
  top: 0;
  border-bottom: 1px solid var(--is-border-thin);
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr {
  border-bottom: 1px solid var(--is-border-thin);
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort {
  background-color: rgba(0, 0, 0, 0.01);
}
.ant-table-small > .ant-table-content .ant-table-header {
  background-color: var(--is-theme-bg);
}
.ant-table-small.ant-table-bordered .ant-table-title {
  border-right: 1px solid var(--is-border-thin);
  border-bottom: 1px solid var(--is-border-thin);
}
.ant-table-small.ant-table-bordered .ant-table-content {
  border-right: 1px solid var(--is-border-thin);
}
.ant-table-small.ant-table-bordered .ant-table-footer {
  border-top: 1px solid var(--is-border-thin);
  border-right: 1px solid var(--is-border-thin);
}
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-thead > tr > th:last-child,
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid var(--is-border-thin);
}
.ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-right: 1px solid var(--is-border-thin);
  border-left: 1px solid var(--is-border-thin);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-timeline {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-timeline-item-tail {
  border-left: 2px solid var(--is-border-thin);
}
.ant-timeline-item-head {
  background-color: var(--is-theme-bg);
}
.ant-timeline-item-head-blue {
  color: var(--is-primary);
  border-color: var(--is-primary);
}
.ant-timeline-item-head-red {
  color: var(--is-danger);
  border-color: var(--is-danger);
}
.ant-timeline-item-head-green {
  color: var(--is-success);
  border-color: var(--is-success);
}
.ant-timeline-item-head-gray {
  color: var(--is-component-text-muted);
  border-color: var(--is-component-border-color-muted);
}
.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  border-left: 2px dotted var(--is-border-thin);
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  border-left: 2px dotted var(--is-border-thin);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: var(--is-default-inverse-light);
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid var(--is-border-thin);
}
.ant-transfer {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-transfer-disabled .ant-transfer-list {
  background: var(--is-default-inverse-light);
}
.ant-transfer-list {
  border: 1px solid var(--is-border);
  border-radius: var(--is-rounded);
}
.ant-transfer-list-search-action {
  color: var(--is-default-inverse-dark);
}
.ant-transfer-list-search-action .anticon {
  color: var(--is-default-inverse-dark);
}
.ant-transfer-list-search-action .anticon:hover {
  color: var(--is-text-muted);
}
.ant-transfer-list-header {
  color: var(--is-text);
  background: var(--is-theme-bg);
  border-bottom: 1px solid var(--is-border-thin);
  border-radius: var(--is-rounded) var(--is-rounded) 0 0;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: var(--is-primary-inverse-light);
}
.ant-transfer-list-content-item-disabled {
  color: var(--is-component-text-muted);
}
.ant-transfer-list-body-not-found {
  color: var(--is-component-text-muted);
}
.ant-transfer-list-body-with-search .ant-transfer-list-body-not-found {
  margin-top: var(--is-su-4);
}
.ant-transfer-list-footer {
  border-top: 1px solid var(--is-border-thin);
  border-radius: 0 0 var(--is-rounded) var(--is-rounded);
}
.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-select-tree-checkbox {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: var(--is-primary);
}
.ant-select-tree-checkbox-checked::after {
  border: 1px solid var(--is-primary);
}
.ant-select-tree-checkbox-inner {
  background-color: var(--is-component-bg);
  border: 1px solid var(--is-border);
}
.ant-select-tree-checkbox-inner::after {
  border: 2px solid var(--is-component-bg);
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border: 2px solid var(--is-component-bg);
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: var(--is-primary);
  border-color: var(--is-primary);
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: var(--is-component-text-muted);
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: var(--is-default-inverse-light);
  border-color: var(--is-border) !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: var(--is-default-inverse-light);
}
.ant-select-tree-checkbox-disabled + span {
  color: var(--is-component-text-muted);
}
.ant-select-tree-checkbox-wrapper {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-select-tree-checkbox-group {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: var(--is-component-bg);
  border-color: var(--is-border);
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  background-color: var(--is-primary);
}
.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: var(--is-component-text-muted);
  border-color: var(--is-component-text-muted);
}
.ant-select-tree {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper {
  color: var(--is-text);
}
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: var(--is-primary-inverse-light);
}
.ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: var(--is-primary-inverse-light);
}
.ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon {
  color: var(--is-primary);
  font-size: 1rem;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
}
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
}
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon {
  color: var(--is-primary);
  font-size: 1rem;
}
li.ant-select-tree-treenode-disabled > span:not(.ant-select-tree-switcher),
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper,
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper span {
  color: var(--is-component-text-muted);
}
.ant-select-tree-dropdown {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-select-tree-dropdown .ant-select-dropdown-search {
  background: var(--is-component-bg);
}
.ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field {
  border: 1px solid var(--is-border);
  border-radius: var(--is-rounded);
}
.ant-select-tree-dropdown .ant-select-dropdown-search.ant-select-search--hide {
  display: none;
}
.ant-select-tree-dropdown .ant-select-not-found {
  color: var(--is-component-text-muted);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
  background: var(--is-primary-inverse-light);
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner {
  border-color: var(--is-primary);
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after {
  border-color: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background: var(--is-component-bg);
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: var(--is-primary);
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before {
  background: var(--is-primary);
}
.ant-tree-checkbox {
  color: var(--is-text);
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: var(--is-primary);
}
.ant-tree-checkbox-checked::after {
  border: 1px solid var(--is-primary);
}
.ant-tree-checkbox-inner {
  background-color: var(--is-component-bg);
  border: 1px solid var(--is-border);
}
.ant-tree-checkbox-inner::after {
  border: 2px solid var(--is-component-bg);
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border: 2px solid var(--is-component-bg);
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: var(--is-primary);
  border-color: var(--is-primary);
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: var(--is-default-inverse-light);
  border-color: var(--is-border) !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: var(--is-default-inverse-light);
}
.ant-tree-checkbox-disabled + span {
  color: var(--is-component-text-muted);
}
.ant-tree-checkbox-wrapper {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-tree-checkbox-group {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: var(--is-component-bg);
  border-color: var(--is-border);
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  background-color: var(--is-primary);
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: var(--is-default-inverse-dark);
  border-color: var(--is-default-inverse-dark);
}
.ant-tree {
  color: var(--is-text);
}
.ant-tree li.drag-over > span[draggable] {
  color: var(--is-primary-text);
  background-color: var(--is-primary);
}
.ant-tree li.drag-over-gap-top > span[draggable] {
  border-top-color: var(--is-primary);
}
.ant-tree li.drag-over-gap-bottom > span[draggable] {
  border-bottom-color: var(--is-primary);
}
.ant-tree li.filter-node > span {
  color: var(--is-danger) !important;
}
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon,
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon {
  color: var(--is-primary);
}
.ant-tree li .ant-tree-node-content-wrapper {
  color: var(--is-text);
}
.ant-tree li .ant-tree-node-content-wrapper:hover {
    background-color: var(--is-primary-inverse-darker);
    color: var(--is-primary-inverse-darker-text);
}
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: var(--is-primary-inverse-light);
}
.ant-tree li:last-child > span.ant-tree-switcher::before,
.ant-tree li:last-child > span.ant-tree-iconEle::before {
  display: none;
}
li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher),
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper,
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
  color: var(--is-component-text-muted);
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  color: var(--is-text-muted);
  background: var(--is-component-bg);
}
.ant-tree.ant-tree-show-line li:not(:last-child)::before {
  border-left: 1px solid var(--is-border);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-typography {
  color: var(--is-text);
}
.ant-typography.ant-typography-secondary {
  color: var(--is-text-muted);
}
.ant-typography.ant-typography-warning {
  color: var(--is-warning);
}
.ant-typography.ant-typography-danger {
  color: var(--is-danger);
}
.ant-typography.ant-typography-disabled {
  color: var(--is-component-text-muted);
}
div.ant-typography,
.ant-typography p {
  margin-bottom: var(--is-su-4);
}
h1.ant-typography,
.ant-typography h1 {
  color: var(--is-default-dark);
}
h2.ant-typography,
.ant-typography h2 {
  color: var(--is-default-dark);
}
h3.ant-typography,
.ant-typography h3 {
  color: var(--is-default-dark);
}
h4.ant-typography,
.ant-typography h4 {
  color: var(--is-default-dark);
}
.ant-typography a {
  color: var(--is-primary);
}
.ant-typography a:focus,
.ant-typography a:hover {
  color: var(--is-primary-dark);
}
.ant-typography a:active {
  color: var(--is-primary-darker);
}
.ant-typography a[disabled] {
  color: var(--is-component-text-muted);
}
.ant-typography code {
  background: rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: var(--is-rounded);
}
.ant-typography mark {
  background-color: var(--is-warning-inverse);
}
.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: var(--is-primary);
}
.ant-typography-expand:focus,
.ant-typography-edit:focus,
.ant-typography-copy:focus,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
  color: var(--is-primary-dark);
}
.ant-typography-expand:active,
.ant-typography-edit:active,
.ant-typography-copy:active {
  color: var(--is-primary);
}
.ant-typography-copy-success,
.ant-typography-copy-success:hover,
.ant-typography-copy-success:focus {
  color: var(--is-success);
}
.ant-typography-edit-content-confirm {
  color: var(--is-text-muted);
}
.ant-typography ul li,
.ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-upload {
  color: var(--is-text);
  font-size: 1rem;
}
.ant-upload.ant-upload-select-picture-card {
  background-color: var(--is-default-inverse-light);
  border: 1px dashed var(--is-border);
  border-radius: var(--is-rounded);
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: var(--is-primary);
}
.ant-upload.ant-upload-drag {
  background: var(--is-default-inverse-light);
  border: 1px dashed var(--is-border);
  border-radius: var(--is-rounded);
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: var(--is-primary);
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: var(--is-primary-dark);
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: var(--is-primary-dark);
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  color: var(--is-default-dark);
  font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: var(--is-text-muted);
  font-size: 1rem;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: var(--is-component-text-muted);
  font-size: 30px;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: var(--is-text-muted);
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: var(--is-text-muted);
}
.ant-upload-list {
  color: var(--is-text);
}
.ant-upload-list-item-info .anticon-loading,
.ant-upload-list-item-info .anticon-paper-clip {
  color: var(--is-text-muted);
  font-size: 1rem;
}
.ant-upload-list-item .anticon-close {
  color: var(--is-text-muted);
}
:root .ant-upload-list-item .anticon-close {
  font-size: 12px;
}
.ant-upload-list-item .anticon-close:hover {
  color: var(--is-text);
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: var(--is-primary-inverse-light);
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .anticon-paper-clip,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: var(--is-danger);
}
.ant-upload-list-item-error .anticon-close {
  color: var(--is-danger) !important;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  border: 1px solid var(--is-border);
  border-radius: var(--is-rounded);
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: var(--is-danger);
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  color: var(--is-default-inverse-light);
  font-size: 16px;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: var(--is-component-bg);
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: var(--is-default-inverse-light);
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading-text {
  margin-top: 18px;
  color: var(--is-text-muted);
}
.ant-upload-list .ant-upload-success-icon {
  color: var(--is-success);
  font-weight: bold;
}