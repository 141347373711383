:root{
    --footerBg: #ffffff;
    --footerText:  #201D1D;
}

[data-theme="dark"]{
    /* --footerBg: #4A4948; */
    --footerBg:#232222;
    --footerText:  #ffffff;
}
.page-footer-container {
    width: 100%;
    background: var(--footerBg);
    color: var(--footerText);
    box-shadow: 0px -3px 4px rgb(0 0 0 / 11%);
}