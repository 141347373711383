.temp-clr{
    color: #fff !important;
}
.metric-txt-clr{
    color: #fff !important;
}
.compare-txt-clr{
    color: #fff !important;
}

.ant-modal-header {
    padding:16px 15px 10px !important;
    border: none !important;
    background: #f0f0f0 !important;
}

.ant-modal-close .ant-modal-close-x {
    width: 46px;
    height: 46px;
    line-height: 46px;
}

.ant-modal-header .ant-modal-title {
    /* color: #235BA2 !important; */
    color: var(--theme-font-color) !important;
    font-size: 24px;
}

.ant-modal-close .anticon-close {
    border-radius: 50%;
    background: #fff;
    padding: 6px;
    font-size: 8px;
    color: #111;
    font-weight: 600 !important;
    box-shadow: rgb(0 0 0 / 45%) 0px 0px 10px;
}

.ant-modal-body {
    padding: 10px !important; 
}

.ant-modal-content .ant-modal-body .is-text-right button {
    border-radius: 40px !important;
    margin-right: 0 !important;
    font-size: 11px !important;
    height: 40px !important;
    width: 60px !important;
    background: var(--arrowSCarouselDisabled) !important;
}

.ant-modal-body .ant-table-content table {
    margin-top: 15px !important; 
}

.ant-modal-body .ant-table-content table .ant-table-thead > tr > th {
    font-size: 11px !important;
    background: #F0F3F8 !important;
}

.ant-modal-body .ant-table-tbody > tr:nth-child(even),
.ant-modal-body .ant-table-tbody > tr:hover {
    background: #F9F9F9 !important;
}

.ant-modal-body .ant-table-tbody > tr > td {
    padding: 10px 16px !important;
}

.ant-modal-content .ant-modal-body .ant-form.ant-form-horizontal .ant-form-item-label {
    line-height: normal !important;
}

.ant-modal-content .ant-modal-body .ant-form.ant-form-horizontal .ant-form-item-label label {
    font-size: 11px !important;
    line-height: normal !important; 
}

.ant-modal-content .ant-modal-body .ant-form.ant-form-horizontal .ant-row.ant-form-item {
    margin-bottom: 5px !important;
}

.ant-modal-content .ant-modal-body .ant-form.ant-form-horizontal .ant-row.ant-form-item .ant-upload-select button,
.ant-modal-content .ant-modal-body .ant-form.ant-form-horizontal .ant-row.ant-form-item .ant-form-item-control .ant-form-item-children button  {
    font-size: 11px !important;
}

.ant-form-item-children .ant-select-enabled .ant-select-selection__rendered {
    font-size: 11px !important;
}

.welcm-title div {
    line-height: normal;
}

.welcm-title .is-font-size-md {
    color: #A9A9A9 !important;
    font-size: 26px !important;
}

.sub-title {
    font-size: 16px !important;
}

.semantic-component-text {
    font-family: inherit !important;
}

.la-top-tile-bg .slider-wrapper .is-col-12.is-display-flex.is-flex-column {
    display: flex !important;
    justify-content: start;
    align-items: start;
    margin: 20px;
    margin-left: 30px;
}

.la-top-tile-bg .slider-wrapper .is-col-12.is-display-flex.is-flex-column img {
    margin: inherit !important;
}

.la-top-tile-bg .slider-wrapper .is-col-12.is-display-flex.is-flex-column .is-font-size-lg.semantic-component-text {
    line-height: normal !important;
}


.la-top-tile-bg .slider-wrapper .is-col-12.is-display-flex.is-flex-column .is-font-size-xxl.is-text-bold.semantic-component-text {
    font-size: 62px !important;
    line-height: normal;
    font-weight: bold !important;
}

.discr-txt {
    color: #9e9898;
    font-weight: normal !important;
    line-height: normal !important;
}

.ant-collapse-item-active .faq-tab {
    margin-bottom:0 !important;
}

.ant-collapse-content-active .ant-collapse-content-box {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.is-modal-title-color {
    color: #235BA2 !important
}

.add-modal form.ant-form.ant-form-horizontal{
    height: auto;
}

.add-modal .ant-input, .add-modal .ant-btn{
    height: 32px !important;
}

.add-modal button.ant-btn.ant-btn-primary{
    background: var(--arrowSCarouselDisabled) !important;;
}