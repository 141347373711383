.contentBackground .page-footer {
	width: 100%;
    margin-left: 0px;
	z-index: 9999;
}
.pageBackground {
	position: absolute;
	left: 0;
	top: 0;
	min-width: 100%;
	min-height: 100%;
	z-index: 1;
}
/* .Backgeound {	
	background-image: url('../../assest/transit/background.png'), linear-gradient(180deg, rgba(4, 21, 58, 0.7) 16.11%, rgba(17, 66, 111, 0.371) 100%);
	background-position: center;
	background-size: cover;	
	background-repeat: no-repeat;
  	background-attachment: local;
	background-blend-mode: overlay;
	  
}  */

.Backgeounds {	
	background-image: url('../../assest/transit/summary.png'), linear-gradient(180deg, rgba(4, 21, 58, 0.7) 16.11%, rgba(17, 66, 111, 0.371) 100%);
	background-position: center;
	background-size: cover;	
	background-repeat: no-repeat;
  	background-attachment: local;
	background-blend-mode: overlay;
	  
}



.pageBackground img {
	width: 100%;
	height: 100vh;
    object-fit: cover;
}
.pageBackground img.overlayBackground {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
}

.contentBackground {
	z-index: 3;
}