:root{
    /* --usefulLinkBg: rgba(230, 232, 232, 1); */
    --useful-link-bg: rgba(120, 120, 120, 0.2) !important;
    --useful-link-text: #201D1D;
    --tiles-bg:#ffff !important;
    --descriptionBg : #2d5b75;
    --dashboardLinkBg : #057be9;
    --is-grid-gutter-width:10px;
    
}

[data-theme="dark"]{
    /* --usefulLinkBg : rgba(90, 90, 90, 1); */
    --useful-link-bg: rgba(90, 90, 90, 0.2) !important;
    --useful-link-text: #ffff;
    --tiles-bg:#4e494a !important;
    --descriptionBg : #232a34;
    --dashboardLinkBg : #5a494e;
}
.temp-clr{
    color: #fff !important;
}
.metric-txt-clr{
    color: #fff !important;
}
.compare-txt-clr{
    color: #fff !important;
}

.useFulName .slick-arrow.slick-prev{
    position: relative;
    left: 89vw;
    top: 0vh;
}

.useFulName .slick-arrow.slick-next{
    /* position: relative; */
    left: 91vw;
    top: 0vh;
}
/* .slick-slide .is-no-gutter{
    background: var(--useful-link-bg) !important;
    color: var(--useful-link-text);
} */
.useFulName > div > div{
    color: var(--useful-link-text);
}
.custom{
    background: var(--useful-link-bg) !important;
    color: var(--useful-link-text) !important; 
}

.useFulName .slick-list{
    width: 92%;
    margin: auto;
}
div .is-row.is-margin-auto{
    margin:auto !important;
}
.content_img+div.is-cursor-pointer{
visibility: hidden;
}