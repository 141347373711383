.ant-btn-secondary {
    color: var(--is-secondary-text) !important;
    background-color: var(--is-secondary) !important;
    border-color: var(--is-secondary) !important;
}
.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  color: var(--is-secondary-dark-text) !important;
  background-color: var(--is-secondary-dark) !important;
  border-color: var(--is-secondary-dark) !important;
}
.ant-btn-secondary:active,
.ant-btn-secondary.active {
  color: var(--is-secondary-darker-text) !important;
  background-color: var(--is-secondary-darker) !important;
  border-color: var(--is-secondary-darker) !important;
}
.ant-btn-secondary-disabled,
.ant-btn-secondary.disabled,
.ant-btn-secondary[disabled],
.ant-btn-secondary-disabled:hover,
.ant-btn-secondary.disabled:hover,
.ant-btn-secondary[disabled]:hover,
.ant-btn-secondary-disabled:focus,
.ant-btn-secondary.disabled:focus,
.ant-btn-secondary[disabled]:focus,
.ant-btn-secondary-disabled:active,
.ant-btn-secondary.disabled:active,
.ant-btn-secondary[disabled]:active,
.ant-btn-secondary-disabled.active,
.ant-btn-secondary.disabled.active,
.ant-btn-secondary[disabled].active {
  color: var(--is-secondary-inverse-dark) !important;
  background-color: var(--is-secondary-light) !important;
  border-color: var(--is-secondary-light) !important;
}
.ant-btn-accent {
    color: var(--is-accent-text) !important;
    background-color: var(--is-accent) !important;
    border-color: var(--is-accent) !important;
}
.ant-btn-accent:hover,
.ant-btn-accent:focus {
  color: var(--is-accent-dark-text) !important;
  background-color: var(--is-accent-dark) !important;
  border-color: var(--is-accent-dark) !important;
}
.ant-btn-accent:active,
.ant-btn-accent.active {
  color: var(--is-accent-darker-text) !important;
  background-color: var(--is-accent-darker) !important;
  border-color: var(--is-accent-darker) !important;
}
.ant-btn-accent-disabled,
.ant-btn-accent.disabled,
.ant-btn-accent[disabled],
.ant-btn-accent-disabled:hover,
.ant-btn-accent.disabled:hover,
.ant-btn-accent[disabled]:hover,
.ant-btn-accent-disabled:focus,
.ant-btn-accent.disabled:focus,
.ant-btn-accent[disabled]:focus,
.ant-btn-accent-disabled:active,
.ant-btn-accent.disabled:active,
.ant-btn-accent[disabled]:active,
.ant-btn-accent-disabled.active,
.ant-btn-accent.disabled.active,
.ant-btn-accent[disabled].active {
  color: var(--is-accent-inverse-dark) !important;
  background-color: var(--is-accent-light) !important;
  border-color: var(--is-accent-light) !important;
}
.ant-btn-success {
    color: var(--is-success-text) !important;
    background-color: var(--is-success) !important;
    border-color: var(--is-success) !important;
}
.ant-btn-success:hover,
.ant-btn-success:focus {
  color: var(--is-success-dark-text) !important;
  background-color: var(--is-success-dark) !important;
  border-color: var(--is-success-dark) !important;
}
.ant-btn-success:active,
.ant-btn-success.active {
  color: var(--is-success-darker-text) !important;
  background-color: var(--is-success-darker) !important;
  border-color: var(--is-success-darker) !important;
}
.ant-btn-success-disabled,
.ant-btn-success.disabled,
.ant-btn-success[disabled],
.ant-btn-success-disabled:hover,
.ant-btn-success.disabled:hover,
.ant-btn-success[disabled]:hover,
.ant-btn-success-disabled:focus,
.ant-btn-success.disabled:focus,
.ant-btn-success[disabled]:focus,
.ant-btn-success-disabled:active,
.ant-btn-success.disabled:active,
.ant-btn-success[disabled]:active,
.ant-btn-success-disabled.active,
.ant-btn-success.disabled.active,
.ant-btn-success[disabled].active {
  color: var(--is-success-inverse-light) !important;
  background-color: var(--is-success-light) !important;
  border-color: var(--is-success-light) !important;
}
.ant-btn-warning {
    color: var(--is-warning-text) !important;
    background-color: var(--is-warning) !important;
    border-color: var(--is-warning) !important;
}
.ant-btn-warning:hover,
.ant-btn-warning:focus {
  color: var(--is-warning-dark-text) !important;
  background-color: var(--is-warning-dark) !important;
  border-color: var(--is-warning-dark) !important;
}
.ant-btn-warning:active,
.ant-btn-warning.active {
  color: var(--is-warning-darker-text) !important;
  background-color: var(--is-warning-darker) !important;
  border-color: var(--is-warning-darker) !important;
}
.ant-btn-warning-disabled,
.ant-btn-warning.disabled,
.ant-btn-warning[disabled],
.ant-btn-warning-disabled:hover,
.ant-btn-warning.disabled:hover,
.ant-btn-warning[disabled]:hover,
.ant-btn-warning-disabled:focus,
.ant-btn-warning.disabled:focus,
.ant-btn-warning[disabled]:focus,
.ant-btn-warning-disabled:active,
.ant-btn-warning.disabled:active,
.ant-btn-warning[disabled]:active,
.ant-btn-warning-disabled.active,
.ant-btn-warning.disabled.active,
.ant-btn-warning[disabled].active {
  color: var(--is-warning-inverse-dark) !important;
  background-color: var(--is-warning-light) !important;
  border-color: var(--is-warning-light) !important;
}
.ant-btn-danger {
    color: var(--is-danger-text) !important;
    background-color: var(--is-danger) !important;
    border-color: var(--is-danger) !important;
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: var(--is-danger-dark-text) !important;
  background-color: var(--is-danger-dark) !important;
  border-color: var(--is-danger-dark) !important;
}
.ant-btn-danger:active,
.ant-btn-danger.active {
  color: var(--is-danger-darker-text) !important;
  background-color: var(--is-danger-darker) !important;
  border-color: var(--is-danger-darker) !important;
}
.ant-btn-danger-disabled,
.ant-btn-danger.disabled,
.ant-btn-danger[disabled],
.ant-btn-danger-disabled:hover,
.ant-btn-danger.disabled:hover,
.ant-btn-danger[disabled]:hover,
.ant-btn-danger-disabled:focus,
.ant-btn-danger.disabled:focus,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger-disabled:active,
.ant-btn-danger.disabled:active,
.ant-btn-danger[disabled]:active,
.ant-btn-danger-disabled.active,
.ant-btn-danger.disabled.active,
.ant-btn-danger[disabled].active {
  color: var(--is-danger-inverse-dark) !important;
  background-color: var(--is-danger-light) !important;
  border-color: var(--is-danger-light) !important;
}

.trend-text {
  font-weight: 500;
  margin-left: var(--is-su-1);
  vertical-align: middle;
  line-height: var(--is-line-height-1);
  font-size: var(--is-font-size-xs);
}

.trend-left-text {
  font-weight: 500;
  margin-right: var(--is-su-1);
  vertical-align: middle;
  line-height: var(--is-line-height-1);
}

.trend-positive {
  color: var(--is-success);   
}

.trend-negative {
  color: var(--is-danger);   
}

.trend-neutral {
  color: var(--is-default-inverse-darkest);   
}

.svg-wrapper {
  display: inline-block;
  vertical-align: middle;
  line-height: var(--is-line-height-1);;
}

.svg-wrapper > div {
  display: inline;
}

.semantic-ant-tag {
  font-size: var(--is-font-size-xxs) !important;
  line-height: var(--is-line-height) !important;
  vertical-align: middle !important;
  border: 1.5px solid var(--is-theme-bg-muted) !important;
  border-radius: 2px;
  color: var(--is-primary) !important;
}

.semantic-icon {
  vertical-align: middle !important;
}

.semantic-badge {
  vertical-align: middle !important;
}

.status-badge {
  min-width: var(--is-wu-1);
}

.status-badge .ant-badge-dot {
  width: var(--is-wu-1);
  height: var(--is-hu-1);
}
.status-badge-rounded .ant-badge-dot {
  border-radius: var(--is-size-25);
}

.div-status-badge {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}
.div-status-badge-highlight {
  padding-right: var(--is-su-1);
  border-radius: 2px;
}

.div-status-badge-with-icon {
  line-height: var(--is-line-height-1);
}

.semantic-landing-title {
  font-size: var(--is-big-font-size-10);
  font-family: Roboto Slab;
}

.semantic-landing-title-boxed {
  background-color: var(--is-secondary);
  color: var(--is-secondary-text);
  padding: var(--is-su-2);
  border-radius: var(--is-su-1);
}

.semantic-landing-subtitle {
  font-size: var(--is-font-size-xxl);
  font-family: Roboto Slab;
  vertical-align: middle;
  display: inline-block;
}

.semantic-page-title {
  font-size: var(--is-big-font-size-1);
  font-family: Roboto Slab;
  vertical-align: middle;
  display: inline-block;
}

.semantic-page-subtitle {
  font-size: var(--is-font-size-md);
  font-family: Roboto;
  vertical-align: middle;
  display: inline-block;
}

.semantic-component-title {
  font-size: var(--is-font-size-lg);
  font-family: Roboto;
  vertical-align: middle;
  display: inline-block;
}

.semantic-component-subtitle {
  font-size: var(--is-font-size-sm);
  font-family: Roboto;
  vertical-align: middle;
  display: inline-block;
}

.semantic-component-legend {
  font-size: var(--is-font-size-sm);
  font-family: Roboto Slab;
  vertical-align: middle;
  display: inline-block;
}

.semantic-component-text {
  font-size: var(--is-font-size-sm);
  font-family: Roboto;
  vertical-align: middle;
  display: inline-block;
  font-weight: 400 !important;
}

.semantic-basic-metric-container, .semantic-basic-metric-content {
  display: flex;
  align-items: left;
  /* margin-left: 19px; */
}

.semantic-basic-metric-container.vertical {
  flex-direction: column;
  text-align: center;
}

.semantic-basic-metric-container.vertical .semantic-basic-metric-content {
  flex-wrap: wrap;
  justify-content: center;
}

.semantic-basic-metric-icon {
  flex-shrink: 1;
  /* margin-right: var(--is-su-1); */
}

.semantic-basic-metric-container.vertical .semantic-basic-metric-icon {
  margin: 0 0 var(--is-su-1);
}

.semantic-basic-metric-prefix-text {
  font-family: Roboto Mono;
  font-size: var(--is-font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var( --is-text-inverse-muted);
  vertical-align: middle;
  display: inline-block;
  line-height: var(--is-line-height-sm);
  margin-top: 3px;
  margin-right: var(--is-su-1);
}

.semantic-basic-metric-prefix-tag {
  padding: 0 3px !important;
  margin-top: 3px !important;
  margin-right: var(--is-su-1) !important;
}

.semantic-basic-metric-prefix-status {
  padding-right: var(--is-su-1) !important;
}
.semantic-basic-metric-prefix-status .ant-badge-dot {
  transform: translateY(-50%) !important;
}

.semantic-basic-metric-container.vertical .semantic-basic-metric-prefix-status {
  padding-top: 4px !important;
  margin-left: 0 !important;
}

.semantic-basic-metric-label {
  font-family: var(--is-font-family);
  display: block;
  line-height: var(--is-line-height-sm);
}

.semantic-basic-metric-container .roboto-slab-family {
  font-family: Roboto Slab;
}

.semantic-basic-metric-container .roboto-family {
  font-family: Roboto;
}

.semantic-basic-metric-value {
  font-family: Roboto Slab;
  vertical-align: middle;
  display: inline-block;
  line-height: var(--is-line-height-sm);
  font-weight: var(--is-font-weight-bold);
  margin-right: var(--is-su-1);
}

.semantic-basic-metric-vertical-value {
  flex: 1 1 100%;
  order: -1;
  margin-right: 0px !important;
}

.semantic-basic-metric-suffix {
  font-family: Roboto Slab;
  display: inline-block;
  line-height: var(--is-line-height-sm);
  color: var(--is-text-muted);
  vertical-align: middle;
  margin: 0 var(--is-su-1) 0 0;
  margin-top: 3px;
}

.semantic-basic-metric-container.vertical .semantic-basic-metric-suffix {
  margin-left: 0;
}

.semantic-basic-metric-trend {
  font-weight: var(--is-font-weight);
  margin-top: 2px;
}

.semantic-image-metric-container, .semantic-image-metric-content {
  display: flex;
  align-items: center;
}

.semantic-image-metric-container.vertical {
  flex-direction: column;
  text-align: center;
}

.semantic-image-metric-container.vertical .semantic-image-metric-content {
  flex-wrap: wrap;
  justify-content: center;
}

.semantic-image-metric-label {
  font-size: var(--is-font-size-xxl);
  font-family: Roboto Slab;
  vertical-align: middle;
  display: inline-block;
  line-height: var(--is-line-height-sm);
}

.semantic-image-metric-value {
  font-size: var(--is-big-font-size-5);
  font-family: Roboto Slab;
  line-height: var(--is-line-height-1);
  display: block;
}

.semantic-image-metric-container.vertical .semantic-image-metric-value {
  line-height: var(--is-line-height);
}

.semantic-image-metric-image {
  width: var(--is-wu-9);
  height: var(--is-hu-9);
  max-width: var(--is-size-100);
  margin-right: var(--is-su-4);
}

.semantic-image-metric-icon {
  margin-right: var(--is-su-4);
}

.semantic-image-metric-container.vertical .semantic-image-metric-image {
  margin: 0 0 var(--is-su-1);
}

.semantic-image-metric-container.vertical .semantic-image-metric-icon {
  margin: 0 0 var(--is-su-1);
}

.semantic-rate-metric-rate {
  display: inline-flex !important;
  flex-direction: column-reverse !important;
}

.semantic-metric-card-header-container {
  display: flex;
  align-items: center;
}

.semantic-metric-card-title {
  display: block;
  font-size: var(--is-font-size-lg);
  line-height: var(--is-line-height-xs);
  font-weight: var(--is-font-weight);
}

.semantic-metric-card-subtitle {
  display: block;
  font-size: var(--is-font-size-md);
  line-height: var(--is-line-height-xs);
  font-weight: var(--is-font-weight);
}