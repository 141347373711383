.antd-override-manual-button-primary{
    background: #0c74c9;
}

header.ant-layout-header+div.row{
    background-color: #FAFAFA;
    height: 100vh !important;
}
.ant-form{
    background-color: #ffffff;
    border-radius: 16px;
    filter: drop-shadow( 0px 4px 50px rgba(0, 0, 0, 0.1));
    padding: 30px;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.ant-form-item{
    margin-bottom: 0;
}
.ant-input,.ant-btn{
    height: 50px;
    font-size: 1.2rem;
}
.ant-btn{
    border-radius: 7px;
    font-size: 14px;
    font-size: 1.1rem;
}
.google-btn{
    background: transparent;
    color:#000;
    border: 1px solid #000;
}
.make_center{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}