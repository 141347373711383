/*Fix for Carousel arrow 
Selector was missing in /provider/app.css as per slick carousel*/
.slick-next:before,
.slick-prev:before {
  color: var(--is-primary-darker) !important;
}
.slick-slide > * {
  height: 100%;
}
.slick-slide > div {
  height: 100%;
}
.slick-slider {
  height: 100%;
  position: relative;
}
.slick-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.slick-list,
.slick-track,
.slick-slide {
  height: 100%;
}
