.slick-slide > * {
    height: 100%;
}

.slick-slide > div {
    height: 100%;
}

.slick-prev:before, .slick-next:before {
    color: var(--is-primary) !important;
}

.slick-slider {
    height: 100%;
    position: relative;
}

.slick-slider.carousel-children.slick-initialized {
    width: 100% !important;
}

.slick-dots {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.slick-list, .slick-track, .slick-slide {
    height: 100%;
}
