:root{
  --pageBgColor : linear-gradient(351deg, #EFF4FA 50%, rgba(0, 0, 0, 0) 12%), radial-gradient(farthest-corner at 100% 0%, #f0eff6 80%, #fbf6f8 73%),linear-gradient(-5deg,#F6F8FB 5%, rgba(0, 0, 0, 0) 83%);
}

[data-theme="dark"]{
  --pageBgColor : linear-gradient(-5deg, #373032 40%, rgba(0, 0, 0, 0) 2%), radial-gradient(farthest-corner at 100% 19%, #242323 75%, #232A34 70%),linear-gradient(-5deg,#3B2A20 5%, rgba(0, 0, 0, 0) 2%);
}
.la-primary-text {
  color: #fff;
}

.middleCardHolder{
  width: 97% !important;
}
.stat-goals {
  width: 10em;
  padding: 10px 0;
  background: rgba(13, 49, 118, 0.2);
  border: 1px dashed #4c80e4;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--is-text-inverse);
}
.header-bg-vector {
  position: absolute;
  top: 140px;
  left: 0;
  /* bottom: 80; */
}
.header-bg-right {
  position: absolute;
  top: 0;
  right: 0;
  /* bottom: 80; */
}

.header-bg-vector img {
  height: 250px;
}
.header-bg-right img {
  height: 394px;
  width: 396px;
}
.stat-img {
  text-align: center;
}
.stat-img img {
  width: 60%;
}

.landing-tile .ant-radio-group-small .ant-radio-button-wrapper {
  height: 20px;
  line-height: 20px;
  font-size: 10px;
}
.pageBackground {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
  background-position: top center;
  background-size: cover;
}

.pageBackground img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.pageBackground img.overlayBackground {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.useful-link-bg {
  /* background-color: #f2f8f4; */
}

.la-tab-bg {
  background-color: #031325
}

.ant-tabs-bar.ant-tabs-top-bar.ant-tabs-card-bar {
  border: unset !important
}

.ant-tabs-tab {
  background: unset !important;
  border: unset !important;
  color: #fff !important;
  font-size: var(--is-font-size-md)
}

.ant-tabs-tab-active.ant-tabs-tab {
  color: #fff !important;
  border-top: 5px solid #1E81F9 !important;
  font-size: var(--is-font-size-md)

}

.mainContent-container {
  box-shadow: 0px 10px 10px 2px rgba(0,0,0,0.5);
}

.pageContainer {
  padding: 0px 0px 0px 0px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  position: relative;
  z-index: 3;
  background: var(--pageBgColor);}
.pageContainer-cust {
  padding: 0px 0px 0px 0px;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100vh;
  position: relative;
  z-index: 3;
}

.pageBackground {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
}

.pageBackground img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
}

.pageBackground img.overlayBackground {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.pageContainer .transitHomeLayout {
  padding: 5px 0px 0px 0px;
}

.ant-col.ant-col-15.gutter-row.home-cards {
  width: 50% !important;
}

.transitHomeLayout {
  margin-left: 0px !important;
  /* margin-right: 10% !important; */
}

.siteLogo {
  position: relative;
  margin-bottom: 40px;
  margin-top: -48px;
}

.siteLogo img {
  width: 247px;
}

.userProfileImage {
  height: 75px;
  width: 5%;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.05)),
    drop-shadow(0px 25px 35px rgba(0, 0, 0, 0.03));
  border-radius: 6px;
}

.userProfileImage img {
  height: 100%;
  width: 100%;
  top: -10;
}

.userProfileDetails {
  color: var(--is-text-inverse);
  text-align: right;
  width: 13%;
  font-size: var(--is-font-size-sm);
}

.userProfile {
  margin-top: var(--is-su-2);
  display: flex !important;
  justify-content: flex-end;
}

/*----------  LEFT SECTION ----------*/
.analyticsArea h1 {
  color: #fff !important;
  font-family: 'Roboto Slab', Helvetica;
  font-size: 37px;
  font-style: normal;
  font-weight: normal;
  height: auto;
  letter-spacing: 0.2px;
  line-height: 43px;
  text-align: left;
  text-shadow: 0px 2px 5px rgb(0 0 0 / 5%);
  white-space: normal;
  width: auto;
  margin-bottom: 7px;
}

.analyticsArea p {
  color: #fff;
  font-family: 'Roboto', Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  height: auto;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-align: left;
  text-shadow: 0px 2px 5px rgb(0 0 0 / 5%);
  padding: 0 70px 0 3px;
}

.analyticsBlock {
  background-color: rgba(29, 48, 85, 0.37);
  border-radius: 4px;
  cursor: pointer;
  pointer-events: auto;
  position: relative;
  transition: all 0.2s ease;
  display: block;
  text-align: center;
  padding: 12px 0px;
  margin-bottom: 8px;

  letter-spacing: 0px;
  line-height: 32px;
  text-shadow: 0px 2px 5px rgb(0 0 0 / 5%);
  white-space: nowrap;
  color: #fff;
  font-family: 'Roboto', Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
}

.analyticsBlock:hover {
  transform: translate(0, -5px);
  box-shadow: 0px 12px 5px -8px rgb(0 0 0 / 20%);
  background-color: #13274e;
}

.analyticsBlock img {
  height: 90px;
  margin-bottom: 10px;
}

.exploreAnalyticsArea {
  color: #fff;
  font-family: 'Roboto Slab', Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0.2px;
  text-shadow: 0px 2px 5px rgb(0 0 0 / 5%);
  padding: 15px !important;
}

.exploreAnalyticsArea h3 {
  font-size: 18px;
  line-height: 24px;
  color: #fff;
}

.exploreAnalyticsArea h3 span {
  font-size: 28px;
  letter-spacing: 0.07px;
}

.exploreAnalyticsArea p {
  font-size: 12px;
  line-height: 18px;
}

/*----------  RIGHT SECTION ----------*/
.mainFacts {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 5%);
  padding: 15px;
  position: relative;
  margin-bottom: 20px;
}

.mainFacts h3 {
  font-size: 18px;
  color: rgba(18, 29, 49, 1);
  font-style: normal;
}

.mainFactsTitle {
  position: relative;
  margin-bottom: 30px;
}

.filterArea {
  border-radius: 2px;
  overflow: hidden;
  margin-bottom: 20px;
}

.filterArea .filterOption {
  padding: 3px 5px;
  font-size: 0.8em;
  line-height: 24px;
  background: #f9fbfc;
  border: 1px solid #e8f0f3;
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
}

.filterArea .filterOption.active {
  background: #f2b20c;
  border: 1px solid #f2b20c;
}

.latestNews {
  margin-bottom: -100px;
}

.latestNews .mainFactsTitle {
  margin-bottom: 5px;
}

.mainFacts h3 img {
  margin-right: 12px;
  position: relative;
  top: -2px;
  height: 0.8em;
}

.mainFacts h3 span {
  color: rgba(76, 127, 228, 1);
}

.waterQuality {
  border: 1px solid #e8f0f3;
  margin-bottom: 10px;
}

.waterQuality p {
  padding: 10px;
  letter-spacing: 0px;
  line-height: 22px;
  white-space: break-spaces;
  color: rgba(18, 29, 49, 1);
  font-family: 'Angkor', Helvetica;
  font-size: var(--is-font-size-md);
  font-style: normal;
  font-weight: 400;
  margin-bottom: 20px;
}

.waterQualityTitle {
  position: relative;
  color: #131d32 !important;
  font-family: 'Angkor', Helvetica;
  /* font-size: 45px; */
  font-size: 18px;
  line-height: 32px;
  font-style: normal;
  font-weight: 300;
  text-align: justify;
  padding: 0px 5px;
  background: linear-gradient(180deg, #f5faff 0%, rgba(232, 240, 243, 0) 100%);
}

.waterQualityTitle span {
  position: absolute;
  right: 5px;
  top: 3px;
}

.waterQualityTitle span img {
  width: 15px;
  height: 15px;
}

.mainFactsDashboard .gutter-row {
  margin-bottom: 12px;
}

.mainFactsDashboard .gutter-row .mainFactsNumbers {
  padding: 10px;
  margin-bottom: 30px;
}

.mainFactsNumbers .mainFactsDashboard img {
  height: 50px;
  margin-bottom: 10px;
}

.mainFactsDashboard p,
.mainFactsDashboard h1 {
  margin-bottom: 0px;
}

.mainFactsDashboard p {
  letter-spacing: 0px;
  line-height: 22px;
  white-space: break-spaces;
  color: rgba(18, 29, 49, 1);
  font-family: 'Angkor', Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  /*margin-bottom: 20px;*/
}

.mainFactsDashboard h1 {
  color: rgba(76, 127, 228, 1) !important;
  font-family: 'Angkor', Helvetica;
  font-size: 45px;
  /*font-size   : 230px;*/
  font-style: normal;
  font-weight: 300;
  text-align: justify;
  padding-bottom: 10px;
}

.mainFactsDashboard h1 span {
  font-size: var(--is-font-size-lg);
  margin-left: 15px;
  color: rgba(76, 127, 228, 1) !important;
}

.mainFactsDashboard span {
  color: rgba(27, 33, 36, 1);
  font-family: 'Angkor', Helvetica;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}

.mainFactsDashboard .gutter-row .mainFactsNumbers:hover svg,
.mainFactsDashboard .gutter-row .mainFactsNumbers:hover h1,
.mainFactsDashboard .gutter-row .mainFactsNumbers:hover p,
.mainFactsDashboard .gutter-row .mainFactsNumbers:hover span {
  color: rgba(76, 127, 228, 1) !important;
}

.mainFactsDashboard .gutter-row .mainFactsNumbers:hover img {
  filter: none;
}

.mainFactsDashboard
  .gutter-row
  .mainFactsNumbers:hover
  .progressBar
  .progressBarIcon.active {
  color: transparent;
}

.progressBar .progressBarIcon {
  display: inline-block;
  background-color: rgba(55, 67, 93, 1);
  opacity: 0.2;
  width: calc(18% - 1px);
  height: 7px;
  margin-right: 3px;
}

.progressBar .progressBarIcon.active {
  opacity: 1;
}

.progressBar .progressBarIcon:last-child {
  margin-right: 0px;
}

.mainFactsDashboard .fullDetails {
  text-align: center;
  font-size: 12px;
  padding: 40px 0px;
}

.NewsArea {
  position: relative;
}

.NewsArea img {
  width: 100%;
  margin-bottom: 20px;
}

.NewsArea p {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  font-family: 'Angkor', Helvetica;
  color: #37445e;
  margin-bottom: 0px;
}

.NewsArea p span {
  color: #4c80e4;
}

.NewsArea h1 {
  margin-bottom: 10px;
  color: #131d32;
  font-weight: 400;
  font-family: 'Angkor', Helvetica;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
}

.NewsArea button.moreNews {
  margin-top: 50px;
  text-transform: capitalize;
  padding: 10px;
  height: auto;
  font-weight: 400;
  font-family: 'Angkor', Helvetica;
  font-size: 14px;
  line-height: 18px;
  background: #4c80e4;
  border-radius: 8px;
}

.NewsAction {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.complianceStatus p {
  font-family: 'Angkor', Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: var(--is-font-size-lg);
  line-height: 32px;
  color: #131d32;
  margin-bottom: 0px;
  padding: 0.1em 0.5em 0px 1em;
}

.complianceStatus .progresbar {
  padding: 10px;
  height: 50px;
}
.complianceStatus .progresbar2 {
  padding: 10px;
  height: 80px;
}

.complianceStatus h1 {
  position: relative;
  font-family: 'Angkor', Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: var(--is-font-size-md);
  line-height: 21px;
  color: #131d32 !important;
  padding: 0px 50px 10px 60px;
  text-align: left;
}

.complianceStatus h1 span {
  position: absolute;
  left: 10px;
  top: 5px;
  margin-left: 0px;
  font-family: 'Angkor', Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 40px;
  /* identical to box height, or 111% */
  color: #1b70be;
}

.quickReferanceLinks {
  background: #f9fbfc;
  border: 1px solid #e8f0f3;
  box-sizing: border-box;
  border-radius: 2px;
  padding-bottom: 10px;
}

.quickReferanceLinks p {
  font-family: 'Angkor', Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  color: #131d32;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.quickReferanceLinks ul {
  list-style-type: none;
  padding: 0px 0px 0px 10px;
  margin: 0px;
}

.quickReferanceLinks ul li {
  color: #244c97;
  font-size: 14px;
  line-height: 22px;
  text-decoration: underline;
  cursor: pointer;
}
.ant-btn {
  text-transform: none !important;
}
.top-section-button button {
  min-width: 150px;
}
.cardBlock {
  border-radius: 4px;
  pointer-events: auto;
  position: relative;
  transition: all 0.2s ease;
  display: block;
  text-align: center;
  padding: 12px 0px;
  margin-bottom: 8px;
  letter-spacing: 0px;
  line-height: 32px;
  text-shadow: 0px 2px 5px rgb(0 0 0 / 5%);
  white-space: nowrap;
  color: #fff;
  font-family: 'Roboto', Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  box-shadow: '0 4px 8px 0 rgba(0,0,0,0.2)';
}

.account-receivable {
  width: '100%';
  height: auto;
  background: #21392e;
  border-radius: 10px;
}
.acc-receive {
  font-weight: 300;
  font-size: 19px;
  line-height: 32px;
  letter-spacing: 0.44px;
  color: #ffffff;
}
.revenue {
  width: '100%';
  background: rgba(27, 49, 39, 0.58);
  backdrop-filter: blur(34px);
  padding: 15px 15px 0 15px;
}
.account-receivable .metric-trend span {
  color: #5eff5a !important;
}
.account-receivable .child {
  padding: 15px;
}

.account-receivable .child h3 {
  font-weight: 400;
  font-size: 19px;
  color: #ffffff !important;
}

.account-receivable .metric-name span {
  color: #d4d8d6;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.account-receivable .metric-name {
    width:57%;
} 

.metric-value span {
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.metric-description p {
  font-size: 11px;
  color: #cdd9d3;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.metric-section {
  margin: 10px 15px 0 15px;
}
.tile-metricvalue h5 {
  font-size: 23px;
  color: white !important;
  /* letter-spacing: 0.5px; */
  font-weight: 600;
  line-height: 21px;
}

.tile-metric-value h5 {
  font-size: 23px;
  letter-spacing: 0.5px;
  font-weight: bold;
}

.metric-trend {
  font-size: 10px;
  color: #5eff5a;
}
.metric-trend-negative {
  font-size: 10px;
  color: #b42828;
}
.metric-trend-var {
  font-size: 10px;
  color: #5eff5a;
}

.tile-metricvalue p {
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 12px;
  color: #cdd9d3;
  font-weight: 400;
  letter-spacing: 0.4px;
}

.tile-metricvalue-working-Capital {
  font-size: 30px;
  color: white !important;
  letter-spacing: 0.5px;
  font-weight: bolder;
}

.main-brands {
  margin: 10px 15px;
  background: rgba(27, 49, 39, 0.7);
  backdrop-filter: blur(4px);
  border-radius: 15px;
}

.main-brands-info {
  padding: 0px 5px 20px 40px;
}

.main-brands-navigationMenu ul {
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 3;
  float: right;
}

.main-brands-navigationMenu li {
  float: left;
}

.main-brands-navigationMenu li a {
  display: block;
  color: white;
  text-align: center;
  padding: 6px 25px;
  text-decoration: none;
  font-size: 11px;
}

.revenue-navigationMenu2 ul {
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 3;
  background: #3c4641;
  float: right;
  border-radius: 13px;
}

.revenue-navigationMenu2 li {
  float: left;
}

.revenue-navigationMenu2 li a {
  display: block;
  color: white;
  text-align: center;
  padding: 6px 13px;
  text-decoration: none;
  font-size: 10px;
}

.spatial-destribution ul {
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 3;

  /* float: right; */
  border-radius: 13px;
}

.spatial-destribution li {
  float: left;
}

.spatial-destribution li a {
  display: block;
  color: white;
  text-align: center;
  padding: 6px 13px;
  text-decoration: none;
  font-size: 11px;
}

.spatial-destribution li a.destribution {
  background: #026939 !important;
  border-radius: 20px;
}

.revenue-navigationMenu ul {
  position: relative;
  list-style-type: none;
  /* margin: 0; */
  overflow: hidden;
  padding-left: 0;
  display: flex;
  align-items: center;
}

.revenue-navigationMenu li {
  float: left;
}

.revenue-navigationMenu li a {
  color: white;
  padding: 10px;
  text-decoration: none;
  font-size: 11px;
}

.search-input .ant-input {
  background: #162c22 !important;
  border-radius: 5px !important;
  /* border: 1px solid #7f8b85 !important; */
  border: none !important;
  height: 32px;
}

.search-input .ant-input-affix-wrapper .ant-input-prefix{
color: white !important;
}

.search-input .ant-input-affix-wrapper .ant-input:not(:first-child) {
padding-left: 40px;
}

.main-brands-logo {
  text-align: center;
  /* padding:10px; */
  cursor: pointer;
}

.main-brands-custom-bg {
  background : #234a38 !important;
  border-radius: 7px;
}

.main-brands-delete-icon {
  background: #e7b505;
  border-radius: 7px 7px 0px 0px;
}

.main-brands-inside-section {
  padding: 0px 10px 0px 10px;
}

.main-brands-logo .metric-name span {
  font-weight: 700;
  font-size: 11px;
  line-height: 34px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #5a8967;
  padding-top: 10px;
}

.main-brands-logo .metric-brand-value {
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -1.5px;
  color: #ffffff;
}
.metric-brand-value {
  color: #f1f2f1;
  font-weight: 400;
  font-size: 18px;
}

.all-brands {
  padding: 15px 0px 15px 0px;
}

.min-h-6{
  min-height: 60px;
}
.footer-section {
  padding: 5px 0px 5px 30px;
  background: #1b3127;
}

.footer-section h5 {
  font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
font-size: 10px;
line-height: 16px;
display: flex;
align-items: center;
letter-spacing: 1.5px;
text-transform: uppercase;
color: #5A8970;
  margin-bottom: 0;
}

.main-brands-navigationMenu .geospatial {
  background: #026939;
  border-radius: 20px;
}
.revenue-card .ant-card {
  /* margin: 0px 15px !important; */
  border-radius: 10px;
  height: auto;
  background: #1b3127 !important;
  border: none;
}
.revenue-card-active {
  /* margin: 0px 15px !important; */
  border-radius: 10px;
  background: #234a38 !important;
  border: none;
}
/* 
.metric-trend span {
  color: #8a9590 !important;
} */

.ant-slider-handle {
  background: #34a34a !important;
}

.ant-slider-track {
  background-color: #34a34a !important;
}

.revenu-card-heading span {
  color: #7f8b85;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 400;
  /* padding-bottom: 5px; */
}

.cards {
  margin-top: 20px;
}

.revenue-card .ant-card-body {
  padding: 10px !important;
  /* padding: 0px 0px 0px 18px !important; */
}
.revenue-card-active .ant-card-body {
  /* padding: 18px !important; */
  padding: 0px 0px 0px 10px !important;
}

.ant-slider-rail {
  background-color: #234e3a !important;
}

/* .revenue-description {
  margin: 0px 0px 0px 23px;
} */
.revenue-description p {
  padding-bottom: 12px;
  font-style: normal;
  font-size: 11px;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 0.44px;
}
.revenue-description p span.percent {
  color: white;
  font-weight: 900;
}
.revenue-description p span.short {
  color: #e85e32;
  font-weight: bold;
}

.ant-slider {
  margin: 0px !important;
  padding: 0px !important;
}

.revenue-navigationMenu2 .ytd {
  background: #026939;
  border-radius: 20px;
}
.revenue-navigationMenu2 .qtd {
  background: #026939;
  border-radius: 20px;
}

.capital-img {
  width: 100%;
}

.spa-destribution {
  padding: 15px 15px 0px 0px;
}

.spatial-distribution-heading h3 {
  color: #ffffff !important;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.redingtonAnalyticsArea p {
  color: #fff;
  font-weight: 400;
font-size: 16px;
letter-spacing: 1px;
  height: auto;
  text-align: left;
  line-height: 23px;
  text-shadow: 0px 2px 5px rgb(0 0 0 / 5%);
  padding: 0 102px 0 3px;
}

.redingtonAnalyticsArea h1 {
  color: #fff !important;
  font-size: 38px;
  font-weight: normal;
  height: auto;
  letter-spacing: 0.2px;
  line-height: 43px;
  text-align: left;
  text-shadow: 0px 2px 5px rgb(0 0 0 / 5%);
  white-space: normal;
  width: auto;
  margin-bottom: 12px;
}

.redingtonAnalysis {
  margin: 50px 0px 0px 0px;
}

.target {
  /* background: #019049; */
  width: 528px;
  height: 300px;
  height: 160px;
  padding: 5px;
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
}

.metric-description .echarts-for-react canvas {
  /* top: -40px !important;
  height: 200px !important; */
  top: -104px !important;
}

.detail-modal .ant-modal-content {
  position: relative;
  background-color: #042927 !important;
  background-clip: padding-box;
  border: 0;
  border-radius: 4px;
  -webkit-box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
  box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
  pointer-events: auto;
}

.header-Background {
  background-image: url('../../assest/redington/top-bg-img.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: local;
  background-blend-mode: overlay;
}
.dark-mode .header-Background,
.dark-mode .main-content-Background{
  background-image: url('../../assest/redington/light-theme-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: local;
  background-blend-mode: overlay;
}
.main-content-Background {
  background-image: url('../../assest/redington/bg-img2.JPEG');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: local;
  background-blend-mode: overlay;
}

.main-content-Backgrounds {
  background-image: url('../../assest/transit/summary.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: local;
  background-blend-mode: overlay;
}
.header-img-middle{
  display: flex;
	position: relative;
  height: 82px;
  width: 302px;
}

.header-img-middles{
  display: flex;
	position: relative;
  height: 60px;
  width: 302px;
}

.header-img-middle::before {
  background-image: url('../../assest/redington/logo.png');
  background-position: center;
 position: absolute;
  background-repeat: no-repeat;
    content: "";
	display: inline-block;
		background-repeat: no-repeat;
	background-position: center;
  height: 82px;
  width: 302px;
	background-size: cover;	
 
}
.dark-mode .header-img-middle::before {
  background-image: url('../../assest/redington/logo-light.png');
  background-position: center;
  background-repeat: no-repeat;
}

.header-img-middles::before {
  background-image: url('../../assest/redington/logo.png');
  background-position: center;
 position: absolute;
  background-repeat: no-repeat;
    content: "";
	display: inline-block;
		background-repeat: no-repeat;
	background-position: center;
  height: 82px;
  width: 302px;
	background-size: cover;	
 
}
.dark-mode .header-img-middles::before {
  background-image: url('../../assest/redington/logo-light.png');
  background-position: center;
  background-repeat: no-repeat;
}

.bubble-chart {
  height: 600px;
}

.bubble-chart .echarts-for-react canvas {
  top: 50px !important;
  /* left: 10px !important; */
}

.redingtonAnalyticsArea {
  margin: 0px 0px 55px 0px;
}

.mapWrapper {
  position: absolute;
  left: 0;
  right: 0;
  min-height: -webkit-fill-available;
}
.text-white{
  color: #fff !important;
}

.homeMapWrapper #map {
  height: 500px !important;
}
.compare-partners .ant-select-selection,
.compare-partners .ant-input{
  background: rgba(255, 255, 255, 0.1) !important;
border: 1px solid #E8F0F3;
border-radius: 4px;
}
.compare-partners .ant-input-affix-wrapper .ant-input-suffix{
  color: #fff !important;
}
.compare-table .ant-table-title{
  background-color: transparent !important;
}
.compare-table .ant-table-title{
  color: #fff !important;
}

.content-Background{
  background-image: url('../../assest/redington/light-theme-bg.png');
}

/* useful link bg */
.pageContainer{

}

.firstTileCard {
  margin: 0.5rem !important;
}

.firstTileCard img {
  padding: 10px !important;
  border-radius: var(--is-su-3) !important;
}

.firstTileCard~.middleCard_mainContent_content {
  padding: 40px !important;
  bottom: 80px !important;
}

.firstTileCard~.middleCard_mainContent_content > div {
  padding: 3% 5% !important;
}

.firstTileCard~.middleCard_mainContent_content .middleCard_Actions {
  padding: 1rem 0 !important;
}
.ant-modal.video{
  width: 80vw !important;
}