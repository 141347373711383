.temp-clr{
    color: #fff !important;
}
.metric-txt-clr{
    color: #fff !important;
}
.compare-txt-clr{
    color: #fff !important;
}

.ant-pagination.ant-pagination-simple {
    color: #fff;
}

.ant-pagination-simple-pager>input {
    color: rgba(0,0,0,0.7);
}