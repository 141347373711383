.ant-table-filter-dropdown input[type="checkbox"]{
    position: absolute;
}

.ant-table-title{
    display: flex;
    justify-content: center;
    color: var(--is-default-dark);
    background: var(--is-theme-bg-muted);
    border-bottom: 1px solid var(--is-default-dark);
}

.ant-table-pagination{
    margin: var(--is-su-3) !important;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 8px 5px;
}
/* .ant-table-tbody > tr:nth-child(2n) {
    background-color:#f8fdfc;
} */