/* FORM IO BOOTSTRAP */

/*=> GRID*/
.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px
}

.container:after,
.container:before {
    content: " ";
    display: table
}

.container:after {
    clear: both
}

@media (min-width:768px) {
    .container {
        width: 750px
    }
}

@media (min-width:992px) {
    .container {
        width: 970px
    }
}

@media (min-width:1200px) {
    .container {
        width: 1200px
    }
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px
}

.container-fluid:after,
.container-fluid:before {
    content: " ";
    display: table
}

.container-fluid:after {
    clear: both
}

.row {
    margin-left: -15px;
    margin-right: -15px
}

.row:after,
.row:before {
    content: " ";
    display: table
}

.row:after {
    clear: both
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px
}

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    float: left
}

.col-xs-1 {
    width: 8.33333%
}

.col-xs-2 {
    width: 16.66667%
}

.col-xs-3 {
    width: 25%
}

.col-xs-4 {
    width: 33.33333%
}

.col-xs-5 {
    width: 41.66667%
}

.col-xs-6 {
    width: 50%
}

.col-xs-7 {
    width: 58.33333%
}

.col-xs-8 {
    width: 66.66667%
}

.col-xs-9 {
    width: 75%
}

.col-xs-10 {
    width: 83.33333%
}

.col-xs-11 {
    width: 91.66667%
}

.col-xs-12 {
    width: 100%
}

.col-xs-pull-0 {
    right: auto
}

.col-xs-pull-1 {
    right: 8.33333%
}

.col-xs-pull-2 {
    right: 16.66667%
}

.col-xs-pull-3 {
    right: 25%
}

.col-xs-pull-4 {
    right: 33.33333%
}

.col-xs-pull-5 {
    right: 41.66667%
}

.col-xs-pull-6 {
    right: 50%
}

.col-xs-pull-7 {
    right: 58.33333%
}

.col-xs-pull-8 {
    right: 66.66667%
}

.col-xs-pull-9 {
    right: 75%
}

.col-xs-pull-10 {
    right: 83.33333%
}

.col-xs-pull-11 {
    right: 91.66667%
}

.col-xs-pull-12 {
    right: 100%
}

.col-xs-push-0 {
    left: auto
}

.col-xs-push-1 {
    left: 8.33333%
}

.col-xs-push-2 {
    left: 16.66667%
}

.col-xs-push-3 {
    left: 25%
}

.col-xs-push-4 {
    left: 33.33333%
}

.col-xs-push-5 {
    left: 41.66667%
}

.col-xs-push-6 {
    left: 50%
}

.col-xs-push-7 {
    left: 58.33333%
}

.col-xs-push-8 {
    left: 66.66667%
}

.col-xs-push-9 {
    left: 75%
}

.col-xs-push-10 {
    left: 83.33333%
}

.col-xs-push-11 {
    left: 91.66667%
}

.col-xs-push-12 {
    left: 100%
}

.col-xs-offset-0 {
    margin-left: 0%
}

.col-xs-offset-1 {
    margin-left: 8.33333%
}

.col-xs-offset-2 {
    margin-left: 16.66667%
}

.col-xs-offset-3 {
    margin-left: 25%
}

.col-xs-offset-4 {
    margin-left: 33.33333%
}

.col-xs-offset-5 {
    margin-left: 41.66667%
}

.col-xs-offset-6 {
    margin-left: 50%
}

.col-xs-offset-7 {
    margin-left: 58.33333%
}

.col-xs-offset-8 {
    margin-left: 66.66667%
}

.col-xs-offset-9 {
    margin-left: 75%
}

.col-xs-offset-10 {
    margin-left: 83.33333%
}

.col-xs-offset-11 {
    margin-left: 91.66667%
}

.col-xs-offset-12 {
    margin-left: 100%
}

@media (min-width:768px) {

    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9 {
        float: left
    }

    .col-sm-1 {
        width: 8.33333%
    }

    .col-sm-2 {
        width: 16.66667%
    }

    .col-sm-3 {
        width: 25%
    }

    .col-sm-4 {
        width: 33.33333%
    }

    .col-sm-5 {
        width: 41.66667%
    }

    .col-sm-6 {
        width: 50%
    }

    .col-sm-7 {
        width: 58.33333%
    }

    .col-sm-8 {
        width: 66.66667%
    }

    .col-sm-9 {
        width: 75%
    }

    .col-sm-10 {
        width: 83.33333%
    }

    .col-sm-11 {
        width: 91.66667%
    }

    .col-sm-12 {
        width: 100%
    }

    .col-sm-pull-0 {
        right: auto
    }

    .col-sm-pull-1 {
        right: 8.33333%
    }

    .col-sm-pull-2 {
        right: 16.66667%
    }

    .col-sm-pull-3 {
        right: 25%
    }

    .col-sm-pull-4 {
        right: 33.33333%
    }

    .col-sm-pull-5 {
        right: 41.66667%
    }

    .col-sm-pull-6 {
        right: 50%
    }

    .col-sm-pull-7 {
        right: 58.33333%
    }

    .col-sm-pull-8 {
        right: 66.66667%
    }

    .col-sm-pull-9 {
        right: 75%
    }

    .col-sm-pull-10 {
        right: 83.33333%
    }

    .col-sm-pull-11 {
        right: 91.66667%
    }

    .col-sm-pull-12 {
        right: 100%
    }

    .col-sm-push-0 {
        left: auto
    }

    .col-sm-push-1 {
        left: 8.33333%
    }

    .col-sm-push-2 {
        left: 16.66667%
    }

    .col-sm-push-3 {
        left: 25%
    }

    .col-sm-push-4 {
        left: 33.33333%
    }

    .col-sm-push-5 {
        left: 41.66667%
    }

    .col-sm-push-6 {
        left: 50%
    }

    .col-sm-push-7 {
        left: 58.33333%
    }

    .col-sm-push-8 {
        left: 66.66667%
    }

    .col-sm-push-9 {
        left: 75%
    }

    .col-sm-push-10 {
        left: 83.33333%
    }

    .col-sm-push-11 {
        left: 91.66667%
    }

    .col-sm-push-12 {
        left: 100%
    }

    .col-sm-offset-0 {
        margin-left: 0%
    }

    .col-sm-offset-1 {
        margin-left: 8.33333%
    }

    .col-sm-offset-2 {
        margin-left: 16.66667%
    }

    .col-sm-offset-3 {
        margin-left: 25%
    }

    .col-sm-offset-4 {
        margin-left: 33.33333%
    }

    .col-sm-offset-5 {
        margin-left: 41.66667%
    }

    .col-sm-offset-6 {
        margin-left: 50%
    }

    .col-sm-offset-7 {
        margin-left: 58.33333%
    }

    .col-sm-offset-8 {
        margin-left: 66.66667%
    }

    .col-sm-offset-9 {
        margin-left: 75%
    }

    .col-sm-offset-10 {
        margin-left: 83.33333%
    }

    .col-sm-offset-11 {
        margin-left: 91.66667%
    }

    .col-sm-offset-12 {
        margin-left: 100%
    }
}

@media (min-width:992px) {

    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9 {
        float: left
    }

    .col-md-1 {
        width: 8.33333%
    }

    .col-md-2 {
        width: 16.66667%
    }

    .col-md-3 {
        width: 25%
    }

    .col-md-4 {
        width: 33.33333%
    }

    .col-md-5 {
        width: 41.66667%
    }

    .col-md-6 {
        width: 50%
    }

    .col-md-7 {
        width: 58.33333%
    }

    .col-md-8 {
        width: 66.66667%
    }

    .col-md-9 {
        width: 75%
    }

    .col-md-10 {
        width: 83.33333%
    }

    .col-md-11 {
        width: 91.66667%
    }

    .col-md-12 {
        width: 100%
    }

    .col-md-pull-0 {
        right: auto
    }

    .col-md-pull-1 {
        right: 8.33333%
    }

    .col-md-pull-2 {
        right: 16.66667%
    }

    .col-md-pull-3 {
        right: 25%
    }

    .col-md-pull-4 {
        right: 33.33333%
    }

    .col-md-pull-5 {
        right: 41.66667%
    }

    .col-md-pull-6 {
        right: 50%
    }

    .col-md-pull-7 {
        right: 58.33333%
    }

    .col-md-pull-8 {
        right: 66.66667%
    }

    .col-md-pull-9 {
        right: 75%
    }

    .col-md-pull-10 {
        right: 83.33333%
    }

    .col-md-pull-11 {
        right: 91.66667%
    }

    .col-md-pull-12 {
        right: 100%
    }

    .col-md-push-0 {
        left: auto
    }

    .col-md-push-1 {
        left: 8.33333%
    }

    .col-md-push-2 {
        left: 16.66667%
    }

    .col-md-push-3 {
        left: 25%
    }

    .col-md-push-4 {
        left: 33.33333%
    }

    .col-md-push-5 {
        left: 41.66667%
    }

    .col-md-push-6 {
        left: 50%
    }

    .col-md-push-7 {
        left: 58.33333%
    }

    .col-md-push-8 {
        left: 66.66667%
    }

    .col-md-push-9 {
        left: 75%
    }

    .col-md-push-10 {
        left: 83.33333%
    }

    .col-md-push-11 {
        left: 91.66667%
    }

    .col-md-push-12 {
        left: 100%
    }

    .col-md-offset-0 {
        margin-left: 0%
    }

    .col-md-offset-1 {
        margin-left: 8.33333%
    }

    .col-md-offset-2 {
        margin-left: 16.66667%
    }

    .col-md-offset-3 {
        margin-left: 25%
    }

    .col-md-offset-4 {
        margin-left: 33.33333%
    }

    .col-md-offset-5 {
        margin-left: 41.66667%
    }

    .col-md-offset-6 {
        margin-left: 50%
    }

    .col-md-offset-7 {
        margin-left: 58.33333%
    }

    .col-md-offset-8 {
        margin-left: 66.66667%
    }

    .col-md-offset-9 {
        margin-left: 75%
    }

    .col-md-offset-10 {
        margin-left: 83.33333%
    }

    .col-md-offset-11 {
        margin-left: 91.66667%
    }

    .col-md-offset-12 {
        margin-left: 100%
    }
}

@media (min-width:1200px) {

    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9 {
        float: left
    }

    .col-lg-1 {
        width: 8.33333%
    }

    .col-lg-2 {
        width: 16.66667%
    }

    .col-lg-3 {
        width: 25%
    }

    .col-lg-4 {
        width: 33.33333%
    }

    .col-lg-5 {
        width: 41.66667%
    }

    .col-lg-6 {
        width: 50%
    }

    .col-lg-7 {
        width: 58.33333%
    }

    .col-lg-8 {
        width: 66.66667%
    }

    .col-lg-9 {
        width: 75%
    }

    .col-lg-10 {
        width: 83.33333%
    }

    .col-lg-11 {
        width: 91.66667%
    }

    .col-lg-12 {
        width: 100%
    }

    .col-lg-pull-0 {
        right: auto
    }

    .col-lg-pull-1 {
        right: 8.33333%
    }

    .col-lg-pull-2 {
        right: 16.66667%
    }

    .col-lg-pull-3 {
        right: 25%
    }

    .col-lg-pull-4 {
        right: 33.33333%
    }

    .col-lg-pull-5 {
        right: 41.66667%
    }

    .col-lg-pull-6 {
        right: 50%
    }

    .col-lg-pull-7 {
        right: 58.33333%
    }

    .col-lg-pull-8 {
        right: 66.66667%
    }

    .col-lg-pull-9 {
        right: 75%
    }

    .col-lg-pull-10 {
        right: 83.33333%
    }

    .col-lg-pull-11 {
        right: 91.66667%
    }

    .col-lg-pull-12 {
        right: 100%
    }

    .col-lg-push-0 {
        left: auto
    }

    .col-lg-push-1 {
        left: 8.33333%
    }

    .col-lg-push-2 {
        left: 16.66667%
    }

    .col-lg-push-3 {
        left: 25%
    }

    .col-lg-push-4 {
        left: 33.33333%
    }

    .col-lg-push-5 {
        left: 41.66667%
    }

    .col-lg-push-6 {
        left: 50%
    }

    .col-lg-push-7 {
        left: 58.33333%
    }

    .col-lg-push-8 {
        left: 66.66667%
    }

    .col-lg-push-9 {
        left: 75%
    }

    .col-lg-push-10 {
        left: 83.33333%
    }

    .col-lg-push-11 {
        left: 91.66667%
    }

    .col-lg-push-12 {
        left: 100%
    }

    .col-lg-offset-0 {
        margin-left: 0%
    }

    .col-lg-offset-1 {
        margin-left: 8.33333%
    }

    .col-lg-offset-2 {
        margin-left: 16.66667%
    }

    .col-lg-offset-3 {
        margin-left: 25%
    }

    .col-lg-offset-4 {
        margin-left: 33.33333%
    }

    .col-lg-offset-5 {
        margin-left: 41.66667%
    }

    .col-lg-offset-6 {
        margin-left: 50%
    }

    .col-lg-offset-7 {
        margin-left: 58.33333%
    }

    .col-lg-offset-8 {
        margin-left: 66.66667%
    }

    .col-lg-offset-9 {
        margin-left: 75%
    }

    .col-lg-offset-10 {
        margin-left: 83.33333%
    }

    .col-lg-offset-11 {
        margin-left: 91.66667%
    }

    .col-lg-offset-12 {
        margin-left: 100%
    }
}

/*=> TABLE*/
table {
    font-size: 12px
}

caption {
    padding-top: 8px;
    padding-bottom: 8px;
    color: var(--is-text-muted);
    text-align: left
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 21px
}

.table>thead>tr>th {
    padding: 8px;
    line-height: 1.4;
    border-top: 1px solid var(--is-border)
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td {
    padding: 8px;
    line-height: 1.4;
    vertical-align: top;
    border-top: 1px solid var(--is-border)
}

.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid var(--is-border)
}

.panel>.table>tbody:first-child>tr:first-child td,
.panel>.table>tbody:first-child>tr:first-child th,
.table>caption+thead>tr:first-child>td,
.table>caption+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>td,
.table>thead:first-child>tr:first-child>th {
    border-top: 0
}

.table>tbody+tbody {
    border-top: 2px solid var(--is-border)
}

.table .table {
    background-color: #fff
}

.table-condensed>tbody>tr>td,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>td,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>thead>tr>th {
    padding: 5px
}

.table-bordered,
.table-bordered>tbody>tr>td,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>td,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
    border: 1px solid var(--is-border)
}

.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
    border-bottom-width: 2px
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #f9f9f9
}

.table-hover>tbody>tr:hover,
.table>tbody>tr.active>td,
.table>tbody>tr.active>th,
.table>tbody>tr>td.active,
.table>tbody>tr>th.active,
.table>tfoot>tr.active>td,
.table>tfoot>tr.active>th,
.table>tfoot>tr>td.active,
.table>tfoot>tr>th.active,
.table>thead>tr.active>td,
.table>thead>tr.active>th,
.table>thead>tr>td.active,
.table>thead>tr>th.active {
    background-color: #f5f5f5
}

table col[class*=col-] {
    position: static;
    float: none;
    display: table-column
}

table td[class*=col-],
table th[class*=col-] {
    position: static;
    float: none;
    display: table-cell
}

.table-hover>tbody>tr.active:hover>td,
.table-hover>tbody>tr.active:hover>th,
.table-hover>tbody>tr:hover>.active,
.table-hover>tbody>tr>td.active:hover,
.table-hover>tbody>tr>th.active:hover {
    background-color: var(--is-border)
}

.table>tbody>tr.success>td,
.table>tbody>tr.success>th,
.table>tbody>tr>td.success,
.table>tbody>tr>th.success,
.table>tfoot>tr.success>td,
.table>tfoot>tr.success>th,
.table>tfoot>tr>td.success,
.table>tfoot>tr>th.success,
.table>thead>tr.success>td,
.table>thead>tr.success>th,
.table>thead>tr>td.success,
.table>thead>tr>th.success {
    background-color: #dff0d8
}

.table-hover>tbody>tr.success:hover>td,
.table-hover>tbody>tr.success:hover>th,
.table-hover>tbody>tr:hover>.success,
.table-hover>tbody>tr>td.success:hover,
.table-hover>tbody>tr>th.success:hover {
    background-color: #d0e9c6
}

.table>tbody>tr.info>td,
.table>tbody>tr.info>th,
.table>tbody>tr>td.info,
.table>tbody>tr>th.info,
.table>tfoot>tr.info>td,
.table>tfoot>tr.info>th,
.table>tfoot>tr>td.info,
.table>tfoot>tr>th.info,
.table>thead>tr.info>td,
.table>thead>tr.info>th,
.table>thead>tr>td.info,
.table>thead>tr>th.info {
    background-color: #d9edf7
}

.table-hover>tbody>tr.info:hover>td,
.table-hover>tbody>tr.info:hover>th,
.table-hover>tbody>tr:hover>.info,
.table-hover>tbody>tr>td.info:hover,
.table-hover>tbody>tr>th.info:hover {
    background-color: #c4e3f3
}

.table>tbody>tr.warning>td,
.table>tbody>tr.warning>th,
.table>tbody>tr>td.warning,
.table>tbody>tr>th.warning,
.table>tfoot>tr.warning>td,
.table>tfoot>tr.warning>th,
.table>tfoot>tr>td.warning,
.table>tfoot>tr>th.warning,
.table>thead>tr.warning>td,
.table>thead>tr.warning>th,
.table>thead>tr>td.warning,
.table>thead>tr>th.warning {
    background-color: #fcf8e3
}

.table-hover>tbody>tr.warning:hover>td,
.table-hover>tbody>tr.warning:hover>th,
.table-hover>tbody>tr:hover>.warning,
.table-hover>tbody>tr>td.warning:hover,
.table-hover>tbody>tr>th.warning:hover {
    background-color: #faf2cc
}

.table>tbody>tr.danger>td,
.table>tbody>tr.danger>th,
.table>tbody>tr>td.danger,
.table>tbody>tr>th.danger,
.table>tfoot>tr.danger>td,
.table>tfoot>tr.danger>th,
.table>tfoot>tr>td.danger,
.table>tfoot>tr>th.danger,
.table>thead>tr.danger>td,
.table>thead>tr.danger>th,
.table>thead>tr>td.danger,
.table>thead>tr>th.danger {
    background-color: #f2dede
}

.table-hover>tbody>tr.danger:hover>td,
.table-hover>tbody>tr.danger:hover>th,
.table-hover>tbody>tr:hover>.danger,
.table-hover>tbody>tr>td.danger:hover,
.table-hover>tbody>tr>th.danger:hover {
    background-color: #ebcccc
}

.table-responsive {
    overflow-x: auto;
    min-height: .01%
}

@media screen and (max-width:767px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15.75px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid var(--is-border)
    }

    .table-responsive>.table {
        margin-bottom: 0
    }

    .table-responsive>.table>tbody>tr>td,
    .table-responsive>.table>tbody>tr>th,
    .table-responsive>.table>tfoot>tr>td,
    .table-responsive>.table>tfoot>tr>th,
    .table-responsive>.table>thead>tr>td,
    .table-responsive>.table>thead>tr>th {
        white-space: nowrap
    }

    .table-responsive>.table-bordered {
        border: 0
    }

    .table-responsive>.table-bordered>tbody>tr>td:first-child,
    .table-responsive>.table-bordered>tbody>tr>th:first-child,
    .table-responsive>.table-bordered>tfoot>tr>td:first-child,
    .table-responsive>.table-bordered>tfoot>tr>th:first-child,
    .table-responsive>.table-bordered>thead>tr>td:first-child,
    .table-responsive>.table-bordered>thead>tr>th:first-child {
        border-left: 0
    }

    .table-responsive>.table-bordered>tbody>tr>td:last-child,
    .table-responsive>.table-bordered>tbody>tr>th:last-child,
    .table-responsive>.table-bordered>tfoot>tr>td:last-child,
    .table-responsive>.table-bordered>tfoot>tr>th:last-child,
    .table-responsive>.table-bordered>thead>tr>td:last-child,
    .table-responsive>.table-bordered>thead>tr>th:last-child {
        border-right: 0
    }

    .table-responsive>.table-bordered>tbody>tr:last-child>td,
    .table-responsive>.table-bordered>tbody>tr:last-child>th,
    .table-responsive>.table-bordered>tfoot>tr:last-child>td,
    .table-responsive>.table-bordered>tfoot>tr:last-child>th {
        border-bottom: 0
    }
}

/*=> FORM*/
fieldset,
legend {
    padding: 0;
    border: 0
}

fieldset {
    margin: 0;
    min-width: 0
}

legend {
    width: 100%;
    margin-bottom: 21px;
    font-size: 22.5px;
    line-height: inherit;
    color: #333;
    border-bottom: 1px solid #e5e5e5
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px
}

input[type=checkbox],
input[type=radio] {
    margin: 4px 0 0;
    margin-top: 1px \9;
    line-height: normal
}

input[type=file],
legend {
    display: block
}

input[type=range] {
    display: block;
    width: 100%
}

select[multiple],
select[size],
textarea.form-control {
    height: auto
}

.form-control,
output {
    display: block;
    font-size: 15px;
    line-height: 1.4;
    color: #6f6f6f
}

output {
    padding-top: 9px
}

.form-control {
    /*
    width: 100%;
    height: 39px;
    padding: 8px 12px;
    background-color: #fff;
    background-image: none;
    border: 1px solid var(--is-border);
    border-radius: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s
    
    ANT STYLE */
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: var(--is-component-text);
    font-size: 1rem;
    line-height: 1.5715;
    background-color: var(--is-component-bg);
    background-image: none;
    border: 1px solid var(--is-component-border-color);
    border-radius: var(--is-rounded);
    -webkit-transition: all .3s;
    transition: all .3s
}

.form-control:focus {
    outline: 0;
    border-color: var(--is-primary-dark);
    -webkit-box-shadow: 0 0 0 2px var(--is-primary-darker);
    box-shadow: 0 0 0 2px var(--is-primary-darker);
}
.form-control:hover {
    border-color: var(--is-primary-dark);
}

.form-control::-moz-placeholder {
    color: var(--is-default-inverse);
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: var(--is-default-inverse);
}

.form-control::-webkit-input-placeholder {
    color: var(--is-default-inverse);
}

.form-control::-ms-expand {
    border: 0;
    background-color: transparent
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    color: var(--is-component-text-muted);
    background-color: var(--is-component-bg-muted);
    opacity: 1
}

.form-control[disabled],
fieldset[disabled] .form-control {
    cursor: not-allowed
}

input[type=search] {
    -webkit-appearance: none
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

    input[type=date].form-control,
    input[type=datetime-local].form-control,
    input[type=month].form-control,
    input[type=time].form-control {
        line-height: 1.5715;
    }

    /*
    .input-group-sm input[type=date],
    .input-group-sm input[type=datetime-local],
    .input-group-sm input[type=month],
    .input-group-sm input[type=time],
    .input-group-sm>.input-group-btn>input.btn[type=date],
    .input-group-sm>.input-group-btn>input.btn[type=datetime-local],
    .input-group-sm>.input-group-btn>input.btn[type=month],
    .input-group-sm>.input-group-btn>input.btn[type=time],
    .input-group-sm>input.form-control[type=date],
    .input-group-sm>input.form-control[type=datetime-local],
    .input-group-sm>input.form-control[type=month],
    .input-group-sm>input.form-control[type=time],
    .input-group-sm>input.input-group-addon[type=date],
    .input-group-sm>input.input-group-addon[type=datetime-local],
    .input-group-sm>input.input-group-addon[type=month],
    .input-group-sm>input.input-group-addon[type=time],
    */
    input[type=date].input-sm,
    input[type=datetime-local].input-sm,
    input[type=month].input-sm,
    input[type=time].input-sm {
        line-height: 1.5715;
    }

    /*
    .input-group-lg input[type=date],
    .input-group-lg input[type=datetime-local],
    .input-group-lg input[type=month],
    .input-group-lg input[type=time],
    .input-group-lg>.input-group-btn>input.btn[type=date],
    .input-group-lg>.input-group-btn>input.btn[type=datetime-local],
    .input-group-lg>.input-group-btn>input.btn[type=month],
    .input-group-lg>.input-group-btn>input.btn[type=time],
    .input-group-lg>input.form-control[type=date],
    .input-group-lg>input.form-control[type=datetime-local],
    .input-group-lg>input.form-control[type=month],
    .input-group-lg>input.form-control[type=time],
    .input-group-lg>input.input-group-addon[type=date],
    .input-group-lg>input.input-group-addon[type=datetime-local],
    .input-group-lg>input.input-group-addon[type=month],
    .input-group-lg>input.input-group-addon[type=time],
    */
    input[type=date].input-lg,
    input[type=datetime-local].input-lg,
    input[type=month].input-lg,
    input[type=time].input-lg {
        line-height: 1.5715;
    }
}

.form-group {
    margin-bottom: 1em;
}

.checkbox,
.radio {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px
}

.checkbox label,
.checkbox-inline,
.radio label,
.radio-inline {
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer
}

.checkbox label,
.radio label {
    min-height: 21px
}

.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox],
.checkbox input[type=radio],
.checkbox-inline input[type=radio],
.radio input[type=radio],
.radio-inline input[type=radio],
.radio input[type=checkbox],
.radio-inline input[type=checkbox] {
    position: absolute;
    margin-left: -20px;
    margin-top: 4px \9
}

.checkbox+.checkbox,
.radio+.radio {
    margin-top: -5px
}

.checkbox-inline,
.radio-inline {
    position: relative;
    display: inline-block;
    vertical-align: middle
}

.checkbox-inline+.checkbox-inline,
.radio-inline+.radio-inline {
    margin-top: 0;
    margin-left: 10px
}

.checkbox-inline.disabled,
.checkbox.disabled label,
.radio-inline.disabled,
.radio.disabled label,
fieldset[disabled] .checkbox label,
fieldset[disabled] .checkbox-inline,
fieldset[disabled] .radio label,
fieldset[disabled] .radio-inline,
fieldset[disabled] input[type=checkbox],
fieldset[disabled] input[type=radio],
input[type=checkbox].disabled,
input[type=checkbox][disabled],
input[type=radio].disabled,
input[type=radio][disabled] {
    cursor: not-allowed
}

.form-control-static {
    padding-top: 9px;
    padding-bottom: 9px;
    margin-bottom: 0;
    min-height: 36px
}

.form-control-static.input-lg,
.form-control-static.input-sm,
.input-group-lg>.form-control-static.form-control,
.input-group-lg>.form-control-static.input-group-addon,
.input-group-lg>.input-group-btn>.form-control-static.btn,
.input-group-sm>.form-control-static.form-control,
.input-group-sm>.form-control-static.input-group-addon,
.input-group-sm>.input-group-btn>.form-control-static.btn {
    padding-left: 0;
    padding-right: 0
}

.form-group-sm .form-control,
.input-group-sm>.form-control,
.input-sm {
    padding: 0 7px;
    line-height: 1.5715;
    /*
    height: 36px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 0
    */
}

.input-group-sm>.input-group-addon {
    /*
    height: 36px;
    line-height: 1.5
    */
    line-height: 1.5715;
}

.input-group-sm>.input-group-btn>.btn {
    padding: 0 7px;
    line-height: 1.5715;
    /*
    height: 36px;
    font-size: 12px;
    border-radius: 0
    */
}

.input-group-sm>.input-group-btn>select.btn,
.input-group-sm>select.form-control,
.input-group-sm>select.input-group-addon,
select.input-sm {
    /*
    height: 36px;
    line-height: 36px
    */
    line-height: 1.5715;
}

.form-group-sm select[multiple].form-control,
.form-group-sm textarea.form-control,
.input-group-sm>.input-group-btn>select.btn[multiple],
.input-group-sm>.input-group-btn>textarea.btn,
.input-group-sm>select.form-control[multiple],
.input-group-sm>select.input-group-addon[multiple],
.input-group-sm>textarea.form-control,
.input-group-sm>textarea.input-group-addon,
select[multiple].input-sm,
textarea.input-sm {
    height: auto
}

.form-group-sm select.form-control {
    /*
    height: 36px;
    line-height: 36px
    */
}

.form-group-sm .form-control-static {
    padding: 0 7px;
    line-height: 1.5715;
    /* height: 36px;
    min-height: 33px;
    font-size: 12px;
    line-height: 1.5 */
}

.form-group-lg .form-control,
.input-group-lg>.form-control,
.input-lg {
    /* height: 60px;
    padding: 16px 20px;
    font-size: 19px;
    line-height: 1.33333;
    border-radius: 0 */
    padding: 6.5px 11px;
    font-size: 16px;
}

.input-group-lg>.input-group-addon {
    /* height: 60px;
    line-height: 1.33333 */
    padding: 6.5px 11px;
    font-size: 16px;
}

.input-group-lg>.input-group-btn>.btn {
    /* height: 60px;
    padding: 16px 20px;
    font-size: 19px;
    line-height: 1.33333;
    border-radius: 0 */
    padding: 6.5px 11px;
    font-size: 16px;
}

.input-group-lg>.input-group-btn>select.btn,
.input-group-lg>select.form-control,
.input-group-lg>select.input-group-addon,
select.input-lg {
    /* height: 60px;
    line-height: 60px */
    padding: 6.5px 11px;
    font-size: 16px;
}

.form-group-lg select[multiple].form-control,
.form-group-lg textarea.form-control,
.input-group-lg>.input-group-btn>select.btn[multiple],
.input-group-lg>.input-group-btn>textarea.btn,
.input-group-lg>select.form-control[multiple],
.input-group-lg>select.input-group-addon[multiple],
.input-group-lg>textarea.form-control,
.input-group-lg>textarea.input-group-addon,
select[multiple].input-lg,
textarea.input-lg {
    height: auto
}

.form-group-lg select.form-control {
    /* height: 60px;
    line-height: 60px */
    padding: 6.5px 11px;
    font-size: 16px;
}

.form-group-lg .form-control-static {
    /* height: 60px;
    min-height: 40px;
    padding: 17px 20px;
    font-size: 19px;
    line-height: 1.33333 */
    padding: 6.5px 11px;
    font-size: 16px;
}

.has-feedback {
    position: relative
}

.has-feedback .form-control {
    padding-right: 48.75px
}

.form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 39px;
    height: 39px;
    line-height: 39px;
    text-align: center;
    pointer-events: none
}

.form-group-lg .form-control+.form-control-feedback,
.input-group-lg+.form-control-feedback,
.input-group-lg>.form-control+.form-control-feedback,
.input-group-lg>.input-group-addon+.form-control-feedback,
.input-group-lg>.input-group-btn>.btn+.form-control-feedback,
.input-lg+.form-control-feedback {
    /* width: 60px;
    height: 60px;
    line-height: 60px */
    padding: 6.5px;
    font-size: 16px;
    line-height: 1.5715;
}

.form-group-sm .form-control+.form-control-feedback,
.input-group-sm+.form-control-feedback,
.input-group-sm>.form-control+.form-control-feedback,
.input-group-sm>.input-group-addon+.form-control-feedback,
.input-group-sm>.input-group-btn>.btn+.form-control-feedback,
.input-sm+.form-control-feedback {
    /* width: 36px;
    height: 36px;
    line-height: 36px */
    padding: 0 2px;
    line-height: 1.5715;
}

.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .control-label,
.has-success .help-block,
/*==> Adjustment*/
.has-success > .form-text.text-muted,
.has-success .radio,
.has-success .radio-inline,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label {
    color:var(--is-success)
}

.has-success .form-control {
    border-color:var(--is-success);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-success .form-control:focus {
    border-color:var(--is-success-dark);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #85d0a1
}

.has-success .input-group-addon {
    color:var(--is-success);
    border-color:var(--is-success);
    background-color: #dff0d8
}

.has-success .form-control-feedback {
    color:var(--is-success)
}

.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .control-label,
.has-warning .help-block,
/*==> Adjustment*/
.has-warning > .form-text.text-muted,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label {
    color: var(--is-warning)
}

.has-warning .form-control {
    border-color: var(--is-warning);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-warning .form-control:focus {
    border-color: var(--is-warning-dark);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #febc53
}

.has-warning .input-group-addon {
    color: var(--is-warning);
    border-color: var(--is-warning);
    background-color: #fcf8e3
}

.has-warning .form-control-feedback {
    color: var(--is-warning)
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
/*==> Adjustment*/
.has-error > .form-text.text-muted,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
    color: var(--is-danger)
}

.has-error .form-control {
    border-color: var(--is-danger);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-error .form-control:focus {
    border-color:var(--is-danger-dark);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #f79483
}

.has-error .input-group-addon {
    color: var(--is-danger);
    border-color: var(--is-danger);
    background-color: #f2dede
}

.has-error .form-control-feedback {
    color: var(--is-danger)
}

.has-feedback label~.form-control-feedback {
    top: 26px
}

.has-feedback label.sr-only~.form-control-feedback {
    top: 0
}

.help-block,
/*==> Adjustment*/
.form-group > .form-text.text-muted {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: var(--is-text-muted)
}

@media (min-width:768px) {
    .form-inline .form-group {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }

    .form-inline .form-control-static {
        display: inline-block
    }

    .form-inline .input-group {
        display: inline-table;
        vertical-align: middle
    }

    .form-inline .input-group .form-control,
    .form-inline .input-group .input-group-addon,
    .form-inline .input-group .input-group-btn {
        width: auto
    }

    .form-inline .input-group>.form-control {
        width: 100%
    }

    .form-inline .control-label {
        margin-bottom: 0;
        vertical-align: middle
    }

    .form-inline .checkbox,
    .form-inline .radio {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        vertical-align: middle
    }

    .form-inline .checkbox label,
    .form-inline .radio label {
        padding-left: 0
    }

    .form-inline .checkbox input[type=checkbox],
    .form-inline .radio input[type=radio] {
        position: relative;
        margin-left: 0
    }

    .form-inline .has-feedback .form-control-feedback {
        top: 0
    }
}

.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 9px
}

.form-horizontal .checkbox,
.form-horizontal .radio {
    min-height: 30px
}

.form-horizontal .form-group {
    margin-left: -15px;
    margin-right: -15px
}

.btn-toolbar:after,
.btn-toolbar:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before {
    content: " ";
    display: table
}

.btn-toolbar:after,
.form-horizontal .form-group:after {
    clear: both
}

@media (min-width:768px) {
    .form-horizontal .control-label {
        text-align: right;
        margin-bottom: 0;
        padding-top: 9px
    }
}

.form-horizontal .has-feedback .form-control-feedback {
    right: 15px
}

@media (min-width:768px) {
    .form-horizontal .form-group-lg .control-label {
        padding-top: 17px;
        font-size: 19px
    }

    .form-horizontal .form-group-sm .control-label {
        padding-top: 9px;
        font-size: 12px
    }
}

/*=> BUTTON*/
.btn {
    /* display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    font-size: 15px;
    line-height: 1.4;
    border-radius: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
    ANT-BTN */
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .015);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .015);
    cursor: pointer;
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: var(--is-font-size-xs);
    border-radius: var(--is-rounded);
    color: var(--is-text);
    background: var(--is-theme-bg);
    border: 1px solid var(--is-border);
    text-transform: uppercase;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

.btn.focus,
.btn:focus,
.btn:hover {
    color: var(--is-primary-light);
    background-color: var(--is-theme-bg);
    border-color: var(--is-primary-light);
    text-decoration: none
}

.btn.active,
.btn:active {
    color: var(--is-primary);
    background-image: none;
    background-color: var(--is-theme-bg);
    outline: 0;
    border-color: var(--is-primary);
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    /* cursor: not-allowed;
    opacity: .65;
    filter: alpha(opacity=65);
    box-shadow: none */
    color: var(--is-component-text-muted);
    background-color: var(--is-component-bg-muted);
    border-color: var(--is-border);
}

a.btn.disabled,
fieldset[disabled] a.btn {
    pointer-events: none
}

.btn-primary {
    color: var(--is-primary-text);
    background-color: var(--is-primary);
    border-color: var(--is-primary);
}

.btn-primary.focus,
.btn-primary:focus {
    color: var(--is-primary-text);
    background-color: var(--is-primary-dark);
    border-color: var(--is-primary-darkest)
}

.btn-primary.active,
.btn-primary:active,
.btn-primary:hover,
.open>.btn-primary.dropdown-toggle {
    color: var(--is-primary-text);
    background-color: var(--is-primary-dark);
    border-color: var(--is-primary-darker)
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.btn-primary.dropdown-toggle.focus,
.open>.btn-primary.dropdown-toggle:focus,
.open>.btn-primary.dropdown-toggle:hover {
    color: var(--is-primary-text);
    background-color: var(--is-primary-darker);
    border-color: var(--is-primary-darkest)
}

.btn-primary.active,
.btn-primary:active,
.open>.btn-primary.dropdown-toggle {
    background-image: none
}

.btn-primary.disabled.focus,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled].focus,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
    background-color:var(--is-primary);
    border-color: var(--is-primary)
}

.btn-primary .badge {
    color:var(--is-primary);
    background-color: #fff
}

.btn-success {
    color:var(--is-success-text);
    background-color:var(--is-success);
    border-color: var(--is-success)
}

.btn-success.focus,
.btn-success:focus {
    color:var(--is-success-text);
    background-color:var(--is-success-dark);
    border-color: var(--is-success-darkest)
}

.btn-success.active,
.btn-success:active,
.btn-success:hover,
.open>.btn-success.dropdown-toggle {
    color: var(--is-success-text);
    background-color:var(--is-success-dark);
    border-color: var(--is-success-darker)
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.btn-success.dropdown-toggle.focus,
.open>.btn-success.dropdown-toggle:focus,
.open>.btn-success.dropdown-toggle:hover {
    color: var(--is-success-text);
    background-color: var(--is-success-darker);
    border-color: var(--is-success-darkest)
}

.btn-success.active,
.btn-success:active,
.open>.btn-success.dropdown-toggle {
    background-image: none
}

.btn-success.disabled.focus,
.btn-success.disabled:focus,
.btn-success.disabled:hover,
.btn-success[disabled].focus,
.btn-success[disabled]:focus,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:hover {
    background-color:var(--is-success);
    border-color: var(--is-success)
}

.btn-success .badge {
    color:var(--is-success);
    background-color: #fff
}

.btn-info {
    color: var(--is-info-text);
    background-color:var(--is-info);
    border-color: var(--is-info)
}

.btn-info.focus,
.btn-info:focus {
    color: var(--is-info-text);
    background-color:var(--is-info-dark);
    border-color: var(--is-info-darkest)
}

.btn-info.active,
.btn-info:active,
.btn-info:hover,
.open>.btn-info.dropdown-toggle {
    color: var(--is-info-text);
    background-color:var(--is-info-dark);
    border-color:var(--is-info-darker)
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info:active.focus,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.btn-info.dropdown-toggle.focus,
.open>.btn-info.dropdown-toggle:focus,
.open>.btn-info.dropdown-toggle:hover {
    color: var(--is-info-text);
    background-color:var(--is-info-darker);
    border-color: var(--is-info-darkest)
}

.btn-info.active,
.btn-info:active,
.open>.btn-info.dropdown-toggle {
    background-image: none
}

.btn-info.disabled.focus,
.btn-info.disabled:focus,
.btn-info.disabled:hover,
.btn-info[disabled].focus,
.btn-info[disabled]:focus,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:hover {
    background-color:var(--is-info);
    border-color: var(--is-info)
}

.btn-info .badge {
    color:var(--is-info);
    background-color: #fff
}

.btn-warning {
    color:var(--is-warning-text);
    background-color: var(--is-warning);
    border-color:var(--is-warning)
}

.btn-warning.focus,
.btn-warning:focus {
    color:var(--is-warning-text);
    background-color: var(--is-warning-dark);
    border-color:var(--is-warning-darkest)
}

.btn-warning.active,
.btn-warning:active,
.btn-warning:hover,
.open>.btn-warning.dropdown-toggle {
    color: var(--is-warning-text);
    background-color: var(--is-warning-dark);
    border-color: var(--is-warning-darker)
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning:active.focus,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.btn-warning.dropdown-toggle.focus,
.open>.btn-warning.dropdown-toggle:focus,
.open>.btn-warning.dropdown-toggle:hover {
    color: var(--is-warning-text);
    background-color: var(--is-warning-darker);
    border-color:var(--is-warning-darkest)
}

.btn-warning.active,
.btn-warning:active,
.open>.btn-warning.dropdown-toggle {
    background-image: none
}

.btn-warning.disabled.focus,
.btn-warning.disabled:focus,
.btn-warning.disabled:hover,
.btn-warning[disabled].focus,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:hover {
    background-color: var(--is-warning);
    border-color:var(--is-warning)
}

.btn-warning .badge {
    color: var(--is-warning);
    background-color: #fff
}

.btn-danger {
    color: var(--is-danger-text);
    background-color: var(--is-danger);
    border-color:var(--is-danger)
}

.btn-danger.focus,
.btn-danger:focus {
    color: var(--is-danger-text);
    background-color:var(--is-danger-dark);
    border-color: var(--is-danger-darkest)
}

.btn-danger.active,
.btn-danger:active,
.btn-danger:hover,
.open>.btn-danger.dropdown-toggle {
    color: var(--is-danger-text);
    background-color:var(--is-danger-dark);
    border-color: var(--is-danger-darker)
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger:active.focus,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.btn-danger.dropdown-toggle.focus,
.open>.btn-danger.dropdown-toggle:focus,
.open>.btn-danger.dropdown-toggle:hover {
    color: var(--is-danger-text);
    background-color: var(--is-danger-darker);
    border-color: var(--is-danger-darkest)
}

.btn-danger.active,
.btn-danger:active,
.open>.btn-danger.dropdown-toggle {
    background-image: none
}

.btn-danger.disabled.focus,
.btn-danger.disabled:focus,
.btn-danger.disabled:hover,
.btn-danger[disabled].focus,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:hover {
    background-color: var(--is-danger);
    border-color:var(--is-danger)
}

.btn-danger .badge {
    color: var(--is-danger);
    background-color: #fff
}

.btn-link {
    color:var(--is-primary);
    font-weight: 400;
    border-radius: 0
}

.btn-link,
.btn-link.active,
.btn-link:active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
    background-color: transparent;
    box-shadow: none
}

.btn-link,
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
    border-color: transparent
}

.btn-link:focus,
.btn-link:hover {
    color:var(--is-primary);
    text-decoration: underline;
    background-color: transparent
}

.btn-link[disabled]:focus,
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:hover {
    color: var(--is-text-muted);
    text-decoration: none
}

.btn-group-lg>.btn,
.btn-lg {
    font-size: 19px;
    line-height: 1.33333;
    border-radius: 0
}

.btn-group-sm>.btn,
.btn-group-xs>.btn,
.btn-sm,
.btn-xs {
    font-size: 12px;
    line-height: 1.5;
    border-radius: 0
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: 5px
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
    width: 100%
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-block;
    vertical-align: middle
}

.btn-group>.btn {
    float: left
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    position: relative
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
    z-index: 2
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group {
    margin-left: -1px
}

.btn-toolbar {
    margin-left: -5px
}

.btn-toolbar .btn,
.btn-toolbar .btn-group,
.btn-toolbar .input-group {
    float: left
}

.btn-toolbar>.btn,
.btn-toolbar>.btn-group,
.btn-toolbar>.input-group {
    margin-left: 5px
}

.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0
}

.btn-group>.btn:first-child {
    margin-left: 0
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.btn-group>.btn:last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.btn-group>.btn-group {
    float: left
}

.btn-group>.btn-group:not(:first-child):not(:last-child)>.btn {
    border-radius: 0
}

.btn-group>.btn-group:first-child:not(:last-child)>.btn:last-child,
.btn-group>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
    outline: 0
}

.btn-group>.btn+.dropdown-toggle {
    padding-left: 8px;
    padding-right: 8px
}

.btn-group-lg.btn-group>.btn+.dropdown-toggle,
.btn-group>.btn-lg+.dropdown-toggle {
    padding-left: 12px;
    padding-right: 12px
}

.btn-group.open .dropdown-toggle {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
}

.btn-group.open .dropdown-toggle.btn-link {
    box-shadow: none
}

.btn .caret {
    margin-left: 0
}

.btn-group-lg>.btn .caret,
.btn-lg .caret {
    border-width: 5px 5px 0
}

.dropup .btn-group-lg>.btn .caret,
.dropup .btn-lg .caret {
    border-width: 0 5px 5px
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group,
.btn-group-vertical>.btn-group>.btn {
    display: block;
    width: 100%;
    max-width: 100%;
    float: none
}

.btn-group-vertical>.btn-group:after,
.btn-group-vertical>.btn-group:before,
.nav:after,
.nav:before {
    content: " ";
    display: table
}

.btn-group-vertical>.btn-group:after,
.nav:after {
    clear: both
}

.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
    margin-top: -1px;
    margin-left: 0
}

.btn-group-vertical>.btn-group:not(:first-child):not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:first-child):not(:last-child) {
    border-radius: 0
}

.btn-group-vertical>.btn:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn:last-child:not(:first-child) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:first-child:not(:last-child)>.btn:last-child,
.btn-group-vertical>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:last-child:not(:first-child)>.btn:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.btn-group-justified,
.input-group {
    display: table;
    border-collapse: separate
}

.btn-group-justified {
    width: 100%;
    table-layout: fixed
}

.btn-group-justified>.btn,
.btn-group-justified>.btn-group {
    float: none;
    display: table-cell;
    width: 1%
}

.btn-group-justified>.btn-group .btn {
    width: 100%
}

.btn-group-justified>.btn-group .dropdown-menu {
    left: auto
}

[data-toggle=buttons]>.btn input[type=checkbox],
[data-toggle=buttons]>.btn input[type=radio],
[data-toggle=buttons]>.btn-group>.btn input[type=checkbox],
[data-toggle=buttons]>.btn-group>.btn input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

/*=> INPUT GROUP
===> SUBSTITUÍDO PELA VERSÃO ATUAL DO BOOTSTRAP
.input-group {
    position: relative
}

.input-group[class*=col-] {
    float: none;
    padding-left: 0;
    padding-right: 0
}

.input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0
}

.input-group .form-control:focus {
    z-index: 3
}

.input-group .form-control,
.input-group-addon,
.input-group-btn {
    display: table-cell
}

.input-group .form-control:not(:first-child):not(:last-child),
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child) {
    border-radius: 0
}

.input-group-addon {
    white-space: nowrap
}

.input-group-addon,
.input-group-btn {
    width: 1%;
    vertical-align: middle
}

.input-group-addon {
    padding: 8px 12px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1;
    color: var(--is-default);
    text-align: center;
    background-color: var(--is-default-inverse);
    border: 1px solid var(--is-border);
    border-radius: 0
}

.input-group-addon.input-sm,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.input-group-addon.btn {
    padding: 8px 12px;
    font-size: 12px;
    border-radius: 0
}

.input-group-addon.input-lg,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.input-group-addon.btn {
    padding: 16px 20px;
    font-size: 19px;
    border-radius: 0
}

.input-group-addon input[type=checkbox],
.input-group-addon input[type=radio] {
    margin-top: 0
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group>.btn,
.input-group-btn:first-child>.dropdown-toggle,
.input-group-btn:last-child>.btn-group:not(:last-child)>.btn,
.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.input-group-addon:first-child {
    border-right: 0
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:first-child>.btn-group:not(:first-child)>.btn,
.input-group-btn:first-child>.btn:not(:first-child),
.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group>.btn,
.input-group-btn:last-child>.dropdown-toggle {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.input-group-addon:last-child {
    border-left: 0
}

.input-group-btn {
    font-size: 0;
    white-space: nowrap
}

.input-group-btn,
.input-group-btn>.btn {
    position: relative
}

.input-group-btn>.btn+.btn {
    margin-left: -1px
}

.input-group-btn>.btn:active,
.input-group-btn>.btn:focus,
.input-group-btn>.btn:hover {
    z-index: 2
}

.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group {
    margin-right: -1px
}

.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group {
    z-index: 2;
    margin-left: -1px
}

BOOTSTRAP V4.4
*/

.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
    position: relative;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control,
.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control,
.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.form-control {
    margin-left: -1px
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
    z-index: 3
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4
}

.input-group>.custom-select:not(:last-child),
.input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.custom-file {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group-append,
.input-group-prepend {
    display: -ms-flexbox;
    display: flex
}

.input-group-append .btn,
.input-group-prepend .btn {
    position: relative;
    z-index: 2
}

.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
    z-index: 3
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
    margin-left: -1px
}

.input-group-prepend {
    margin-right: -1px
}

.input-group-append {
    margin-left: -1px
}

.input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem
}

.input-group-text input[type=checkbox],
.input-group-text input[type=radio] {
    margin-top: 0
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control:not(textarea) {
    height: calc(1.5em + 1rem + 2px)
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control:not(textarea) {
    height: calc(1.5em + .5rem + 2px)
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
    padding-right: 1.75rem
}

.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}


/*=> LIST GROUP*/
.list-group {
    margin-bottom: 20px;
    padding-left: 0
}

.list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd
}

.list-group-item:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

a.list-group-item,
button.list-group-item {
    color: #555
}

a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover {
    text-decoration: none;
    color: #555;
    background-color: #f5f5f5
}

button.list-group-item {
    width: 100%;
    text-align: left
}

.list-group-item.disabled,
.list-group-item.disabled:focus,
.list-group-item.disabled:hover {
    background-color: #eee;
    color: #999;
    cursor: not-allowed
}

.list-group-item.disabled .list-group-item-heading,
.list-group-item.disabled:focus .list-group-item-heading,
.list-group-item.disabled:hover .list-group-item-heading {
    color: inherit
}

.list-group-item.disabled .list-group-item-text,
.list-group-item.disabled:focus .list-group-item-text,
.list-group-item.disabled:hover .list-group-item-text {
    color: #999
}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
    z-index: 2;
    color: #fff;
    background-color: #008cba;
    border-color: #008cba
}

.list-group-item.active .list-group-item-heading,
.list-group-item.active .list-group-item-heading>.small,
.list-group-item.active .list-group-item-heading>small,
.list-group-item.active:focus .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading>.small,
.list-group-item.active:focus .list-group-item-heading>small,
.list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading>.small,
.list-group-item.active:hover .list-group-item-heading>small {
    color: inherit
}

.list-group-item.active .list-group-item-text,
.list-group-item.active:focus .list-group-item-text,
.list-group-item.active:hover .list-group-item-text {
    color: #87e1ff
}

.list-group-item-success {
    color: #43ac6a;
    background-color: #dff0d8
}

a.list-group-item-success,
button.list-group-item-success {
    color: #43ac6a
}

a.list-group-item-success:focus,
a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
    color: #43ac6a;
    background-color: #d0e9c6
}

a.list-group-item-success.active,
a.list-group-item-success.active:focus,
a.list-group-item-success.active:hover,
button.list-group-item-success.active,
button.list-group-item-success.active:focus,
button.list-group-item-success.active:hover {
    color: #fff;
    background-color: #43ac6a;
    border-color: #43ac6a
}

.list-group-item-info {
    color: #5bc0de;
    background-color: #d9edf7
}

a.list-group-item-info,
button.list-group-item-info {
    color: #5bc0de
}

a.list-group-item-info:focus,
a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
    color: #5bc0de;
    background-color: #c4e3f3
}

a.list-group-item-info.active,
a.list-group-item-info.active:focus,
a.list-group-item-info.active:hover,
button.list-group-item-info.active,
button.list-group-item-info.active:focus,
button.list-group-item-info.active:hover {
    color: #fff;
    background-color: #5bc0de;
    border-color: #5bc0de
}

.list-group-item-warning {
    color: #e99002;
    background-color: #fcf8e3
}

a.list-group-item-warning,
button.list-group-item-warning {
    color: #e99002
}

a.list-group-item-warning:focus,
a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
    color: #e99002;
    background-color: #faf2cc
}

a.list-group-item-warning.active,
a.list-group-item-warning.active:focus,
a.list-group-item-warning.active:hover,
button.list-group-item-warning.active,
button.list-group-item-warning.active:focus,
button.list-group-item-warning.active:hover {
    color: #fff;
    background-color: #e99002;
    border-color: #e99002
}

.list-group-item-danger {
    color: #f04124;
    background-color: #f2dede
}

a.list-group-item-danger,
button.list-group-item-danger {
    color: #f04124
}

a.list-group-item-danger:focus,
a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
    color: #f04124;
    background-color: #ebcccc
}

a.list-group-item-danger.active,
a.list-group-item-danger.active:focus,
a.list-group-item-danger.active:hover,
button.list-group-item-danger.active,
button.list-group-item-danger.active:focus,
button.list-group-item-danger.active:hover {
    color: #fff;
    background-color: #f04124;
    border-color: #f04124
}

.list-group-item-heading {
    margin-top: 0;
    margin-bottom: 5px
}

.list-group-item-text {
    margin-bottom: 0;
    line-height: 1.3
}

/*=> PANEL
===> SUBSTITUÍDO POR 'CARD'
.panel {
    margin-bottom: 21px;
    background-color: var(--is-component-bg);
    border: 1px solid transparent;
    border-radius: 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05)
}

.panel-body {
    padding: 15px
}

.panel-body:after,
.panel-body:before {
    content: " ";
    display: table
}

.panel-body:after {
    clear: both
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent
}

.panel-heading>.dropdown .dropdown-toggle,
.panel-title,
.panel-title>.small,
.panel-title>.small>a,
.panel-title>a,
.panel-title>small,
.panel-title>small>a {
    color: inherit
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 17px
}

.panel-footer {
    padding: 10px 15px;
    background-color: var(--is-component-bg-muted);
    border-top: 1px solid var(--is-component-border-color);
    border-bottom-right-radius: -1;
    border-bottom-left-radius: -1
}

.panel>.list-group,
.panel>.panel-collapse>.list-group {
    margin-bottom: 0
}

.panel>.list-group .list-group-item,
.panel>.panel-collapse>.list-group .list-group-item {
    border-width: 1px 0;
    border-radius: 0
}

.panel>.list-group:first-child .list-group-item:first-child,
.panel>.panel-collapse>.list-group:first-child .list-group-item:first-child {
    border-top: 0;
    border-top-right-radius: -1;
    border-top-left-radius: -1
}

.panel>.list-group:last-child .list-group-item:last-child,
.panel>.panel-collapse>.list-group:last-child .list-group-item:last-child {
    border-bottom: 0;
    border-bottom-right-radius: -1;
    border-bottom-left-radius: -1
}

.panel>.panel-heading+.panel-collapse>.list-group .list-group-item:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.panel-heading+.list-group .list-group-item:first-child {
    border-top-width: 0
}

.list-group+.panel-footer {
    border-top-width: 0
}

.panel>.panel-collapse>.table,
.panel>.table,
.panel>.table-responsive>.table {
    margin-bottom: 0
}

.panel>.panel-collapse>.table caption,
.panel>.table caption,
.panel>.table-responsive>.table caption {
    padding-left: 15px;
    padding-right: 15px
}

.panel>.table-responsive:first-child>.table:first-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child,
.panel>.table:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child,
.panel>.table:first-child>thead:first-child>tr:first-child {
    border-top-left-radius: -1;
    border-top-right-radius: -1
}

.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:first-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child td:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child th:first-child,
.panel>.table:first-child>thead:first-child>tr:first-child td:first-child,
.panel>.table:first-child>thead:first-child>tr:first-child th:first-child {
    border-top-left-radius: -1
}

.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:last-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:last-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:last-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:last-child,
.panel>.table:first-child>tbody:first-child>tr:first-child td:last-child,
.panel>.table:first-child>tbody:first-child>tr:first-child th:last-child,
.panel>.table:first-child>thead:first-child>tr:first-child td:last-child,
.panel>.table:first-child>thead:first-child>tr:first-child th:last-child {
    border-top-right-radius: -1
}

.panel>.table-responsive:last-child>.table:last-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child,
.panel>.table:last-child,
.panel>.table:last-child>tbody:last-child>tr:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child {
    border-bottom-left-radius: -1;
    border-bottom-right-radius: -1
}

.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:first-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:first-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:first-child,
.panel>.table:last-child>tbody:last-child>tr:last-child td:first-child,
.panel>.table:last-child>tbody:last-child>tr:last-child th:first-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child th:first-child {
    border-bottom-left-radius: -1
}

.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:last-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:last-child,
.panel>.table:last-child>tbody:last-child>tr:last-child td:last-child,
.panel>.table:last-child>tbody:last-child>tr:last-child th:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child th:last-child {
    border-bottom-right-radius: -1
}

.panel-group .panel-heading+.panel-collapse>.list-group,
.panel-group .panel-heading+.panel-collapse>.panel-body,
.panel>.panel-body+.table,
.panel>.panel-body+.table-responsive,
.panel>.table+.panel-body,
.panel>.table-responsive+.panel-body {
    border-top: 1px solid var(--is-border)
}

.panel>.table-bordered,
.panel>.table-responsive>.table-bordered {
    border: 0
}

.panel>.table-bordered>tbody>tr>td:first-child,
.panel>.table-bordered>tbody>tr>th:first-child,
.panel>.table-bordered>tfoot>tr>td:first-child,
.panel>.table-bordered>tfoot>tr>th:first-child,
.panel>.table-bordered>thead>tr>td:first-child,
.panel>.table-bordered>thead>tr>th:first-child,
.panel>.table-responsive>.table-bordered>tbody>tr>td:first-child,
.panel>.table-responsive>.table-bordered>tbody>tr>th:first-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>td:first-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>th:first-child,
.panel>.table-responsive>.table-bordered>thead>tr>td:first-child,
.panel>.table-responsive>.table-bordered>thead>tr>th:first-child {
    border-left: 0
}

.panel>.table-bordered>tbody>tr>td:last-child,
.panel>.table-bordered>tbody>tr>th:last-child,
.panel>.table-bordered>tfoot>tr>td:last-child,
.panel>.table-bordered>tfoot>tr>th:last-child,
.panel>.table-bordered>thead>tr>td:last-child,
.panel>.table-bordered>thead>tr>th:last-child,
.panel>.table-responsive>.table-bordered>tbody>tr>td:last-child,
.panel>.table-responsive>.table-bordered>tbody>tr>th:last-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>td:last-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>th:last-child,
.panel>.table-responsive>.table-bordered>thead>tr>td:last-child,
.panel>.table-responsive>.table-bordered>thead>tr>th:last-child {
    border-right: 0
}

.panel>.table-bordered>tbody>tr:first-child>td,
.panel>.table-bordered>tbody>tr:first-child>th,
.panel>.table-bordered>thead>tr:first-child>td,
.panel>.table-bordered>thead>tr:first-child>th,
.panel>.table-responsive>.table-bordered>tbody>tr:first-child>td,
.panel>.table-responsive>.table-bordered>tbody>tr:first-child>th,
.panel>.table-responsive>.table-bordered>thead>tr:first-child>td,
.panel>.table-responsive>.table-bordered>thead>tr:first-child>th {
    border-bottom: 0
}

.panel>.table-bordered>tbody>tr:last-child>td,
.panel>.table-bordered>tbody>tr:last-child>th,
.panel>.table-bordered>tfoot>tr:last-child>td,
.panel>.table-bordered>tfoot>tr:last-child>th,
.panel>.table-responsive>.table-bordered>tbody>tr:last-child>td,
.panel>.table-responsive>.table-bordered>tbody>tr:last-child>th,
.panel>.table-responsive>.table-bordered>tfoot>tr:last-child>td,
.panel>.table-responsive>.table-bordered>tfoot>tr:last-child>th {
    border-bottom: 0
}

.panel>.table-responsive {
    border: 0;
    margin-bottom: 0
}

.panel-group {
    margin-bottom: 21px
}

.panel-group .panel {
    margin-bottom: 0;
    border-radius: 0
}

.panel-group .panel+.panel {
    margin-top: 5px
}

.panel-group .panel-heading {
    border-bottom: 0
}

.panel-group .panel-footer {
    border-top: 0
}

.panel-group .panel-footer+.panel-collapse .panel-body {
    border-bottom: 1px solid var(--is-border)
}

.panel-default,
.well blockquote {
    border-color: var(--is-border)
}

.panel-default>.panel-heading {
    color: var(--is-text);
    background-color: var(--is-component-bg-muted);
    border-color: var(--is-border)
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: var(--is-border)
}

.panel-default>.panel-heading .badge {
    color:var(--is-default-text);
    background-color: var(--is-default);
}

.panel-default>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: var(--is-border)
}

.panel-primary {
    border-color:var(--is-primary)
}

.panel-primary>.panel-heading {
    color: var(--is-primary-text);
    background-color:var(--is-primary);
    border-color:var(--is-primary)
}

.panel-primary>.panel-heading+.panel-collapse>.panel-body {
    border-top-color:var(--is-primary)
}

.panel-primary>.panel-heading .badge {
    color:var(--is-primary);
    background-color: #fff
}

.panel-primary>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color:var(--is-primary)
}

.panel-success {
    border-color: var(--is-success)
}

.panel-success>.panel-heading {
    color: var(--is-success-text);
    background-color:var(--is-success);
    border-color: var(--is-success)
}

.panel-success>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: var(--is-success)
}

.panel-success>.panel-heading .badge {
    color:var(--is-success);
    background-color: #fff
}

.panel-success>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: var(--is-success)
}

.panel-info {
    border-color: var(--is-info)
}

.panel-info>.panel-heading {
    color:var(--is-info-text);
    background-color:var(--is-info);
    border-color: var(--is-info)
}

.panel-info>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: var(--is-info)
}

.panel-info>.panel-heading .badge {
    color:var(--is-info);
    background-color: #fff
}

.panel-info>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: var(--is-info)
}

.panel-warning {
    border-color:var(--is-warning)
}

.panel-warning>.panel-heading {
    color: var(--is-warning-text);
    background-color: var(--is-warning);
    border-color:var(--is-warning)
}

.panel-warning>.panel-heading+.panel-collapse>.panel-body {
    border-top-color:var(--is-warning)
}

.panel-warning>.panel-heading .badge {
    color: var(--is-warning);
    background-color: #fff
}

.panel-warning>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color:var(--is-warning)
}

.panel-danger {
    border-color:var(--is-danger)
}

.panel-danger>.panel-heading {
    color: var(--is-danger-text);
    background-color: var(--is-danger);
    border-color:var(--is-danger)
}

.panel-danger>.panel-heading+.panel-collapse>.panel-body {
    border-top-color:var(--is-danger)
}

.panel-danger>.panel-heading .badge {
    color: var(--is-danger);
    background-color: #fff
}

.panel-danger>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color:var(--is-danger)
}
*/
/*=> CARD*/
.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem
}

.card-title {
    margin-bottom: .75rem
}

.card-subtitle {
    margin-top: -.375rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: 1.25rem
}

.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125)
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0
}

.card-footer {
    padding: .75rem 1.25rem;
    background-color: rgba(0, 0, 0, .03);
    border-top: 1px solid rgba(0, 0, 0, .125)
}

.card-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
}

.card-header-tabs {
    margin-right: -.625rem;
    margin-bottom: -.75rem;
    margin-left: -.625rem;
    border-bottom: 0
}

.card-header-pills {
    margin-right: -.625rem;
    margin-left: -.625rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem
}

.card-img,
.card-img-bottom,
.card-img-top {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%
}

.card-img,
.card-img-top {
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.card-deck .card {
    margin-bottom: 15px
}

@media (min-width:576px) {
    .card-deck {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px
    }

    .card-deck .card {
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px
    }
}

.card-group>.card {
    margin-bottom: 15px
}

@media (min-width:576px) {
    .card-group {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .card-group>.card {
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-header,
    .card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-footer,
    .card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-header,
    .card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-footer,
    .card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.card-columns .card {
    margin-bottom: .75rem
}

@media (min-width:576px) {
    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1
    }

    .card-columns .card {
        display: inline-block;
        width: 100%
    }
}

.accordion>.card {
    overflow: hidden
}

.accordion>.card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.accordion>.card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.accordion>.card>.card-header {
    border-radius: 0;
    margin-bottom: -1px
}

/*=> WELL*/
.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: var(--is-component-bg-muted);
    border: 1px solid var(--is-border);
    border-radius: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05)
}

.well-lg,
.well-sm {
    padding: 24px;
    border-radius: 0
}

.well-sm {
    padding: 9px
}

/*=> COMMON ADJUSTMENTS*/
.btn {
    padding: 8px 12px
}

.btn-group-lg>.btn,
.btn-lg {
    padding: 16px 20px
}

.btn-group-sm>.btn,
.btn-sm {
    padding: 8px 12px
}

.btn-group-xs>.btn,
.btn-xs {
    padding: 4px 6px
}


.checkbox,
.control-label,
.help-block,
/*==> Adjustment*/
.form-group > .form-text.text-muted,
.radio,
label {
    font-size: 12px;
    font-weight: 400
}

input[type=checkbox],
input[type=radio] {
    margin-top: 1px
}

.panel-footer,
.panel-heading {
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.panel-default .close {
    color: var(--is-default-darker)
}

/* INTEGRATION & ADJUSTMENTS formio.full.css */

/*=> DROPDOWN*/
.form-control.dropdown .choices__item.choices__item--selectable {
    display: flex!important;
}
.form-control.dropdown .choices__item.choices__item--selectable .choices__button {
    margin-right: 21px!important;
}
.formio-component-select .control-label--hidden.field-required {
    top: 7px!important;
    right: 43px!important;
    margin: 0!important;
}
/*=> DATAGRID & INPUT MULTIPLE*/
.datagrid-table,
.formio-component-multiple .table-bordered {
    border: none!important;
}
.datagrid-table > thead > tr > td,
.datagrid-table > thead > tr > th,
.formio-component-multiple .table-bordered > thead > tr > td,
.formio-component-multiple .table-bordered > thead > tr > th {
    border: none!important;
    padding: 0 5px 5px 0!important;
}
.datagrid-table > tbody > tr > td,
.datagrid-table > tbody > tr > th,
.datagrid-table > tfoot > tr > td,
.datagrid-table > tfoot > tr > th,
.datagrid-table > thead > tr > td,
.formio-component-multiple .table-bordered > tbody > tr > td,
.formio-component-multiple .table-bordered > tbody > tr > th,
.formio-component-multiple .table-bordered > tfoot > tr > td,
.formio-component-multiple .table-bordered > tfoot > tr > th,
.formio-component-multiple .table-bordered > thead > tr > td {
    border: none!important;
    padding: 0 5px 10px 0!important;
}
.datagrid-table > tbody > tr > td:last-child,
.form-group.formio-component-textfield.formio-component-multiple .table > tbody > tr:not(:last-child) > td:last-child {
    text-align: right;
    width: 40px;
    padding-right: 0!important;
}
/*=> DATETIME*/
.formio-component-datetime .input-group > .form-control.input {
    border-radius: var(--is-rounded) 0 0 var(--is-rounded)!important;
}