/* @import "./fonts/Roboto/stylesheet.css";
@import "./fonts/RobotoMono/stylesheet.css";
@import "./fonts/RobotoSlab/stylesheet.css";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap'); */

:root {
  --is-default: #4d4d4d;
  --is-primary: #4c80e4;
  --is-secondary: #737d87;
  --is-accent: #ff4c24;
  --is-success: #2ecc40;
  --is-warning: #faad14;
  --is-danger: #ff4136;
  --is-theme-bg: #fff;
  --is-theme-bg-muted: #f2f2f2;
  --is-theme-bg-muted-strong: #e0e0e0;
  --is-theme-bg-inverse: #1a1a1a;
  --is-theme-bg-inverse-muted: #2e2e2e;
  --is-theme-bg-inverse-muted-strong: #404040;
  --is-component-bg: white;
  --is-component-bg-muted: #fafafa;
  --is-component-text: #4d4d4d;
  --is-component-text-muted: #afafaf;
  --is-component-border-color: var(--is-border);
  --is-component-border-color-muted: var(--is-border-thin);
  --is-component-border-radius: var(--is-rounded);
  --is-border: #cccccc;
  --is-border-thin: #e6e6e6;
  --is-border-bold: #999999;
  --is-border-inverse: #474747;
  --is-text: #4d4d4d;
  --is-text-muted: #949494;
  --is-text-inverse: #f6f6f6;
  --is-text-inverse-muted: #c9c9c9;
  --is-link-color: #296eb3;
  --is-link-color-hover: #1f5386;
  --is-color-white: #fff;
  --is-color-blue: #0074d9;
  --is-color-geekblue: #2f54eb;
  --is-color-teal: #39cccc;
  --is-color-cyan: #13c2c2;
  --is-color-green: #2ecc40;
  --is-color-lime: #a0d911;
  --is-color-yellow: #ffdc00;
  --is-color-gold: #faad14;
  --is-color-orange: #ff851b;
  --is-color-volcano: #fa541c;
  --is-color-red: #ff4136;
  --is-color-pink: #ff69b4;
  --is-color-magenta: #eb2f96;
  --is-color-purple: #b10dc9;
  --is-color-black: #000;
  --is-color-grey: grey;
  --is-color-violet: violet;
  --is-color-white-inverse: #0d0d0d;
  --is-color-blue-inverse: aliceblue;
  --is-color-geekblue-inverse: #f5f6fe;
  --is-color-teal-inverse: #f5fcfc;
  --is-color-cyan-inverse: #f1fefe;
  --is-color-green-inverse: #f4fdf5;
  --is-color-lime-inverse: #fbfef2;
  --is-color-yellow-inverse: #fffdf2;
  --is-color-gold-inverse: #fffbf3;
  --is-color-orange-inverse: #fff9f4;
  --is-color-volcano-inverse: #fff6f4;
  --is-color-red-inverse: #fff6f5;
  --is-color-pink-inverse: #fff8fb;
  --is-color-magenta-inverse: #fef5fa;
  --is-color-purple-inverse: #fcf1fe;
  --is-color-black-inverse: #fcf1fe;
  --is-default-text: #f9f9f9;
  --is-default-text-disabled: #ededed;
  --is-default-link: #f9f9f9;
  --is-default-link-hover: #dadada;
  --is-default-border: #363636;
  --is-default-border-hover: #1f1f1f;
  --is-default-light: #707070;
  --is-default-light-text: #f9f9f9;
  --is-default-dark: #363636;
  --is-default-dark-text: #f9f9f9;
  --is-default-darker: #1f1f1f;
  --is-default-darker-text: #f9f9f9;
  --is-default-darkest: #0f0f0f;
  --is-default-darkest-text: #f9f9f9;
  --is-default-inverse: #ededed;
  --is-default-inverse-text: #151515;
  --is-default-inverse-text-disabled: #707070;
  --is-default-inverse-link: #0c0c0c;
  --is-default-inverse-link-hover: #1f1f1f;
  --is-default-inverse-border: #c9c9c9;
  --is-default-inverse-border-hover: #c9c9c9;
  --is-default-inverse-light: #f6f6f6;
  --is-default-inverse-light-text: #151515;
  --is-default-inverse-dark: #c9c9c9;
  --is-default-inverse-dark-text: #151515;
  --is-default-inverse-darker: #a6a6a6;
  --is-default-inverse-darker-text: #151515;
  --is-default-inverse-darkest: #828282;
  --is-default-inverse-darkest-text: #f9f9f9;
  --is-primary-text: #f7fafd;
  --is-primary-text-disabled: #e7f1fa;
  --is-primary-link: #f7fafd;
  --is-primary-link-hover: #cde1f4;
  --is-primary-border: #1d4d7d;
  --is-primary-border-hover: #102c48;
  --is-primary-light: #428bd4;
  --is-primary-light-text: #f7fafd;
  --is-primary-dark: #1d4d7d;
  --is-primary-dark-text: #f7fafd;
  --is-primary-darker: #102c48;
  --is-primary-darker-text: #f7fafd;
  --is-primary-darkest: #081624;
  --is-primary-darkest-text: #f7fafd;
  --is-primary-inverse: #e7f1fa;
  --is-primary-inverse-text: #0b1f32;
  --is-primary-inverse-text-disabled: #428bd4;
  --is-primary-inverse-link: #07121d;
  --is-primary-inverse-link-hover: #102c48;
  --is-primary-inverse-border: #b8d4ef;
  --is-primary-inverse-border-hover: #b8d4ef;
  --is-primary-inverse-light: #f3f8fc;
  --is-primary-inverse-light-text: #0b1f32;
  --is-primary-inverse-dark: #b8d4ef;
  --is-primary-inverse-dark-text: #0b1f32;
  --is-primary-inverse-darker: #89b7e4;
  --is-primary-inverse-darker-text: #0b1f32;
  --is-primary-inverse-darkest: #5a9ad9;
  --is-primary-inverse-darkest-text: #f7fafd;
  --is-secondary-text: #fafafb;
  --is-secondary-text-disabled: #f1f2f3;
  --is-secondary-link: #fafafb;
  --is-secondary-link-hover: #e2e4e6;
  --is-secondary-border: #51585f;
  --is-secondary-border-hover: #2e3236;
  --is-secondary-light: #8f979f;
  --is-secondary-light-text: #fafafb;
  --is-secondary-dark: #51585f;
  --is-secondary-dark-text: #fafafb;
  --is-secondary-darker: #2e3236;
  --is-secondary-darker-text: #fafafb;
  --is-secondary-darkest: #17191b;
  --is-secondary-darkest-text: #fafafb;
  --is-secondary-inverse: #f1f2f3;
  --is-secondary-inverse-text: #202326;
  --is-secondary-inverse-text-disabled: #8f979f;
  --is-secondary-inverse-link: #121416;
  --is-secondary-inverse-link-hover: #2e3236;
  --is-secondary-inverse-border: #d5d8db;
  --is-secondary-inverse-border-hover: #d5d8db;
  --is-secondary-inverse-light: #f8f9f9;
  --is-secondary-inverse-light-text: #202326;
  --is-secondary-inverse-dark: #d5d8db;
  --is-secondary-inverse-dark-text: #202326;
  --is-secondary-inverse-darker: #b9bec3;
  --is-secondary-inverse-darker-text: #202326;
  --is-secondary-inverse-darkest: #9da4ab;
  --is-secondary-inverse-darkest-text: #fafafb;
  --is-accent-text: #fff9f7;
  --is-accent-text-disabled: #ffede9;
  --is-accent-link: #fff9f7;
  --is-accent-link-hover: #ffd9d1;
  --is-accent-border: #cc2500;
  --is-accent-border-hover: #741500;
  --is-accent-light: #ff7050;
  --is-accent-light-text: #fff9f7;
  --is-accent-dark: #cc2500;
  --is-accent-dark-text: #fff9f7;
  --is-accent-darker: #741500;
  --is-accent-darker-text: #fff9f7;
  --is-accent-darkest: #3a0b00;
  --is-accent-darkest-text: #fff9f7;
  --is-accent-inverse: #ffede9;
  --is-accent-inverse-text: #3a0b00;
  --is-accent-inverse-text-disabled: #e92b00;
  --is-accent-inverse-link: #3a0b00;
  --is-accent-inverse-link-hover: #691300;
  --is-accent-inverse-border: #ffc9bd;
  --is-accent-inverse-border-hover: #ffc9bd;
  --is-accent-inverse-light: #fff6f4;
  --is-accent-inverse-light-text: #3a0b00;
  --is-accent-inverse-dark: #ffc9bd;
  --is-accent-inverse-dark-text: #3a0b00;
  --is-accent-inverse-darker: #ffa692;
  --is-accent-inverse-darker-text: #3a0b00;
  --is-accent-inverse-darkest: #ff8266;
  --is-accent-inverse-darkest-text: #3a0b00;
  --is-success-text: #f8fdf8;
  --is-success-text-disabled: #eafaec;
  --is-success-link: #07210a;
  --is-success-link-hover: #d2f5d6;
  --is-success-border: #208f2d;
  --is-success-border-hover: #12521a;
  --is-success-light: #55d964;
  --is-success-light-text: #f8fdf8;
  --is-success-dark: #208f2d;
  --is-success-dark-text: #f8fdf8;
  --is-success-darker: #12521a;
  --is-success-darker-text: #f8fdf8;
  --is-success-darkest: #09290d;
  --is-success-darkest-text: #f8fdf8;
  --is-success-inverse: #eafaec;
  --is-success-inverse-text: #0d3912;
  --is-success-inverse-text-disabled: #55d964;
  --is-success-inverse-link: #07210a;
  --is-success-inverse-link-hover: #12521a;
  --is-success-inverse-border: #bff1c5;
  --is-success-inverse-border-hover: #bff1c5;
  --is-success-inverse-light: #f4fdf5;
  --is-success-inverse-light-text: #0d3912;
  --is-success-inverse-dark: #bff1c5;
  --is-success-inverse-dark-text: #0d3912;
  --is-success-inverse-darker: #95e79e;
  --is-success-inverse-darker-text: #0d3912;
  --is-success-inverse-darkest: #6ade78;
  --is-success-inverse-darkest-text: #0d3912;
  --is-warning-text: #352401;
  --is-warning-text-disabled: #fff7e8;
  --is-warning-link: #352401;
  --is-warning-link-hover: #feeece;
  --is-warning-border: #6a4702;
  --is-warning-border-hover: #b97c04;
  --is-warning-light: #fbbd43;
  --is-warning-light-text: #352401;
  --is-warning-dark: #b97c04;
  --is-warning-dark-text: #fffcf7;
  --is-warning-darker: #6a4702;
  --is-warning-darker-text: #fffcf7;
  --is-warning-darkest: #352401;
  --is-warning-darkest-text: #fffcf7;
  --is-warning-inverse: #fff7e8;
  --is-warning-inverse-text: #352401;
  --is-warning-inverse-text-disabled: #d48e05;
  --is-warning-inverse-link: #352401;
  --is-warning-inverse-link-hover: #5f4002;
  --is-warning-inverse-border: #fee6b9;
  --is-warning-inverse-border-hover: #fee6b9;
  --is-warning-inverse-light: #fffbf3;
  --is-warning-inverse-light-text: #352401;
  --is-warning-inverse-dark: #fee6b9;
  --is-warning-inverse-dark-text: #352401;
  --is-warning-inverse-darker: #fdd68a;
  --is-warning-inverse-darker-text: #352401;
  --is-warning-inverse-darkest: #fcc65b;
  --is-warning-inverse-darkest-text: #352401;
  --is-danger-text: #fff8f8;
  --is-danger-text-disabled: #ffeceb;
  --is-danger-link: #fff8f8;
  --is-danger-link-hover: #ffd7d5;
  --is-danger-border: #d80c00;
  --is-danger-border-hover: #7c0700;
  --is-danger-light: #ff675e;
  --is-danger-light-text: #fff8f8;
  --is-danger-dark: #d80c00;
  --is-danger-dark-text: #fff8f8;
  --is-danger-darker: #7c0700;
  --is-danger-darker-text: #fff8f8;
  --is-danger-darkest: #3e0300;
  --is-danger-darkest-text: #fff8f8;
  --is-danger-inverse: #ffeceb;
  --is-danger-inverse-text: #3e0300;
  --is-danger-inverse-text-disabled: #f70e00;
  --is-danger-inverse-link: #3e0300;
  --is-danger-inverse-link-hover: #6f0600;
  --is-danger-inverse-border: #ffc6c3;
  --is-danger-inverse-border-hover: #ffc6c3;
  --is-danger-inverse-light: #fff6f5;
  --is-danger-inverse-light-text: #3e0300;
  --is-danger-inverse-dark: #ffc6c3;
  --is-danger-inverse-dark-text: #3e0300;
  --is-danger-inverse-darker: #ffa09b;
  --is-danger-inverse-darker-text: #3e0300;
  --is-danger-inverse-darkest: #ff7a72;
  --is-danger-inverse-darkest-text: #3e0300;
  --is-text-primary: #296eb3;
  --is-text-secondary: #737d87;
  --is-text-accent: #ff4c24;
  --is-text-success: #2ecc40;
  --is-text-warning: #faad14;
  --is-text-danger: #ff4136;
  --is-color-white-text: black;
  --is-color-blue-text: white;
  --is-color-geekblue-text: white;
  --is-color-teal-text: black;
  --is-color-cyan-text: black;
  --is-color-green-text: black;
  --is-color-lime-text: black;
  --is-color-yellow-text: black;
  --is-color-gold-text: black;
  --is-color-orange-text: black;
  --is-color-volcano-text: white;
  --is-color-red-text: white;
  --is-color-pink-text: black;
  --is-color-magenta-text: white;
  --is-color-purple-text: white;
  --is-color-black-text: white;
  --is-color-grey-text: white;
  --is-color-violet-text: white;
  --is-overlay: rgba(0, 0, 0, 0.6);
  --is-overlay-weak: rgba(0, 0, 0, 0.45);
  --is-overlay-strong: rgba(0, 0, 0, 0.75);
  --is-overlay-inverse: rgba(255, 255, 255, 0.6);
  --is-overlay-inverse-weak: rgba(255, 255, 255, 0.45);
  --is-overlay-inverse-strong: rgba(255, 255, 255, 0.75);
  --is-palette-divergent-1: #297ed9;
  --is-palette-divergent-2: #6a89e0;
  --is-palette-divergent-3: #9396e7;
  --is-palette-divergent-4: #b4a4ec;
  --is-palette-divergent-5: #d0b2f2;
  --is-palette-divergent-6: #e9c3f8;
  --is-palette-divergent-7: #e1b7dd;
  --is-palette-divergent-8: #c59abd;
  --is-palette-divergent-9: #a87e9d;
  --is-palette-divergent-10: #8d647f;
  --is-palette-divergent-11: #714a62;
  --is-palette-divergent-12: #573247;
  --is-palette-standard-1: #024c8f;
  --is-palette-standard-2: #3a9267;
  --is-palette-standard-3: #297ed9;
  --is-palette-standard-4: #da637a;
  --is-palette-standard-5: #95c137;
  --is-palette-standard-6: #acbce9;
  --is-palette-standard-7: #f69e62;
  --is-palette-standard-8: #9396e7;
  --is-palette-standard-9: #a87e9d;
  --is-palette-standard-10: #edbc5e;
  --is-palette-standard-11: #756e98;
  --is-palette-standard-12: #714a62;
  --is-palette-single-hue-1: #01386b;
  --is-palette-single-hue-2: #024c8f;
  --is-palette-single-hue-3: #3566b1;
  --is-palette-single-hue-4: #5176bc;
  --is-palette-single-hue-5: #6a87c7;
  --is-palette-single-hue-6: #8098d2;
  --is-palette-single-hue-7: #96aade;
  --is-palette-single-hue-8: #acbce9;
  --is-palette-single-hue-9: #c1cef4;
  --is-palette-single-hue-10: #d7e1ff;
  --is-palette-single-hue-11: #e8edff;
  --is-palette-single-hue-12: #d7e0e9;
  --is-palette-divergent-1-text: white;
  --is-palette-divergent-2-text: black;
  --is-palette-divergent-3-text: black;
  --is-palette-divergent-4-text: black;
  --is-palette-divergent-5-text: black;
  --is-palette-divergent-6-text: black;
  --is-palette-divergent-7-text: black;
  --is-palette-divergent-8-text: black;
  --is-palette-divergent-9-text: black;
  --is-palette-divergent-10-text: white;
  --is-palette-divergent-11-text: white;
  --is-palette-divergent-12-text: white;
  --is-palette-standard-1-text: white;
  --is-palette-standard-2-text: white;
  --is-palette-standard-3-text: white;
  --is-palette-standard-4-text: white;
  --is-palette-standard-5-text: black;
  --is-palette-standard-6-text: black;
  --is-palette-standard-7-text: black;
  --is-palette-standard-8-text: black;
  --is-palette-standard-9-text: black;
  --is-palette-standard-10-text: black;
  --is-palette-standard-11-text: white;
  --is-palette-standard-12-text: white;
  --is-palette-single-hue-1-text: white;
  --is-palette-single-hue-2-text: white;
  --is-palette-single-hue-3-text: white;
  --is-palette-single-hue-4-text: white;
  --is-palette-single-hue-5-text: black;
  --is-palette-single-hue-6-text: black;
  --is-palette-single-hue-7-text: black;
  --is-palette-single-hue-8-text: black;
  --is-palette-single-hue-9-text: black;
  --is-palette-single-hue-10-text: black;
  --is-palette-single-hue-11-text: black;
  --is-palette-single-hue-12-text: black;
  --is-font-sans-serif: sans-serif;
  --is-font-serif: serif;
  --is-font-system: Roboto, sans-serif;
  --is-font-monospace: Menlo, Consolas, "DejaVu Sans Mono", monospace;
  --is-font-family: var(--is-font-system);
  --is-font-size-pivot: 14px;
  --is-font-size: 1.3rem;
  --is-font-size-xxs: 0.7rem;
  --is-font-size-xs: 0.8rem;
  --is-font-size-sm: 1.0rem;
  --is-font-size-md: 1.3rem;
  --is-font-size-lg: 2rem;
  --is-font-size-xl: 2.5rem;
  --is-font-size-xxl: 3rem;
  --is-font-weight-light: 300;
  --is-font-weight: 400;
  --is-font-weight-bold: 700;
  --is-line-height: 1.5;
  --is-line-height-1: 1;
  --is-line-height-xs: 1.15;
  --is-line-height-sm: 1.3;
  --is-line-height-lg: 1.75;
  --is-line-height-xl: 2;
  --is-big-font-size-1: 2.5rem;
  --is-big-font-size-2: 3rem;
  --is-big-font-size-3: 3.5rem;
  --is-big-font-size-4: 4rem;
  --is-big-font-size-5: 4.5rem;
  --is-big-font-size-6: 5rem;
  --is-big-font-size-7: 5.5rem;
  --is-big-font-size-8: 6rem;
  --is-big-font-size-9: 6.5rem;
  --is-big-font-size-10: 7rem;
  --is-grid-gutter-width: 10px;
  --is-grid-no-gutter: 0;
  --is-grid-gutter-width-sm: 5px;
  --is-grid-gutter-width-lg: 20px;
  --is-container-max-width-sm: 540px;
  --is-container-max-width-md: 720px;
  --is-container-max-width-lg: 960px;
  --is-container-max-width-xl: 1140px;
  --is-container-spacing-x: var(--is-su-2);
  --is-container-spacing-y: var(--is-su-1);
  --is-rounded-1: 0.25rem;
  --is-rounded-2: 0.5rem;
  --is-rounded-3: 0.75rem;
  --is-rounded-4: 1rem;
  --is-rounded: var(--is-rounded-1);
  --is-box-shadow: 0.125rem 0.2rem 0.75rem rgba(0, 0, 0, 0.15);
  --is-box-shadow-xs: 1px 1px 3px rgba(0, 0, 0, 0.175);
  --is-box-shadow-sm: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.175);
  --is-box-shadow-lg: 0.125rem 0.35rem 1.5rem rgba(0, 0, 0, 0.175);
  --is-box-shadow-xl: 0.125rem 0.75rem 2rem rgba(0, 0, 0, 0.175);
  --is-size-25: 25% !important;
  --is-size-50: 50% !important;
  --is-size-75: 75% !important;
  --is-size-100: 100% !important;
  --is-size-auto: auto !important;
  --is-vw-100: 100vw !important;
  --is-vh-100: 100vh !important;
  --is-wu: 0.71429rem;
  --is-wu-0: 0;
  --is-wu-1: var(--is-wu);
  --is-wu-2: calc(var(--is-wu) * 2);
  --is-wu-3: calc(var(--is-wu) * 3);
  --is-wu-4: calc(var(--is-wu) * 4);
  --is-wu-5: calc(var(--is-wu) * 5);
  --is-wu-6: calc(var(--is-wu) * 6);
  --is-wu-7: calc(var(--is-wu) * 7);
  --is-wu-8: calc(var(--is-wu) * 8);
  --is-wu-9: calc(var(--is-wu) * 9);
  --is-wu-10: calc(var(--is-wu) * 10);
  --is-wu-11: calc(var(--is-wu) * 11);
  --is-wu-12: calc(var(--is-wu) * 12);
  --is-wu-13: calc(var(--is-wu) * 13);
  --is-wu-14: calc(var(--is-wu) * 14);
  --is-wu-15: calc(var(--is-wu) * 15);
  --is-wu-16: calc(var(--is-wu) * 16);
  --is-wu-17: calc(var(--is-wu) * 17);
  --is-wu-18: calc(var(--is-wu) * 18);
  --is-wu-19: calc(var(--is-wu) * 19);
  --is-wu-20: calc(var(--is-wu) * 20);
  --is-wu-21: calc(var(--is-wu) * 21);
  --is-wu-22: calc(var(--is-wu) * 22);
  --is-wu-23: calc(var(--is-wu) * 23);
  --is-wu-24: calc(var(--is-wu) * 24);
  --is-wu-25: calc(var(--is-wu) * 25);
  --is-wu-26: calc(var(--is-wu) * 26);
  --is-wu-27: calc(var(--is-wu) * 27);
  --is-wu-28: calc(var(--is-wu) * 28);
  --is-wu-29: calc(var(--is-wu) * 29);
  --is-wu-30: calc(var(--is-wu) * 30);
  --is-wu-31: calc(var(--is-wu) * 31);
  --is-wu-32: calc(var(--is-wu) * 32);
  --is-wu-33: calc(var(--is-wu) * 33);
  --is-wu-34: calc(var(--is-wu) * 34);
  --is-wu-35: calc(var(--is-wu) * 35);
  --is-wu-36: calc(var(--is-wu) * 36);
  --is-wu-37: calc(var(--is-wu) * 37);
  --is-wu-38: calc(var(--is-wu) * 38);
  --is-wu-39: calc(var(--is-wu) * 39);
  --is-wu-40: calc(var(--is-wu) * 40);
  --is-wu-41: calc(var(--is-wu) * 41);
  --is-wu-42: calc(var(--is-wu) * 42);
  --is-wu-43: calc(var(--is-wu) * 43);
  --is-wu-44: calc(var(--is-wu) * 44);
  --is-wu-45: calc(var(--is-wu) * 45);
  --is-wu-46: calc(var(--is-wu) * 46);
  --is-wu-47: calc(var(--is-wu) * 47);
  --is-wu-48: calc(var(--is-wu) * 48);
  --is-wu-49: calc(var(--is-wu) * 49);
  --is-wu-50: calc(var(--is-wu) * 50);
  --is-wu-55: calc(var(--is-wu) * 55);
  --is-wu-60: calc(var(--is-wu) * 60);
  --is-wu-65: calc(var(--is-wu) * 65);
  --is-wu-70: calc(var(--is-wu) * 70);
  --is-wu-75: calc(var(--is-wu) * 75);
  --is-wu-80: calc(var(--is-wu) * 80);
  --is-wu-85: calc(var(--is-wu) * 85);
  --is-wu-90: calc(var(--is-wu) * 90);
  --is-wu-95: calc(var(--is-wu) * 95);
  --is-wu-100: calc(var(--is-wu) * 100);
  --is-hu: 0.71429rem;
  --is-hu-0: 0;
  --is-hu-1: var(--is-hu);
  --is-hu-2: calc(var(--is-hu) * 2);
  --is-hu-3: calc(var(--is-hu) * 3);
  --is-hu-4: calc(var(--is-hu) * 4);
  --is-hu-5: calc(var(--is-hu) * 5);
  --is-hu-6: calc(var(--is-hu) * 6);
  --is-hu-7: calc(var(--is-hu) * 7);
  --is-hu-8: calc(var(--is-hu) * 8);
  --is-hu-9: calc(var(--is-hu) * 9);
  --is-hu-10: calc(var(--is-hu) * 10);
  --is-hu-11: calc(var(--is-hu) * 11);
  --is-hu-12: calc(var(--is-hu) * 12);
  --is-hu-13: calc(var(--is-hu) * 13);
  --is-hu-14: calc(var(--is-hu) * 14);
  --is-hu-15: calc(var(--is-hu) * 15);
  --is-hu-16: calc(var(--is-hu) * 16);
  --is-hu-17: calc(var(--is-hu) * 17);
  --is-hu-18: calc(var(--is-hu) * 18);
  --is-hu-19: calc(var(--is-hu) * 19);
  --is-hu-20: calc(var(--is-hu) * 20);
  --is-hu-21: calc(var(--is-hu) * 21);
  --is-hu-22: calc(var(--is-hu) * 22);
  --is-hu-23: calc(var(--is-hu) * 23);
  --is-hu-24: calc(var(--is-hu) * 24);
  --is-hu-25: calc(var(--is-hu) * 25);
  --is-hu-26: calc(var(--is-hu) * 26);
  --is-hu-27: calc(var(--is-hu) * 27);
  --is-hu-28: calc(var(--is-hu) * 28);
  --is-hu-29: calc(var(--is-hu) * 29);
  --is-hu-30: calc(var(--is-hu) * 30);
  --is-hu-31: calc(var(--is-hu) * 31);
  --is-hu-32: calc(var(--is-hu) * 32);
  --is-hu-33: calc(var(--is-hu) * 33);
  --is-hu-34: calc(var(--is-hu) * 34);
  --is-hu-35: calc(var(--is-hu) * 35);
  --is-hu-36: calc(var(--is-hu) * 36);
  --is-hu-37: calc(var(--is-hu) * 37);
  --is-hu-38: calc(var(--is-hu) * 38);
  --is-hu-39: calc(var(--is-hu) * 39);
  --is-hu-40: calc(var(--is-hu) * 40);
  --is-hu-41: calc(var(--is-hu) * 41);
  --is-hu-42: calc(var(--is-hu) * 42);
  --is-hu-43: calc(var(--is-hu) * 43);
  --is-hu-44: calc(var(--is-hu) * 44);
  --is-hu-45: calc(var(--is-hu) * 45);
  --is-hu-46: calc(var(--is-hu) * 46);
  --is-hu-47: calc(var(--is-hu) * 47);
  --is-hu-48: calc(var(--is-hu) * 48);
  --is-hu-49: calc(var(--is-hu) * 49);
  --is-hu-50: calc(var(--is-hu) * 50);
  --is-hu-55: calc(var(--is-hu) * 55);
  --is-hu-60: calc(var(--is-hu) * 60);
  --is-hu-65: calc(var(--is-hu) * 65);
  --is-hu-70: calc(var(--is-hu) * 70);
  --is-hu-75: calc(var(--is-hu) * 75);
  --is-hu-80: calc(var(--is-hu) * 80);
  --is-hu-85: calc(var(--is-hu) * 85);
  --is-hu-90: calc(var(--is-hu) * 90);
  --is-hu-95: calc(var(--is-hu) * 95);
  --is-hu-100: calc(var(--is-hu) * 100);
  --is-hu-1000: calc(var(--is-hu) * 1000);
  --is-su: calc(var(--is-hu) / 2);
  --is-su-0: 0;
  --is-su-1: var(--is-su);
  --is-su-2: calc(var(--is-su) * 2);
  --is-su-3: calc(var(--is-su) * 3);
  --is-su-4: calc(var(--is-su) * 4);
  --is-su-5: calc(var(--is-su) * 5);
  --is-su-6: calc(var(--is-su) * 6);
  --is-su-7: calc(var(--is-su) * 7);
  --is-su-8: calc(var(--is-su) * 8);
  --is-su-9: calc(var(--is-su) * 9);
  --is-su-10: calc(var(--is-su) * 10);
  --is-su-11: calc(var(--is-su) * 11);
  --is-su-12: calc(var(--is-su) * 12);
  --is-su-13: calc(var(--is-su) * 13);
  --is-su-14: calc(var(--is-su) * 14);
  --is-su-15: calc(var(--is-su) * 15);
  --is-su-16: calc(var(--is-su) * 16);
  --is-su-17: calc(var(--is-su) * 17);
  --is-su-18: calc(var(--is-su) * 18);
  --is-su-19: calc(var(--is-su) * 19);
  --is-su-20: calc(var(--is-su) * 20);
  --is-icon-size-1: 1em;
  --is-icon-size-2: 2em;
  --is-icon-size-3: 3em;
  --is-icon-size-4: 4em;
  --is-icon-size-5: 5em;
  --is-icon-size-6: 6em;
  --is-icon-size-7: 7em;
  --is-icon-size-8: 8em;
  --is-icon-size-9: 9em;
  --is-icon-size-10: 10em;
  --is-scale-1: 1.1;
  --is-scale-2: 1.2;
  --is-scale-3: 1.3;
  --is-scale-4: 1.4;
  --is-scale-5: 1.5;
}

*,
::before,
::after {
  box-sizing: border-box;
}

html {
  font-size: var(--is-font-size-pivot);
}

.istyle {
  background: var(--is-theme-bg);
  font-family: var(--is-font-family);
  font-weight: var(--is-font-weight);
  font-size: var(--is-font-size);
  color: var(--is-text);
  line-height: var(--is-line-height);
}

.is-container,
.is-container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: calc(var(--is-grid-gutter-width) / 2);
  padding-left: calc(var(--is-grid-gutter-width) / 2);
}

@media (min-width: 576px) {
  .is-container {
    max-width: var(--is-container-max-width-sm);
  }
}

@media (min-width: 768px) {
  .is-container {
    max-width: var(--is-container-max-width-md);
  }
}

@media (min-width: 992px) {
  .is-container {
    max-width: var(--is-container-max-width-lg);
  }
}

@media (min-width: 1200px) {
  .is-container {
    max-width: var(--is-container-max-width-xl);
  }
}

.is-page-spacing {
  padding: var(--is-grid-gutter-width-lg) var(--is-grid-gutter-width);
}

.is-container-spacing {
  padding: var(--is-container-spacing-y) var(--is-container-spacing-x);
}

.is-row {
  display: flex;
  flex-wrap: wrap;
  flex: auto;
  margin-right: calc(var(--is-grid-gutter-width) / -2);
  margin-left: calc(var(--is-grid-gutter-width) / -2);
}

.is-no-gutter > .is-row,
.is-row.is-no-gutter {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.is-gutter-sm > .is-row,
.is-row.is-gutter-sm {
  margin-right: calc(var(--is-grid-gutter-width-sm) / -2);
  margin-left: calc(var(--is-grid-gutter-width-sm) / -2);
}

.is-gutter-lg > .is-row,
.is-row.is-gutter-lg {
  margin-right: calc(var(--is-grid-gutter-width-lg) / -2);
  margin-left: calc(var(--is-grid-gutter-width-lg) / -2);
}

.row-nowrap > .is-row,
.row-nowrap.is-row {
  flex-wrap: nowrap;
}

.is-row-flush {
  margin-left: 0;
  margin-right: 0;
}
.is-row-flush > .is-col,
.is-row-flush > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.is-row-start {
  justify-content: flex-start;
}

.is-row-center {
  justify-content: center;
}

.is-row-end {
  justify-content: flex-end;
}

.is-row-around {
  justify-content: space-around;
}

.is-row-between {
  justify-content: space-between;
}

@media (min-width: 576px) {
  .is-row-sm-start {
    justify-content: flex-start;
  }
  .is-row-sm-center {
    justify-content: center;
  }
  .is-row-sm-end {
    justify-content: flex-end;
  }
  .is-row-sm-around {
    justify-content: space-around;
  }
  .is-row-sm-between {
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .is-row-md-start {
    justify-content: flex-start;
  }
  .is-row-md-center {
    justify-content: center;
  }
  .is-row-md-end {
    justify-content: flex-end;
  }
  .is-row-md-around {
    justify-content: space-around;
  }
  .is-row-md-between {
    justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .is-row-lg-start {
    justify-content: flex-start;
  }
  .is-row-lg-center {
    justify-content: center;
  }
  .is-row-lg-end {
    justify-content: flex-end;
  }
  .is-row-lg-around {
    justify-content: space-around;
  }
  .is-row-lg-between {
    justify-content: space-between;
  }
}

@media (min-width: 1200px) {
  .is-row-xl-start {
    justify-content: flex-start;
  }
  .is-row-xl-center {
    justify-content: center;
  }
  .is-row-xl-end {
    justify-content: flex-end;
  }
  .is-row-xl-around {
    justify-content: space-around;
  }
  .is-row-xl-between {
    justify-content: space-between;
  }
}

.is-col,
.is-col-auto,
.is-col-1,
.is-col-2,
.is-col-3,
.is-col-4,
.is-col-5,
.is-col-6,
.is-col-7,
.is-col-8,
.is-col-9,
.is-col-10,
.is-col-11,
.is-col-12,
.is-col-sm,
.is-col-sm-auto,
.is-col-sm-1,
.is-col-sm-2,
.is-col-sm-3,
.is-col-sm-4,
.is-col-sm-5,
.is-col-sm-6,
.is-col-sm-7,
.is-col-sm-8,
.is-col-sm-9,
.is-col-sm-10,
.is-col-sm-11,
.is-col-sm-12,
.is-col-md,
.is-col-md-auto,
.is-col-md-1,
.is-col-md-2,
.is-col-md-3,
.is-col-md-4,
.is-col-md-5,
.is-col-md-6,
.is-col-md-7,
.is-col-md-8,
.is-col-md-9,
.is-col-md-10,
.is-col-md-11,
.is-col-md-12,
.is-col-lg,
.is-col-lg-auto,
.is-col-lg-1,
.is-col-lg-2,
.is-col-lg-3,
.is-col-lg-4,
.is-col-lg-5,
.is-col-lg-6,
.is-col-lg-7,
.is-col-lg-8,
.is-col-lg-9,
.is-col-lg-10,
.is-col-lg-11,
.is-col-lg-12,
.is-col-xl,
.is-col-xl-auto,
.is-col-xl-1,
.is-col-xl-2,
.is-col-xl-3,
.is-col-xl-4,
.is-col-xl-5,
.is-col-xl-6,
.is-col-xl-7,
.is-col-xl-8,
.is-col-xl-9,
.is-col-xl-10,
.is-col-xl-11,
.is-col-xl-12 {
  width: 100%;
  min-height: 1px;
  padding-right: calc(var(--is-grid-gutter-width) / 2);
  padding-left: calc(var(--is-grid-gutter-width) / 2);
}

.is-no-gutter > .is-row > *[class*="is-col"],
.is-row.is-no-gutter > *[class*="is-col"] {
  padding-right: var(--is-grid-no-gutter);
  padding-left: var(--is-grid-no-gutter);
}

.is-gutter-sm > .is-row > *[class*="is-col"],
.is-row.is-gutter-sm > *[class*="is-col"] {
  padding-right: calc(var(--is-grid-gutter-width-sm) / 2);
  padding-left: calc(var(--is-grid-gutter-width-sm) / 2);
}

.is-gutter-lg > .is-row > *[class*="is-col"],
.is-row.is-gutter-lg > *[class*="is-col"] {
  padding-right: calc(var(--is-grid-gutter-width-lg) / 2);
  padding-left: calc(var(--is-grid-gutter-width-lg) / 2);
}

.is-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.is-col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.is-col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.is-col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.is-col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.is-col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.is-col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.is-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.is-col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.is-col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.is-col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.is-col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.is-col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.is-col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .is-col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .is-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .is-col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .is-col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .is-col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .is-col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .is-col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .is-col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .is-col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .is-col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .is-col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .is-col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .is-col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .is-col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0%;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .is-col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .is-col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .is-col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .is-col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .is-col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .is-col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .is-col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .is-col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .is-col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .is-col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .is-col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .is-col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .is-col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .is-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0%;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .is-col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .is-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .is-col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .is-col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .is-col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .is-col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .is-col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .is-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .is-col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .is-col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .is-col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .is-col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .is-col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .is-col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0%;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .is-col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .is-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .is-col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .is-col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .is-col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .is-col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .is-col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .is-col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .is-col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .is-col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .is-col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .is-col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .is-col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .is-col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0%;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.is-bg-theme {
  background-color: var(--is-theme-bg) !important;
}

.is-bg-theme-muted {
  background-color: var(--is-theme-bg-muted) !important;
}

.is-bg-theme-muted-strong {
  background-color: var(--is-theme-bg-muted-strong) !important;
}

.is-bg-theme-inverse {
  color: var(--is-text-inverse) !important;
  background-color: var(--is-theme-bg-inverse) !important;
}

.is-bg-theme-inverse-muted {
  color: var(--is-text-inverse) !important;
  background-color: var(--is-theme-bg-inverse-muted) !important;
}

.is-bg-theme-inverse-muted-strong {
  color: var(--is-text-inverse) !important;
  background-color: var(--is-theme-bg-inverse-muted-strong) !important;
}

.is-bg-component {
  color: var(--is-component-text) !important;
  background-color: var(--is-component-bg) !important;
}

.is-bg-component-muted {
  color: var(--is-component-text-muted) !important;
  background-color: var(--is-component-bg-muted) !important;
}

.is-bg-component-muted-strong {
  color: var(--is-component-text-muted) !important;
  background-color: var(--is-component-bg-muted-strong) !important;
}

.is-bg-default {
  color: var(--is-default-text) !important;
  background-color: var(--is-default) !important;
}

.is-bg-default-inverse-light {
  color: var(--is-default-inverse-light-text) !important;
  background-color: var(--is-default-inverse-light) !important;
}

.is-bg-default-inverse {
  color: var(--is-default-inverse-text) !important;
  background-color: var(--is-default-inverse) !important;
}

.is-bg-default-inverse-dark {
  color: var(--is-default-inverse-dark-text) !important;
  background-color: var(--is-default-inverse-dark) !important;
}

.is-bg-default-inverse-darker {
  color: var(--is-default-inverse-darker-text) !important;
  background-color: var(--is-default-inverse-darker) !important;
}

.is-bg-default-inverse-darkest {
  color: var(--is-default-inverse-darkest-text) !important;
  background-color: var(--is-default-inverse-darkest) !important;
}

.is-bg-default-light {
  color: var(--is-default-light-text) !important;
  background-color: var(--is-default-light) !important;
}

.is-bg-default-dark {
  color: var(--is-default-dark-text) !important;
  background-color: var(--is-default-dark) !important;
}

.is-bg-default-darker {
  color: var(--is-default-darker-text) !important;
  background-color: var(--is-default-darker) !important;
}

.is-bg-default-darkest {
  color: var(--is-default-darkest-text) !important;
  background-color: var(--is-default-darkest) !important;
}

.is-bg-primary {
  color: var(--is-primary-text) !important;
  background-color: var(--is-primary) !important;
}

.is-bg-primary-inverse-light {
  color: var(--is-primary-inverse-light-text) !important;
  background-color: var(--is-primary-inverse-light) !important;
}

.is-bg-primary-inverse {
  color: var(--is-primary-inverse-text) !important;
  background-color: var(--is-primary-inverse) !important;
}

.is-bg-primary-inverse-dark {
  color: var(--is-primary-inverse-dark-text) !important;
  background-color: var(--is-primary-inverse-dark) !important;
}

.is-bg-primary-inverse-darker {
  color: var(--is-primary-inverse-darker-text) !important;
  background-color: var(--is-primary-inverse-darker) !important;
}

.is-bg-primary-inverse-darkest {
  color: var(--is-primary-inverse-darkest-text) !important;
  background-color: var(--is-primary-inverse-darkest) !important;
}

.is-bg-primary-light {
  color: var(--is-primary-light-text) !important;
  background-color: var(--is-primary-light) !important;
}

.is-bg-primary-dark {
  color: var(--is-primary-dark-text) !important;
  background-color: var(--is-primary-dark) !important;
}

.is-bg-primary-darker {
  color: var(--is-primary-darker-text) !important;
  background-color: var(--is-primary-darker) !important;
}

.is-bg-primary-darkest {
  color: var(--is-primary-darkest-text) !important;
  background-color: var(--is-primary-darkest) !important;
}

.is-bg-secondary {
  color: var(--is-secondary-text) !important;
  background-color: var(--is-secondary) !important;
}

.is-bg-secondary-inverse-light {
  color: var(--is-secondary-inverse-light-text) !important;
  background-color: var(--is-secondary-inverse-light) !important;
}

.is-bg-secondary-inverse {
  color: var(--is-secondary-inverse-text) !important;
  background-color: var(--is-secondary-inverse) !important;
}

.is-bg-secondary-inverse-dark {
  color: var(--is-secondary-inverse-dark-text) !important;
  background-color: var(--is-secondary-inverse-dark) !important;
}

.is-bg-secondary-inverse-darker {
  color: var(--is-secondary-inverse-darker-text) !important;
  background-color: var(--is-secondary-inverse-darker) !important;
}

.is-bg-secondary-inverse-darkest {
  color: var(--is-secondary-inverse-darkest-text) !important;
  background-color: var(--is-secondary-inverse-darkest) !important;
}

.is-bg-secondary-light {
  color: var(--is-secondary-light-text) !important;
  background-color: var(--is-secondary-light) !important;
}

.is-bg-secondary-dark {
  color: var(--is-secondary-dark-text) !important;
  background-color: var(--is-secondary-dark) !important;
}

.is-bg-secondary-darker {
  color: var(--is-secondary-darker-text) !important;
  background-color: var(--is-secondary-darker) !important;
}

.is-bg-secondary-darkest {
  color: var(--is-secondary-darkest-text) !important;
  background-color: var(--is-secondary-darkest) !important;
}

.is-bg-accent {
  color: var(--is-accent-text) !important;
  background-color: var(--is-accent) !important;
}

.is-bg-accent-inverse-light {
  color: var(--is-accent-inverse-light-text) !important;
  background-color: var(--is-accent-inverse-light) !important;
}

.is-bg-accent-inverse {
  color: var(--is-accent-inverse-text) !important;
  background-color: var(--is-accent-inverse) !important;
}

.is-bg-accent-inverse-dark {
  color: var(--is-accent-inverse-dark-text) !important;
  background-color: var(--is-accent-inverse-dark) !important;
}

.is-bg-accent-inverse-darker {
  color: var(--is-accent-inverse-darker-text) !important;
  background-color: var(--is-accent-inverse-darker) !important;
}

.is-bg-accent-inverse-darkest {
  color: var(--is-accent-inverse-darkest-text) !important;
  background-color: var(--is-accent-inverse-darkest) !important;
}

.is-bg-accent-light {
  color: var(--is-accent-light-text) !important;
  background-color: var(--is-accent-light) !important;
}

.is-bg-accent-dark {
  color: var(--is-accent-dark-text) !important;
  background-color: var(--is-accent-dark) !important;
}

.is-bg-accent-darker {
  color: var(--is-accent-darker-text) !important;
  background-color: var(--is-accent-darker) !important;
}

.is-bg-accent-darkest {
  color: var(--is-accent-darkest-text) !important;
  background-color: var(--is-accent-darkest) !important;
}

.is-bg-success {
  color: var(--is-success-text) !important;
  background-color: var(--is-success) !important;
}

.is-bg-success-inverse-light {
  color: var(--is-success-inverse-light-text) !important;
  background-color: var(--is-success-inverse-light) !important;
}

.is-bg-success-inverse {
  color: var(--is-success-inverse-text) !important;
  background-color: var(--is-success-inverse) !important;
}

.is-bg-success-inverse-dark {
  color: var(--is-success-inverse-dark-text) !important;
  background-color: var(--is-success-inverse-dark) !important;
}

.is-bg-success-inverse-darker {
  color: var(--is-success-inverse-darker-text) !important;
  background-color: var(--is-success-inverse-darker) !important;
}

.is-bg-success-inverse-darkest {
  color: var(--is-success-inverse-darkest-text) !important;
  background-color: var(--is-success-inverse-darkest) !important;
}

.is-bg-success-light {
  color: var(--is-success-light-text) !important;
  background-color: var(--is-success-light) !important;
}

.is-bg-success-dark {
  color: var(--is-success-dark-text) !important;
  background-color: var(--is-success-dark) !important;
}

.is-bg-success-darker {
  color: var(--is-success-darker-text) !important;
  background-color: var(--is-success-darker) !important;
}

.is-bg-success-darkest {
  color: var(--is-success-darkest-text) !important;
  background-color: var(--is-success-darkest) !important;
}

.is-bg-warning {
  color: var(--is-warning-text) !important;
  background-color: var(--is-warning) !important;
}

.is-bg-warning-inverse-light {
  color: var(--is-warning-inverse-light-text) !important;
  background-color: var(--is-warning-inverse-light) !important;
}

.is-bg-warning-inverse {
  color: var(--is-warning-inverse-text) !important;
  background-color: var(--is-warning-inverse) !important;
}

.is-bg-warning-inverse-dark {
  color: var(--is-warning-inverse-dark-text) !important;
  background-color: var(--is-warning-inverse-dark) !important;
}

.is-bg-warning-inverse-darker {
  color: var(--is-warning-inverse-darker-text) !important;
  background-color: var(--is-warning-inverse-darker) !important;
}

.is-bg-warning-inverse-darkest {
  color: var(--is-warning-inverse-darkest-text) !important;
  background-color: var(--is-warning-inverse-darkest) !important;
}

.is-bg-warning-light {
  color: var(--is-warning-light-text) !important;
  background-color: var(--is-warning-light) !important;
}

.is-bg-warning-dark {
  color: var(--is-warning-dark-text) !important;
  background-color: var(--is-warning-dark) !important;
}

.is-bg-warning-darker {
  color: var(--is-warning-darker-text) !important;
  background-color: var(--is-warning-darker) !important;
}

.is-bg-warning-darkest {
  color: var(--is-warning-darkest-text) !important;
  background-color: var(--is-warning-darkest) !important;
}

.is-bg-danger {
  color: var(--is-danger-text) !important;
  background-color: var(--is-danger) !important;
}

.is-bg-danger-inverse-light {
  color: var(--is-danger-inverse-light-text) !important;
  background-color: var(--is-danger-inverse-light) !important;
}

.is-bg-danger-inverse {
  color: var(--is-danger-inverse-text) !important;
  background-color: var(--is-danger-inverse) !important;
}

.is-bg-danger-inverse-dark {
  color: var(--is-danger-inverse-dark-text) !important;
  background-color: var(--is-danger-inverse-dark) !important;
}

.is-bg-danger-inverse-darker {
  color: var(--is-danger-inverse-darker-text) !important;
  background-color: var(--is-danger-inverse-darker) !important;
}

.is-bg-danger-inverse-darkest {
  color: var(--is-danger-inverse-darkest-text) !important;
  background-color: var(--is-danger-inverse-darkest) !important;
}

.is-bg-danger-light {
  color: var(--is-danger-light-text) !important;
  background-color: var(--is-danger-light) !important;
}

.is-bg-danger-dark {
  color: var(--is-danger-dark-text) !important;
  background-color: var(--is-danger-dark) !important;
}

.is-bg-danger-darker {
  color: var(--is-danger-darker-text) !important;
  background-color: var(--is-danger-darker) !important;
}

.is-bg-danger-darkest {
  color: var(--is-danger-darkest-text) !important;
  background-color: var(--is-danger-darkest) !important;
}

.is-bg-light {
  color: var(--is-color-light-text) !important;
  background-color: var(--is-color-light) !important;
}

.is-bg-light-inverse {
  color: var(--is-color-light) !important;
  background-color: var(--is-color-light-inverse) !important;
}

.is-bg-dark {
  color: var(--is-color-dark-text) !important;
  background-color: var(--is-color-dark) !important;
}

.is-bg-dark-inverse {
  color: var(--is-color-dark) !important;
  background-color: var(--is-color-dark-inverse) !important;
}

.is-bg-white {
  color: var(--is-color-white-text) !important;
  background-color: var(--is-color-white) !important;
}

.is-bg-white-inverse {
  color: var(--is-color-white) !important;
  background-color: var(--is-color-white-inverse) !important;
}

.is-bg-blue {
  color: var(--is-color-blue-text) !important;
  background-color: var(--is-color-blue) !important;
}

.is-bg-blue-inverse {
  color: var(--is-color-blue) !important;
  background-color: var(--is-color-blue-inverse) !important;
}

.is-bg-geekblue {
  color: var(--is-color-geekblue-text) !important;
  background-color: var(--is-color-geekblue) !important;
}

.is-bg-geekblue-inverse {
  color: var(--is-color-geekblue) !important;
  background-color: var(--is-color-geekblue-inverse) !important;
}

.is-bg-teal {
  color: var(--is-color-teal-text) !important;
  background-color: var(--is-color-teal) !important;
}

.is-bg-teal-inverse {
  color: var(--is-color-teal) !important;
  background-color: var(--is-color-teal-inverse) !important;
}

.is-bg-cyan {
  color: var(--is-color-cyan-text) !important;
  background-color: var(--is-color-cyan) !important;
}

.is-bg-cyan-inverse {
  color: var(--is-color-cyan) !important;
  background-color: var(--is-color-cyan-inverse) !important;
}

.is-bg-green {
  color: var(--is-color-green-text) !important;
  background-color: var(--is-color-green) !important;
}

.is-bg-green-inverse {
  color: var(--is-color-green) !important;
  background-color: var(--is-color-green-inverse) !important;
}

.is-bg-lime {
  color: var(--is-color-lime-text) !important;
  background-color: var(--is-color-lime) !important;
}

.is-bg-lime-inverse {
  color: var(--is-color-lime) !important;
  background-color: var(--is-color-lime-inverse) !important;
}

.is-bg-yellow {
  color: var(--is-color-yellow-text) !important;
  background-color: var(--is-color-yellow) !important;
}

.is-bg-yellow-inverse {
  color: var(--is-color-yellow) !important;
  background-color: var(--is-color-yellow-inverse) !important;
}

.is-bg-gold {
  color: var(--is-color-gold-text) !important;
  background-color: var(--is-color-gold) !important;
}

.is-bg-gold-inverse {
  color: var(--is-color-gold) !important;
  background-color: var(--is-color-gold-inverse) !important;
}

.is-bg-orange {
  color: var(--is-color-orange-text) !important;
  background-color: var(--is-color-orange) !important;
}

.is-bg-orange-inverse {
  color: var(--is-color-orange) !important;
  background-color: var(--is-color-orange-inverse) !important;
}

.is-bg-volcano {
  color: var(--is-color-volcano-text) !important;
  background-color: var(--is-color-volcano) !important;
}

.is-bg-volcano-inverse {
  color: var(--is-color-volcano) !important;
  background-color: var(--is-color-volcano-inverse) !important;
}

.is-bg-red {
  color: var(--is-color-red-text) !important;
  background-color: var(--is-color-red) !important;
}

.is-bg-red-inverse {
  color: var(--is-color-red) !important;
  background-color: var(--is-color-red-inverse) !important;
}

.is-bg-pink {
  color: var(--is-color-pink-text) !important;
  background-color: var(--is-color-pink) !important;
}

.is-bg-pink-inverse {
  color: var(--is-color-pink) !important;
  background-color: var(--is-color-pink-inverse) !important;
}

.is-bg-magenta {
  color: var(--is-color-magenta-text) !important;
  background-color: var(--is-color-magenta) !important;
}

.is-bg-magenta-inverse {
  color: var(--is-color-magenta) !important;
  background-color: var(--is-color-magenta-inverse) !important;
}

.is-bg-purple {
  color: var(--is-color-purple-text) !important;
  background-color: var(--is-color-purple) !important;
}

.is-bg-purple-inverse {
  color: var(--is-color-purple) !important;
  background-color: var(--is-color-purple-inverse) !important;
}

.is-bg-black {
  color: var(--is-color-black-text) !important;
  background-color: var(--is-color-black) !important;
}

.is-bg-black-inverse {
  color: var(--is-color-black) !important;
  background-color: var(--is-color-black-inverse) !important;
}

.is-overlay {
  background-color: var(--is-overlay) !important;
  color: var(--is-text-inverse) !important;
}

.is-overlay-weak {
  background-color: var(--is-overlay-weak) !important;
  color: var(--is-text-inverse) !important;
}

.is-overlay-strong {
  background-color: var(--is-overlay-strong) !important;
  color: var(--is-text-inverse) !important;
}

.is-overlay-inverse {
  background-color: var(--is-overlay-inverse) !important;
  color: var(--is-text) !important;
}

.is-overlay-inverse-weak {
  background-color: var(--is-overlay-inverse-weak) !important;
  color: var(--is-text) !important;
}

.is-overlay-inverse-strong {
  background-color: var(--is-overlay-inverse-strong) !important;
  color: var(--is-text) !important;
}

.is-bg-grey {
  color: var(--is-color-grey-text) !important;
  background-color: var(--is-color-grey) !important;
}

.is-bg-violet {
  color: var(--is-color-violet-text) !important;
  background-color: var(--is-color-violet) !important;
}

.is-border {
  border: 1px solid var(--is-border) !important;
}

.border-inherit {
  border-color: inherit !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

.is-border-top {
  border-top: 1px solid var(--is-border) !important;
}

.border-top-inherit {
  border-top-color: inherit !important;
}

.border-top-dashed {
  border-top-style: dashed !important;
}

.border-top-dotted {
  border-top-style: dotted !important;
}

.is-border-bottom {
  border-bottom: 1px solid var(--is-border) !important;
}

.border-bottom-inherit {
  border-bottom-color: inherit !important;
}

.border-bottom-dashed {
  border-bottom-style: dashed !important;
}

.border-bottom-dotted {
  border-bottom-style: dotted !important;
}

.is-border-left {
  border-left: 1px solid var(--is-border) !important;
}

.border-left-inherit {
  border-left-color: inherit !important;
}

.border-left-dashed {
  border-left-style: dashed !important;
}

.border-left-dotted {
  border-left-style: dotted !important;
}

.is-border-right {
  border-right: 1px solid var(--is-border) !important;
}

.border-right-inherit {
  border-right-color: inherit !important;
}

.border-right-dashed {
  border-right-style: dashed !important;
}

.border-right-dotted {
  border-right-style: dotted !important;
}

.is-border-0 {
  border-width: 0 !important;
}

.is-border-thin {
  border-color: var(--is-border-thin) !important;
}

.is-border-bold {
  border-color: var(--is-border-bold) !important;
}

.is-border-inverse {
  border-color: var(--is-border-inverse) !important;
}

.is-border-component {
  border-color: var(--is-component-border-color) !important;
}

.is-border-component-muted {
  border-color: var(--is-component-border-color-muted) !important;
}

.is-border-primary {
  border-color: var(--is-primary) !important;
}

.is-border-primary-inverse {
  border-color: var(--is-primary-inverse) !important;
}

.is-border-secondary {
  border-color: var(--is-secondary) !important;
}

.is-border-secondary-inverse {
  border-color: var(--is-secondary-inverse) !important;
}

.is-border-accent {
  border-color: var(--is-accent) !important;
}

.is-border-accent-inverse {
  border-color: var(--is-accent-inverse) !important;
}

.is-border-success {
  border-color: var(--is-success) !important;
}

.is-border-success-inverse {
  border-color: var(--is-success-inverse) !important;
}

.is-border-warning {
  border-color: var(--is-warning) !important;
}

.is-border-warning-inverse {
  border-color: var(--is-warning-inverse) !important;
}

.is-border-danger {
  border-color: var(--is-danger) !important;
}

.is-border-danger-inverse {
  border-color: var(--is-danger-inverse) !important;
}

@media (min-width: 576px) {
  .is-sm-border-0 {
    border-width: 0 !important;
  }
  .is-sm-border-top-0 {
    border-top-width: 0 !important;
  }
  .is-sm-border-bottom-0 {
    border-bottom-width: 0 !important;
  }
  .is-sm-border-left-0 {
    border-left-width: 0 !important;
  }
  .is-sm-border-right-0 {
    border-right-width: 0 !important;
  }
}

@media (min-width: 768px) {
  .is-md-border-0 {
    border-width: 0 !important;
  }
  .is-md-border-top-0 {
    border-top-width: 0 !important;
  }
  .is-md-border-bottom-0 {
    border-bottom-width: 0 !important;
  }
  .is-md-border-left-0 {
    border-left-width: 0 !important;
  }
  .is-md-border-right-0 {
    border-right-width: 0 !important;
  }
}

@media (min-width: 992px) {
  .is-lg-border-0 {
    border-width: 0 !important;
  }
  .is-lg-border-top-0 {
    border-top-width: 0 !important;
  }
  .is-lg-border-bottom-0 {
    border-bottom-width: 0 !important;
  }
  .is-lg-border-left-0 {
    border-left-width: 0 !important;
  }
  .is-lg-border-right-0 {
    border-right-width: 0 !important;
  }
}

@media (min-width: 1200px) {
  .is-xl-border-0 {
    border-width: 0 !important;
  }
  .is-xl-border-top-0 {
    border-top-width: 0 !important;
  }
  .is-xl-border-bottom-0 {
    border-bottom-width: 0 !important;
  }
  .is-xl-border-left-0 {
    border-left-width: 0 !important;
  }
  .is-xl-border-right-0 {
    border-right-width: 0 !important;
  }
}

.is-rounded-0 {
  border-radius: 0 !important;
}

.is-rounded-1 {
  border-radius: var(--is-rounded-1) !important;
}

.is-rounded,
.is-rounded-2 {
  border-radius: var(--is-rounded) !important;
}

.is-rounded-3 {
  border-radius: var(--is-rounded-3) !important;
}

.is-rounded-4 {
  border-radius: var(--is-rounded-4) !important;
}

.is-rounded-circle {
  border-radius: 50% !important;
}

.is-rounded-pill {
  border-radius: 50rem !important;
}

.is-rounded-top {
  border-top-left-radius: var(--is-rounded) !important;
  border-top-right-radius: var(--is-rounded) !important;
}

.is-rounded-right {
  border-top-right-radius: var(--is-rounded) !important;
  border-bottom-right-radius: var(--is-rounded) !important;
}

.is-rounded-bottom {
  border-bottom-right-radius: var(--is-rounded) !important;
  border-bottom-left-radius: var(--is-rounded) !important;
}

.is-rounded-left {
  border-bottom-left-radius: var(--is-rounded) !important;
  border-top-left-radius: var(--is-rounded) !important;
}

.is-cursor-pointer {
  cursor: pointer !important;
}

.is-cursor-help {
  cursor: help !important;
}

.is-display-none {
  display: none !important;
}

.is-display-inline {
  display: inline !important;
}

.is-display-inline-block {
  display: inline-block !important;
}

.is-display-block {
  display: block !important;
}

.is-display-flex {
  display: flex !important;
}

.is-display-inline-flex {
  display: inline-flex !important;
}

@media print {
  .is-print-display-none {
    display: none !important;
  }
  .is-print-display-inline {
    display: inline !important;
  }
  .is-print-display-inline-block {
    display: inline-block !important;
  }
  .is-print-display-block {
    display: block !important;
  }
  .is-print-display-flex {
    display: flex !important;
  }
  .is-print-display-inline-flex {
    display: inline-flex !important;
  }
}

.is-flex-fill {
  flex: 1 1 auto !important;
}

.is-flex-row {
  flex-direction: row !important;
}

.is-flex-column {
  flex-direction: column !important;
}

.is-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.is-flex-column-reverse {
  flex-direction: column-reverse !important;
}

.is-flex-grow-0 {
  flex-grow: 0 !important;
}

.is-flex-grow-1 {
  flex-grow: 1 !important;
}

.is-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.is-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.is-flex-wrap {
  flex-wrap: wrap !important;
}

.is-flex-nowrap {
  flex-wrap: nowrap !important;
}

.is-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.is-justify-content-start {
  justify-content: flex-start !important;
}

.is-justify-content-end {
  justify-content: flex-end !important;
}

.is-justify-content-center {
  justify-content: center !important;
}

.is-justify-content-between {
  justify-content: space-between !important;
}

.is-justify-content-around {
  justify-content: space-around !important;
}

.is-align-items-start {
  align-items: flex-start !important;
}

.is-align-items-end {
  align-items: flex-end !important;
}

.is-align-items-center {
  align-items: center !important;
}

.is-align-items-baseline {
  align-items: baseline !important;
}

.is-align-items-stretch {
  align-items: stretch !important;
}

.is-align-content-start {
  align-content: flex-start !important;
}

.is-align-content-end {
  align-content: flex-end !important;
}

.is-align-content-center {
  align-content: center !important;
}

.is-align-content-between {
  align-content: space-between !important;
}

.is-align-content-around {
  align-content: space-around !important;
}

.is-align-content-stretch {
  align-content: stretch !important;
}

.is-align-self-auto {
  align-self: auto !important;
}

.is-align-self-start {
  align-self: flex-start !important;
}

.is-align-self-end {
  align-self: flex-end !important;
}

.is-align-self-center {
  align-self: center !important;
}

.is-align-self-baseline {
  align-self: baseline !important;
}

.is-align-self-stretch {
  align-self: stretch !important;
}

.is-order-first {
  order: -1 !important;
}

.is-order-0 {
  order: 0 !important;
}

.is-order-1 {
  order: 1 !important;
}

.is-order-2 {
  order: 2 !important;
}

.is-order-3 {
  order: 3 !important;
}

.is-order-4 {
  order: 4 !important;
}

.is-order-5 {
  order: 5 !important;
}

.is-order-last {
  order: 6 !important;
}

.is-float-left {
  float: left !important;
}

.is-float-right {
  float: right !important;
}

.is-float-none {
  float: none !important;
}

.is-clear {
  clear: both !important;
}

.is-clear-left {
  clear: left !important;
}

.is-clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.is-opacity-1 {
  opacity: 1 !important;
}

.is-opacity-90 {
  opacity: 0.9 !important;
}

.is-opacity-80 {
  opacity: 0.8 !important;
}

.is-opacity-70 {
  opacity: 0.7 !important;
}

.is-opacity-60 {
  opacity: 0.6 !important;
}

.is-opacity-50 {
  opacity: 0.5 !important;
}

.is-opacity-40 {
  opacity: 0.4 !important;
}

.is-opacity-30 {
  opacity: 0.3 !important;
}

.is-opacity-20 {
  opacity: 0.2 !important;
}

.is-opacity-10 {
  opacity: 0.1 !important;
}

.is-opacity-0 {
  opacity: 0 !important;
}

.is-overflow-visible {
  overflow: visible !important;
}

.is-overflow-auto {
  overflow: auto !important;
}

.is-overflow-y-auto {
  overflow-y: auto !important;
}

.is-overflow-x-auto {
  overflow-x: auto !important;
}

.is-overflow-hidden {
  overflow: hidden !important;
}

.is-overflow-y-hidden {
  overflow-y: hidden !important;
}

.is-overflow-x-hidden {
  overflow-x: hidden !important;
}

.is-pos-static {
  position: static !important;
}

.is-pos-relative {
  position: relative !important;
}

.is-pos-absolute {
  position: absolute !important;
}

.is-pos-fixed {
  position: fixed !important;
}

.is-pos-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.is-pos-top-0 {
  top: 0 !important;
}

.is-pos-bottom-0 {
  bottom: 0 !important;
}

.is-pos-left-0 {
  left: 0 !important;
}

.is-pos-right-0 {
  right: 0 !important;
}

.is-pos-full {
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
}

.is-pos-x-center {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.is-pos-y-center {
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.is-mar-gutter {
  margin: var(--is-grid-gutter-width) !important;
}

.is-mar-top-gutter {
  margin-top: var(--is-grid-gutter-width) !important;
}

.is-mar-bottom-gutter {
  margin-bottom: var(--is-grid-gutter-width) !important;
}

.is-mar-left-gutter {
  margin-left: var(--is-grid-gutter-width) !important;
}

.is-mar-right-gutter {
  margin-right: var(--is-grid-gutter-width) !important;
}

.is-mar-x-gutter {
  margin-right: var(--is-grid-gutter-width) !important;
  margin-left: var(--is-grid-gutter-width) !important;
}

.is-mar-y-gutter {
  margin-top: var(--is-grid-gutter-width) !important;
  margin-bottom: var(--is-grid-gutter-width) !important;
}

.is-mar-gutter-sm {
  margin: var(--is-grid-gutter-width-sm) !important;
}

.is-mar-top-gutter-sm {
  margin-top: var(--is-grid-gutter-width-sm) !important;
}

.is-mar-bottom-gutter-sm {
  margin-bottom: var(--is-grid-gutter-width-sm) !important;
}

.is-mar-left-gutter-sm {
  margin-left: var(--is-grid-gutter-width-sm) !important;
}

.is-mar-right-gutter-sm {
  margin-right: var(--is-grid-gutter-width-sm) !important;
}

.is-mar-x-gutter-sm {
  margin-right: var(--is-grid-gutter-width-sm) !important;
  margin-left: var(--is-grid-gutter-width-sm) !important;
}

.is-mar-y-gutter-sm {
  margin-top: var(--is-grid-gutter-width-sm) !important;
  margin-bottom: var(--is-grid-gutter-width-sm) !important;
}

.is-mar-gutter-lg {
  margin: var(--is-grid-gutter-width-lg) !important;
}

.is-mar-top-gutter-lg {
  margin-top: var(--is-grid-gutter-width-lg) !important;
}

.is-mar-bottom-gutter-lg {
  margin-bottom: var(--is-grid-gutter-width-lg) !important;
}

.is-mar-left-gutter-lg {
  margin-left: var(--is-grid-gutter-width-lg) !important;
}

.is-mar-right-gutter-lg {
  margin-right: var(--is-grid-gutter-width-lg) !important;
}

.is-mar-y-gutter-lg {
  margin-top: var(--is-grid-gutter-width-lg) !important;
  margin-bottom: var(--is-grid-gutter-width-lg) !important;
}

.is-mar-x-gutter-lg {
  margin-left: var(--is-grid-gutter-width-lg) !important;
  margin-right: var(--is-grid-gutter-width-lg) !important;
}

.is-grid-vertical-gutter {
  margin-top: calc(var(--is-grid-gutter-width) / 2) !important;
  margin-bottom: calc(var(--is-grid-gutter-width) / 2) !important;
}

.is-grid-vertical-gutter-sm {
  margin-top: calc(var(--is-grid-gutter-width-sm) / 2) !important;
  margin-bottom: calc(var(--is-grid-gutter-width-sm) / 2) !important;
}

.is-grid-vertical-gutter-lg {
  margin-top: calc(var(--is-grid-gutter-width-lg) / 2) !important;
  margin-bottom: calc(var(--is-grid-gutter-width-lg) / 2) !important;
}

.is-grid-bottom-gutter {
  margin-bottom: calc(var(--is-grid-gutter-width) / 2) !important;
}

.is-grid-bottom-gutter-sm {
  margin-bottom: calc(var(--is-grid-gutter-width-sm) / 2) !important;
}

.is-grid-bottom-gutter-lg {
  margin-bottom: calc(var(--is-grid-gutter-width-lg) / 2) !important;
}

.is-grid-top-gutter {
  margin-top: calc(var(--is-grid-gutter-width) / 2) !important;
}

.is-grid-top-gutter-sm {
  margin-top: calc(var(--is-grid-gutter-width-sm) / 2) !important;
}

.is-grid-top-gutter-lg {
  margin-top: calc(var(--is-grid-gutter-width-lg) / 2) !important;
}

.is-grid-vertical-gutter.is-height-100 {
  height: calc(100% - var(--is-grid-gutter-width)) !important;
}

.is-grid-vertical-gutter-sm.is-height-100 {
  height: calc(100% - var(--is-grid-gutter-width-sm)) !important;
}

.is-grid-vertical-gutter-lg.is-height-100 {
  height: calc(100% - var(--is-grid-gutter-width-lg)) !important;
}

.is-pad-gutter {
  padding: var(--is-grid-gutter-width) !important;
}

.is-pad-top-gutter {
  padding-top: var(--is-grid-gutter-width) !important;
}

.is-pad-bottom-gutter {
  padding-bottom: var(--is-grid-gutter-width) !important;
}

.is-pad-left-gutter {
  padding-left: var(--is-grid-gutter-width) !important;
}

.is-pad-right-gutter {
  padding-right: var(--is-grid-gutter-width) !important;
}

.is-pad-x-gutter {
  padding-right: var(--is-grid-gutter-width) !important;
  padding-left: var(--is-grid-gutter-width) !important;
}

.is-pad-y-gutter {
  padding-top: var(--is-grid-gutter-width) !important;
  padding-bottom: var(--is-grid-gutter-width) !important;
}

.is-pad-gutter-sm {
  padding: var(--is-grid-gutter-width-sm) !important;
}

.is-pad-top-gutter-sm {
  padding-top: var(--is-grid-gutter-width-sm) !important;
}

.is-pad-bottom-gutter-sm {
  padding-bottom: var(--is-grid-gutter-width-sm) !important;
}

.is-pad-left-gutter-sm {
  padding-left: var(--is-grid-gutter-width-sm) !important;
}

.is-pad-right-gutter-sm {
  padding-right: var(--is-grid-gutter-width-sm) !important;
}

.is-pad-x-gutter-sm {
  padding-right: var(--is-grid-gutter-width-sm) !important;
  padding-left: var(--is-grid-gutter-width-sm) !important;
}

.is-pad-y-gutter-sm {
  padding-top: var(--is-grid-gutter-width-sm) !important;
  padding-bottom: var(--is-grid-gutter-width-sm) !important;
}

.is-pad-gutter-lg {
  padding: var(--is-grid-gutter-width-lg) !important;
}

.is-pad-top-gutter-lg {
  padding-top: var(--is-grid-gutter-width-lg) !important;
}

.is-pad-bottom-gutter-lg {
  padding-bottom: var(--is-grid-gutter-width-lg) !important;
}

.is-pad-left-gutter-lg {
  padding-left: var(--is-grid-gutter-width-lg) !important;
}

.is-pad-right-gutter-lg {
  padding-right: var(--is-grid-gutter-width-lg) !important;
}

.is-pad-x-gutter-lg {
  padding-right: var(--is-grid-gutter-width-lg) !important;
  padding-left: var(--is-grid-gutter-width-lg) !important;
}

.is-pad-y-gutter-lg {
  padding-top: var(--is-grid-gutter-width-lg) !important;
  padding-bottom: var(--is-grid-gutter-width-lg) !important;
}

.is-pos-left-gutter {
  left: calc(var(--is-grid-gutter-width) / 2) !important;
}

.is-pos-right-gutter {
  right: calc(var(--is-grid-gutter-width) / 2) !important;
}

.is-pos-left-gutter-sm {
  left: calc(var(--is-grid-gutter-width-sm) / 2) !important;
}

.is-pos-right-gutter-sm {
  right: calc(var(--is-grid-gutter-width-sm) / 2) !important;
}

.is-pos-left-gutter-lg {
  left: calc(var(--is-grid-gutter-width-lg) / 2) !important;
}

.is-pos-right-gutter-lg {
  right: calc(var(--is-grid-gutter-width-lg) / 2) !important;
}

.is-shadow {
  box-shadow: var(--is-box-shadow) !important;
}

.is-shadow-xs {
  box-shadow: var(--is-box-shadow-xs) !important;
}

.is-shadow-sm {
  box-shadow: var(--is-box-shadow-sm) !important;
}

.is-shadow-lg {
  box-shadow: var(--is-box-shadow-lg) !important;
}

.is-shadow-xl {
  box-shadow: var(--is-box-shadow-xl) !important;
}

.is-shadow-none {
  box-shadow: none !important;
}

.is-width-25 {
  width: var(--is-size-25) !important;
}

.is-width-50 {
  width: var(--is-size-50) !important;
}

.is-width-75 {
  width: var(--is-size-75) !important;
}

.is-width-100 {
  width: var(--is-size-100) !important;
}

.is-width-auto {
  width: var(--is-size-auto) !important;
}

.is-height-25 {
  height: var(--is-size-25) !important;
}

.is-height-50 {
  height: var(--is-size-50) !important;
}

.is-height-75 {
  height: var(--is-size-75) !important;
}

.is-height-100 {
  height: var(--is-size-100) !important;
}

.is-height-auto {
  height: var(--is-size-auto) !important;
}

.is-max-width-100 {
  max-width: var(--is-size-100) !important;
}

.is-max-height-100 {
  max-height: var(--is-size-100) !important;
}

.is-vw-100 {
  width: var(--is-vw-100) !important;
}

.is-vh-100 {
  height: var(--is-vh-100) !important;
}

.is-min-vw-100 {
  min-width: var(--is-vw-100) !important;
}

.is-min-vh-100 {
  min-height: var(--is-vh-100) !important;
}

.is-wu-1 {
  width: var(--is-wu);
}

.is-wu-2 {
  width: var(--is-wu-2);
}

.is-wu-3 {
  width: var(--is-wu-3);
}

.is-wu-4 {
  width: var(--is-wu-4);
}

.is-wu-5 {
  width: var(--is-wu-5);
}

.is-wu-6 {
  width: var(--is-wu-6);
}

.is-wu-7 {
  width: var(--is-wu-7);
}

.is-wu-8 {
  width: var(--is-wu-8);
}

.is-wu-9 {
  width: var(--is-wu-9);
}

.is-wu-10 {
  width: var(--is-wu-10);
}

.is-wu-11 {
  width: var(--is-wu-11);
}

.is-wu-12 {
  width: var(--is-wu-12);
}

.is-wu-13 {
  width: var(--is-wu-13);
}

.is-wu-14 {
  width: var(--is-wu-14);
}

.is-wu-15 {
  width: var(--is-wu-15);
}

.is-wu-16 {
  width: var(--is-wu-16);
}

.is-wu-17 {
  width: var(--is-wu-17);
}

.is-wu-18 {
  width: var(--is-wu-18);
}

.is-wu-19 {
  width: var(--is-wu-19);
}

.is-wu-20 {
  width: var(--is-wu-20);
}

.is-wu-21 {
  width: var(--is-wu-21);
}

.is-wu-22 {
  width: var(--is-wu-22);
}

.is-wu-23 {
  width: var(--is-wu-23);
}

.is-wu-24 {
  width: var(--is-wu-24);
}

.is-wu-25 {
  width: var(--is-wu-25);
}

.is-wu-26 {
  width: var(--is-wu-26);
}

.is-wu-27 {
  width: var(--is-wu-27);
}

.is-wu-28 {
  width: var(--is-wu-28);
}

.is-wu-29 {
  width: var(--is-wu-29);
}

.is-wu-30 {
  width: var(--is-wu-30);
}

.is-wu-31 {
  width: var(--is-wu-31);
}

.is-wu-32 {
  width: var(--is-wu-32);
}

.is-wu-33 {
  width: var(--is-wu-33);
}

.is-wu-34 {
  width: var(--is-wu-34);
}

.is-wu-35 {
  width: var(--is-wu-35);
}

.is-wu-36 {
  width: var(--is-wu-36);
}

.is-wu-37 {
  width: var(--is-wu-37);
}

.is-wu-38 {
  width: var(--is-wu-38);
}

.is-wu-39 {
  width: var(--is-wu-39);
}

.is-wu-40 {
  width: var(--is-wu-40);
}

.is-wu-41 {
  width: var(--is-wu-41);
}

.is-wu-42 {
  width: var(--is-wu-42);
}

.is-wu-43 {
  width: var(--is-wu-43);
}

.is-wu-44 {
  width: var(--is-wu-44);
}

.is-wu-45 {
  width: var(--is-wu-45);
}

.is-wu-46 {
  width: var(--is-wu-46);
}

.is-wu-47 {
  width: var(--is-wu-47);
}

.is-wu-48 {
  width: var(--is-wu-48);
}

.is-wu-49 {
  width: var(--is-wu-49);
}

.is-wu-50 {
  width: var(--is-wu-50);
}

.is-wu-55 {
  width: var(--is-wu-55);
}

.is-wu-60 {
  width: var(--is-wu-60);
}

.is-wu-65 {
  width: var(--is-wu-65);
}

.is-wu-70 {
  width: var(--is-wu-70);
}

.is-wu-75 {
  width: var(--is-wu-75);
}

.is-wu-80 {
  width: var(--is-wu-80);
}

.is-wu-85 {
  width: var(--is-wu-85);
}

.is-wu-90 {
  width: var(--is-wu-90);
}

.is-wu-95 {
  width: var(--is-wu-95);
}

.is-wu-100 {
  width: var(--is-wu-100);
}

.is-min-wu-1 {
  width: var(--is-wu);
}

.is-min-wu-2 {
  width: var(--is-wu-2);
}

.is-min-wu-3 {
  width: var(--is-wu-3);
}

.is-min-wu-4 {
  width: var(--is-wu-4);
}

.is-min-wu-5 {
  width: var(--is-wu-5);
}

.is-min-wu-6 {
  width: var(--is-wu-6);
}

.is-min-wu-7 {
  width: var(--is-wu-7);
}

.is-min-wu-8 {
  width: var(--is-wu-8);
}

.is-min-wu-9 {
  width: var(--is-wu-9);
}

.is-min-wu-10 {
  width: var(--is-wu-10);
}

.is-min-wu-11 {
  width: var(--is-wu-11);
}

.is-min-wu-12 {
  width: var(--is-wu-12);
}

.is-min-wu-13 {
  width: var(--is-wu-13);
}

.is-min-wu-14 {
  width: var(--is-wu-14);
}

.is-min-wu-15 {
  width: var(--is-wu-15);
}

.is-min-wu-16 {
  width: var(--is-wu-16);
}

.is-min-wu-17 {
  width: var(--is-wu-17);
}

.is-min-wu-18 {
  width: var(--is-wu-18);
}

.is-min-wu-19 {
  width: var(--is-wu-19);
}

.is-min-wu-20 {
  width: var(--is-wu-20);
}

.is-min-wu-21 {
  width: var(--is-wu-21);
}

.is-min-wu-22 {
  width: var(--is-wu-22);
}

.is-min-wu-23 {
  width: var(--is-wu-23);
}

.is-min-wu-24 {
  width: var(--is-wu-24);
}

.is-min-wu-25 {
  width: var(--is-wu-25);
}

.is-min-wu-26 {
  width: var(--is-wu-26);
}

.is-min-wu-27 {
  width: var(--is-wu-27);
}

.is-min-wu-28 {
  width: var(--is-wu-28);
}

.is-min-wu-29 {
  width: var(--is-wu-29);
}

.is-min-wu-30 {
  width: var(--is-wu-30);
}

.is-min-wu-31 {
  width: var(--is-wu-31);
}

.is-min-wu-32 {
  width: var(--is-wu-32);
}

.is-min-wu-33 {
  width: var(--is-wu-33);
}

.is-min-wu-34 {
  width: var(--is-wu-34);
}

.is-min-wu-35 {
  width: var(--is-wu-35);
}

.is-min-wu-36 {
  width: var(--is-wu-36);
}

.is-min-wu-37 {
  width: var(--is-wu-37);
}

.is-min-wu-38 {
  width: var(--is-wu-38);
}

.is-min-wu-39 {
  width: var(--is-wu-39);
}

.is-min-wu-40 {
  width: var(--is-wu-40);
}

.is-min-wu-41 {
  width: var(--is-wu-41);
}

.is-min-wu-42 {
  width: var(--is-wu-42);
}

.is-min-wu-43 {
  width: var(--is-wu-43);
}

.is-min-wu-44 {
  width: var(--is-wu-44);
}

.is-min-wu-45 {
  width: var(--is-wu-45);
}

.is-min-wu-46 {
  width: var(--is-wu-46);
}

.is-min-wu-47 {
  width: var(--is-wu-47);
}

.is-min-wu-48 {
  width: var(--is-wu-48);
}

.is-min-wu-49 {
  width: var(--is-wu-49);
}

.is-min-wu-50 {
  width: var(--is-wu-50);
}

.is-min-wu-55 {
  width: var(--is-wu-55);
}

.is-min-wu-60 {
  width: var(--is-wu-60);
}

.is-min-wu-65 {
  width: var(--is-wu-65);
}

.is-min-wu-70 {
  width: var(--is-wu-70);
}

.is-min-wu-75 {
  width: var(--is-wu-75);
}

.is-min-wu-80 {
  width: var(--is-wu-80);
}

.is-min-wu-85 {
  width: var(--is-wu-85);
}

.is-min-wu-90 {
  width: var(--is-wu-90);
}

.is-min-wu-95 {
  width: var(--is-wu-95);
}

.is-min-wu-100 {
  width: var(--is-wu-100);
}

.is-max-wu-1 {
  width: var(--is-wu);
}

.is-max-wu-2 {
  width: var(--is-wu-2);
}

.is-max-wu-3 {
  width: var(--is-wu-3);
}

.is-max-wu-4 {
  width: var(--is-wu-4);
}

.is-max-wu-5 {
  width: var(--is-wu-5);
}

.is-max-wu-6 {
  width: var(--is-wu-6);
}

.is-max-wu-7 {
  width: var(--is-wu-7);
}

.is-max-wu-8 {
  width: var(--is-wu-8);
}

.is-max-wu-9 {
  width: var(--is-wu-9);
}

.is-max-wu-10 {
  width: var(--is-wu-10);
}

.is-max-wu-11 {
  width: var(--is-wu-11);
}

.is-max-wu-12 {
  width: var(--is-wu-12);
}

.is-max-wu-13 {
  width: var(--is-wu-13);
}

.is-max-wu-14 {
  width: var(--is-wu-14);
}

.is-max-wu-15 {
  width: var(--is-wu-15);
}

.is-max-wu-16 {
  width: var(--is-wu-16);
}

.is-max-wu-17 {
  width: var(--is-wu-17);
}

.is-max-wu-18 {
  width: var(--is-wu-18);
}

.is-max-wu-19 {
  width: var(--is-wu-19);
}

.is-max-wu-20 {
  width: var(--is-wu-20);
}

.is-max-wu-21 {
  width: var(--is-wu-21);
}

.is-max-wu-22 {
  width: var(--is-wu-22);
}

.is-max-wu-23 {
  width: var(--is-wu-23);
}

.is-max-wu-24 {
  width: var(--is-wu-24);
}

.is-max-wu-25 {
  width: var(--is-wu-25);
}

.is-max-wu-26 {
  width: var(--is-wu-26);
}

.is-max-wu-27 {
  width: var(--is-wu-27);
}

.is-max-wu-28 {
  width: var(--is-wu-28);
}

.is-max-wu-29 {
  width: var(--is-wu-29);
}

.is-max-wu-30 {
  width: var(--is-wu-30);
}

.is-max-wu-31 {
  width: var(--is-wu-31);
}

.is-max-wu-32 {
  width: var(--is-wu-32);
}

.is-max-wu-33 {
  width: var(--is-wu-33);
}

.is-max-wu-34 {
  width: var(--is-wu-34);
}

.is-max-wu-35 {
  width: var(--is-wu-35);
}

.is-max-wu-36 {
  width: var(--is-wu-36);
}

.is-max-wu-37 {
  width: var(--is-wu-37);
}

.is-max-wu-38 {
  width: var(--is-wu-38);
}

.is-max-wu-39 {
  width: var(--is-wu-39);
}

.is-max-wu-40 {
  width: var(--is-wu-40);
}

.is-max-wu-41 {
  width: var(--is-wu-41);
}

.is-max-wu-42 {
  width: var(--is-wu-42);
}

.is-max-wu-43 {
  width: var(--is-wu-43);
}

.is-max-wu-44 {
  width: var(--is-wu-44);
}

.is-max-wu-45 {
  width: var(--is-wu-45);
}

.is-max-wu-46 {
  width: var(--is-wu-46);
}

.is-max-wu-47 {
  width: var(--is-wu-47);
}

.is-max-wu-48 {
  width: var(--is-wu-48);
}

.is-max-wu-49 {
  width: var(--is-wu-49);
}

.is-max-wu-50 {
  width: var(--is-wu-50);
}

.is-max-wu-55 {
  width: var(--is-wu-55);
}

.is-max-wu-60 {
  width: var(--is-wu-60);
}

.is-max-wu-65 {
  width: var(--is-wu-65);
}

.is-max-wu-70 {
  width: var(--is-wu-70);
}

.is-max-wu-75 {
  width: var(--is-wu-75);
}

.is-max-wu-80 {
  width: var(--is-wu-80);
}

.is-max-wu-85 {
  width: var(--is-wu-85);
}

.is-max-wu-90 {
  width: var(--is-wu-90);
}

.is-max-wu-95 {
  width: var(--is-wu-95);
}

.is-max-wu-100 {
  width: var(--is-wu-100);
}

.is-hu-1 {
  height: var(--is-hu);
}

.is-hu-2 {
  height: var(--is-hu-2);
}

.is-hu-3 {
  height: var(--is-hu-3);
}

.is-hu-4 {
  height: var(--is-hu-4);
}

.is-hu-5 {
  height: var(--is-hu-5);
}

.is-hu-6 {
  height: var(--is-hu-6);
}

.is-hu-7 {
  height: var(--is-hu-7);
}

.is-hu-8 {
  height: var(--is-hu-8);
}

.is-hu-9 {
  height: var(--is-hu-9);
}

.is-hu-10 {
  height: var(--is-hu-10);
}

.is-hu-11 {
  height: var(--is-hu-11);
}

.is-hu-12 {
  height: var(--is-hu-12);
}

.is-hu-13 {
  height: var(--is-hu-13);
}

.is-hu-14 {
  height: var(--is-hu-14);
}

.is-hu-15 {
  height: var(--is-hu-15);
}

.is-hu-16 {
  height: var(--is-hu-16);
}

.is-hu-17 {
  height: var(--is-hu-17);
}

.is-hu-18 {
  height: var(--is-hu-18);
}

.is-hu-19 {
  height: var(--is-hu-19);
}

.is-hu-20 {
  height: var(--is-hu-20);
}

.is-hu-21 {
  height: var(--is-hu-21);
}

.is-hu-22 {
  height: var(--is-hu-22);
}

.is-hu-23 {
  height: var(--is-hu-23);
}

.is-hu-24 {
  height: var(--is-hu-24);
}

.is-hu-25 {
  height: var(--is-hu-25);
}

.is-hu-26 {
  height: var(--is-hu-26);
}

.is-hu-27 {
  height: var(--is-hu-27);
}

.is-hu-28 {
  height: var(--is-hu-28);
}

.is-hu-29 {
  height: var(--is-hu-29);
}

.is-hu-30 {
  height: var(--is-hu-30);
}

.is-hu-31 {
  height: var(--is-hu-31);
}

.is-hu-32 {
  height: var(--is-hu-32);
}

.is-hu-33 {
  height: var(--is-hu-33);
}

.is-hu-34 {
  height: var(--is-hu-34);
}

.is-hu-35 {
  height: var(--is-hu-35);
}

.is-hu-36 {
  height: var(--is-hu-36);
}

.is-hu-37 {
  height: var(--is-hu-37);
}

.is-hu-38 {
  height: var(--is-hu-38);
}

.is-hu-39 {
  height: var(--is-hu-39);
}

.is-hu-40 {
  height: var(--is-hu-40);
}

.is-hu-41 {
  height: var(--is-hu-41);
}

.is-hu-42 {
  height: var(--is-hu-42);
}

.is-hu-43 {
  height: var(--is-hu-43);
}

.is-hu-44 {
  height: var(--is-hu-44);
}

.is-hu-45 {
  height: var(--is-hu-45);
}

.is-hu-46 {
  height: var(--is-hu-46);
}

.is-hu-47 {
  height: var(--is-hu-47);
}

.is-hu-48 {
  height: var(--is-hu-48);
}

.is-hu-49 {
  height: var(--is-hu-49);
}

.is-hu-50 {
  height: var(--is-hu-50);
}

.is-hu-55 {
  height: var(--is-hu-55);
}

.is-hu-60 {
  height: var(--is-hu-60);
}

.is-hu-65 {
  height: var(--is-hu-65);
}

.is-hu-70 {
  height: var(--is-hu-70);
}

.is-hu-75 {
  height: var(--is-hu-75);
}

.is-hu-80 {
  height: var(--is-hu-80);
}

.is-hu-85 {
  height: var(--is-hu-85);
}

.is-hu-90 {
  height: var(--is-hu-90);
}

.is-hu-95 {
  height: var(--is-hu-95);
}

.is-hu-100 {
  height: var(--is-hu-100);
}

.is-min-hu-1 {
  height: var(--is-hu);
}

.is-min-hu-2 {
  height: var(--is-hu-2);
}

.is-min-hu-3 {
  height: var(--is-hu-3);
}

.is-min-hu-4 {
  height: var(--is-hu-4);
}

.is-min-hu-5 {
  height: var(--is-hu-5);
}

.is-min-hu-6 {
  height: var(--is-hu-6);
}

.is-min-hu-7 {
  height: var(--is-hu-7);
}

.is-min-hu-8 {
  height: var(--is-hu-8);
}

.is-min-hu-9 {
  height: var(--is-hu-9);
}

.is-min-hu-10 {
  height: var(--is-hu-10);
}

.is-min-hu-11 {
  height: var(--is-hu-11);
}

.is-min-hu-12 {
  height: var(--is-hu-12);
}

.is-min-hu-13 {
  height: var(--is-hu-13);
}

.is-min-hu-14 {
  height: var(--is-hu-14);
}

.is-min-hu-15 {
  height: var(--is-hu-15);
}

.is-min-hu-16 {
  height: var(--is-hu-16);
}

.is-min-hu-17 {
  height: var(--is-hu-17);
}

.is-min-hu-18 {
  height: var(--is-hu-18);
}

.is-min-hu-19 {
  height: var(--is-hu-19);
}

.is-min-hu-20 {
  height: var(--is-hu-20);
}

.is-min-hu-21 {
  height: var(--is-hu-21);
}

.is-min-hu-22 {
  height: var(--is-hu-22);
}

.is-min-hu-23 {
  height: var(--is-hu-23);
}

.is-min-hu-24 {
  height: var(--is-hu-24);
}

.is-min-hu-25 {
  height: var(--is-hu-25);
}

.is-min-hu-26 {
  height: var(--is-hu-26);
}

.is-min-hu-27 {
  height: var(--is-hu-27);
}

.is-min-hu-28 {
  height: var(--is-hu-28);
}

.is-min-hu-29 {
  height: var(--is-hu-29);
}

.is-min-hu-30 {
  height: var(--is-hu-30);
}

.is-min-hu-31 {
  height: var(--is-hu-31);
}

.is-min-hu-32 {
  height: var(--is-hu-32);
}

.is-min-hu-33 {
  height: var(--is-hu-33);
}

.is-min-hu-34 {
  height: var(--is-hu-34);
}

.is-min-hu-35 {
  height: var(--is-hu-35);
}

.is-min-hu-36 {
  height: var(--is-hu-36);
}

.is-min-hu-37 {
  height: var(--is-hu-37);
}

.is-min-hu-38 {
  height: var(--is-hu-38);
}

.is-min-hu-39 {
  height: var(--is-hu-39);
}

.is-min-hu-40 {
  height: var(--is-hu-40);
}

.is-min-hu-41 {
  height: var(--is-hu-41);
}

.is-min-hu-42 {
  height: var(--is-hu-42);
}

.is-min-hu-43 {
  height: var(--is-hu-43);
}

.is-min-hu-44 {
  height: var(--is-hu-44);
}

.is-min-hu-45 {
  height: var(--is-hu-45);
}

.is-min-hu-46 {
  height: var(--is-hu-46);
}

.is-min-hu-47 {
  height: var(--is-hu-47);
}

.is-min-hu-48 {
  height: var(--is-hu-48);
}

.is-min-hu-49 {
  height: var(--is-hu-49);
}

.is-min-hu-50 {
  height: var(--is-hu-50);
}

.is-min-hu-55 {
  height: var(--is-hu-55);
}

.is-min-hu-60 {
  height: var(--is-hu-60);
}

.is-min-hu-65 {
  height: var(--is-hu-65);
}

.is-min-hu-70 {
  height: var(--is-hu-70);
}

.is-min-hu-75 {
  height: var(--is-hu-75);
}

.is-min-hu-80 {
  height: var(--is-hu-80);
}

.is-min-hu-85 {
  height: var(--is-hu-85);
}

.is-min-hu-90 {
  height: var(--is-hu-90);
}

.is-min-hu-95 {
  height: var(--is-hu-95);
}

.is-min-hu-100 {
  height: var(--is-hu-100);
}

.is-max-hu-1 {
  height: var(--is-hu);
}

.is-max-hu-2 {
  height: var(--is-hu-2);
}

.is-max-hu-3 {
  height: var(--is-hu-3);
}

.is-max-hu-4 {
  height: var(--is-hu-4);
}

.is-max-hu-5 {
  height: var(--is-hu-5);
}

.is-max-hu-6 {
  height: var(--is-hu-6);
}

.is-max-hu-7 {
  height: var(--is-hu-7);
}

.is-max-hu-8 {
  height: var(--is-hu-8);
}

.is-max-hu-9 {
  height: var(--is-hu-9);
}

.is-max-hu-10 {
  height: var(--is-hu-10);
}

.is-max-hu-11 {
  height: var(--is-hu-11);
}

.is-max-hu-12 {
  height: var(--is-hu-12);
}

.is-max-hu-13 {
  height: var(--is-hu-13);
}

.is-max-hu-14 {
  height: var(--is-hu-14);
}

.is-max-hu-15 {
  height: var(--is-hu-15);
}

.is-max-hu-16 {
  height: var(--is-hu-16);
}

.is-max-hu-17 {
  height: var(--is-hu-17);
}

.is-max-hu-18 {
  height: var(--is-hu-18);
}

.is-max-hu-19 {
  height: var(--is-hu-19);
}

.is-max-hu-20 {
  height: var(--is-hu-20);
}

.is-max-hu-21 {
  height: var(--is-hu-21);
}

.is-max-hu-22 {
  height: var(--is-hu-22);
}

.is-max-hu-23 {
  height: var(--is-hu-23);
}

.is-max-hu-24 {
  height: var(--is-hu-24);
}

.is-max-hu-25 {
  height: var(--is-hu-25);
}

.is-max-hu-26 {
  height: var(--is-hu-26);
}

.is-max-hu-27 {
  height: var(--is-hu-27);
}

.is-max-hu-28 {
  height: var(--is-hu-28);
}

.is-max-hu-29 {
  height: var(--is-hu-29);
}

.is-max-hu-30 {
  height: var(--is-hu-30);
}

.is-max-hu-31 {
  height: var(--is-hu-31);
}

.is-max-hu-32 {
  height: var(--is-hu-32);
}

.is-max-hu-33 {
  height: var(--is-hu-33);
}

.is-max-hu-34 {
  height: var(--is-hu-34);
}

.is-max-hu-35 {
  height: var(--is-hu-35);
}

.is-max-hu-36 {
  height: var(--is-hu-36);
}

.is-max-hu-37 {
  height: var(--is-hu-37);
}

.is-max-hu-38 {
  height: var(--is-hu-38);
}

.is-max-hu-39 {
  height: var(--is-hu-39);
}

.is-max-hu-40 {
  height: var(--is-hu-40);
}

.is-max-hu-41 {
  height: var(--is-hu-41);
}

.is-max-hu-42 {
  height: var(--is-hu-42);
}

.is-max-hu-43 {
  height: var(--is-hu-43);
}

.is-max-hu-44 {
  height: var(--is-hu-44);
}

.is-max-hu-45 {
  height: var(--is-hu-45);
}

.is-max-hu-46 {
  height: var(--is-hu-46);
}

.is-max-hu-47 {
  height: var(--is-hu-47);
}

.is-max-hu-48 {
  height: var(--is-hu-48);
}

.is-max-hu-49 {
  height: var(--is-hu-49);
}

.is-max-hu-50 {
  height: var(--is-hu-50);
}

.is-max-hu-55 {
  height: var(--is-hu-55);
}

.is-max-hu-60 {
  height: var(--is-hu-60);
}

.is-max-hu-65 {
  height: var(--is-hu-65);
}

.is-max-hu-70 {
  height: var(--is-hu-70);
}

.is-max-hu-75 {
  height: var(--is-hu-75);
}

.is-max-hu-80 {
  height: var(--is-hu-80);
}

.is-max-hu-85 {
  height: var(--is-hu-85);
}

.is-max-hu-90 {
  height: var(--is-hu-90);
}

.is-max-hu-95 {
  height: var(--is-hu-95);
}

.is-max-hu-100 {
  height: var(--is-hu-100);
}

[class*="is-icon-size"] {
  max-width: 100%;
  height: auto;
}

.is-icon-size-1 {
  width: var(--is-icon-size-1);
}

.is-icon-size-2 {
  width: var(--is-icon-size-2);
}

.is-icon-size-3 {
  width: var(--is-icon-size-3);
}

.is-icon-size-4 {
  width: var(--is-icon-size-4);
}

.is-icon-size-5 {
  width: var(--is-icon-size-5);
}

.is-icon-size-6 {
  width: var(--is-icon-size-6);
}

.is-icon-size-7 {
  width: var(--is-icon-size-7);
}

.is-icon-size-8 {
  width: var(--is-icon-size-8);
}

.is-icon-size-9 {
  width: var(--is-icon-size-9);
}

.is-icon-size-10 {
  width: var(--is-icon-size-10);
}

.is-mar-auto {
  margin: auto !important;
}

.is-mar-0 {
  margin: 0 !important;
}

.is-mar-1 {
  margin: var(--is-su) !important;
}

.is-mar-2 {
  margin: var(--is-su-2) !important;
}

.is-mar-3 {
  margin: var(--is-su-3) !important;
}

.is-mar-4 {
  margin: var(--is-su-4) !important;
}

.is-mar-5 {
  margin: var(--is-su-5) !important;
}

.is-mar-6 {
  margin: var(--is-su-6) !important;
}

.is-mar-7 {
  margin: var(--is-su-7) !important;
}

.is-mar-8 {
  margin: var(--is-su-8) !important;
}

.is-mar-9 {
  margin: var(--is-su-9) !important;
}

.is-mar-10 {
  margin: var(--is-su-10) !important;
}

.is-mar-11 {
  margin: var(--is-su-11) !important;
}

.is-mar-12 {
  margin: var(--is-su-12) !important;
}

.is-mar-13 {
  margin: var(--is-su-13) !important;
}

.is-mar-14 {
  margin: var(--is-su-14) !important;
}

.is-mar-15 {
  margin: var(--is-su-15) !important;
}

.is-mar-16 {
  margin: var(--is-su-16) !important;
}

.is-mar-17 {
  margin: var(--is-su-17) !important;
}

.is-mar-18 {
  margin: var(--is-su-18) !important;
}

.is-mar-19 {
  margin: var(--is-su-19) !important;
}

.is-mar-20 {
  margin: var(--is-su-20) !important;
}

.is-mar-x-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.is-mar-x-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.is-mar-x-1 {
  margin-right: var(--is-su) !important;
  margin-left: var(--is-su) !important;
}

.is-mar-x-2 {
  margin-right: var(--is-su-2) !important;
  margin-left: var(--is-su-2) !important;
}

.is-mar-x-3 {
  margin-right: var(--is-su-3) !important;
  margin-left: var(--is-su-3) !important;
}

.is-mar-x-4 {
  margin-right: var(--is-su-4) !important;
  margin-left: var(--is-su-4) !important;
}

.is-mar-x-5 {
  margin-right: var(--is-su-5) !important;
  margin-left: var(--is-su-5) !important;
}

.is-mar-x-6 {
  margin-right: var(--is-su-6) !important;
  margin-left: var(--is-su-6) !important;
}

.is-mar-x-7 {
  margin-right: var(--is-su-7) !important;
  margin-left: var(--is-su-7) !important;
}

.is-mar-x-8 {
  margin-right: var(--is-su-8) !important;
  margin-left: var(--is-su-8) !important;
}

.is-mar-x-9 {
  margin-right: var(--is-su-9) !important;
  margin-left: var(--is-su-9) !important;
}

.is-mar-x-10 {
  margin-right: var(--is-su-10) !important;
  margin-left: var(--is-su-10) !important;
}

.is-mar-x-11 {
  margin-right: var(--is-su-11) !important;
  margin-left: var(--is-su-11) !important;
}

.is-mar-x-12 {
  margin-right: var(--is-su-12) !important;
  margin-left: var(--is-su-12) !important;
}

.is-mar-x-13 {
  margin-right: var(--is-su-13) !important;
  margin-left: var(--is-su-13) !important;
}

.is-mar-x-14 {
  margin-right: var(--is-su-14) !important;
  margin-left: var(--is-su-14) !important;
}

.is-mar-x-15 {
  margin-right: var(--is-su-15) !important;
  margin-left: var(--is-su-15) !important;
}

.is-mar-x-16 {
  margin-right: var(--is-su-16) !important;
  margin-left: var(--is-su-16) !important;
}

.is-mar-x-17 {
  margin-right: var(--is-su-17) !important;
  margin-left: var(--is-su-17) !important;
}

.is-mar-x-18 {
  margin-right: var(--is-su-18) !important;
  margin-left: var(--is-su-18) !important;
}

.is-mar-x-19 {
  margin-right: var(--is-su-19) !important;
  margin-left: var(--is-su-19) !important;
}

.is-mar-x-20 {
  margin-right: var(--is-su-20) !important;
  margin-left: var(--is-su-20) !important;
}

.is-mar-y-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.is-mar-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.is-mar-y-1 {
  margin-top: var(--is-su) !important;
  margin-bottom: var(--is-su) !important;
}

.is-mar-y-2 {
  margin-top: var(--is-su-2) !important;
  margin-bottom: var(--is-su-2) !important;
}

.is-mar-y-3 {
  margin-top: var(--is-su-3) !important;
  margin-bottom: var(--is-su-3) !important;
}

.is-mar-y-4 {
  margin-top: var(--is-su-4) !important;
  margin-bottom: var(--is-su-4) !important;
}

.is-mar-y-5 {
  margin-top: var(--is-su-5) !important;
  margin-bottom: var(--is-su-5) !important;
}

.is-mar-y-6 {
  margin-top: var(--is-su-6) !important;
  margin-bottom: var(--is-su-6) !important;
}

.is-mar-y-7 {
  margin-top: var(--is-su-7) !important;
  margin-bottom: var(--is-su-7) !important;
}

.is-mar-y-8 {
  margin-top: var(--is-su-8) !important;
  margin-bottom: var(--is-su-8) !important;
}

.is-mar-y-9 {
  margin-top: var(--is-su-9) !important;
  margin-bottom: var(--is-su-9) !important;
}

.is-mar-y-10 {
  margin-top: var(--is-su-10) !important;
  margin-bottom: var(--is-su-10) !important;
}

.is-mar-y-11 {
  margin-top: var(--is-su-11) !important;
  margin-bottom: var(--is-su-11) !important;
}

.is-mar-y-12 {
  margin-top: var(--is-su-12) !important;
  margin-bottom: var(--is-su-12) !important;
}

.is-mar-y-13 {
  margin-top: var(--is-su-13) !important;
  margin-bottom: var(--is-su-13) !important;
}

.is-mar-y-14 {
  margin-top: var(--is-su-14) !important;
  margin-bottom: var(--is-su-14) !important;
}

.is-mar-y-15 {
  margin-top: var(--is-su-15) !important;
  margin-bottom: var(--is-su-15) !important;
}

.is-mar-y-16 {
  margin-top: var(--is-su-16) !important;
  margin-bottom: var(--is-su-16) !important;
}

.is-mar-y-17 {
  margin-top: var(--is-su-17) !important;
  margin-bottom: var(--is-su-17) !important;
}

.is-mar-y-18 {
  margin-top: var(--is-su-18) !important;
  margin-bottom: var(--is-su-18) !important;
}

.is-mar-y-19 {
  margin-top: var(--is-su-19) !important;
  margin-bottom: var(--is-su-19) !important;
}

.is-mar-y-20 {
  margin-top: var(--is-su-20) !important;
  margin-bottom: var(--is-su-20) !important;
}

.is-mar-top-auto {
  margin-top: auto !important;
}

.is-mar-top-0 {
  margin-top: 0 !important;
}

.is-mar-top-1 {
  margin-top: var(--is-su) !important;
}

.is-mar-top-2 {
  margin-top: var(--is-su-2) !important;
}

.is-mar-top-3 {
  margin-top: var(--is-su-3) !important;
}

.is-mar-top-4 {
  margin-top: var(--is-su-4) !important;
}

.is-mar-top-5 {
  margin-top: var(--is-su-5) !important;
}

.is-mar-top-6 {
  margin-top: var(--is-su-6) !important;
}

.is-mar-top-7 {
  margin-top: var(--is-su-7) !important;
}

.is-mar-top-8 {
  margin-top: var(--is-su-8) !important;
}

.is-mar-top-9 {
  margin-top: var(--is-su-9) !important;
}

.is-mar-top-10 {
  margin-top: var(--is-su-10) !important;
}

.is-mar-top-11 {
  margin-top: var(--is-su-11) !important;
}

.is-mar-top-12 {
  margin-top: var(--is-su-12) !important;
}

.is-mar-top-13 {
  margin-top: var(--is-su-13) !important;
}

.is-mar-top-14 {
  margin-top: var(--is-su-14) !important;
}

.is-mar-top-15 {
  margin-top: var(--is-su-15) !important;
}

.is-mar-top-16 {
  margin-top: var(--is-su-16) !important;
}

.is-mar-top-17 {
  margin-top: var(--is-su-17) !important;
}

.is-mar-top-18 {
  margin-top: var(--is-su-18) !important;
}

.is-mar-top-19 {
  margin-top: var(--is-su-19) !important;
}

.is-mar-top-20 {
  margin-top: var(--is-su-20) !important;
}

.is-mar-right-auto {
  margin-right: auto !important;
}

.is-mar-right-0 {
  margin-right: 0 !important;
}

.is-mar-right-1 {
  margin-right: var(--is-su) !important;
}

.is-mar-right-2 {
  margin-right: var(--is-su-2) !important;
}

.is-mar-right-3 {
  margin-right: var(--is-su-3) !important;
}

.is-mar-right-4 {
  margin-right: var(--is-su-4) !important;
}

.is-mar-right-5 {
  margin-right: var(--is-su-5) !important;
}

.is-mar-right-6 {
  margin-right: var(--is-su-6) !important;
}

.is-mar-right-7 {
  margin-right: var(--is-su-7) !important;
}

.is-mar-right-8 {
  margin-right: var(--is-su-8) !important;
}

.is-mar-right-9 {
  margin-right: var(--is-su-9) !important;
}

.is-mar-right-10 {
  margin-right: var(--is-su-10) !important;
}

.is-mar-right-11 {
  margin-right: var(--is-su-11) !important;
}

.is-mar-right-12 {
  margin-right: var(--is-su-12) !important;
}

.is-mar-right-13 {
  margin-right: var(--is-su-13) !important;
}

.is-mar-right-14 {
  margin-right: var(--is-su-14) !important;
}

.is-mar-right-15 {
  margin-right: var(--is-su-15) !important;
}

.is-mar-right-16 {
  margin-right: var(--is-su-16) !important;
}

.is-mar-right-17 {
  margin-right: var(--is-su-17) !important;
}

.is-mar-right-18 {
  margin-right: var(--is-su-18) !important;
}

.is-mar-right-19 {
  margin-right: var(--is-su-19) !important;
}

.is-mar-right-20 {
  margin-right: var(--is-su-20) !important;
}

.is-mar-bottom-auto {
  margin-bottom: auto !important;
}

.is-mar-bottom-0 {
  margin-bottom: 0 !important;
}

.is-mar-bottom-1 {
  margin-bottom: var(--is-su) !important;
}

.is-mar-bottom-2 {
  margin-bottom: var(--is-su-2) !important;
}

.is-mar-bottom-3 {
  margin-bottom: var(--is-su-3) !important;
}

.is-mar-bottom-4 {
  margin-bottom: var(--is-su-4) !important;
}

.is-mar-bottom-5 {
  margin-bottom: var(--is-su-5) !important;
}

.is-mar-bottom-6 {
  margin-bottom: var(--is-su-6) !important;
}

.is-mar-bottom-7 {
  margin-bottom: var(--is-su-7) !important;
}

.is-mar-bottom-8 {
  margin-bottom: var(--is-su-8) !important;
}

.is-mar-bottom-9 {
  margin-bottom: var(--is-su-9) !important;
}

.is-mar-bottom-10 {
  margin-bottom: var(--is-su-10) !important;
}

.is-mar-bottom-11 {
  margin-bottom: var(--is-su-11) !important;
}

.is-mar-bottom-12 {
  margin-bottom: var(--is-su-12) !important;
}

.is-mar-bottom-13 {
  margin-bottom: var(--is-su-13) !important;
}

.is-mar-bottom-14 {
  margin-bottom: var(--is-su-14) !important;
}

.is-mar-bottom-15 {
  margin-bottom: var(--is-su-15) !important;
}

.is-mar-bottom-16 {
  margin-bottom: var(--is-su-16) !important;
}

.is-mar-bottom-17 {
  margin-bottom: var(--is-su-17) !important;
}

.is-mar-bottom-18 {
  margin-bottom: var(--is-su-18) !important;
}

.is-mar-bottom-19 {
  margin-bottom: var(--is-su-19) !important;
}

.is-mar-bottom-20 {
  margin-bottom: var(--is-su-20) !important;
}

.is-mar-left-auto {
  margin-left: auto !important;
}

.is-mar-left-0 {
  margin-left: 0 !important;
}

.is-mar-left-1 {
  margin-left: var(--is-su) !important;
}

.is-mar-left-2 {
  margin-left: var(--is-su-2) !important;
}

.is-mar-left-3 {
  margin-left: var(--is-su-3) !important;
}

.is-mar-left-4 {
  margin-left: var(--is-su-4) !important;
}

.is-mar-left-5 {
  margin-left: var(--is-su-5) !important;
}

.is-mar-left-6 {
  margin-left: var(--is-su-6) !important;
}

.is-mar-left-7 {
  margin-left: var(--is-su-7) !important;
}

.is-mar-left-8 {
  margin-left: var(--is-su-8) !important;
}

.is-mar-left-9 {
  margin-left: var(--is-su-9) !important;
}

.is-mar-left-10 {
  margin-left: var(--is-su-10) !important;
}

.is-mar-left-11 {
  margin-left: var(--is-su-11) !important;
}

.is-mar-left-12 {
  margin-left: var(--is-su-12) !important;
}

.is-mar-left-13 {
  margin-left: var(--is-su-13) !important;
}

.is-mar-left-14 {
  margin-left: var(--is-su-14) !important;
}

.is-mar-left-15 {
  margin-left: var(--is-su-15) !important;
}

.is-mar-left-16 {
  margin-left: var(--is-su-16) !important;
}

.is-mar-left-17 {
  margin-left: var(--is-su-17) !important;
}

.is-mar-left-18 {
  margin-left: var(--is-su-18) !important;
}

.is-mar-left-19 {
  margin-left: var(--is-su-19) !important;
}

.is-mar-left-20 {
  margin-left: var(--is-su-20) !important;
}

.is-pad-0 {
  padding: 0 !important;
}

.is-pad-1 {
  padding: var(--is-su) !important;
}

.is-pad-2 {
  padding: var(--is-su-2) !important;
}

.is-pad-3 {
  padding: var(--is-su-3) !important;
}

.is-pad-4 {
  padding: var(--is-su-4) !important;
}

.is-pad-5 {
  padding: var(--is-su-5) !important;
}

.is-pad-6 {
  padding: var(--is-su-6) !important;
}

.is-pad-7 {
  padding: var(--is-su-7) !important;
}

.is-pad-8 {
  padding: var(--is-su-8) !important;
}

.is-pad-9 {
  padding: var(--is-su-9) !important;
}

.is-pad-10 {
  padding: var(--is-su-10) !important;
}

.is-pad-11 {
  padding: var(--is-su-11) !important;
}

.is-pad-12 {
  padding: var(--is-su-12) !important;
}

.is-pad-13 {
  padding: var(--is-su-13) !important;
}

.is-pad-14 {
  padding: var(--is-su-14) !important;
}

.is-pad-15 {
  padding: var(--is-su-15) !important;
}

.is-pad-16 {
  padding: var(--is-su-16) !important;
}

.is-pad-17 {
  padding: var(--is-su-17) !important;
}

.is-pad-18 {
  padding: var(--is-su-18) !important;
}

.is-pad-19 {
  padding: var(--is-su-19) !important;
}

.is-pad-20 {
  padding: var(--is-su-20) !important;
}

.is-pad-x-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.is-pad-x-1 {
  padding-right: var(--is-su) !important;
  padding-left: var(--is-su) !important;
}

.is-pad-x-2 {
  padding-right: var(--is-su-2) !important;
  padding-left: var(--is-su-2) !important;
}

.is-pad-x-3 {
  padding-right: var(--is-su-3) !important;
  padding-left: var(--is-su-3) !important;
}

.is-pad-x-4 {
  padding-right: var(--is-su-4) !important;
  padding-left: var(--is-su-4) !important;
}

.is-pad-x-5 {
  padding-right: var(--is-su-5) !important;
  padding-left: var(--is-su-5) !important;
}

.is-pad-x-6 {
  padding-right: var(--is-su-6) !important;
  padding-left: var(--is-su-6) !important;
}

.is-pad-x-7 {
  padding-right: var(--is-su-7) !important;
  padding-left: var(--is-su-7) !important;
}

.is-pad-x-8 {
  padding-right: var(--is-su-8) !important;
  padding-left: var(--is-su-8) !important;
}

.is-pad-x-9 {
  padding-right: var(--is-su-9) !important;
  padding-left: var(--is-su-9) !important;
}

.is-pad-x-10 {
  padding-right: var(--is-su-10) !important;
  padding-left: var(--is-su-10) !important;
}

.is-pad-x-11 {
  padding-right: var(--is-su-11) !important;
  padding-left: var(--is-su-11) !important;
}

.is-pad-x-12 {
  padding-right: var(--is-su-12) !important;
  padding-left: var(--is-su-12) !important;
}

.is-pad-x-13 {
  padding-right: var(--is-su-13) !important;
  padding-left: var(--is-su-13) !important;
}

.is-pad-x-14 {
  padding-right: var(--is-su-14) !important;
  padding-left: var(--is-su-14) !important;
}

.is-pad-x-15 {
  padding-right: var(--is-su-15) !important;
  padding-left: var(--is-su-15) !important;
}

.is-pad-x-16 {
  padding-right: var(--is-su-16) !important;
  padding-left: var(--is-su-16) !important;
}

.is-pad-x-17 {
  padding-right: var(--is-su-17) !important;
  padding-left: var(--is-su-17) !important;
}

.is-pad-x-18 {
  padding-right: var(--is-su-18) !important;
  padding-left: var(--is-su-18) !important;
}

.is-pad-x-19 {
  padding-right: var(--is-su-19) !important;
  padding-left: var(--is-su-19) !important;
}

.is-pad-x-20 {
  padding-right: var(--is-su-20) !important;
  padding-left: var(--is-su-20) !important;
}

.is-pad-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.is-pad-y-1 {
  padding-top: var(--is-su) !important;
  padding-bottom: var(--is-su) !important;
}

.is-pad-y-2 {
  padding-top: var(--is-su-2) !important;
  padding-bottom: var(--is-su-2) !important;
}

.is-pad-y-3 {
  padding-top: var(--is-su-3) !important;
  padding-bottom: var(--is-su-3) !important;
}

.is-pad-y-4 {
  padding-top: var(--is-su-4) !important;
  padding-bottom: var(--is-su-4) !important;
}

.is-pad-y-5 {
  padding-top: var(--is-su-5) !important;
  padding-bottom: var(--is-su-5) !important;
}

.is-pad-y-6 {
  padding-top: var(--is-su-6) !important;
  padding-bottom: var(--is-su-6) !important;
}

.is-pad-y-7 {
  padding-top: var(--is-su-7) !important;
  padding-bottom: var(--is-su-7) !important;
}

.is-pad-y-8 {
  padding-top: var(--is-su-8) !important;
  padding-bottom: var(--is-su-8) !important;
}

.is-pad-y-9 {
  padding-top: var(--is-su-9) !important;
  padding-bottom: var(--is-su-9) !important;
}

.is-pad-y-10 {
  padding-top: var(--is-su-10) !important;
  padding-bottom: var(--is-su-10) !important;
}

.is-pad-y-11 {
  padding-top: var(--is-su-11) !important;
  padding-bottom: var(--is-su-11) !important;
}

.is-pad-y-12 {
  padding-top: var(--is-su-12) !important;
  padding-bottom: var(--is-su-12) !important;
}

.is-pad-y-13 {
  padding-top: var(--is-su-13) !important;
  padding-bottom: var(--is-su-13) !important;
}

.is-pad-y-14 {
  padding-top: var(--is-su-14) !important;
  padding-bottom: var(--is-su-14) !important;
}

.is-pad-y-15 {
  padding-top: var(--is-su-15) !important;
  padding-bottom: var(--is-su-15) !important;
}

.is-pad-y-16 {
  padding-top: var(--is-su-16) !important;
  padding-bottom: var(--is-su-16) !important;
}

.is-pad-y-17 {
  padding-top: var(--is-su-17) !important;
  padding-bottom: var(--is-su1-7) !important;
}

.is-pad-y-18 {
  padding-top: var(--is-su-18) !important;
  padding-bottom: var(--is-su-18) !important;
}

.is-pad-y-19 {
  padding-top: var(--is-su-19) !important;
  padding-bottom: var(--is-su-19) !important;
}

.is-pad-y-20 {
  padding-top: var(--is-su-20) !important;
  padding-bottom: var(--is-su-20) !important;
}

.is-pad-top-0 {
  padding-top: 0 !important;
}

.is-pad-top-1 {
  padding-top: var(--is-su) !important;
}

.is-pad-top-2 {
  padding-top: var(--is-su-2) !important;
}

.is-pad-top-3 {
  padding-top: var(--is-su-3) !important;
}

.is-pad-top-4 {
  padding-top: var(--is-su-4) !important;
}

.is-pad-top-5 {
  padding-top: var(--is-su-5) !important;
}

.is-pad-top-6 {
  padding-top: var(--is-su-6) !important;
}

.is-pad-top-7 {
  padding-top: var(--is-su-7) !important;
}

.is-pad-top-8 {
  padding-top: var(--is-su-8) !important;
}

.is-pad-top-9 {
  padding-top: var(--is-su-9) !important;
}

.is-pad-top-10 {
  padding-top: var(--is-su-10) !important;
}

.is-pad-top-11 {
  padding-top: var(--is-su-11) !important;
}

.is-pad-top-12 {
  padding-top: var(--is-su-12) !important;
}

.is-pad-top-13 {
  padding-top: var(--is-su-13) !important;
}

.is-pad-top-14 {
  padding-top: var(--is-su-14) !important;
}

.is-pad-top-15 {
  padding-top: var(--is-su-15) !important;
}

.is-pad-top-16 {
  padding-top: var(--is-su-16) !important;
}

.is-pad-top-17 {
  padding-top: var(--is-su-17) !important;
}

.is-pad-top-18 {
  padding-top: var(--is-su-18) !important;
}

.is-pad-top-19 {
  padding-top: var(--is-su-19) !important;
}

.is-pad-top-20 {
  padding-top: var(--is-su-20) !important;
}

.is-pad-right-0 {
  padding-right: 0 !important;
}

.is-pad-right-1 {
  padding-right: var(--is-su) !important;
}

.is-pad-right-2 {
  padding-right: var(--is-su-2) !important;
}

.is-pad-right-3 {
  padding-right: var(--is-su-3) !important;
}

.is-pad-right-4 {
  padding-right: var(--is-su-4) !important;
}

.is-pad-right-5 {
  padding-right: var(--is-su-5) !important;
}

.is-pad-right-6 {
  padding-right: var(--is-su-6) !important;
}

.is-pad-right-7 {
  padding-right: var(--is-su-7) !important;
}

.is-pad-right-8 {
  padding-right: var(--is-su-8) !important;
}

.is-pad-right-9 {
  padding-right: var(--is-su-9) !important;
}

.is-pad-right-10 {
  padding-right: var(--is-su-10) !important;
}

.is-pad-right-11 {
  padding-right: var(--is-su-11) !important;
}

.is-pad-right-12 {
  padding-right: var(--is-su-12) !important;
}

.is-pad-right-13 {
  padding-right: var(--is-su-13) !important;
}

.is-pad-right-14 {
  padding-right: var(--is-su-14) !important;
}

.is-pad-right-15 {
  padding-right: var(--is-su-15) !important;
}

.is-pad-right-16 {
  padding-right: var(--is-su-16) !important;
}

.is-pad-right-17 {
  padding-right: var(--is-su-17) !important;
}

.is-pad-right-18 {
  padding-right: var(--is-su-18) !important;
}

.is-pad-right-19 {
  padding-right: var(--is-su-19) !important;
}

.is-pad-right-20 {
  padding-right: var(--is-su-20) !important;
}

.is-pad-bottom-0 {
  padding-bottom: 0 !important;
}

.is-pad-bottom-1 {
  padding-bottom: var(--is-su) !important;
}

.is-pad-bottom-2 {
  padding-bottom: var(--is-su-2) !important;
}

.is-pad-bottom-3 {
  padding-bottom: var(--is-su-3) !important;
}

.is-pad-bottom-4 {
  padding-bottom: var(--is-su-4) !important;
}

.is-pad-bottom-5 {
  padding-bottom: var(--is-su-5) !important;
}

.is-pad-bottom-6 {
  padding-bottom: var(--is-su-6) !important;
}

.is-pad-bottom-7 {
  padding-bottom: var(--is-su-7) !important;
}

.is-pad-bottom-8 {
  padding-bottom: var(--is-su-8) !important;
}

.is-pad-bottom-9 {
  padding-bottom: var(--is-su-9) !important;
}

.is-pad-bottom-10 {
  padding-bottom: var(--is-su-10) !important;
}

.is-pad-bottom-11 {
  padding-bottom: var(--is-su-11) !important;
}

.is-pad-bottom-12 {
  padding-bottom: var(--is-su-12) !important;
}

.is-pad-bottom-13 {
  padding-bottom: var(--is-su-13) !important;
}

.is-pad-bottom-14 {
  padding-bottom: var(--is-su-14) !important;
}

.is-pad-bottom-15 {
  padding-bottom: var(--is-su-15) !important;
}

.is-pad-bottom-16 {
  padding-bottom: var(--is-su-16) !important;
}

.is-pad-bottom-17 {
  padding-bottom: var(--is-su-17) !important;
}

.is-pad-bottom-18 {
  padding-bottom: var(--is-su-18) !important;
}

.is-pad-bottom-19 {
  padding-bottom: var(--is-su-19) !important;
}

.is-pad-bottom-20 {
  padding-bottom: var(--is-su-20) !important;
}

.is-pad-left-0 {
  padding-left: 0 !important;
}

.is-pad-left-1 {
  padding-left: var(--is-su) !important;
}

.is-pad-left-2 {
  padding-left: var(--is-su-2) !important;
}

.is-pad-left-3 {
  padding-left: var(--is-su-3) !important;
}

.is-pad-left-4 {
  padding-left: var(--is-su-4) !important;
}

.is-pad-left-5 {
  padding-left: var(--is-su-5) !important;
}

.is-pad-left-6 {
  padding-left: var(--is-su-6) !important;
}

.is-pad-left-7 {
  padding-left: var(--is-su-7) !important;
}

.is-pad-left-8 {
  padding-left: var(--is-su-8) !important;
}

.is-pad-left-9 {
  padding-left: var(--is-su-9) !important;
}

.is-pad-left-10 {
  padding-left: var(--is-su-10) !important;
}

.is-pad-left-11 {
  padding-left: var(--is-su-11) !important;
}

.is-pad-left-12 {
  padding-left: var(--is-su-12) !important;
}

.is-pad-left-13 {
  padding-left: var(--is-su-13) !important;
}

.is-pad-left-14 {
  padding-left: var(--is-su-14) !important;
}

.is-pad-left-15 {
  padding-left: var(--is-su-15) !important;
}

.is-pad-left-16 {
  padding-left: var(--is-su-16) !important;
}

.is-pad-left-17 {
  padding-left: var(--is-su-17) !important;
}

.is-pad-left-18 {
  padding-left: var(--is-su-18) !important;
}

.is-pad-left-19 {
  padding-left: var(--is-su-19) !important;
}

.is-pad-left-20 {
  padding-left: var(--is-su-20) !important;
}

.is-text {
  color: var(--is-text) !important;
}

.is-text-muted {
  color: var(--is-text-muted) !important;
}

.is-text-component {
  color: var(--is-text-component) !important;
}

.is-text-component-disabled {
  color: var(--is-text-component-disabled) !important;
}

.is-text-inverse {
  color: var(--is-text-inverse) !important;
}

.is-text-inverse-muted {
  color: var(--is-text-inverse-muted) !important;
}

.is-text-inverse {
  color: var(--is-text-inverse) !important;
}

.is-text-muted-inverse {
  color: var(--is-text-muted-inverse) !important;
}

.is-text-white {
  color: var(--is-color-white) !important;
}

.is-text-black {
  color: var(--is-color-black) !important;
}

.is-text-blue {
  color: var(--is-color-blue) !important;
}

.is-text-geekblue {
  color: var(--is-color-geekblue) !important;
}

.is-text-teal {
  color: var(--is-color-teal) !important;
}

.is-text-cyan {
  color: var(--is-color-cyan) !important;
}

.is-text-green {
  color: var(--is-color-green) !important;
}

.is-text-lime {
  color: var(--is-color-lime) !important;
}

.is-text-yellow {
  color: var(--is-color-yellow) !important;
}

.is-text-gold {
  color: var(--is-color-gold) !important;
}

.is-text-orange {
  color: var(--is-color-orange) !important;
}

.is-text-volcano {
  color: var(--is-color-volcano) !important;
}

.is-text-red {
  color: var(--is-color-red) !important;
}

.is-text-pink {
  color: var(--is-color-pink) !important;
}

.is-text-magento {
  color: var(--is-color-magento) !important;
}

.is-text-purple {
  color: var(--is-color-purple) !important;
}

.is-text-inherit {
  color: inherit !important;
}

.is-text-primary {
  color: var(--is-primary) !important;
}

.is-text-secondary {
  color: var(--is-secondary) !important;
}

.is-text-accent {
  color: var(--is-accent) !important;
}

.is-text-success {
  color: var(--is-success) !important;
}

.is-text-warning {
  color: var(--is-warning) !important;
}

.is-text-danger {
  color: var(--is-danger) !important;
}

.is-text-primary-inverse {
  color: var(--is-primary-inverse) !important;
}

.is-text-secondary-inverse {
  color: var(--is-secondary-inverse) !important;
}

.is-text-accent-inverse {
  color: var(--is-accent-inverse) !important;
}

.is-text-success-inverse {
  color: var(--is-success-inverse) !important;
}

.is-text-warning-inverse {
  color: var(--is-warning-inverse) !important;
}

.is-text-danger-inverse {
  color: var(--is-danger-inverse) !important;
}

.is-link-color {
  color: var(--is-link-color) !important;
}
.is-link-color:hover,
.is-link-color:focus,
.is-link-color:active,
.is-link-color.active {
  color: var(--is-link-color-hover) !important;
}

a.is-link-color-inherit {
  color: inherit !important;
}
a.is-link-color-inherit:hover,
a.is-link-color-inherit:focus,
a.is-link-color-inherit:active,
a.is-link-color-inherit.active {
  color: inherit !important;
  text-decoration: underline;
}

.is-font-size-xxs {
  font-size: var(--is-font-size-xxs) !important;
}

.is-font-size-xs {
  font-size: var(--is-font-size-xs) !important;
}

.is-font-size-sm {
  font-size: var(--is-font-size-sm) !important;
}

.is-font-size-md {
  font-size: var(--is-font-size-md) !important;
}

.is-font-size-lg {
  font-size: var(--is-font-size-lg) !important;
}

.is-font-size-xl {
  font-size: var(--is-font-size-xl) !important;
  /* padding: var(--is-su) !important; */
}

.is-font-size-xxl {
  font-size: var(--is-font-size-xxl) !important;
}

.is-big-font-size-1 {
  font-size: var(--is-big-font-size-1) !important;
}

.is-big-font-size-2 {
  font-size: var(--is-big-font-size-2) !important;
}

.is-big-font-size-3 {
  font-size: var(--is-big-font-size-3) !important;
}

.is-big-font-size-4 {
  font-size: var(--is-big-font-size-4) !important;
}

.is-big-font-size-5 {
  font-size: var(--is-big-font-size-5) !important;
}

.is-big-font-size-6 {
  font-size: var(--is-big-font-size-6) !important;
}

.is-big-font-size-7 {
  font-size: var(--is-big-font-size-7) !important;
}

.is-big-font-size-8 {
  font-size: var(--is-big-font-size-8) !important;
}

.is-big-font-size-9 {
  font-size: var(--is-big-font-size-9) !important;
}

.is-big-font-size-10 {
  font-size: var(--is-big-font-size-10) !important;
}

.is-font-weight-bold {
  font-weight: var(--is-font-weight-bold) !important;
}

.is-font-weight-light {
  font-weight: 300 !important;
}

.is-font-weight-lighter {
  font-weight: lighter !important;
}

.is-font-weight-normal {
  font-weight: 400 !important;
}

.is-font-weight-bolder {
  font-weight: bolder !important;
}

.is-font-italic {
  font-style: italic !important;
}

.is-text-left {
  text-align: left !important;
}

.is-text-right {
  text-align: right !important;
}

.is-text-center {
  text-align: center !important;
}

.is-text-justify {
  text-align: justify !important;
}

.is-text-lower {
  text-transform: lowercase !important;
}

.is-text-upper {
  text-transform: uppercase !important;
}

.is-text-capitalize {
  text-transform: capitalize !important;
}

.is-text-nowrap {
  white-space: nowrap !important;
}

.is-text-break {
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
}

.is-text-ellipsis {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  line-break: anywhere;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.is-font-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !important;
}

.is-lh {
  line-height: var(--is-line-height) !important;
}

.is-lh-1 {
  line-height: var(--is-line-height-1) !important;
}

.is-lh-inherit {
  line-height: inherit !important;
}

.is-lh-xs {
  line-height: var(--is-line-height-xs) !important;
}

.is-lh-sm {
  line-height: var(--is-line-height-sm) !important;
}

.is-lh-lg {
  line-height: var(--is-line-height-lg) !important;
}

.is-lh-xl {
  line-height: var(--is-line-height-xl) !important;
}

.is-valign-baseline {
  vertical-align: baseline !important;
}

.is-valign-top {
  vertical-align: top !important;
}

.is-valign-middle {
  vertical-align: middle !important;
}

.is-valign-bottom {
  vertical-align: bottom !important;
}

.is-valign-text-bottom {
  vertical-align: text-bottom !important;
}

.is-valign-text-top {
  vertical-align: text-top !important;
}

.is-scale-1 {
  transform: scale(--is-scale-1);
}

.is-scale-2 {
  transform: scale(--is-scale-2);
}

.is-scale-3 {
  transform: scale(--is-scale-3);
}

.is-scale-4 {
  transform: scale(--is-scale-4);
}

.is-scale-5 {
  transform: scale(--is-scale-5);
}

.is-scale-hover-1 {
  transition: transform 0.3s;
}
.is-scale-hover-1:hover {
  transform: scale(--is-scale-hover-1);
}

.is-scale-hover-2 {
  transition: transform 0.3s;
}
.is-scale-hover-2:hover {
  transform: scale(--is-scale-hover-2);
}

.is-scale-hover-3 {
  transition: transform 0.3s;
}
.is-scale-hover-3:hover {
  transform: scale(--is-scale-hover-3);
}

.is-scale-hover-4 {
  transition: transform 0.3s;
}
.is-scale-hover-4:hover {
  transform: scale(--is-scale-hover-4);
}

.is-scale-hover-5 {
  transition: transform 0.3s;
}
.is-scale-hover-5:hover {
  transform: scale(--is-scale-hover-5);
}

.is-zindex-0 {
  z-index: 0 !important;
}

.is-zindex-1 {
  z-index: 1 !important;
}

.is-zindex-2 {
  z-index: 2 !important;
}

.is-zindex-3 {
  z-index: 3 !important;
}

.is-zindex-4 {
  z-index: 4 !important;
}

.is-zindex-5 {
  z-index: 5 !important;
}

.is-zindex-n1 {
  z-index: -1 !important;
}

@media print {
  @page {
    /* size: A4; DIN A4 standard, Europe */
    margin: 5mm;
  }
  html,
  body {
    background: #fff !important;
    overflow: visible;
  }
  .is-bg-print {
    background: #fff !important;
  }
  .is-hidden-print {
    display: none !important;
  }
}

.is-page-break-area > .is-row {
  display: block !important;
}
.is-page-break-area > .is-row::before,
.is-page-break-area > .is-row::after {
  content: " ";
  display: table;
  clear: both;
}
.is-page-break-area > .is-row > [class*="is-col"] {
  flex: none;
  page-break-inside: avoid !important;
  float: left;
}
