.webix_view {
  font-size: var(--is-font-size);
  color: var(--is-text);
  border: 0px solid var(--is-border);
  background-color: var(--is-component-bg);
}
.webix_view.webix_form {
  background-color: var(--is-component-bg);
}
.webix_layout_space {
  background-color: var(--is-theme-bg);
}
.webix_layout_wide {
  background-color: var(--is-theme-bg);
}
.webix_layout_line,
.webix_multiview,
.webix_layout_clean,
.webix_layout_accordion {
  background: transparent;
}
.webix_view > .webix_disabled {
  background-color: var(--is-theme-bg-muted-strong);
}
.webix_clean {
  background: transparent;
}
.webix_section {
  color: var(--is-text);
}
.webix_section:before {
  background-color: var(--is-border);
}
.webix_section > .webix_template {
  background-color: var(--is-component-bg);
}
.webix_layout_space > .webix_section > .webix_template {
  background-color: var(--is-theme-bg);
}
.webix_layout_wide > .webix_section > .webix_template {
  background-color: var(--is-theme-bg);
}
.webix_layout_line > .webix_section > .webix_template,
.webix_layout_clean > .webix_section > .webix_template {
  background-color: var(--is-component-bg);
}
.webix_header {
  font-size: var(--is-font-size-lg);
  color: var(--is-text);
}
.webix_header .webix_el_label .webix_el_box {
  font-size: var(--is-font-size-lg);
  color: var(--is-text);
}
.webix_spacer {
  background: transparent;
}
.webix_view_align {
  background: transparent;
}
.webix_resize_handle_x .webix_handle_content,
.webix_resize_handle_y .webix_handle_content {
  background: rgba(100, 100, 100, 0.1);
  border: 1px dotted var(--is-border);
}
.webix_resizer_y {
  background-color: var(--is-component-bg);
}
.webix_resizer_x {
  background-color: var(--is-component-bg);
}
.webix_accordionitem_header {
  border-bottom: 1px solid var(--is-border);
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-lg);
  color: var(--is-text);
}
.webix_accordionitem_header .webix_el_label .webix_el_box {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-lg);
  color: var(--is-text);
}
.webix_nav_inactive {
  border: 2px solid rgba(0, 0, 0, 0.15);
  background: var(--is-component-bg);
}
.webix_nav_active {
  border: 2px solid rgba(0, 0, 0, 0.1);
  background: var(--is-component-bg);
}
.webix_nav_active:hover,
.webix_nav_active:focus {
  box-shadow: 0 0 2px var(--is-component-bg);
}
.webix_list_item {
  border-bottom: 1px solid var(--is-border);
  text-align: left;
  border-color: var(--is-border);
}
.webix_list_item.webix_invalid {
  background-color: var(--is-theme-bg-muted);
}
.webix_list_item.webix_selected {
  color: var(--is-text);
  background: var(--is-theme-bg-muted);
}
.webix_list_item.webix_selected:focus {
  background: var(--is-theme-bg-muted-strong);
}
.webix_list .webix_list_item.webix_selected {
  border-bottom: 1px solid var(--is-border);
}
.webix_group_back,
.webix_group_back.webix_selected {
  background: var(--is-theme-bg-muted);
  font-family: var(--is-font-family);
  font-size: var(--is-font-size);
  color: var(--is-default-dark);
}
.webix_arrow_icon {
  color: var(--is-text);
}
.webix_list_count {
  font-size: var(--is-font-size);
  box-shadow: 0 1px 0 var(--is-theme-bg-inverse);
}
.webix_unit_header {
  background-color: var(--is-theme-bg-muted);
}
.webix_list-x .webix_list_item {
  border-right: 1px solid var(--is-default-inverse);
  border-color: var(--is-default-inverse);
}
.webix_list-x .webix_list_item.webix_selected {
  border-left: 1px solid var(--is-default-inverse);
  border-right: 1px solid var(--is-default-inverse);
}
/* Style for disabled items */
.webix_list_item.webix_disabled {
  color: var(--is-text-muted);
  background-color: var(--is-component-bg);
}
.webix_list_item.webix_disabled:focus {
  background-color: var(--is-component-bg);
}
.webix_list_item.webix_disabled .webix_badge {
  background-color: var(--is-warning-inverse-darker);
}
.webix_pager_item .webix_icon,
.webix_pager_item_selected .webix_icon {
  font-size: var(--is-font-size);
}
.webix_pager_item {
  background: var(--is-theme-bg-muted);
  color: var(--is-primary);
}
.webix_pager_item:hover {
  background-color: var(--is-default-inverse);
}
.webix_pager_item:focus {
  background-color: var(--is-default-inverse);
}
.webix_pager_item:active {
  background-color: var(--is-default-inverse-dark);
}
.webix_pager_item:hover {
  background-color: var(--is-default-inverse);
}
.webix_pager_item:focus {
  background-color: var(--is-default-inverse);
}
.webix_pager_item:active {
  background-color: var(--is-default-inverse-dark);
}
.webix_pager_item_selected:hover {
  background-color: var(--is-primary-dark);
}
.webix_pager_item_selected:focus {
  background-color: var(--is-primary-dark);
}
.webix_pager_item_selected:active {
  background-color: var(--is-primary-darker);
}
/*default item in dataview*/
.webix_dataview_item {
  border-bottom: 1px solid var(--is-default-inverse);
  border-color: var(--is-default-inverse);
  border-right: 1px solid var(--is-default-inverse);
}
.webix_dataview_item.webix_selected {
  box-shadow: inset 2px 0 var(--is-primary);
}
.webix_dataview_item.webix_disabled {
  color: var(--is-default-inverse-darker);
}
.webix_dataview_item.webix_selected.webix_disabled {
  box-shadow: inset 2px 0 var(--is-default-inverse-darker);
}
/*default item in selected state*/
.webix_dataview_item.webix_selected {
  color: var(--is-text);
  background: var(--is-theme-bg-muted);
  border-bottom: 1px solid var(--is-default-inverse);
  border-right: 1px solid var(--is-default-inverse);
}
.webix_dataview_item.webix_selected:focus {
  background: var(--is-default-inverse);
}
.webix_dataview_item.webix_invalid {
  background-color: var(--is-theme-bg-muted);
}
.webix_dataview_item.tiles .webix_dataview_inner_item {
  border: 1px solid var(--is-default-inverse);
}
.webix_dataview_item.tiles.webix_selected,
.webix_dataview_item.tiles.webix_drag_over {
  background: transparent;
}
.webix_dataview_item.tiles.webix_selected .webix_dataview_inner_item {
  background-color: var(--is-theme-bg-muted);
}
.webix_dataview_item.tiles.webix_selected:focus .webix_dataview_inner_item {
  background-color: var(--is-default-inverse);
}
.webix_dataview_item.tiles.webix_drag_over .webix_dataview_inner_item {
  background: transparent;
}
/*chart container*/
.webix_chart {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-sm);
  color: var(--is-text);
}
/*labels*/
.webix_canvas_text {
  font-size: var(--is-font-size-xs);
}
.webix_axis_title_x {
  font-size: var(--is-font-size-sm);
}
.webix_axis_title_y {
  font-size: var(--is-font-size-sm);
}
.webix_chart_legend {
  display: none;
}
.webix_chart_legend_item.hidden {
  color: var(--is-default-inverse-dark);
}
.webix_axis_item_y.webix_radar {
  color: var(--is-text);
}
.webix_canvas_text.webix_axis_radar_title {
  color: var(--is-default-light);
  font-size: var(--is-font-size-xs);
}
.webix_axis_item_y,
.webix_axis_item_x {
  color: var(--is-default-light);
}
/*data frame*/
.webix_chart_frame {
  background-color: var(--is-primary-inverse-dark);
  border-left: 1px solid var(--is-primary-light);
  border-right: 1px solid var(--is-primary-light);
}
.webix_chart_resizer {
  background-color: var(--is-component-bg);
  border: 1px solid var(--is-border);
}
.webix_chart_resizer:focus {
  border-color: var(--is-primary);
}
.webix_dt_editor {
  background: var(--is-component-bg);
}
.webix_dt_editor input,
.webix_dt_editor select {
  background: var(--is-component-bg);
  border: 1px solid var(--is-warning);
  font-family: var(--is-font-family);
  font-size: var(--is-font-size);
  color: var(--is-text);
}
.webix_ss_vscroll_footer {
  border-left: 1px solid var(--is-default-inverse);
  border-top: 1px solid var(--is-default-inverse);
}
.webix_ss_vscroll_header {
  border-left: 1px solid var(--is-border);
  border-bottom: 1px solid var(--is-border);
}
.webix_table_cell {
  font-size: var(--is-font-size);
}
.webix_ss_filter select,
.webix_ss_filter input {
  color: var(--is-text) !important;
  border: 1px solid var(--is-border);
  font-family: var(--is-font-family);
}
/*headers and footers*/
.webix_ss_header,
.webix_ss_header TD,
.webix_ss_vscroll_header,
.webix_dd_drag_column {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size);
  color: var(--is-default-dark);
  background: var(--is-theme-bg-muted);
}
.webix_ss_footer TD,
.webix_ss_vscroll_footer {
  background: var(--is-default-inverse-light);
}
/*borders in header and footer*/
.webix_ss_header td {
  border-right: 1px solid var(--is-border);
  border-bottom: 1px solid var(--is-border);
}
.webix_ss_footer td {
  border-right: 1px solid var(--is-default-inverse);
  border-top: 1px solid var(--is-default-inverse);
}
/*borders between split areas*/
.webix_ss_right .webix_column.webix_first > div,
.webix_hs_right td.webix_first {
  border-left: 1px solid var(--is-border);
}
.webix_ss_left .webix_column.webix_last > div {
  border-right: 1px solid var(--is-border);
}
.webix_last_topcell.webix_cell {
  border-bottom: 1px solid var(--is-border);
}
/*normal data cell of the table*/
.webix_column > div,
.webix_table_cell {
  border-right: 1px solid var(--is-default-inverse);
  border-bottom: 1px solid var(--is-default-inverse);
}
/*header selection in column mode*/
.webix_sel_hcell,
.webix_dd_over_column {
  background-color: var(--is-primary-inverse-light);
}
.webix_block_selection {
  background: rgba(0, 0, 0, 0.1);
  border: 2px solid var(--is-default-inverse);
}
.webix_dd_drag {
  border-left: 1px solid var(--is-default-inverse);
}
.webix_dd_drag > div {
  background: var(--is-component-bg);
  border-top: 1px solid var(--is-default-inverse);
  border-right: 1px solid var(--is-default-inverse);
  border-bottom: 1px solid var(--is-default-inverse);
}
.webix_column > .webix_drag_over.webix_cell {
  background-color: var(--is-theme-bg-muted-strong);
  color: var(--is-text);
}
.webix_cell.webix_invalid {
  background-color: var(--is-theme-bg-muted);
}
.webix_invalid_cell:after {
  border-top: 8px solid var(--is-danger);
}
/*selection*/
.webix_column > div.webix_cell_select,
.webix_column > div.webix_column_select,
.webix_column > div.webix_row_select {
  color: var(--is-text);
  background: var(--is-theme-bg-muted);
}
.webix_column > div.webix_cell_select:focus,
.webix_column > div.webix_column_select:focus,
.webix_column > div.webix_row_select:focus {
  background: var(--is-default-inverse);
}
/*extra borders, for selection in cell mode*/
.webix_column > div.webix_cell_select {
  border-bottom: 1px solid var(--is-default-inverse);
  border-left: 1px solid var(--is-default-inverse);
  border-right: 1px solid var(--is-default-inverse);
}
/*extra borders, for selection in row mode*/
.webix_column > div.webix_row_select {
  border-bottom: 1px solid var(--is-default-inverse);
}
/*extra borders for selection in column mode*/
.webix_column > div.webix_column_select {
  border-left: 1px solid var(--is-default-inverse);
  border-right: 1px solid var(--is-default-inverse);
}
.webix_cell.webix_dtable_colrow,
.webix_cell.webix_dtable_subrow,
.webix_dtable_subview {
  background-color: var(--is-component-bg);
  border-bottom: 1px solid var(--is-default-inverse);
}
.webix_span_layer,
.webix_span_layer_top {
  background: transparent;
}
.webix_cell.webix_dtable_colrow.webix_selected {
  color: var(--is-text);
  background: var(--is-theme-bg-muted);
}
.webix_cell.webix_dtable_colrow.webix_selected:focus {
  background: var(--is-default-inverse);
}
.webix_dtable_subrow {
  border-top: 1px solid var(--is-default-inverse);
}
.webix_dtable_subview {
  background: var(--is-default-inverse);
  border-top: 1px solid var(--is-default-inverse);
}
.webix_ss_right .webix_span_layer,
.webix_ss_right .webix_span_layer_top {
  border-left: 1px solid var(--is-border);
}
.webix_dtable_span {
  background: var(--is-component-bg);
}
.webix_dtable_span.webix_selected {
  color: var(--is-text);
  background: var(--is-theme-bg-muted);
}
.webix_dtable_span.webix_selected:focus {
  background: var(--is-default-inverse);
}
.webix_hcell span.webix_input_icon {
  background-color: var(--is-border);
}
.webix_area_selection {
  background: var(--is-default);
}
.webix_dtable_focused .webix_area_selection {
  background-color: var(--is-primary-dark);
}
.webix_area_selection_handle {
  border: 1px solid var(--is-default-inverse-light);
  background: var(--is-default);
}
.webix_topcell {
  background: var(--is-component-bg);
}
.webix_dtable .webix_resize_handle_x .webix_handle_content,
.webix_dtable .webix_resize_handle_y .webix_handle_content {
  border: none;
  background-color: var(--is-primary);
}
.webix_dtable_focused .webix_column > div.webix_row_select,
.webix_dtable_focused .webix_column > div.webix_column_select {
  background: var(--is-default-inverse);
}
.webix_win_head {
  border-bottom: 1px solid var(--is-border);
}
.webix_modal {
  background-color: var(--is-theme-bg-inverse);
}

.webix_resize_handle {
  bottom: -5px;
  right: -4px;
  width: 16px;
  border-top: 1px solid var(--is-text-muted);
  background: none;
  transform: rotate(-36deg);
  overflow: hidden;
}
  /*Customization to change item color*/
  .webix_resize_handle::before {
    content: '';
    display: block;
    width: 10px;
    height: 1px;
    position: relative;
    top: 2px;
    right: -3px;
    background-color: var(--is-text-muted);
  }
  .webix_resize_handle::after {
    content: '';
    display: block;
    top: 4px;
    right: -6px;
    height: 1px;
    width: 5px;
    background-color: var(--is-text-muted);
    position: relative;
  }
.webix_resize_frame {
  filter:alpha(opacity=25)!important;
  opacity: .25!important;
  background: var(--is-primary);
  border: 1px solid var(--is-primary-dark);
  box-shadow: 1px 3px 6px var(--is-primary-darkest);
}
.webix_tree_item.webix_selected {
  background: transparent;
}
.webix_tree_item.webix_selected span {
  color: var(--is-text);
  background: var(--is-theme-bg-muted);
}
.webix_tree_item.webix_selected span:focus {
  background: var(--is-default-inverse);
}
.webix_tree_item.webix_invalid {
  background-color: var(--is-theme-bg-muted);
}
.webix_measure_size {
  font-family: var(--is-font-family);
}
.webix_tree_img {
  background: transparent;
}
.webix_tree_img {
  background-color: var(--is-component-bg);
}
.webix_cal_month {
  font-size: var(--is-font-size);
}
.webix_view > .webix_cal_header div {
  color: var(--is-text);
  border-bottom: var(--is-border) 1px solid;
}
.webix_cal_body .webix_selected,
.webix_cal_body .webix_cal_select,
.webix_cal_body .webix_cal_select.webix_cal_today {
  color: var(--is-text);
  background: var(--is-theme-bg-muted);
}
.webix_cal_day,
.webix_cal_week_num {
  font-size: var(--is-font-size-xs);
  color: var(--is-text);
}
.webix_cal_week_num {
  color: #a2adbe;
  border-right: 1px solid var(--is-border);
}
.webix_cal_event {
  color: var(--is-accent);
}
.webix_cal_outside {
  color: var(--is-primary-inverse-dark);
}
.webix_cal_inactive {
  background: var(--is-default-inverse-darkest);
}
.webix_cal_inactive {
  background: var(--is-default-inverse-darkest);
}
.webix_cal_day_disabled,
.webix_cal_day_disabled.webix_cal_event {
  color: var(--is-primary-inverse-dark);
  background: var(--is-default-inverse-light);
}
.webix_hours .webix_cal_day_disabled,
.webix_minutes .webix_cal_day_disabled,
.webix_hours .webix_cal_day_disabled.webix_cal_event,
.webix_minutes .webix_cal_day_disabled.webix_cal_event {
  background: var(--is-component-bg);
}
.webix_selected {
  background: var(--is-theme-bg-muted);
  color: var(--is-text);
}
.webix_cal_footer {
  font-size: var(--is-font-size-xs);
  color: var(--is-text);
}
.webix_cal_icon:hover,
.webix_cal_time:hover,
.webix_cal_month_name:hover {
  color: var(--is-primary);
}
.webix_cal_icon:focus,
.webix_cal_time:focus,
.webix_cal_month_name:focus {
  color: var(--is-primary);
}
.webix_cal_month_name.webix_readonly {
  cursor: default;
}
.webix_time_header .webix_cal_hours,
.webix_time_header .webix_cal_minutes {
  font-size: var(--is-font-size);
  border-bottom: 1px solid var(--is-border);
}
.webix_cal_body .webix_hours {
  border-right: 1px solid var(--is-border);
}
.webix_time_footer {
  font-size: var(--is-font-size-xs);
}
.webix_cal_done {
  font-size: var(--is-font-size-xs);
  border: 1px solid var(--is-border);
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-lg);
  background: var(--is-theme-bg-muted);
  color: var(--is-primary);
}
.webix_cal_done:hover {
  background-color: var(--is-default-inverse);
}
.webix_cal_done:focus {
  background-color: var(--is-default-inverse);
}
.webix_cal_done:active {
  background-color: var(--is-default-inverse-dark);
}
.webix_cal_blurred_hours {
  background-color: var(--is-default-inverse-light);
}
.webix_property_line.webix_focused > div,
.webix_property_line:focus > div {
  background-color: var(--is-default-inverse-light);
}
.webix_property_label,
.webix_property_value {
  border-bottom: 1px solid var(--is-default-inverse);
}
.webix_property_label {
  border-right: 1px solid var(--is-default-inverse);
}
.webix_property_label_line {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-lg);
  color: var(--is-text);
  border-bottom: 1px solid var(--is-default-inverse);
}
.webix_property_label_line .webix_el_label .webix_el_box {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-lg);
  color: var(--is-text);
}
.webix_property_col_ind {
  border: 1px solid var(--is-border);
  background: var(--is-component-bg);
}
/*style used by tooltip's container*/
.webix_tooltip {
  background-color: var(--is-component-bg);
  border: 1px solid var(--is-border);
  font-family: var(--is-font-family);
  font-size: var(--is-font-size);
  color: var(--is-text);
}
/* comments list templates */
.webix_comments_name {
  color: var(--is-text-muted);
}
.webix_comments_menu.webix_icon {
  color: var(--is-default-inverse-darker);
}
.webix_comments_message a {
  color: var(--is-primary);
}
.webix_comments_message a:visited {
  color: #7e57c2;
}
.webix_comments_mention {
  color: var(--is-primary);
}
.webix_comments_status {
  border: 2px solid var(--is-primary-text);
}
.webix_comments_status.active {
  background-color: var(--is-success-light);
}
.webix_comments_status.busy {
  background-color: var(--is-danger);
}
.webix_comments_status.away {
  background-color: var(--is-warning);
}
.webix_comments_status.none {
  background-color: var(--is-theme-bg-muted-strong);
}
.webix_comments_avatar_text {
  font-size: var(--is-font-size-lg);
  color: var(--is-primary);
  background-color: var(--is-theme-bg-muted-strong);
}
.webix_comments_date {
  color: var(--is-default-inverse-darker);
  font-size: var(--is-font-size-xs);
}
/* buttons*/
.webix_comments_more {
  background: var(--is-theme-bg-muted);
  color: var(--is-primary);
}
.webix_comments_more:hover {
  background-color: var(--is-default-inverse);
}
.webix_message {
  font-family: var(--is-font-family);
}
.webix_modal_box {
  background-color: var(--is-component-bg);
  font-family: var(--is-font-family);
  border: 1px solid var(--is-border);
}
/*Skin section*/
.webix_popup_button {
  font-size: var(--is-font-size);
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-lg);
  background: var(--is-theme-bg-muted);
  color: var(--is-primary);
}
.webix_popup_button:hover {
  background-color: var(--is-default-inverse);
}
.webix_popup_button:focus {
  background-color: var(--is-default-inverse);
}
.webix_popup_button:active {
  background-color: var(--is-default-inverse-dark);
}
.webix_popup_button.confirm {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-lg);
  background: var(--is-primary);
  color: var(--is-primary-text);
}
.webix_popup_button.confirm:hover {
  background-color: var(--is-primary-dark);
}
.webix_popup_button.confirm:focus {
  background-color: var(--is-primary-dark);
}
.webix_popup_button.confirm:active {
  background-color: var(--is-primary-darker);
}
.webix_popup_title {
  color: var(--is-text);
  font-size: var(--is-font-size-lg);
}
.webix_popup_text {
  font-size: var(--is-font-size);
  color: var(--is-text);
}
.webix_alert .webix_popup_title,
.webix_confirm .webix_popup_title,
.webix_alert .webix_popup_text,
.webix_confirm .webix_popup_text {
  box-shadow: inset 0 4px var(--is-primary);
}
.webix_alert-warning .webix_popup_title,
.webix_confirm-warning .webix_popup_title,
.webix_alert-warning .webix_popup_text,
.webix_confirm-warning .webix_popup_text {
  box-shadow: inset 0 4px var(--is-warning);
}
.webix_alert-error .webix_popup_title,
.webix_confirm-error .webix_popup_title,
.webix_alert-error .webix_popup_text,
.webix_confirm-error .webix_popup_text {
  box-shadow: inset 0 4px var(--is-danger);
}
.webix_message {
  font-size: var(--is-font-size);
  color: var(--is-text);
  box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.12), 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid var(--is-border);
  background-color: var(--is-component-bg);
}
.webix_message.webix_debug {
  color: var(--is-default-dark);
  border-color: var(--is-warning-inverse-dark);
  background-color: var(--is-warning-inverse);
}
.webix_message.webix_error {
  color: var(--is-default-dark);
  border-color: var(--is-danger-inverse-dark);
  background-color: var(--is-danger-inverse);
}
.webix_message.webix_success {
  color: var(--is-default-dark);
  border-color: var(--is-success-inverse-dark);
  background-color: var(--is-success-inverse);
}
.webix_color_row div[aria-selected="true"]:focus div {
  box-shadow: inset 0 0 15px #fff;
}
.webix_color_selector {
  border: 2px solid var(--is-default-inverse-light);
}
.webix_daterange .webix_view {
  background-color: var(--is-component-bg);
}
.webix_cal_range {
  background-color: var(--is-component-bg);
}
.webix_cal_range_start,
.webix_cal_range_end,
.webix_cal_event.webix_cal_range_start,
.webix_cal_event.webix_cal_range_end,
.webix_cal_body .webix_cal_today.webix_cal_range_start,
.webix_cal_body .webix_cal_today.webix_cal_range_end {
  background-color: var(--is-theme-bg-muted);
  color: var(--is-text);
}
.webix_cal_outside.webix_cal_range,
.webix_cal_body .webix_cal_outside.webix_cal_range_start,
.webix_cal_body .webix_cal_outside.webix_cal_range_end,
.webix_cal_body .webix_cal_outside.webix_cal_today.webix_cal_range_start,
.webix_cal_body .webix_cal_outside.webix_cal_today.webix_cal_range_end {
  color: var(--is-primary-inverse-dark);
}
.webix_daterange .webix_cal_icons .webix_cal_icon {
  border-top: 1px solid var(--is-border);
}
.webix_drag_zone {
  background-color: var(--is-component-bg);
  font-family: var(--is-font-family);
  font-size: var(--is-font-size);
  color: var(--is-text);
}
.webix_drag_over,
.webix_drop_zone {
  background-color: var(--is-theme-bg-muted-strong);
}
.webix_drag_main {
  background-color: var(--is-component-bg);
}
.webix_drag_main .webix_tree_item,
.webix_drag_main .webix_list_item,
.webix_drag_main .webix_dataview_item {
  border: 1px solid var(--is-default-inverse);
}
.webix_drag_multiple,
.webix_drag_multiple_last {
  border: 1px solid var(--is-default-inverse);
  background-color: var(--is-component-bg);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}
.webix_drop_area {
  background-color: var(--is-theme-bg-muted);
}
.webix_list-x .webix_drop_area {
  border-right: 1px solid var(--is-default-inverse);
}
.webix_list .webix_drop_area {
  border-bottom: 1px solid var(--is-default-inverse);
}
.webix_dataview .webix_drop_area {
  border-right: 1px solid var(--is-default-inverse);
  border-bottom: 1px solid var(--is-default-inverse);
}
.webix_scroll_x {
  background-color: var(--is-default-inverse-dark);
  background: rgba(0, 0, 0, 0.4);
}
.webix_scroll_y {
  background-color: var(--is-default-inverse-dark);
  background: rgba(0, 0, 0, 0.4);
}
.webix_c_scroll_y > div {
  background-color: var(--is-default-inverse-dark);
  background: rgba(0, 0, 0, 0.4);
}
.webix_c_scroll_x > div {
  background-color: var(--is-default-inverse-dark);
}
.webix_secondary .webix_button {
  background: var(--is-secondary);
  color: var(--is-secondary-text);
}
.webix_secondary .webix_button:hover {
  background-color: var(--is-secondary-dark);
}
.webix_secondary .webix_button:focus {
  background-color: var(--is-secondary-dark);
}
.webix_secondary .webix_button:active {
  background-color: var(--is-secondary-darker);
}
.webix_secondary .webix_button .webix_icon,
.webix_secondary .webix_button .webix_img_btn_text,
.webix_secondary .webix_button .webix_icon_btn {
  color: var(--is-secondary-text);
}
.webix_secondary .webix_pressed .webix_button {
  background-color: var(--is-secondary-dark);
}
.webix_primary .webix_button {
  background: var(--is-primary);
  color: var(--is-primary-text);
}
.webix_primary .webix_button:hover {
  background-color: var(--is-primary-dark);
}
.webix_primary .webix_button:focus {
  background-color: var(--is-primary-dark);
}
.webix_primary .webix_button:active {
  background-color: var(--is-primary-darker);
}
.webix_primary .webix_button .webix_icon,
.webix_primary .webix_button .webix_img_btn_text,
.webix_primary .webix_button .webix_icon_btn {
  color: var(--is-primary-text);
}
.webix_primary .webix_pressed .webix_button {
  background-color: var(--is-primary-darker);
}
.webix_danger .webix_button {
  background: var(--is-danger);
  color: var(--is-danger-text);
}
.webix_danger .webix_button:hover {
  background-color: var(--is-danger-dark);
}
.webix_danger .webix_button:focus {
  background-color: var(--is-danger-dark);
}
.webix_danger .webix_button:active {
  background-color: var(--is-danger-darker);
}
.webix_danger .webix_button .webix_icon,
.webix_danger .webix_button .webix_img_btn_text,
.webix_danger .webix_button .webix_icon_btn {
  color: var(--is-danger-text);
}
.webix_danger .webix_pressed .webix_button {
  background-color: var(--is-danger-darker);
}
.webix_transparent .webix_button {
  color: var(--is-text);
  background: transparent;
}
.webix_transparent .webix_button .webix_img_btn_text {
  color: var(--is-text);
}
.webix_transparent .webix_button .webix_icon,
.webix_transparent .webix_button .webix_icon_btn {
  color: var(--is-default-inverse-darker);
}
.webix_button,
.webixbutton {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-lg);
  color: var(--is-primary);
}
.webix_img_btn .webix_icon {
  color: var(--is-primary);
}
.webix_img_btn_text {
  color: var(--is-primary);
}
.webix_view.webix_control .webix_disabled_box .webix_button {
  color: var(--is-default-inverse-darker);
  background: var(--is-theme-bg-muted);
  border-color: var(--is-theme-bg-muted);
}
.webix_view.webix_control .webix_disabled_box .webix_img_btn_text {
  color: var(--is-default-inverse-darker);
}
.webix_disabled_view.webix_control .webix_icon_btn,
.webix_disabled_view.webix_control .webix_icon {
  color: var(--is-default-inverse-darker);
}
.webix_toolbar .webix_disabled_view.webix_control .webix_icon,
.webix_toolbar .webix_disabled_view.webix_control .webix_disabled_box .webix_img_btn_text,
.webix_toolbar .webix_disabled_view.webix_control .webix_icon_btn {
  color: var(--is-default-inverse-darker);
}
.webix_richtext_container .webix_template {
  border: solid var(--is-border);
}
.webix_richtext_inp_label .webix_template {
  color: var(--is-text);
  font-family: var(--is-font-family);
  font-size: var(--is-font-size);
  color: var(--is-default-dark);
}
.webix_control {
  background: transparent;
}
.webix_control input,
.webix_control button,
.webix_control textarea,
.webix_el_label,
.webix_inp_label,
.webix_inp_top_label,
.webix_inp_bottom_label {
  font-family: var(--is-font-family);
}
.webix_el_box {
  font-size: var(--is-font-size);
}
.webixtoolbarlabel {
  font-size: var(--is-font-size-lg);
}
.webix_el_text input,
.webix_el_search input,
.webix_el_combo input,
.webix_el_datepicker input,
.webix_el_colorpicker input {
  font-size: var(--is-font-size);
  border: 1px solid var(--is-border);
  color: var(--is-text);
  background: var(--is-component-bg);
  font-family: var(--is-font-family);
  border-color: var(--is-border);
}
.webix_inp_label,
.webix_inp_top_label,
.webix_label_right {
  color: var(--is-text);
  font-size: var(--is-font-size);
}
.webix_inp_bottom_label {
  font-size: var(--is-font-size-xs);
  color: var(--is-secondary-dark);
}
.webix_el_label {
  color: var(--is-text);
}
.webix_el_select select {
  font-size: var(--is-font-size);
  font-family: var(--is-font-family);
  border: 1px solid var(--is-border);
}
.webix_inp_counter_next,
.webix_inp_counter_prev,
.webix_inp_counter_value {
  border: 1px solid var(--is-border);
}
.webix_inp_counter_value {
  font-size: var(--is-font-size);
  color: var(--is-text);
}
.webix_inp_counter_prev {
  background: var(--is-theme-bg-muted);
  color: var(--is-primary);
}
.webix_inp_counter_prev:hover {
  background-color: var(--is-default-inverse);
}
.webix_inp_counter_prev:focus {
  background-color: var(--is-default-inverse);
}
.webix_inp_counter_prev:active {
  background-color: var(--is-default-inverse-dark);
}
.webix_inp_counter_next {
  background: var(--is-theme-bg-muted);
  color: var(--is-primary);
}
.webix_inp_counter_next:hover {
  background-color: var(--is-default-inverse);
}
.webix_inp_counter_next:focus {
  background-color: var(--is-default-inverse);
}
.webix_inp_counter_next:active {
  background-color: var(--is-default-inverse-dark);
}
.webix_el_textarea textarea {
  border: 1px solid var(--is-border);
  color: var(--is-text);
  font-size: var(--is-font-size);
  font-family: var(--is-font-family);
}
.webix_segment_0,
.webix_segment_1 {
  border: 1px solid var(--is-border);
}
.webix_segment_N {
  border: 1px solid var(--is-border);
}
.webix_segment_0,
.webix_segment_1,
.webix_segment_N {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-lg);
  background: var(--is-theme-bg-muted);
  color: var(--is-primary);
}
.webix_segment_0:hover,
.webix_segment_1:hover,
.webix_segment_N:hover {
  background-color: var(--is-default-inverse);
}
.webix_segment_0:focus,
.webix_segment_1:focus,
.webix_segment_N:focus {
  background-color: var(--is-default-inverse);
}
.webix_segment_0:active,
.webix_segment_1:active,
.webix_segment_N:active {
  background-color: var(--is-default-inverse-dark);
}
.webix_segment_0.webix_selected,
.webix_segment_1.webix_selected,
.webix_segment_N.webix_selected {
  color: var(--is-text);
  background: var(--is-theme-bg-muted);
  border-color: var(--is-primary);
  background-color: var(--is-primary);
  color: var(--is-primary-text);
}
.webix_segment_0.webix_selected:focus,
.webix_segment_1.webix_selected:focus,
.webix_segment_N.webix_selected:focus {
  background: var(--is-default-inverse);
}
.webix_segment_0.webix_disabled,
.webix_segment_1.webix_disabled,
.webix_segment_N.webix_disabled {
  color: var(--is-default-inverse-darker);
  background-color: var(--is-default-inverse-light);
}
.webix_segment_0.webix_disabled:hover,
.webix_segment_1.webix_disabled:hover,
.webix_segment_N.webix_disabled:hover,
.webix_segment_0.webix_disabled:focus,
.webix_segment_1.webix_disabled:focus,
.webix_segment_N.webix_disabled:focus,
.webix_segment_0.webix_disabled:active,
.webix_segment_1.webix_disabled:active,
.webix_segment_N.webix_disabled:active,
.webix_segment_0.webix_disabled.webix_selected,
.webix_segment_1.webix_disabled.webix_selected,
.webix_segment_N.webix_disabled.webix_selected,
.webix_segment_0.webix_disabled.webix_selected:hover,
.webix_segment_1.webix_disabled.webix_selected:hover,
.webix_segment_N.webix_disabled.webix_selected:hover {
  background-color: var(--is-default-inverse-light);
}
.webix_inp_static {
  border: 1px solid var(--is-border);
  background: var(--is-component-bg);
}
.webix_placeholder {
  color: var(--is-default-inverse-darker);
}
::-webkit-input-placeholder {
  color: var(--is-default-inverse-darker);
}
::-moz-placeholder {
  color: var(--is-default-inverse-darker);
}
/* Firefox 19+ */
:-moz-placeholder {
  color: var(--is-default-inverse-darker);
}
/* Firefox 18- */
:-ms-input-placeholder {
  color: var(--is-default-inverse-darker);
}
.webix_el_combo,
.webix_el_richselect,
.webix_el_datepicker,
.webix_el_colorpicker,
.webix_el_search {
  font-size: var(--is-font-size);
}
.webix_el_combo .webix_el_box,
.webix_el_richselect .webix_el_box,
.webix_el_datepicker .webix_el_box,
.webix_el_colorpicker .webix_el_box,
.webix_el_search .webix_el_box {
  color: var(--is-text);
}
.webix_el_combo input,
.webix_el_richselect input,
.webix_el_datepicker input,
.webix_el_colorpicker input,
.webix_el_search input,
.webix_el_combo .webix_inp_static,
.webix_el_richselect .webix_inp_static,
.webix_el_datepicker .webix_inp_static,
.webix_el_colorpicker .webix_inp_static,
.webix_el_search .webix_inp_static {
  color: var(--is-text);
  background: var(--is-component-bg);
  font-family: var(--is-font-family);
  border-color: var(--is-border);
}
.webix_el_combo .webix_input_icon,
.webix_el_richselect .webix_input_icon,
.webix_el_datepicker .webix_input_icon,
.webix_el_colorpicker .webix_input_icon,
.webix_el_search .webix_input_icon {
  background: transparent;
}
.webix_radio_option.webix_disabled .webix_label_right,
.webix_radio_option.webix_disabled .webix_inp_radio_border {
  color: var(--is-default-inverse-darker);
}
.webix_el_radio .webix_radio_option.webix_disabled .webix_custom_radio {
  color: var(--is-default-inverse-darker);
}
.webix_invalid .webix_el_box input,
.webix_invalid .webix_custom_radio,
.webix_invalid .webix_el_box .webix_inp_static,
.webix_invalid .webix_el_box select,
.webix_invalid .webix_el_box textarea,
input.webix_invalid,
select.webix_invalid,
textarea.webix_invalid {
  background-color: var(--is-component-bg-muted);
  border-color: var(--is-danger-inverse);
}
.webix_invalid .webix_inp_bottom_label {
  font-size: var(--is-font-size-xs);
  color: var(--is-danger);
}
.webix_el_label.webix_disabled_view {
  color: var(--is-default-inverse-darker);
}
.webix_view.webix_control .webix_disabled_box label,
.webix_view.webix_control .webix_disabled_box .webix_input_icon,
.webix_view.webix_control .webix_disabled_box button {
  color: var(--is-default-inverse-darker);
}
.webix_view.webix_control .webix_disabled_box input,
.webix_view.webix_control .webix_disabled_box select,
.webix_view.webix_control .webix_disabled_box textarea,
.webix_view.webix_control .webix_disabled_box .webix_inp_static {
  color: var(--is-default-inverse-darker);
  background: var(--is-default-inverse-light);
  border-color: var(--is-default-inverse);
}
.webix_view.webix_control .webix_disabled_box .webix_inp_counter_prev,
.webix_view.webix_control .webix_disabled_box .webix_inp_counter_next,
.webix_view.webix_control .webix_disabled_box .webix_segment_0,
.webix_view.webix_control .webix_disabled_box .webix_segment_1,
.webix_view.webix_control .webix_disabled_box .webix_segment_N {
  color: var(--is-default-inverse-darker);
  background: var(--is-theme-bg-muted);
  border-color: var(--is-theme-bg-muted);
}
.webix_disabled_top_label {
  color: var(--is-default-inverse-darker);
}
label.webix_required:after,
legend.webix_required:after,
.webix_richtext_inp_label.webix_required .webix_template:after {
  color: var(--is-danger);
}
.webix_el_text .webix_multicombo_input {
  font-size: var(--is-font-size);
  color: var(--is-text);
  font-family: var(--is-font-family);
}
.webix_multicombo_value,
.webix_multicombo_tag {
  background-color: var(--is-theme-bg-muted);
}
.webix_multicombo_delete {
  color: var(--is-default-dark);
}
.webix_multilist .webix_selected {
  background: var(--is-theme-bg-muted);
  color: var(--is-text);
}
.webix_checksuggest_select_all {
  border-bottom: 1px solid var(--is-border);
  border-color: var(--is-border);
}
.webix_checksuggest_select_all:hover .webix_label_right {
  color: var(--is-theme-bg-muted);
}
.webix_el_texthighlight .webix_text_highlight {
  background-color: var(--is-component-bg);
}
.webix_el_texthighlight.webix_control .webix_el_box input,
.webix_el_texthighlight.webix_control .webix_el_box textarea {
  caret-color: var(--is-text);
  background: transparent;
}
.webix_el_tabbar {
  background: transparent;
}
.webix_tab_filler {
  border-bottom: 1px solid var(--is-border);
  background: transparent;
}
.webix_tab_filler:first-child {
  border-right: 1px solid var(--is-border);
}
.webix_item_tab:first-child {
  border-left: 1px solid var(--is-border);
}
.webix_item_tab {
  border: 1px solid var(--is-border);
  background: var(--is-component-bg);
  color: var(--is-primary);
}
.webix_item_tab.webix_selected {
  background: var(--is-component-bg);
  color: var(--is-primary);
}
.webix_after_all_tabs {
  border-left: 1px solid var(--is-border);
  border-right: 1px solid var(--is-border);
}
.webix_view.webix_control .webix_disabled_box .webix_item_tab {
  color: var(--is-default-inverse-darker);
  background: var(--is-theme-bg-muted);
  border-color: var(--is-theme-bg-muted);
}
.webix_icon.webix_tab_close {
  font-size: var(--is-font-size-xs);
}
.webix_icon.webix_tab_close:focus {
  color: var(--is-danger);
}
.webix_all_tabs .webix_item_tab.webix_disabled {
  color: var(--is-default-inverse-darker);
  background-color: var(--is-default-inverse-light);
}
.webix_all_tabs .webix_item_tab.webix_disabled:hover,
.webix_all_tabs .webix_item_tab.webix_disabled.webix_selected,
.webix_all_tabs .webix_item_tab.webix_disabled.webix_selected:hover {
  color: var(--is-default-inverse-darker);
  background-color: var(--is-default-inverse-light);
}
.webix_menu .webix_list_item:hover,
.webix_menu-x .webix_list_item:hover,
.webix_menu .webix_list_item:active,
.webix_menu-x .webix_list_item:active,
.webix_menu .webix_list_item:focus,
.webix_menu-x .webix_list_item:focus {
  background: rgba(100, 100, 100, 0.1);
}
.webix_menu .webix_list_item,
.webix_menu-x .webix_list_item {
  background: var(--is-component-bg);
}
.webix_menu-x {
  color: var(--is-primary-text);
  background: transparent;
}
.webix_menu-x .webix_list_item {
  border-right: 1px solid var(--is-primary-inverse-light);
  background: var(--is-primary);
  color: var(--is-primary-text);
  font-size: var(--is-font-size);
}
.webix_menu-x .webix_list_item .webix_icon_btn {
  color: var(--is-primary-text);
}
.webix_menu-x .webix_list_item:hover,
.webix_menu-x .webix_list_item:active,
.webix_menu-x .webix_list_item:focus {
  background-color: var(--is-primary-dark);
}
.webix_menu-x .webix_list_item.webix_disabled {
  color: var(--is-default-inverse-darker);
  background-color: var(--is-default-inverse-light);
}
.webix_menu-x.webix_disabled_view .webix_list_item.menu {
  color: var(--is-default-inverse-darker);
  background: var(--is-theme-bg-muted);
}
/* Submenu */
.webix_menu {
  background: var(--is-component-bg);
  border: 1px solid var(--is-border);
}
.webix_menu .webix_context_separator .sep_line {
  border-top: 1px solid var(--is-border);
}
/* Toolbar */
.webix_toolbar.webix_layout_subbar .webix_el_label .webix_el_box {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-lg);
  color: var(--is-text);
}
.webix_toolbar .webix_layout_line,
.webix_toolbar .webix_layout_clean {
  background: transparent;
}
.webix_upload_server {
  color: var(--is-success);
}
.webix_upload_flash {
  background: var(--is-component-bg);
}
.webix_drop_file {
  background: var(--is-theme-bg-muted);
}
.webix_fieldset > fieldset {
  border: 1px solid var(--is-border);
}
.webix_fieldset_label {
  color: var(--is-text);
  font-size: var(--is-font-size-xs);
}
.webix_forminput_label {
  color: var(--is-text);
  font-family: var(--is-font-family);
}
.webix_slider_box .webix_slider_left {
  background: var(--is-primary-inverse-light);
  border: 1px solid var(--is-border);
}
.webix_slider_box .webix_slider_right {
  background: var(--is-component-bg);
  border: 1px solid var(--is-border);
}
.webix_slider_box .webix_slider_handle:focus {
  border-color: var(--is-primary);
}
.webix_slider_box .webix_slider_left {
  border: 1px solid var(--is-border);
  background: var(--is-primary);
}
.webix_slider_box .webix_slider_right {
  border-color: var(--is-border);
}
.webix_slider_box .webix_slider_handle {
  border: 1px solid var(--is-border);
  background-color: var(--is-component-bg);
}
.webix_slider_alt .webix_slider_box .webix_slider_left {
  border: 1px solid var(--is-border);
}
.webix_slider_alt .webix_slider_box .webix_slider_right {
  border: 1px solid var(--is-border);
}
.webix_slider_alt .webix_slider_box .webix_slider_handle:focus {
  border-color: var(--is-primary);
}
.webix_slider_alt .webix_slider_box .webix_slider_left {
  border: 1px solid var(--is-border);
  background: var(--is-primary);
}
.webix_slider_alt .webix_slider_box .webix_slider_right {
  border-color: var(--is-border);
}
.webix_slider_alt .webix_slider_box .webix_slider_handle {
  background-color: var(--is-component-bg);
  border: 1px solid var(--is-border);
}
.webix_switch_box {
  background-color: var(--is-theme-bg-muted);
  border: 1px solid var(--is-border);
  color: var(--is-text);
}
.webix_switch_handle {
  border: 1px solid var(--is-component-border-color);
  background-color: var(--is-component-bg);
}
.webix_switch_box.webix_switch_on {
  background-color: var(--is-theme-bg-muted);
  color: var(--is-component-bg);
}
.webix_progress_top,
.webix_progress_bottom {
  background: var(--is-theme-bg-muted);
}
.webix_progress_top .webix_progress_state,
.webix_progress_bottom .webix_progress_state {
  background: var(--is-theme-bg-muted);
}
.webix_progress_icon .webix_progress_state {
  color: var(--is-text-muted);
}
.webix_gage_value {
  color: var(--is-text);
}
.webix_bullet_scale {
  font-size: var(--is-font-size-xs);
}
.webix_organogram_item {
  background-color: var(--is-primary-inverse-light);
  border: 1px solid var(--is-primary-inverse-dark);
}
.webix_organogram_item.webix_selected {
  background-color: var(--is-theme-bg-muted);
  border-color: var(--is-theme-bg-muted);
}
.webix_organogram_list {
  border: 1px solid var(--is-border);
}
.webix_timeline_details {
  color: var(--is-default-inverse-darker);
}
.webix_treemap {
  background-color: var(--is-theme-bg-muted);
}
.webix_treemap_item {
  color: var(--is-text);
  background: transparent;
}
.webix_treemap_item:hover {
  box-shadow: inset 0 0 5px #666;
}
.webix_treemap_item.webix_selected {
  border-color: var(--is-theme-bg-muted);
  box-shadow: inset 0 0 1px 2px var(--is-theme-bg-muted);
}
.webix_treemap_header {
  border-bottom: 1px solid var(--is-border);
}
.webix_treemap_reset:before {
  color: var(--is-text);
  font-family: "Webix Material Icons";
  font-size: var(--is-font-size-lg);
}
.webix_barcode .webix_canvas_text {
  font-size: var(--is-font-size);
}
.portlet_marker,
.portlet_markertop,
.portlet_markerbottom,
.portlet_markerleft,
.portlet_markerright {
  background: var(--is-primary-inverse-dark);
}
.panel_icon {
  font-size: var(--is-font-size-sm);
}
.panel_target {
  border: 1px solid var(--is-border);
  background: var(--is-theme-bg-muted);
}
/* SideBar*/
.webix_sidebar {
  background: var(--is-primary-inverse-light);
}
.webix_sidebar .webix_tree_item.webix_selected:focus span {
  background: transparent;
}
.webix_sidebar .webix_tree_item {
  color: var(--is-text);
}
.webix_sidebar .webix_tree_item.webix_selected,
.webix_sidebar .webix_tree_item.webix_selected span {
  background-color: var(--is-theme-bg-muted);
}
.webix_sidebar .webix_tree_branch_1 {
  border-bottom: 1px solid var(--is-border);
}
/*SubMenu (Popup) */
.webix_sidebar_popup {
  box-shadow: 2px 3px 3px var(--is-border);
}
.webix_sidebar_popup_right {
  box-shadow: -3px 3px 3px var(--is-border);
}
/*SubMenu: title*/
.webix_sidebar_popup_title {
  background: var(--is-primary-inverse-light);
}
.webix_sidebar_popup_title.webix_selected {
  border-left-color: var(--is-theme-bg-muted);
  background: var(--is-theme-bg-muted);
}
.webix_sidebar_popup_title .webix_template {
  border: 1px solid var(--is-border);
}
.webix_sidebar_popup_right .webix_sidebar_popup_title .webix_template {
  border: 1px solid var(--is-border);
}
.webix_sidebar_popup_list .webix_list_item {
  border-left: 1px solid var(--is-border);
  border-right: 1px solid var(--is-border);
}
/*SubMenu: list*/
.webix_sidebar_popup_list .webix_list_item:first-child {
  border-top: 1px solid var(--is-border);
}
.webix_sidebar_popup_list .webix_list_item:hover {
  background: var(--is-primary-inverse-light);
}
.webix_sidebar_popup_list .webix_list_item.webix_selected:hover {
  background: var(--is-theme-bg-muted);
}
.webix_view.webix_pdf {
  background-color: var(--is-default);
}
.webix_pdf .canvas_wrapper {
  box-shadow: 5px 5px 15px var(--is-theme-bg-inverse);
}
.webix_toolbar.pdf_bar .webix_template {
  color: var(--is-default-text);
}
.webix_toolbar.pdf_bar .webix_el_box input,
.webix_toolbar.pdf_bar .webix_inp_static {
  background-color: var(--is-default-light);
  color: var(--is-default-light-text);
  border-color: var(--is-default);
}
.webix_toolbar.pdf_bar .webix_el_box input:focus,
.webix_toolbar.pdf_bar .webix_inp_static:focus {
  border-color: var(--is-default-dark);
}
.webix_toolbar.pdf_bar .webix_el_box .webix_input_icon {
  color: var(--is-default-text);
}
.pdf_opt_list .webix_list {
  background-color: var(--is-default);
  color: var(--is-default-text);
}
.pdf_opt_list .webix_list .webix_list_item {
  border-color: var(--is-default);
}
.pdf_opt_list .webix_list .webix_list_item.webix_selected,
.pdf_opt_list .webix_list .webix_list_item:hover {
  color: var(--is-text);
  background-color: var(--is-component-bg);
}
.webix_dbllist .webix_list,
.webix_dbllist .bottom_label {
  background: var(--is-theme-bg-muted);
}
.webix_dbllist .webix_list_item {
  background: var(--is-component-bg);
  border: 1px solid var(--is-border);
}
.webix_dbllist .webix_list_item.webix_selected {
  border-bottom-color: var(--is-border);
  background-color: var(--is-theme-bg-muted);
  color: var(--is-text);
}
.webix_dbllist .dbllist_button {
  border: 1px solid var(--is-border);
  font-size: var(--is-font-size);
  font-family: var(--is-font-family);
  background-color: var(--is-theme-bg-muted);
}
.webix_dbllist .dbllist_button .webix_icon {
  color: var(--is-primary);
}
.webix_dbllist .dbllist_button:hover,
.webix_dbllist .dbllist_button:focus,
.webix_dbllist .dbllist_button:active {
  background-color: var(--is-default-inverse);
}
.webix_invalid .webix_list {
  background: var(--is-danger-inverse-light);
}
.webix_sparklines_line {
  stroke: var(--is-primary-light);
}
.webix_sparklines_item {
  fill: var(--is-primary-light);
}
.webix_sparklines_bar {
  fill: var(--is-primary-inverse-darkest);
}
.webix_sparklines_bar_negative {
  fill: var(--is-danger-inverse-darkest);
}
.webix_sparklines_area {
  fill: var(--is-primary-inverse-light);
}
.webix_sparklines_origin {
  stroke: var(--is-default-inverse-darkest);
}
  /*datatable specific*/
  .webix_table_print {
    border: 1px solid var(--is-border);
  }
  .webix_table_print .webix_cell {
    border-right: 1px solid var(--is-border);
    border-bottom: 1px solid var(--is-border);
  }
  .webix_table_print .webix_header_cell {
    font-family: var(--is-font-family);
    font-size: var(--is-font-size);
    color: var(--is-default-dark);
    background: var(--is-theme-bg-muted);
    border-right: 1px solid var(--is-border);
    border-bottom: 1px solid var(--is-border);
  }
  .webix_table_print .webix_footer_cell {
    display: table-cell;
    background: var(--is-default-inverse-light);
    border-right: 1px solid var(--is-default-inverse);
    border-top: 1px solid var(--is-default-inverse);
    border-bottom: 1px solid var(--is-default-inverse);
  }
.webix_input_icon {
  color: var(--is-default-inverse-darkest);
}
.webix_icon_button .webix_icon {
  color: var(--is-default-inverse-darker);
}
.webix_icon_btn {
  color: var(--is-default-inverse-darker);
}
.webix_badge {
  background-color: var(--is-accent);
  color: var(--is-accent-text);
  font-size: var(--is-font-size-xs);
}
.mainFont {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size);
  color: var(--is-text);
}
.buttonFont {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-lg);
  color: var(--is-primary);
}
.barFont {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-lg);
  color: var(--is-text);
}
.selectFocusRule {
  background: var(--is-default-inverse);
}
/*[L]*/
/*[L]*/
.layoutBarBG .webix_el_label .webix_el_box {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-lg);
  color: var(--is-text);
}




.webix_view.webix_layout_accordion {
  background-color: var(--is-theme-bg);
}
.webix_accordionitem .webix_accordionitem_header:hover,
.webix_accordionitem .webix_accordionitem_header:focus {
  background-color: var(--is-component-bg-muted);
}
.webix_accordionitem.horizontal.collapsed,
.webix_accordionitem.collapsed {
  background-color: var(--is-theme-bg-muted);
}
.webix_accordionitem.horizontal.collapsed .webix_accordionitem_header,
.webix_accordionitem.collapsed .webix_accordionitem_header {
  background-color: var(--is-theme-bg-muted);
  color: var(--is-primary);
}
.webix_accordionitem.horizontal.collapsed .webix_accordionitem_header .webix_accordionitem_button,
.webix_accordionitem.collapsed .webix_accordionitem_header .webix_accordionitem_button {
  color: var(--is-primary);
}
.webix_accordionitem.horizontal.collapsed .webix_accordionitem_header .webix_accordionitem_label .webix_icon,
.webix_accordionitem.collapsed .webix_accordionitem_header .webix_accordionitem_label .webix_icon {
  color: var(--is-primary);
}
.webix_accordionitem.horizontal.collapsed .webix_accordionitem_header:hover,
.webix_accordionitem.collapsed .webix_accordionitem_header:hover,
.webix_accordionitem.horizontal.collapsed .webix_accordionitem_header:focus,
.webix_accordionitem.collapsed .webix_accordionitem_header:focus {
  background-color: var(--is-default-inverse);
}
.webix_layout_accordion.webix_dark .webix_accordionitem_header {
  background-color: var(--is-secondary);
  color: var(--is-secondary-text);
}
.webix_layout_accordion.webix_dark .webix_accordionitem_header .webix_accordionitem_button {
  color: var(--is-secondary-text);
}
.webix_layout_accordion.webix_dark .webix_accordionitem_header.collapsed {
  background-color: var(--is-theme-bg-muted);
  color: var(--is-primary);
}
.webix_layout_accordion.webix_dark .webix_accordionitem_header.collapsed .webix_accordionitem_button {
  color: var(--is-primary);
}
.webix_layout_accordion.webix_dark .webix_accordionitem_header:hover,
.webix_layout_accordion.webix_dark .webix_accordionitem_header:focus {
  background-color: var(--is-secondary-dark);
}
.webix_layout_accordion.webix_dark .webix_accordionitem.horizontal.collapsed .webix_accordionitem_header:hover,
.webix_layout_accordion.webix_dark .webix_accordionitem.collapsed .webix_accordionitem_header:hover,
.webix_layout_accordion.webix_dark .webix_accordionitem.horizontal.collapsed .webix_accordionitem_header:focus,
.webix_layout_accordion.webix_dark .webix_accordionitem.collapsed .webix_accordionitem_header:focus {
  background-color: var(--is-default-inverse);
}
.webix_accordionitem .webix_accordionitem_header .webix_accordionitem_button {
  color: var(--is-default-inverse-darker);
}
.webix_accordionitem .webix_accordionitem_header .webix_accordionitem_label .webix_icon {
  color: var(--is-default-inverse-darker);
}
.webix_accordionitem_label {
  font-size: var(--is-font-size);
}
.webix_toolbar.webix_layout_subbar {
  background: transparent;
}
.webix_toolbar.webix_layout_subbar .webix_view {
  color: var(--is-text);
}
.webix_el_tabbar + .webix_multiview {
  background-color: var(--is-component-bg);
}
.webix_popup_button {
  border-color: var(--is-theme-bg-muted);
}
.webix_popup_button.confirm {
  border-color: var(--is-primary);
}
.dataHeaderFont {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size);
  color: var(--is-default-dark);
}
.webix_dtable.webix_header_border .webix_ss_header td {
  border-right-color: var(--is-border);
  border-bottom-color: var(--is-border);
}
.webix_dtable.webix_footer_border .webix_ss_footer td {
  border-right-color: var(--is-border);
  border-top-color: var(--is-border);
}
.webix_size_row td {
  border: none;
}
.webix_dtable.webix_data_border .webix_column > div,
.webix_dtable.webix_data_border .webix_table_cell {
  border-right-color: var(--is-default-inverse);
}
.webix_dtable .webix_ss_left .webix_column.webix_last > div {
  border-right: 1px solid var(--is-border);
}
.webix_dtable div.webix_last_topcell {
  border-bottom: 1px solid var(--is-border);
}
.webix_dtable .webix_ss_right .webix_column.webix_first > div {
  border-left: 1px solid var(--is-border);
}
.webix_dtable .webix_hs_right td.webix_first {
  border-left: 1px solid var(--is-border);
}
.webix_dtable .webix_hs_left td.webix_last {
  border-right: 1px solid var(--is-border);
}
.webix_dtable .webix_dtable_subview {
  background: var(--is-component-bg);
}
.webix_dtable .webix_dtable_subview .webix_view {
  border-color: var(--is-default-inverse);
}
.webix_dtable .webix_ss_filter select,
.webix_dtable .webix_ss_filter input {
  border-color: var(--is-border);
}
.webix_dtable .webix_ss_filter select:focus,
.webix_dtable .webix_ss_filter input:focus {
  border-color: var(--is-primary);
}
.webix_dtable .webix_select_mark > div.webix_row_select {
  box-shadow: inset 2px 0 var(--is-primary);
}
.webix_dtable .webix_ss_header td.webix_last_row {
  border-bottom: 1px solid var(--is-primary-inverse-darkest);
}
.webix_dtable .webix_ss_footer tr:nth-child(2) td {
  border-top: 1px solid var(--is-primary-inverse-darkest);
}
.webix_dtable .webix_ss_footer TD,
.webix_dtable .webix_ss_vscroll_footer {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size);
  color: var(--is-default-dark);
  background: var(--is-theme-bg-muted);
}
.webix_dtable .webix_column > .webix_drag_over.webix_cell {
  background-color: var(--is-theme-bg-muted);
}
.webix_block_selection {
  background: var(--is-primary);
}
.webix_area_selection,
.webix_area_selection_handle {
  background: var(--is-primary);
}
.webix_dtable_focused .webix_area_selection,
.webix_dtable_focused .webix_area_selection_handle {
  background-color: var(--is-primary-dark);
}
.dtBodyCell {
  border-bottom: 1px solid var(--is-default-inverse);
}
.webix_dtable div.webix_ss_vscroll_header {
  border-bottom-color: var(--is-primary-inverse-darkest);
}
.webix_dtable div.webix_ss_vscroll_footer {
  border-top-color: var(--is-primary-inverse-darkest);
  border-left-color: var(--is-border);
}
.webix_view .webix_dt_editor input,
.webix_view .webix_dt_editor select {
  border-color: var(--is-primary);
}
.dataItemStyle {
  border-color: var(--is-default-inverse);
}
.webix_list_item.webix_group_back {
  color: var(--is-default-dark);
  background: var(--is-theme-bg-muted);
  border-bottom: 1px solid var(--is-border);
}
.webix_dataview_item {
  border-right-color: var(--is-default-inverse);
}
.webix_dataview .webix_dataview_item.tiles.webix_selected .webix_dataview_inner_item {
  border-color: var(--is-primary-inverse-darkest);
}
.webix_view .webix_pager_item {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-lg);
  background: var(--is-theme-bg-muted);
  color: var(--is-primary);
}
.webix_view .webix_pager_item:hover {
  background-color: var(--is-default-inverse);
}
.webix_view .webix_pager_item:focus {
  background-color: var(--is-default-inverse);
}
.webix_view .webix_pager_item:active {
  background-color: var(--is-default-inverse-dark);
}
.webix_view .webix_pager_item_selected {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-lg);
  background: var(--is-primary);
  color: var(--is-primary-text);
}
.webix_view .webix_pager_item_selected:hover {
  background-color: var(--is-primary-dark);
}
.webix_view .webix_pager_item_selected:focus {
  background-color: var(--is-primary-dark);
}
.webix_view .webix_pager_item_selected:active {
  background-color: var(--is-primary-darker);
}
.webix_list .webix_unit_header {
  border-bottom: 1px solid var(--is-border);
  background: var(--is-theme-bg-muted);
  font-family: var(--is-font-family);
  font-size: var(--is-font-size);
  color: var(--is-default-dark);
}
.webix_list .webix_unit_header:not(:first-child) {
  border-top: 1px solid var(--is-border);
}
.webix_arrow_icon {
  color: var(--is-text);
}
.webix_tree .webix_tree_item.webix_selected {
  background-color: var(--is-theme-bg-muted);
}
.webix_tree .webix_tree_item.webix_selected:focus {
  background-color: var(--is-default-inverse);
}
.webix_tree_open,
.webix_tree_close {
  color: inherit;
}

.webix_tree_folder,
.webix_tree_folder_open,
.webix_tree_file {
  color: var(--is-border);
}
.webix_menu-x .webix_list_item .webix_submenu_icon,
.webix_view.webix_menu .webix_list_item .webix_submenu_icon {
  font-size: var(--is-font-size);
}
.webix_menu.webix_view .webix_list_item:hover,
.webix_menu.webix_view .webix_list_item:active {
  background-color: var(--is-component-bg-muted);
  color: var(--is-text);
}
.webix_menu.webix_view .webix_list_item.webix_selected {
  background: var(--is-theme-bg-muted);
}
.webix_menu.webix_view .webix_list_item.webix_disabled {
  background-color: var(--is-component-bg);
}
.webix_menu.webix_view .webix_icon {
  color: var(--is-default-inverse-darker);
}
.webix_property .webix_property_label_line {
  background-color: var(--is-theme-bg-muted);
}
.webix_drag_over,
.webix_drop_zone {
  background-color: var(--is-theme-bg-muted);
}
.webix_el_combo span.webix_input_icon,
.webix_el_richselect span.webix_input_icon,
.webix_el_datepicker span.webix_input_icon,
.webix_el_colorpicker span.webix_input_icon,
.webix_el_dbldatepicker span.webix_input_icon {
  color: var(--is-default-inverse-darker);
}
.focusStyle {
  border: 1px solid var(--is-primary);
}
.webix_el_search input:focus,
.webix_el_text input:focus,
.webix_el_combo input:focus,
.webix_el_colorpicker input:focus,
.webix_el_datepicker input:focus {
  border: 1px solid var(--is-primary);
}
.webix_el_richselect .webix_inp_static:focus,
.webix_el_datepicker .webix_inp_static:focus,
.webix_el_colorpicker .webix_inp_static:focus,
.webix_el_dbldatepicker .webix_inp_static:focus {
  border: 1px solid var(--is-primary);
}
.webix_el_textarea textarea:focus {
  border: 1px solid var(--is-primary);
}
.webix_el_select select:focus {
  border: 1px solid var(--is-primary);
}
.webix_focused .webix_inp_static {
  border: 1px solid var(--is-primary);
}
.webix_multilist .wxi-checkbox-marked {
  color: var(--is-primary);
}
.webix_multilist .wxi-checkbox-blank {
  color: var(--is-default-inverse-darker);
}
.webix_multicombo_value,
.webix_multicombo_tag {
  background-color: var(--is-theme-bg);
}
.webix_multicombo_value .webix_multicombo_delete:after {
  color: var(--is-default-inverse-darker);
}
.webix_checksuggest_select_all:hover {
  background-color: var(--is-theme-bg-muted);
}
.webix_checksuggest_select_all:hover .webix_label_right {
  color: var(--is-primary);
}
.webix_checksuggest_select_all .webix_label_right {
  font-size: var(--is-font-size-lg);
  color: var(--is-primary);
}
.buttonStyle {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-lg);
  color: var(--is-primary);
  outline: none;
}
div.webix_inp_static,
.webix_el_textarea textarea {
  background: var(--is-component-bg);
  font-family: var(--is-font-family);
  border-color: var(--is-border);
  /*.altBackColor;
	.altBackBorderColor;*/
}
.webix_el_select select {
  font-family: var(--is-font-family);
  padding: 0 var(--is-su-2);
  border-color: var(--is-border);
  background-color: var(--is-component-bg);
}
.webix_el_checkbox input {
  border-color: var(--is-border);
}
.webix_el_counter .webix_inp_counter_value {
  width: 42px;
  background: var(--is-component-bg);
  font-family: var(--is-font-family);
  border-color: var(--is-border);
}
.webix_el_counter .webix_inp_counter_prev,
.webix_el_counter .webix_inp_counter_next {
  background: var(--is-theme-bg-muted);
  border: 1px solid var(--is-border);
}
.webix_view.webix_control.webix_el_tabbar {
  background: var(--is-component-bg);
}
.webix_all_tabs .webix_item_tab {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-lg);
  color: var(--is-primary);
}
.webix_all_tabs .webix_item_tab:hover {
  background-color: var(--is-theme-bg-muted);
}
.webix_all_tabs .webix_item_tab .webix_icon.webix_tab_close {
  color: var(--is-default-inverse-darker);
}
.webix_all_tabs .webix_item_tab .webix_icon.webix_tab_close:focus {
  color: var(--is-danger);
}
.webix_all_tabs .webix_item_tab.webix_selected {
  box-shadow: inset 0 -2px var(--is-primary);
}
.webix_all_tabs .webix_item_tab.webix_selected:focus {
  color: var(--is-primary-darker);
  box-shadow: inset 0 -2px var(--is-primary-darker);
}
.webix_all_tabs .webix_item_tab.webix_selected.webix_disabled {
  box-shadow: inset 0 -2px var(--is-default-inverse-darker);
}
.webix_all_tabs .webix_tab_more_icon .webix_icon {
  color: var(--is-primary);
}
.webix_all_tabs .webix_tab_more_icon:hover,
.webix_all_tabs .webix_tab_more_icon:focus {
  background-color: var(--is-theme-bg-muted);
}
.webixtype_bottom .webix_item_tab,
.webixtype_bottom .webix_item_tab.webix_selected {
  background: var(--is-component-bg);
}
.webixtype_bottom .webix_item_tab.webix_selected {
  box-shadow: inset 0 2px var(--is-primary);
  background: var(--is-component-bg);
  color: var(--is-primary);
}
.webixtype_bottom .webix_item_tab.webix_selected:focus {
  box-shadow: inset 0 2px var(--is-primary-darker);
}
.webixtype_bottom .webix_item_tab.webix_selected.webix_disabled {
  box-shadow: inset 0 2px var(--is-default-inverse-darker);
}
.webix_el_tabbar .webixtype_icon .webix_img_btn .webix_icon_btn {
  color: var(--is-primary);
}
.webix_el_tabbar .webixtype_icon .webix_item_tab.webix_selected {
  background: var(--is-component-bg);
}
.webix_el_tabbar .webixtype_icon .webix_img_btn:active,
.webix_el_tabbar .webixtype_icon .webix_img_btn_top:active {
  background-color: var(--is-component-bg);
}
.webix_el_tabbar .webixtype_iconTop .webix_icon,
.webix_el_tabbar .webixtype_image .webix_icon {
  color: var(--is-primary);
}
.webix_el_tabbar .webixtype_iconTop .webix_item_tab,
.webix_el_tabbar .webixtype_image .webix_item_tab {
  font-size: var(--is-font-size);
}
.webix_el_tabbar .webixtype_iconTop .webix_img_btn_text,
.webix_el_tabbar .webixtype_image .webix_img_btn_text {
  font-size: var(--is-font-size);
}
.webix_el_tabbar .webixtype_iconTop .webix_item_tab.webix_selected,
.webix_el_tabbar .webixtype_image .webix_item_tab.webix_selected {
  background: var(--is-component-bg);
}
.webix_el_tabbar .webixtype_iconTop .webix_item_tab.webix_selected .webix_img_btn_text,
.webix_el_tabbar .webixtype_image .webix_item_tab.webix_selected .webix_img_btn_text {
  color: var(--is-primary);
}
.webix_el_tabbar .webixtype_iconTop .webix_item_tab.webix_selected .webix_icon,
.webix_el_tabbar .webixtype_image .webix_item_tab.webix_selected .webix_icon {
  color: var(--is-primary);
}
.webix_el_tabbar .webixtype_iconTop .webix_item_tab.webix_selected .webix_icon_btn,
.webix_el_tabbar .webixtype_image .webix_item_tab.webix_selected .webix_icon_btn {
  background-color: var(--is-component-bg);
}
.webix_el_tabbar .webixtype_iconTop .webix_item_tab.webix_selected .webix_img_btn,
.webix_el_tabbar .webixtype_image .webix_item_tab.webix_selected .webix_img_btn {
  color: var(--is-primary);
}
.webix_el_tabbar .webixtype_icon .webix_item_tab .webix_img_btn,
.webix_el_tabbar .webixtype_iconTop .webix_item_tab .webix_img_btn,
.webix_el_tabbar .webixtype_image .webix_item_tab .webix_img_btn {
  color: var(--is-primary);
}
.webix_el_tabbar .webixtype_icon .webix_tab_more_icon,
.webix_el_tabbar .webixtype_iconTop .webix_tab_more_icon,
.webix_el_tabbar .webixtype_image .webix_tab_more_icon,
.webix_el_tabbar .webixtype_bottom .webix_tab_more_icon {
  background: var(--is-component-bg);
}
.webix_el_tabbar .webixtype_icon .webix_tab_more_icon .webix_icon,
.webix_el_tabbar .webixtype_iconTop .webix_tab_more_icon .webix_icon,
.webix_el_tabbar .webixtype_image .webix_tab_more_icon .webix_icon,
.webix_el_tabbar .webixtype_bottom .webix_tab_more_icon .webix_icon {
  color: var(--is-primary);
}
.webix_el_tabbar .webixtype_icon .webix_tab_more_icon:hover,
.webix_el_tabbar .webixtype_iconTop .webix_tab_more_icon:hover,
.webix_el_tabbar .webixtype_image .webix_tab_more_icon:hover,
.webix_el_tabbar .webixtype_bottom .webix_tab_more_icon:hover,
.webix_el_tabbar .webixtype_icon .webix_tab_more_icon:focus,
.webix_el_tabbar .webixtype_iconTop .webix_tab_more_icon:focus,
.webix_el_tabbar .webixtype_image .webix_tab_more_icon:focus,
.webix_el_tabbar .webixtype_bottom .webix_tab_more_icon:focus {
  background-color: var(--is-theme-bg-muted);
}
.webix_el_tabbar .webixtype_icon .webix_img_btn:hover,
.webix_el_tabbar .webixtype_iconTop .webix_img_btn:hover,
.webix_el_tabbar .webixtype_image .webix_img_btn:hover,
.webix_el_tabbar .webixtype_icon .webix_img_btn_top:hover,
.webix_el_tabbar .webixtype_iconTop .webix_img_btn_top:hover,
.webix_el_tabbar .webixtype_image .webix_img_btn_top:hover {
  background-color: transparent;
}
.passiveBarBG {
  background: var(--is-component-bg);
  color: var(--is-primary);
}
.webix_remove_upload .cancel_icon:hover {
  color: var(--is-danger);
}
.webix_upload_error .error_icon {
  color: var(--is-danger);
}
.webix_inp_counter_next:hover,
.webix_inp_counter_prev:hover {
  background-color: var(--is-default-inverse);
}
.webix_inp_counter_next:focus,
.webix_inp_counter_prev:focus {
  background-color: var(--is-default-inverse);
}
.webix_inp_counter_next:active,
.webix_inp_counter_prev:active {
  background-color: var(--is-default-inverse-dark);
}
.webix_el_text span.webix_input_icon {
  color: var(--is-default-inverse-darker);
}
.webix_el_text .webix_input_icon:hover,
.webix_el_text .webix_input_icon:active {
  color: var(--is-primary);
}
.webix_segment_0:hover,
.webix_segment_1:hover,
.webix_segment_N:hover {
  background-color: var(--is-default-inverse);
}
.webix_segment_0:focus,
.webix_segment_1:focus,
.webix_segment_N:focus {
  background-color: var(--is-default-inverse);
}
.webix_segment_0:active,
.webix_segment_1:active,
.webix_segment_N:active {
  background-color: var(--is-default-inverse-dark);
}
.webix_selected.webix_segment_0:hover,
.webix_selected.webix_segment_1:hover,
.webix_selected.webix_segment_N:hover {
  background-color: var(--is-primary-dark);
}
.webix_selected.webix_segment_0:focus,
.webix_selected.webix_segment_1:focus,
.webix_selected.webix_segment_N:focus {
  background-color: var(--is-primary-dark);
}
.webix_selected.webix_segment_0:active,
.webix_selected.webix_segment_1:active,
.webix_selected.webix_segment_N:active {
  background-color: var(--is-primary-darker);
}
.webix_segment_0,
.webix_segment_1,
.webix_segment_N {
  border-color: var(--is-theme-bg-muted);
  background-color: var(--is-theme-bg-muted);
  color: var(--is-primary);
}
.webix_section > .webix_template {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-xs);
  color: var(--is-text);
}
.webix_view.webix_control .webix_disabled_box .webix_input_icon,
.webix_view.webix_control .webix_disabled_box .webix_custom_checkbox,
.webix_view.webix_control .webix_disabled_box .webix_custom_radio {
  color: var(--is-border);
}
.webix_view > .webix_disabled {
  background-color: var(--is-theme-bg);
}
.webix_disabled_view .webix_disabled_top_label {
  color: var(--is-default-inverse-darker);
}
.webix_checkbox_1 .webix_custom_checkbox {
  color: var(--is-primary);
}
.webix_custom_checkbox {
  color: var(--is-default-inverse-darker);
}
.webix_custom_checkbox:focus {
  color: var(--is-primary-dark);
}
.webix_el_radio .webix_radio_1 .webix_custom_radio {
  color: var(--is-primary);
}
.webix_el_radio .webix_custom_radio {
  color: var(--is-default-inverse-darker);
}
.webix_el_radio .webix_custom_radio:focus {
  color: var(--is-primary-dark);
}
.webix_cal_icon,
.webix_cal_time,
.webix_cal_month_name {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size);
  color: var(--is-text);
}
.webix_cal_icon:hover,
.webix_cal_time:hover,
.webix_cal_month_name:hover,
.webix_cal_icon:focus,
.webix_cal_time:focus,
.webix_cal_month_name:focus {
  color: var(--is-primary);
}
.webix_cal_month_name,
.webix_time_header .webix_cal_hours,
.webix_time_header .webix_cal_minutes {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size);
  color: var(--is-default-dark);
}
.webix_cal_month_name.webix_readonly:hover,
.webix_cal_month_name.webix_readonly:focus {
  color: var(--is-default-dark);
}
.webix_cal_prev_button,
.webix_cal_next_button {
  color: var(--is-default-inverse-darker);
}
.webix_cal_prev_button:hover,
.webix_cal_next_button:hover,
.webix_cal_prev_button:focus,
.webix_cal_next_button:focus {
  color: var(--is-primary);
}
.webix_view > .webix_cal_header div {
  font-size: var(--is-font-size-xs);
}
.webix_cal_body .webix_cal_week_num {
  color: var(--is-text);
}
.webix_cal_body .webix_cal_today .webix_cal_day_inner {
  box-shadow: inset 0 0 0 1px var(--is-primary);
}
.webix_cal_body .webix_cal_day_inner {
  font-size: var(--is-font-size-xs);
}
.webix_cal_body .webix_cal_day_inner:hover {
  background-color: var(--is-theme-bg-muted);
}
.webix_cal_body .webix_cal_event {
  color: var(--is-primary);
}
.webix_cal_body .webix_cal_outside {
  color: var(--is-default-inverse-darker);
}
.webix_cal_body .webix_cal_select .webix_cal_day_inner,
.webix_cal_body .webix_cal_today.webix_cal_select .webix_cal_day_inner {
  color: var(--is-primary-text);
  background-color: var(--is-primary);
}
.webix_cal_body .webix_cal_select .webix_cal_day_inner:hover,
.webix_cal_body .webix_cal_today.webix_cal_select .webix_cal_day_inner:hover {
  background-color: var(--is-primary);
}
.webix_cal_body .webix_cal_select:focus .webix_cal_day_inner,
.webix_cal_body .webix_cal_today.webix_cal_select:focus .webix_cal_day_inner {
  background-color: var(--is-primary-dark);
}
.webix_cal_body .webix_cal_block {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-xs);
  color: var(--is-text);
}
.webix_cal_body .webix_cal_block.webix_selected span {
  background-color: var(--is-primary);
  color: var(--is-primary-text);
}
.webix_cal_body .webix_cal_block.webix_selected span:hover {
  background-color: var(--is-primary);
}
.webix_cal_body .webix_cal_block.webix_selected:focus span {
  background-color: var(--is-primary-dark);
}
.webix_cal_body .webix_cal_block span:hover {
  background-color: var(--is-theme-bg-muted);
}
.webix_cal_body .webix_hours .webix_cal_day_disabled,
.webix_cal_body .webix_hours .webix_cal_day_disabled.webix_cal_event,
.webix_cal_body .webix_minutes .webix_cal_day_disabled,
.webix_cal_body .webix_minutes .webix_cal_day_disabled.webix_cal_event,
.webix_cal_body .webix_cal_day_disabled,
.webix_cal_body .webix_cal_day_disabled.webix_cal_event {
  background-color: var(--is-default-inverse-light);
  color: var(--is-default-inverse-darker);
}
.webix_cal_body .webix_cal_range_last + .webix_cal_outside {
  background-color: var(--is-component-bg);
}
.webix_cal_body .webix_cal_range_first:before,
.webix_cal_body .webix_cal_range_last:after {
  background: var(--is-primary-inverse-light);
}
.webix_cal_body .webix_cal_range_last + .webix_cal_outside {
  background-color: var(--is-component-bg);
}
.webix_cal_body .webix_cal_range_start .webix_cal_day_inner,
.webix_cal_body .webix_cal_range_end .webix_cal_day_inner,
.webix_cal_body .webix_cal_today.webix_cal_range_start .webix_cal_day_inner,
.webix_cal_body .webix_cal_today.webix_cal_range_end .webix_cal_day_inner {
  color: var(--is-primary-text);
  background-color: var(--is-primary);
}
.webix_cal_body .webix_cal_range {
  background-color: var(--is-primary-inverse-light);
}
.webix_progress_top .webix_progress_state,
.webix_progress_bottom .webix_progress_state {
  background: var(--is-primary);
  background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
}
.webix_switch_box {
  color: var(--is-text);
  background-color: var(--is-theme-bg-muted);
}
.webix_switch_box.webix_switch_on {
  background-color: var(--is-primary);
}
.webix_sidebar {
  background-color: var(--is-component-bg);
}
.webix_sidebar .webix_tree_item:hover {
  background-color: var(--is-default-inverse);
}
.webix_sidebar.webix_sidebar_left .webix_tree_item.webix_sidebar_selected + .webix_tree_leaves .webix_tree_item,
.webix_sidebar.webix_sidebar_left .webix_tree_item.webix_selected,
.webix_sidebar.webix_sidebar_left .webix_tree_item.webix_sidebar_selected {
  box-shadow: inset 2px 0 var(--is-primary);
}
.webix_sidebar.webix_sidebar_right .webix_tree_item.webix_sidebar_selected + .webix_tree_leaves .webix_tree_item,
.webix_sidebar.webix_sidebar_right .webix_tree_item.webix_selected,
.webix_sidebar.webix_sidebar_right .webix_tree_item.webix_sidebar_selected {
  box-shadow: inset -2px 0 var(--is-primary);
}
.webix_sidebar .webix_sidebar_icon,
.webix_sidebar .webix_sidebar_dir_icon {
  color: var(--is-default-inverse-darker);
}
.webix_sidebar .webix_tree_item,
.webix_sidebar .webix_tree_branch_1 > .webix_tree_item {
  color: var(--is-text);
}
.webix_sidebar .webix_tree_item.webix_selected,
.webix_sidebar .webix_tree_item.webix_sidebar_selected {
  color: var(--is-primary);
  background-color: var(--is-theme-bg-muted);
}
.webix_sidebar .webix_tree_item.webix_selected .webix_sidebar_icon,
.webix_sidebar .webix_tree_item.webix_sidebar_selected .webix_sidebar_icon,
.webix_sidebar .webix_tree_item.webix_selected .webix_sidebar_dir_icon,
.webix_sidebar .webix_tree_item.webix_sidebar_selected .webix_sidebar_dir_icon {
  color: var(--is-primary);
}
.webix_sidebar .webix_tree_item.webix_selected span {
  color: var(--is-primary);
}
.webix_sidebar.webix_sidebar_expanded .webix_tree_item.webix_sidebar_selected:hover {
  background-color: var(--is-default-inverse);
}
.webix_sidebar_popup_list .webix_list_item.webix_sidebar_selected {
  background-color: var(--is-theme-bg-muted);
  color: var(--is-primary);
}
.webix_sidebar_popup_list .webix_list_item.webix_sidebar_selected:hover {
  background-color: var(--is-theme-bg-muted) !important;
}
.webix_sidebar_popup_list .webix_list_item.webix_selected {
  color: var(--is-primary);
}
.webix_sidebar_popup_left .webix_list_item.webix_selected,
.webix_sidebar_popup_left .webix_list_item.webix_sidebar_selected {
  box-shadow: inset 2px 0 var(--is-primary);
}
.webix_sidebar_popup_right .webix_list_item.webix_selected,
.webix_sidebar_popup_right .webix_list_item.webix_sidebar_selected {
  box-shadow: inset -2px 0 var(--is-primary);
}
.webix_sidebar_popup .webix_sidebar_popup_title {
  background-color: var(--is-theme-bg-muted);
}
.webix_sidebar_popup .webix_sidebar_popup_title.webix_selected {
  color: var(--is-primary);
}
.webix_list_item.webix_selected {
  box-shadow: inset 2px 0 var(--is-primary);
}
.webix_list_item.webix_disabled {
  color: var(--is-default-inverse-darker);
}
.webix_list_item.webix_selected.webix_disabled {
  box-shadow: inset 2px 0 var(--is-default-inverse-darker);
}
.webix_icon_button:hover .webix_icon {
  color: var(--is-text);
}
.webix_icon_button:hover:before {
  background-color: var(--is-theme-bg-muted);
}
.webix_img_btn_top .webix_icon {
  color: var(--is-default-inverse-darker);
}
.webix_resize_frame {
  box-shadow: 0 2px 6px 0 #000, 0 1px 4px 0 #000;
}
.webix_tooltip {
  background-color: var(--is-default);
  color: var(--is-default-text);
}
.portlet_marker,
.portlet_markertop,
.portlet_markerbottom,
.portlet_markerleft,
.portlet_markerright {
  background: var(--is-primary);
}
.webix_treemap_item:hover {
  box-shadow: inset 0 0 0 1px var(--is-primary-inverse-darkest);
}
.webix_treemap_item.webix_selected {
  border-color: inherit;
  box-shadow: inset 0 0 0 2px var(--is-primary);
}
.webix_inp_label,
.webix_inp_top_label,
legend.webix_forminput_label,
.webix_el_label .webix_el_box {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size);
  color: var(--is-default-dark);
}
.webix_richtext .webix_richtext_container {
  box-shadow: inset 0 1px var(--is-border);
}
.webix_dbllist .bottom_label {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size);
  color: var(--is-default-inverse-darker);
}
.webix_organogram .webix_organogram_item {
  background: var(--is-theme-bg-muted);
  border-color: var(--is-border);
}
.webix_organogram .webix_organogram_item.webix_selected {
  border-color: var(--is-primary);
  background: var(--is-primary-inverse-light);
}
.webix_chart .webix_chart_legend_item,
.webix_chart .webix_canvas_text,
.webix_chart .webix_axis_title_x,
.webix_chart .webix_axis_title_y {
  font-family: var(--is-font-family);
  font-size: var(--is-font-size-xs);
  color: var(--is-text);
}
.webix_chart .webix_canvas_text.webix_axis_item_x,
.webix_chart .webix_canvas_text.webix_axis_item_y,
.webix_chart .webix_axis_title_x,
.webix_chart .webix_axis_title_y,
.webix_chart .webix_canvas_text.webix_axis_radar_title {
  color: var(--is-default-inverse-darker);
}
.webix_toolbar.pdf_bar {
  background-color: var(--is-default);
}
.webix_toolbar.pdf_bar .webix_img_btn:focus,
.webix_toolbar.pdf_bar .webix_img_btn:hover {
  background-color: var(--is-default-dark);
}
.invalidStyle {
  color: var(--is-danger);
  background-color: var(--is-danger-inverse-light);
}
.webix_list_item.webix_invalid,
.webix_tree_item.webix_invalid,
.webix_dataview_item.webix_invalid,
.webix_cell.webix_invalid {
  color: var(--is-danger);
  background-color: var(--is-danger-inverse-light);
}
.webix_invalid .webix_el_box input,
.webix_invalid .webix_el_box .webix_inp_static,
.webix_invalid .webix_el_box select,
.webix_invalid .webix_el_box textarea,
input.webix_invalid,
select.webix_invalid,
textarea.webix_invalid {
  color: var(--is-danger);
  background-color: var(--is-danger-inverse-light);
  border-color: var(--is-danger-inverse-darker);
}
.webix_invalid .webix_inp_bottom_label {
  color: var(--is-danger);
}
.webix_nav_panel .webix_nav_active {
  background-color: var(--is-component-bg);
}
.webix_light {
  color: var(--is-default-inverse-dark);
}


/* PIVOT TABLE */

.webix_pivot_configuration .webix_list:after {
  color: var(--is-primary-inverse);
}
.webix_pivot .webix_pivot_plus {
  color: var(--is-success);
}
.webix_pivot_transparent {
  color: var(--is-text-inverse);
}
.webix_pivot .webix_pivot_list_marker,
.webix_pivot_chart_popup .webix_pivot_list_marker {
  border: 1px solid var(--is-primary-inverse-darker);
}
.webix_column > .webix_max {
  background-color: var(--is-success-inverse-light);
  color: var(--is-success-inverse-light-text);
}
.webix_column > .webix_min {
  background-color: var(--is-danger-inverse-light);
  color: var(--is-danger-inverse-light-text);
}
.webix_pivot_header_icon {
  color: var(--is-primary-inverse-darker);
}
.webix_pivot_fields_msg {
  color: var(--is-text-muted);
}
.webix_pivot_field_selected .webix_pivot_list_marker {
  border-color: var(--is-primary-inverse-darker);
  background-color: var(--is-primary-inverse-darker);
}
.webix_pivot.webix_popup .webix_layout_space,
.webix_pivot.webix_popup .webix_layout_wide {
  background: var(--is-theme-bg-muted);
}
.webix_pivot.webix_popup .webix_pivot_popup_title {
  background: var(--is-component-bg-muted);
  color: var(--is-text);
}
.webix_pivot .webix_dtable .webix_ss_right .webix_column.webix_first > div,
.webix_pivot .webix_dtable .webix_hs_right td.webix_first {
  border-left: 1px solid var(--is-border);
}
.webix_pivot .webix_dtable {
  color: var(--is-text);
}
.webix_pivot .webix_ss_footer TD,
.webix_pivot .webix_ss_vscroll_footer {
  background: var(--is-primary-inverse-light);
  border-color: var(--is-primary-inverse-dark);
}
.webix_pivot .webix_ss_left .webix_cell {
  background: var(--is-component-bg-muted);
  border-color: var(--is-border);
  color: var(--is-text);
}
.webix_pivot .webix_ss_left .webix_cell .webix_row_select {
  color: #000;
}
.webix_pivot .webix_dtable .webix_dd_drag_column,
.webix_pivot .webix_ss_header,
.webix_pivot .webix_ss_header TD,
.webix_pivot .webix_ss_vscroll_header {
  background: var(--is-component-bg-muted);
}
.webix_pivot .webix_ss_header td {
  border-color: var(--is-primary-inverse-dark);
}
.webix_pivot .webix_column > div.webix_cell_select,
.webix_pivot .webix_column > div.webix_column_select,
.webix_pivot .webix_column > div.webix_row_select {
  background: var(--is-primary-inverse);
  color: var(--is-primary-dark);
}
.webix_pivot_operation {
  color: var(--is-primary-inverse-darker);
}
.pt-settings {
  color: var(--is-primary-inverse-darker);
}
.webix_pivot .webix_cell .webix_tree_folder_open,
.webix_pivot .webix_cell .webix_tree_folder,
.webix_pivot .webix_cell .webix_tree_file {
  color: var(--is-primary-inverse-darker);
}
.webix_pivot .webix_dtable .webix_ss_left .webix_column.webix_last > div.webix_row_select,
.webix_pivot .webix_cell.webix_row_select,
.webix_pivot .webix_column > div.webix_row_select {
  border-color: var(--is-primary-inverse-dark);
  border-top: 1px solid var(--is-primary-inverse-dark);
}
.webix_pivot .webix_column > div.webix_row_select + .webix_cell {
  border-top: 1px solid var(--is-primary-inverse-dark);
}
.webix_pivot .webix_column > div,
.webix_pivot .webix_table_cell {
  border-top: 1px solid var(--is-border);
}
.webix_pivot .webix_layout_toolbar.webix_toolbar {
  background: var(--is-primary);
}
.webix_pivot .webix_el_richselect .webix_el_box .webix_inp_static,
.webix_pivot .webix_multicombo .webix_el_box .webix_inp_static,
.webix_pivot .webix_el_datepicker .webix_el_box .webix_inp_static,
.webix_pivot .webix_el_text .webix_el_box .webix_inp_static,
.webix_pivot .webix_el_richselect input,
.webix_pivot .webix_multicombo input,
.webix_pivot .webix_el_datepicker input,
.webix_pivot .webix_el_text input {
  color: var(--is-text);
}
.pt-list-drag {
  color: var(--is-default-inverse-dark);
}
.webix_pivot .webix_list .webix_pivot_close {
  color: var(--is-primary-inverse);
}
.webix_pivot .webix_list .webix_pivot_close:hover,
.webix_pivot .webix_list .webix_pivot_close:active,
.webix_pivot .webix_list .webix_pivot_close:focus {
  color: var(--is-danger-inverse);
}
.webix_pivot_richselect_suggest .webix_list_item,
.webix_pivot_popup .webix_list_item,
.webix_pivot_multicombo_suggest .webix_list_item,
.webix_pivot_richselect_suggest .webix_list_item.webix_selected,
.webix_pivot_popup .webix_list_item.webix_selected,
.webix_pivot_multicombo_suggest .webix_list_item.webix_selected {
  color: var(--is-text);
}
.webix_pivot_richselect_suggest .webix_list_item.webix_selected,
.webix_pivot_popup .webix_list_item.webix_selected,
.webix_pivot_multicombo_suggest .webix_list_item.webix_selected,
.webix_pivot_richselect_suggest .webix_list_item.webix_selected.webix_selected,
.webix_pivot_popup .webix_list_item.webix_selected.webix_selected,
.webix_pivot_multicombo_suggest .webix_list_item.webix_selected.webix_selected,
.webix_pivot_richselect_suggest .webix_list_item:hover,
.webix_pivot_popup .webix_list_item:hover,
.webix_pivot_multicombo_suggest .webix_list_item:hover,
.webix_pivot_richselect_suggest .webix_list_item.webix_selected:hover,
.webix_pivot_popup .webix_list_item.webix_selected:hover,
.webix_pivot_multicombo_suggest .webix_list_item.webix_selected:hover {
  color: var(--is-primary-inverse-darker);
}
.webix_pivot_richselect_suggest .webix_list_item .webix_icon,
.webix_pivot_popup .webix_list_item .webix_icon,
.webix_pivot_multicombo_suggest .webix_list_item .webix_icon,
.webix_pivot_richselect_suggest .webix_list_item.webix_selected .webix_icon,
.webix_pivot_popup .webix_list_item.webix_selected .webix_icon,
.webix_pivot_multicombo_suggest .webix_list_item.webix_selected .webix_icon {
  color: var(--is-text);
}
.webix_pivot_header_fields {
  background: var(--is-component-bg-muted);
  color: var(--is-text);
}
.webix_popup.webix_pivot .webix_layout_toolbar.webix_toolbar .webix_secondary button,
.webix_pivot_chart_popup .webix_layout_toolbar.webix_toolbar .webix_secondary button {
  border: 1px solid var(--is-primary-text);
  color: var(--is-primary-text);
}
.webix_popup.webix_pivot .webix_layout_toolbar.webix_toolbar .webix_primary button,
.webix_pivot_chart_popup .webix_layout_toolbar.webix_toolbar .webix_primary button {
  background-color: var(--is-primary-inverse-light);
  color: var(--is-primary-inverse-light-text);
}
.webix_pivot_values .webix_pivot_link + .webix_pivot_link,
.webix_pivot_chart_values .webix_pivot_link + .webix_pivot_link {
  border-top: 1px solid var(--is-border);
}
.webix_pivot .webix_ss_sort_asc,
.webix_pivot .webix_ss_sort_desc {
  color: var(--is-text);
}
.webix_pivot tr[section="header"] .webix_ss_sort_asc,
.webix_pivot tr[section="header"] .webix_ss_sort_desc {
  background: var(--is-primary-inverse-light);
}
.webix_link_selection {
  color: var(--is-primary-inverse-darker);
}
.webix_pivot_transparent {
  color: var(--is-text-inverse);
}
.webix_pivot_popup_title,
.webix_pivot_chart_popup .webix_pivot_header_fields {
  background: var(--is-default-inverse);
}
.webix_pivot_chart_popup .webix_layout_wide,
.webix_pivot_chart_popup .webix_layout_space {
  background-color: var(--is-theme-bg-muted);
}
.webix_pivot_footer {
  background-color: var(--is-theme-bg-muted);
}
.webix_pivot_chart_popup .webix_layout_toolbar.webix_toolbar {
  background: var(--is-primary);
}
.webix_pivot_chart_popup .webix_pivot_popup_title {
  background: var(--is-primary-inverse-light);
  color: var(--is-text);
}
.webix_pivot .webix_el_combo span.webix_input_icon,
.webix_pivot .webix_el_richselect span.webix_input_icon {
  border-color: var(--is-border);
  color: var(--is-text);
}
.webix_pivot_popup_title,
.webix_pivot_chart_popup .webix_pivot_header_fields {
  background: var(--is-primary-inverse-light);
  color: var(--is-text);
}
.webix_pivot_popup_chart .webix_el_box .webix_inp_static {
  color: var(--is-primary-inverse-darker);
}
.webix_pivot_configure {
  background-color: var(--is-primary-inverse-light);
  border: 1px solid var(--is-primary-inverse-dark);
}
.webix_pivot .webix_toolbar {
  background-color: var(--is-primary);
}
.webix_pivot .webix_toolbar .webix_control label,
.webix_pivot .webix_toolbar .webix_el_label .webix_el_box {
  color: var(--is-primary-inverse);
}
.webix_pivot_popup_chart .webix_list .wxi-checkbox-blank,
.webix_pivot_popup_chart .webix_list .wxi-checkbox-marked,
.webix_pivot_popup_chart .webix_list .webix_pivot_icon {
  color: var(--is-primary-inverse-darker);
}
.webix_pivot .webix_input_icon.wxi-calendar {
  border-color: var(--is-border);
  color: var(--is-text);
}
.webix_pivot_configure_label {
  color: var(--is-primary);
}


/* SPREADSHEET */

.webix_ssheet_dialog_table .webix_icon {
  color: var(--is-text-muted);
}
.webix_ssheet_dialog_table .webix_icon:hover {
  color: var(--is-default-dark);
}
.webix_ssheet_icon {
  color: var(--is-text);
}
.webix_ssheet_cp_color {
  background: #e0e3e5;
}
.webix_ssheet_color .webix_input_icon.wxi-menu-down {
  background-color: transparent;
  color: var(--is-default-dark);
}
.webix_ssheet_colorboard .webix_color_selector {
  border: 1px solid var(--is-text);
  box-shadow: inset 0px 0px 1px 1px #fff;
}
.webix_toolbar.webix_ssheet_toolbar {
  background: var(--is-component-bg-muted);
  border-top-color: var(--is-border);
}
.webix_ssheet_toolbar .webix_button,
.webix_ssheet_toolbar .webix_inp_static {
  background: var(--is-component-bg-muted) !important;
  color: var(--is-text);
}
.webix_ssheet_toolbar .webix_button:hover,
.webix_ssheet_toolbar .webix_inp_static:hover {
  background: #e7e7e7 !important;
}
.webix_ssheet .webix_ssheet_toolbar .webix_button,
.webix_ssheet .webix_ssheet_toolbar .webix_img_btn {
  color: var(--is-text);
  border-color: var(--is-border);
}
.webix_ssheet .webix_ssheet_toolbar .webix_el_label {
  color: var(--is-text);
}
.webix_ssheet .webix_ssheet_toolbar .webix_icon_btn {
  color: var(--is-text-muted);
}
.webix_ssheet .webix_ssheet_toolbar .webix_button:hover,
.webix_ssheet .webix_ssheet_toolbar .webix_button:focus,
.webix_ssheet .webix_ssheet_toolbar .webix_pressed .webix_button {
  border-color: var(--is-border);
  color: var(--is-text);
}
.webix_ssheet .webix_icon {
  color: var(--is-text-muted) !important;
}
.webix_ssheet_toolbar .webix_selected {
  background-color: #e7e7e7 !important;
}
.webix_ssheet_toolbar .webix_segment_0,
.webix_ssheet_toolbar .webix_segment_1,
.webix_ssheet_toolbar .webix_segment_N {
  border-color: var(--is-border) !important;
}
.webix_ssheet .webix_el_toggle .webix_el_box.webix_pressed .webix_button {
  background-color: #e7e7e7;
}
.webix_ssheet_subbar_title {
  color: var(--is-text);
}
.webix_toolbar.webix_ssheet_toolbar .webix_inp_static,
.webix_ssheet_color .webix_inp_static {
  background: var(--is-component-bg-muted);
  border-color: var(--is-border) !important;
}
.webix_toolbar .webix_button {
  border: 1px solid var(--is-border);
}
.webix_toolbar.webix_ssheet_toolbar .webix_el_box:hover .webix_inp_static,
.webix_toolbar.webix_ssheet_toolbar .webix_inp_static:focus,
.webix_ssheet_color .webix_el_box:hover .webix_inp_static,
.webix_ssheet .webix_ssheet_toolbar .webix_img_btn:hover,
.webix_ssheet .webix_ssheet_toolbar .webix_img_btn:focus {
  border-color: var(--is-border) !important;
  background-color: rgba(0, 0, 0, 0.05);
}
.webix_ssheet_toolbar .wxi-menu-down {
  color: var(--is-text-muted) !important;
  background-color: transparent !important;
}
.webix_ssheet_toolbar_spacer {
  background: var(--is-theme-bg-muted-strong)
}
.webix_ssheet_dataview .webix_selected {
  background-color: #e7e7e7 !important;
}
.webix_ssheet_right {
  color: var(--is-text-muted);
}
.webix_ssheet_suggest .webix_list .webix_selected {
  background-color: #e7e7e7;
  color: var(--is-default-dark);
}
.webix_ssheet_suggest .webix_list_item:hover,
.webix_ssheet_dataview .webix_dataview_item:hover,
.webix_ssheet_dataview .webix_dataview_item:active {
  background-color: #f1f1f1;
}
.webix_ssheet .webix_ssheet_toolbar .webix_button .webix_img_btn_text {
  color: var(--is-text);
}
.webix_ssheet_dialog .webix_win_head {
  border-bottom-color: transparent;
}
.webix_ssheet_dialog .webix_win_head button {
  border: none;
  background: transparent;
}
.cancel {
  border-color: transparent;
  background: transparent;
  color: var(--is-default-dark);
}
.cancelHover {
  background: var(--is-theme-bg-muted);
  border-color: var(--is-theme-bg-muted);
}
.webix_ssheet_form .webix_el_combo input,
.webix_ssheet_dialog .webix_el_combo input,
.webix_ssheet_form .webix_el_text input,
.webix_ssheet_dialog .webix_el_text input,
.webix_ssheet_form .webix_inp_static,
.webix_ssheet_dialog .webix_inp_static,
.webix_ssheet_form .webix_inp_static:focus,
.webix_ssheet_dialog .webix_inp_static:focus {
  background-color: transparent;
}
.webix_ssheet_form span.webix_input_icon,
.webix_ssheet_dialog span.webix_input_icon {
  background: transparent;
  color: var(--is-text-muted);
}
.webix_ssheet_form .ssheet_cancel_button button,
.webix_ssheet_dialog .ssheet_cancel_button button {
  border-color: transparent;
  background: transparent;
  color: var(--is-default-dark);
}
.webix_ssheet_form .ssheet_cancel_button button:hover,
.webix_ssheet_dialog .ssheet_cancel_button button:hover,
.webix_ssheet_form .ssheet_cancel_button button:focus,
.webix_ssheet_dialog .ssheet_cancel_button button:focus {
  background: var(--is-theme-bg-muted);
  border-color: var(--is-theme-bg-muted);
}
.webix_ssheet_form .webix_selected,
.webix_ssheet_dialog .webix_selected {
  color: var(--is-default-dark);
  background: var(--is-theme-bg-muted);
}
.webix_ssheet_dialog_table .webix_ss_header td {
  border-right-color: #eaf4fb;
}
.webix_ssheet_dialog_table .webix_ss_vscroll_header {
  border-left-color: #eaf4fb;
}
.webix_ssheet_dialog_table .webix_cell {
  border-right-color: transparent;
}
.webix_ssheet_dialog_table div.webix_row_select {
  color: var(--is-default-dark);
  background: var(--is-theme-bg-muted);
}
.webix_ssheet_cformat {
  color: #666666;
  background: var(--is-component-bg);
}
.webix_ssheet_cformat_list .webix_list_item {
  border-bottom: 0;
}
.webix_ssheet_cformat_list .webix_list_item.webix_selected {
  background: transparent;
}
.webix_ssheet_format_type .webix_list_item:hover,
.webix_ssheet_format_negative .webix_list_item:hover,
.webix_ssheet_format_date .webix_list_item:hover {
  background: var(--is-component-bg-muted);
}
.webix_ssheet_preview .webix_template {
  color: #bbb;
}
.webix_ssheet_confirm .webix_popup_button:first-child {
  border-color: transparent;
  background: transparent;
  color: var(--is-default-dark);
}
.webix_ssheet_confirm .webix_popup_button:first-child:hover {
  background: var(--is-theme-bg-muted);
  border-color: var(--is-theme-bg-muted);
}
.ss_sep_line {
  border-top: 1px solid var(--is-border);
}
.webix_ssheet_menu {
  background: var(--is-component-bg);
}
.webix_ssheet_menu .webix_list_item {
  background: var(--is-component-bg);
  color: var(--is-default-dark);
  border: none;
  border-bottom: 1px solid var(--is-border);
}
.webix_ssheet_menu .webix_list_item:hover,
.webix_ssheet_menu .webix_list_item:active,
.webix_ssheet_menu .webix_list_item:focus {
  background: var(--is-component-bg-muted);
  box-shadow: 0 0 0 3px var(--is-component-bg) inset;
}
.webix_ssheet_bottom_toolbar.webix_layout_toolbar {
  background: var(--is-theme-bg-muted);
}
.webix_ssheet_bottom_toolbar.webix_layout_toolbar .webix_button {
  color: var(--is-text);
  border-color: transparent;
  background: transparent;
}
.webix_ssheet_bottom_toolbar.webix_layout_toolbar .webix_button:hover,
.webix_ssheet_bottom_toolbar.webix_layout_toolbar .webix_button:focus,
.webix_ssheet_bottom_toolbar.webix_layout_toolbar .webix_button:active {
  background: var(--is-theme-bg-muted-strong);
  color: var(--is-text);
}
.ssheet_list_sheets {
  background: var(--is-theme-bg-muted);
}
.ssheet_list_sheets .webix_list_item {
  border-left: none;
  border-color: var(--is-border);
  border-top: 1px solid var(--is-border);
  border-bottom: 1px solid var(--is-border);
  background: var(--is-theme-bg-muted);
  color: var(--is-text);
}
.ssheet_list_sheets .webix_list_item:first-child {
  border-left: 1px solid var(--is-border);
}
.ssheet_list_sheets .webix_list_item.webix_selected {
  border-top-color: var(--is-component-bg);
  background: var(--is-component-bg);
  color: var(--is-text);
  border-right-color: var(--is-border);
  border-bottom-color: var(--is-border);
}
.ssheet_list_sheets .webix_list_item:hover {
  background: var(--is-theme-bg-muted-strong)
}
.ssheet_list_sheets .webix_list_item.webix_selected:hover {
  background: var(--is-component-bg-hover);
}
.webix_drag_zone .ssheet_order_sheets {
  background: var(--is-component-bg);
  color: var(--is-text);
}
.webix_ssheet_bottom_toolbar .webix_el_button.webix_disabled_view .webix_el_htmlbutton {
  background: var(--is-theme-bg-muted);
  border-color: var(--is-theme-bg-muted);
  color: var(--is-text);
}
.webix_ssheet_bottom_toolbar .webix_el_button.webix_disabled_view .webix_el_htmlbutton:hover,
.webix_ssheet_bottom_toolbar .webix_el_button.webix_disabled_view .webix_el_htmlbutton:focus,
.webix_ssheet_bottom_toolbar .webix_el_button.webix_disabled_view .webix_el_htmlbutton:active {
  background: var(--is-theme-bg-muted-strong)
}
.webix_ssheet_bottom_toolbar .webix_el_button.webix_disabled_view .webix_icon {
  color: var(--is-text-muted) !important;
}
.webix_ssheet_condition_neutral {
  background: var(--is-warning-inverse-light) !important;
  color: var(--is-warning-dark) !important;
}
.webix_ssheet_condition_bad {
  background: var(--is-danger-inverse-light) !important;
  color: var(--is-danger-dark) !important;
}
.webix_ssheet_condition_good {
  background: var(--is-success-inverse-light) !important;
  color: var(--is-success-dark) !important;
}
.webix_ssheet_condition_normal {
  background: var(--is-component-bg) !important;
  color: var(--is-default-dark) !important;
}
.webix_lock:before {
  color: var(--is-warning);
}
.webix_ssheet_format_green {
  color: green !important;
}
.webix_ssheet_format_red {
  color: red !important;
}
.webix_ssheet_format_blue {
  color: blue !important;
}
.webix_ssheet_format_orange {
  color: darkorange !important;
}
.webix_ssheet_format_black {
  color: var(--is-default-dark) !important;
}
.webix_ssheet_format_magenta {
  color: magenta !important;
}
.webix_ssheet_format_violet {
  color: darkviolet !important;
}
.ssheet_commented_sign:before {
  border-top: 8px solid var(--is-default-dark);
  border-left: 8px solid transparent;
}
.webix_ssheet_highlight_background_1 {
  background: var(--is-success-darker) !important;
}
.webix_ssheet_highlight_color_1 {
  color: var(--is-success-light);
}
.webix_ssheet_highlight_background_2 {
  background: var(--is-danger-darker) !important;
}
.webix_ssheet_highlight_color_2 {
  color: var(--is-danger-light);
}
.webix_ssheet_highlight_background_3 {
  background: var(--is-default-darker) !important;
}
.webix_ssheet_highlight_color_3 {
  color: var(--is-default-light);
}
.webix_ssheet_highlight_background_4 {
  background: var(--is-warning-darker) !important;
}
.webix_ssheet_highlight_color_4 {
  color: var(--is-warning-light);
}
.webix_ssheet_highlight_background_5 {
  background: var(--is-primary-darker) !important;
}
.webix_ssheet_highlight_color_5 {
  color: var(--is-primary-light);
}
.webix_ssheet_highlight_background_6 {
  background: var(--is-accent-darker) !important;
}
.webix_ssheet_highlight_color_6 {
  color: var(--is-accent-light);
}
.webix_ssheet_highlight_background_7 {
  background: var(--is-secondary-darker) !important;
}
.webix_ssheet_highlight_color_7 {
  color: var(--is-secondary-light);
}
.sheet_column_0 div {
  background: var(--is-theme-bg-muted-strong);
  color: var(--is-default-dark);
  border-color: var(--is-border);
}
.sheet_column_0 .webix_ssheet_hide_row:after {
  background: var(--is-text);
}
.webix_hcell.webix_highlight,
.sheet_column_0 div.webix_highlight {
  background-color: var(--is-primary-light);
  color: var(--is-primary-light-text);
}
.webix_borderless .webix_last_topcell {
  border-bottom: 1px solid var(--is-border);
}
.webix_ssheet_table .webix_dd_drag_column,
.webix_ssheet_table .webix_ss_header,
.webix_ssheet_table .webix_ss_header TD,
.webix_ssheet_table .webix_ss_vscroll_header {
  background: var(--is-theme-bg-muted-strong);
  color: var(--is-default-dark);
}
.webix_ssheet_table .webix_ss_header td {
  border-right-color: var(--is-border);
  border-bottom-color: var(--is-border);
}
.webix_ssheet_table .webix_hcell {
  border: 2px solid transparent;
}
.webix_ssheet_table .webix_ssheet_hide_column {
  border-right-color: var(--is-text);
}
.webix_ssheet_table .webix_ssheet_hide_row {
  border-bottom-color: var(--is-text);
}
.webix_ssheet_table div.webix_ss_vscroll_header {
  border-color: var(--is-border);
}
.webix_ssheet_table .webix_ss_header tr:last-child td {
  border-bottom: 1px solid var(--is-border);
}
.webix_ssheet_suggest .webix_ssheet_options .webix_selected {
  background-color: transparent;
  color: var(--is-default-dark);
  border-bottom: none;
}
.webix_ssheet_table .webix_cell {
  color: var(--is-default-dark);
}
.webix_cell.ss_filter:after,
.webix_top_cell.ss_filter:after {
  color: var(--is-text-muted);
}
.webix_cell.ss_editor:after,
.webix_top_cell.ss_editor:after {
  color: var(--is-default-dark);
}
.webix_cell.ss_filter_active:after,
.webix_top_cell.ss_filter_active:after {
  color: var(--is-primary);
}
.webix_ssheet_counter .webix_inp_counter_next,
.webix_ssheet_counter .webix_inp_counter_prev {
  background: none;
  color: var(--is-primary);
}
.webix_ssheet_counter .webix_inp_counter_next:hover,
.webix_ssheet_counter .webix_inp_counter_prev:hover {
  background: var(--is-component-bg-muted);
}
.webix_ssheet_counter .webix_inp_counter_value {
  border-top-color: transparent;
  background-color: transparent;
}
.webix_el_counter input[type=text]:focus {
  border-color: transparent;
  border-bottom-color: var(--is-primary);
}
a.docs {
  color: var(--is-primary);
}
.webix_ssheet_table .webix_hs_left .webix_first .webix_hcell:before {
  border-top-color: transparent;
  border-right-color: var(--is-primary-dark);
}


/* KANBAN */


.webix_kanban {
  background-color: var(--is-theme-bg);
}
.webix_kanban .webix_accordionitem.collapsed {
  border-color: var(--is-border);
}
.webix_kanban .webix_accordionitem.collapsed .webix_accordionitem_header {
  border-color: transparent;
}
.webix_kanban .webix_accordionitem_header {
  border-left: 1px solid var(--is-border);
  border-right: 1px solid var(--is-border);
  background-color: var(--is-component-bg);
}
.webix_kanban_list {
  border-color: var(--is-border);
}
.webix_kanban .webix_accordionitem {
  border-left-color: var(--is-theme-bg-muted);
  border-right-color: var(--is-theme-bg-muted);
  border-bottom-color: var(--is-theme-bg-muted);
  background: var(--is-theme-bg-muted);
}
.webix_kanban .webix_accordionitem_body {
  background-color: var(--is-theme-bg-muted-strong);
}
/*List template*/
.webix_kanban_list {
  background-color: var(--is-theme-bg-muted);
}
.webix_dataview_item.webix_kanban_list_item,
.webix_dataview_item.webix_kanban_list_item.webix_selected {
  background-color: var(--is-theme-bg-muted);
}
.webix_kanban_list_item,
.webix_kanban_list_item.webix_list_item.webix_selected {
  background-color: var(--is-theme-bg-muted);
}
.webix_kanban_list_item.webix_drag_over .webix_kanban_list_content {
  border-color: var(--is-primary);
  border-left-color: var(--is-primary);
}
.webix_kanban_list_content {
  background-color: var(--is-component-bg);;
  color: var(--is-text);
  border: 1px solid var(--is-border);
  border-left: 3px solid var(--is-primary);
}
.webix_kanban_list_item.webix_selected .webix_kanban_list_content {
  background: var(--is-component-bg-muted);
  color: var(--is-text);
}
.webix_kanban_list_item .webix_kanban_footer .webix_icon {
  color: var(--is-text-muted);
}
.webix_kanban_list_item .webix_kanban_footer_icon:hover {
  color: var(--is-primary);
}
.webix_kanban_list_item .webix_kanban_footer_icon:hover .webix_icon {
  color: var(--is-primary);
}
.webix_kanban_list_item .webix_kanban_tag {
  background: var(--is-theme-bg-muted);
}
.webix_kanban_user_avatar {
  background: var(--is-theme-bg-muted);
  color: var(--is-text-muted);
}
.webix_kanban_user_avatar:hover {
  box-shadow: var(--is-primary) 0 0 2px 1px;
}
.webix_kanban_drop_inner {
  border: 1px dashed var(--is-primary);
}
.webix_kanban_list .webix_drop_area {
  background-color: transparent;
}
.webix_list_item .webix_kanban_list_avatar {
  background: var(--is-theme-bg-muted);
  color: var(--is-text-muted);
}
/*Uploader*/
.webix_kanban_uploader .webix_button.webix_img_btn {
  color: var(--is-text);
}
.webix_kanban_uploader .webix_button.webix_img_btn:hover {
  background: transparent;
  color: var(--is-primary);
}
.webix_kanban_uploader .webix_button.webix_img_btn:hover .webix_kanban_icon {
  color: var(--is-primary);
}
.webix_kanban_uploader .webix_button.webix_img_btn:active {
  background: transparent;
}
.webix_kanban_uploader .webix_button.webix_img_btn:focus {
  background: transparent;
}
.webix_kanban_uploader_progress_server {
  color: var(--is-success);
}
.webix_kanban_uploader_progress_error {
  color: var(--is-warning);
}
.webix_kanban_uploader_title {
  color: var(--is-default-light-text);
  background-color: var(--is-default-light);
}
.webix_kanban_uploader_body {
  background-color: var(--is-theme-bg-muted);
}
.webix_kanban_uploader_body .webix_icon {
  color: var(--is-text-muted);
}
.webix_kanban_remove_upload {
  color: var(--is-default-light-text);
  background-color: var(--is-default-light);
}
.webix_kanban_remove_upload:hover {
  color: var(--is-accent);
}
.webix_kanban_editor .webix_win_head {
  border-color: transparent;
}
.webix_kanban_colorpicker .webix_list_item.webix_selected {
  background-color: var(--is-component-bg-muted);
  color: var(--is-text);
}
.webix_kanban_dataview_uploader .webix_overlay {
  border: 2px dashed var(--is-primary);
}
/*Header*/
.webix_kanban_header {
  background: var(--is-theme-bg-muted);
  color: var(--is-text);
}
.webix_kanban_sub_header {
  background: var(--is-default-light);
  color: var(--is-default-light-text);
}
.webix_kanban_add_icon.webix_icon:hover {
  color: var(--is-primary);
}
.webix_kanban_header .webix_icon,
.webix_kanban_sub_header .webix_icon {
  color: var(--is-text-muted);
}
.webix_kanban_sub_header .webix_icon {
  color: var(--is-default-light-text);
}
