.side-menu-top {
  right: 0;
  display: flex;
  height: 100%;
}

.side-menu-top .ant-menu {
  position: relative !important;
  right: 0;
  overflow: hidden;
}

.side-menu-top .ant-menu-inline-collapsed {
  width: 0;
}

 .root .containerLogo{
    background: #0C1F44;
}

 .root .containerLogo:after{
    border-left: 20px solid #0C1F44;
}

.dark .content_img img,.light .dashboard-icon{
  filter: brightness(0) invert(1);
}
