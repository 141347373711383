:root {
    --middlecardheaderBg :  linear-gradient(180deg, #e6e9f4 10%, rgba(0, 0, 0, 0) 2%), radial-gradient(farthest-corner at 100% 40%, #f0eff6 80%, #fbf6f8 70%),linear-gradient(-5deg,#f3f7fc 15%, rgba(0, 0, 0, 0) 2%);
    --arrowsCarousel : white;
    /* --arrowSCarouselBackground : linear-gradient(145deg, #f0f0f0, #cacaca); */
    --arrowSCarouselBackground : #494949;
    --arrowSCarouselDisabled : #bbb;
    --theme-font-color : black;
    --cardBg: white;
    --tutorialCardContentBg: white;
    --cardContentBg:#f1f7f9;
    --cardContent: #434B4F;
}



[data-theme="dark"] {
    --middlecardheaderBg : linear-gradient(180deg, #353335 10%, rgba(0, 0, 0, 0) 2%), radial-gradient(farthest-corner at 100% 40%, #37312e 80%, #2c2e31 70%),linear-gradient(-5deg,#f3f7fc 15%, rgba(0, 0, 0, 0) 2%);
    --theme-font-color : white;
    --arrowsCarousel : black;
    /* --arrowSCarouselBackground : linear-gradient(145deg, #f0f0f0, #cacaca); */
    --arrowSCarouselBackground : #fefcfc;
    --arrowSCarouselDisabled : #655851;
    --cardBg : #2e2c2a;
    --tutorialCardContentBg:#333231;
    --cardContentBg:#333231;
    --cardContent:#E1E1E1;
}

.theme-font-color{
    color: var(--theme-font-color) !important;
}

.middleCards-childCard .ant-tag.ant-tag-checkable{
    border-color : var(--theme-font-color) !important;
    color: var(--theme-font-color) !important;
}
.middleCards-childCard .ant-tag.ant-tag-checkable.ant-tag-checkable-checked{
    color: white !important;
    border-color: #1262D0 !important;
    background-color: #1262D0 !important;
}
.middleCards-childCard{
    background: var(--cardBg) !important;
}
.arrowButtonsCarousel{
    color: var(--arrowsCarousel);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--arrowSCarouselBackground);
    height: 22px;
    width: 22px;
    font-size: small;
}

.arrowButtonsCarousel.disabled{
   background: var(--arrowSCarouselDisabled) !important;
   cursor: default !important;
}
.anticon-right{
    margin-left: 6px;
}
.slick-dots{
    display: none !important;
}


.middleCard_mainContent_content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.7rem;
}

.middleCard_mainContent{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* margin-bottom: 0.7rem; */
    margin:1rem;
    /* -webkit-box-shadow: -20px 13px 23px -19px rgb(0 0 0 / 52%);
    -moz-box-shadow: -20px 13px 23px -19px rgba(0,0,0,0.52);
    box-shadow: -20px 13px 23px -19px rgb(0 0 0 / 52%); */
}

.middleCards-childCard{
    height: 55rem;
    border-radius: var(--is-su-2);
    box-shadow: 0px 2px 12px -4px rgb(0 0 0 / 52%);
}
.firstTileCard img{
    border-radius: var(--is-su-6);
    padding: var(--is-su-5);
}
.firstTileCard{
    box-shadow: none;
}
.middleCard_mainContent_content{
    padding: 0 var(--is-hu-5);
    position: relative;
    bottom: 70px;
}
.middleCard_header{
    /* font-size: 0.8rem; */
    font-size: var(--is-font-size-sm);
}
.middleCard_subheader{
    /* font-size: 1.5rem;
    font-weight: 600; */
    font-size:var(--is-font-size-md);
    font-weight:var(--is-font-weight-bold)
}
.middleCard_Actions button{
    color: var(--cardContent);
    background-color: transparent;
    border-color: var(--cardContent);
    border-radius: var(--is-su-6);
    font-size: 1.2rem;
    /* margin: 0 1rem 0 0; */
}
.middleCard_mainContent_content > div{
    background: var(--tutorialCardContentBg);
    color: var(--cardContent);
}
.cards_model_popup .ant-btn-round{
    color: var(--is-secondary-text);
    background-color: var(--is-color-blue);
    border-color: var(--is-color-blue);
    border-radius: var(--is-su-6);
    margin-top: 3rem;
}
.middleCard_Actions button span{
    margin-left: unset !important;
}
.secondTileCard button span, .collapse-content button span{
    margin-left: unset !important;
}
.secondTileCard button,.collapse-content button{
    color: var(--cardContent);
    background-color: transparent;
    border-color: var(--cardContent);
    border-radius: var(--is-su-6);
    margin-top: 0.6rem;
}
.secondTileCard button, .collapse-content button, .middleCard_Actions button{
    height: 2.5rem;
    font-size: var(--is-font-size-sm);
}
.faqCollapse button{
    color: var(--cardContent);
    background-color: transparent;
    border-color: var(--cardContent);
    border-radius: var(--is-su-6);
}
.middleCard_Actions{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 3rem 0;
}

.secondTileCard{
    display: flex;
    width: 98% !important;
    height: 13rem;
    background-color: #f1f7f9 !important;
}
.collapse-content{
    font-size: var(--is-font-size-sm);
    padding-right: var(--is-su-5) !important;
    padding-left: var(--is-su-5) !important;
}

.secondTileCard .ant-card-body{
    padding: 1.2rem !important;
}
.secondTileCard .ant-card-cover{
    /* flex: 0.4; */
    contain: content;
    border: 0px solid #1C6EA4;
    border-radius: 3px 3px 38px 3px
}
.secondTileCard .ant-card-body{
    flex: 1;
}

.faqCollapse{
    font-size: 1.2rem;
}
.ant-card-meta-description{
    font-size: 0.8rem;
}
.ant-card-meta-title{
    font-size: 1rem;
    font-weight: 600;
}
.cards_model_popup .ant-modal-content{
    height: inherit;
}
.ant-modal-close-icon{
    color: var(--arrowsCarousel) !important;
    background: var(--arrowSCarouselBackground) !important;
    box-shadow: none !important;
    font-size: 10px !important;
}
.middleCards-childCard .ant-card-head{
    border-bottom: unset;
    background : var(--middlecardheaderBg);
    border-bottom-right-radius: initial;
    border-bottom-left-radius: initial;
}
.secondTileCard{
    box-shadow: 0px 2px 12px -4px rgb(0 0 0 / 52%);
    -webkit-box-shadow:0px 2px 12px -4px rgb(0 0 0 / 52%);
    -moz-box-shadow:0px 2px 12px -4px rgb(0 0 0 / 52%);
}
.secondTileCard .ant-card-body,.secondTileCard{
    background: var(--cardContentBg) !important;
}
.secondTileCard .ant-card-cover img{
    height: 13rem;
}
.secondTileCard .ant-card-meta-title, .secondTileCard .ant-card-meta-description{
    color: var(--cardContent);
}
.ant-tag.ant-tag-checkable{
    color: var(--is-secondary-darkest);
    background-color: transparent;
    border-color: var(--is-secondary-darkest);
    border-radius: var(--is-su-6);
    padding: 0.4rem;
}

.faqCollapse .ant-collapse-item.site-collapse-custom-panel {
    margin: 0.3rem 1rem;
    background: var(--cardContentBg) !important;
}
.faqCollapse{
    border:none !important;
    padding: 1rem 0;
}
.faqCollapse .ant-collapse-header{
    color: var(--cardContent) !important;
}
.ant-collapse-item.site-collapse-custom-panel .anticon.anticon-up.ant-collapse-arrow{
    background: var(--is-secondary);
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 15px;
    font-size: 10px;
}
.ant-collapse-item-active.site-collapse-custom-panel .anticon.anticon-up.ant-collapse-arrow{
    background: var(--is-primary);
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 15px;
    font-size: 10px;
}
.ant-modal-body canvas{
    height: 630px !important;
}
.ant-modal-body nav .pager{
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end;
    list-style-type: none;
    column-gap: 5px;
}
.ant-modal-body nav .pager > .previous button{
    border-radius: 50%;
    background-color: #494949;
    color: white;
}
.ant-modal-body nav .pager > .next button{
    border-radius: 50%;
    background-color: #494949;
    color: white;
}
.ant-modal-body nav .pager > button{
    background-color: var(--is-primary);
    border-color: var(--is-primary);
    border-radius: var(--is-su-6);
}
.ant-modal-body nav .pager button a{
    color: var(--is-default-text);
}
.slick-prev:before, .slick-next:before {
    color: var(--arrowSCarouselBackground) !important;
    opacity: unset !important;
    font-size: 22px !important;
}
.ant-card-meta-title{
    white-space: normal !important;
}
.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before{
    color: var(--arrowSCarouselDisabled) !important;
}

.faqCollapse .ant-collapse-content{
    color: var(--cardContent) !important;
}

.show-all-middle-cards{
    cursor: pointer;
}